import { useUser } from 'hooks'
import { Check } from 'lucide-react'
import { useLazyGetUserSignatureQuery } from 'modules/profile/profile/user-profile-api'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'

const UseProfileImage = ({ onChange, name, setSignatureId }) => {
	const { user } = useUser()
	const [fetchSignature, { data, isFetching }] = useLazyGetUserSignatureQuery()
	const signature = useMemo(() => data?.signature, [data?.signature])
	const [showSuccess, setShowSuccess] = useState(false)
	useEffect(() => {
		if (user?.id) {
			fetchSignature(user?.id)
		}
	}, [user?.id])

	const onSetSignature = async (url) => {
		const $url = new URL(url)
		onChange(name, $url)
		setShowSuccess(true)
		if (setSignatureId) {
			setSignatureId(signature?.id)
		}
		setTimeout(() => setShowSuccess(false), 2000)
	}

	return (
		<div>
			{isFetching ? (
				<Spinner />
			) : signature ? (
				<div className='flex flex-col space-y-3'>
					<div className='flex h-[165px] max-h-[153px] items-center justify-center space-x-6 rounded-lg !border border-dashed border-main p-6 text-center sm:min-w-[400px]'>
						<img src={signature?.path} className='block h-full w-auto' />
					</div>
					<div className='mt-2 flex justify-center space-x-3'>
						{/* <Button type='button' onClick={onRemove} variant='danger-outline' size='sm'>
							Clear
						</Button> */}
						<Button
							type='button'
							onClick={() => onSetSignature(signature?.path)}
							variant='primary-outline'
							size='sm'>
							<span className='flex'>
								Use{' '}
								{showSuccess && (
									<Check className='ms-2 h-6 w-6 text-green-500 animate-pulse' />
								)}
							</span>
						</Button>
					</div>
				</div>
			) : (
				<p>Upload signature in profile</p>
			)}
		</div>
	)
}

export default UseProfileImage
