import { useAppRouter } from 'hooks'
import { useLazyGetFormResponseQuery } from 'modules/documents/document-api'
import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import Spinner from 'theme/ui/spinner'
import DateResponse from './date-response'
import NPSGauge from './nps-response'
import RadioResponse from './radio-response'
import RatingResponse from './rating-response'
import ResponsePopup from './response-popup'
import SelectResponse from './select-response'
import SignatureResponse from './signature-response'

const FormResponse = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [formData, setFormData] = useState([])
	const transformFormData = (data) => {
		const responsesMap = new Map()

		data.forEach((item) => {
			switch (item.type) {
				case 'text':
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							name: item.name,
							label: item.label,
							type: 'text',
							responses: [],
						})
					}
					responsesMap.get(item.name).responses.push(item.value)
					break

				case 'signature':
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							name: item.name,
							label: item.label,
							type: 'signature',
							responses: [],
						})
					}
					responsesMap.get(item.name).responses.push(item.value)
					break

				case 'radio': {
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							name: item.name,
							label: item.label,
							type: 'radio',
							options: new Map(),
						})
					}

					const optionMap = responsesMap.get(item.name).options

					if (Array.isArray(item.value)) {
						// Multiple selection case
						item.value.forEach((selectedValue) => {
							const selectedOption = item.values.find(
								(v) => v.value === selectedValue,
							)
							if (selectedOption) {
								optionMap.set(
									selectedOption.label,
									(optionMap.get(selectedOption.label) || 0) + 1,
								)
							}
						})
					} else {
						// Single selection case
						const selectedOption = item.values.find((v) => v.value === item.value)
						if (selectedOption) {
							optionMap.set(
								selectedOption.label,
								(optionMap.get(selectedOption.label) || 0) + 1,
							)
						}
					}
					break
				}

				case 'select': {
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							name: item.name,
							label: item.label,
							type: 'select',
							options: new Map(),
							multiple: item.multiple,
							responses: [],
						})
					}

					const selectData = responsesMap.get(item.name)

					if (item.multiple) {
						if (Array.isArray(item.value)) {
							item.value.forEach((selectedOption) => {
								selectData.responses.push(selectedOption.label)
							})
						}
					} else {
						if (item.value && typeof item.value === 'object' && item.value.label) {
							const label = item.value.label
							selectData.options.set(label, (selectData.options.get(label) || 0) + 1)
						}
					}
					break
				}

				case 'netpromoter': {
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							name: item.name,
							label: item.label,
							type: 'netpromoter',
							promoters: 0,
							passives: 0,
							detractors: 0,
							totalResponses: 0,
							npsScore: 0,
							value: [],
						})
					}

					const scoreData = responsesMap.get(item.name)
					const scoreValue = parseInt(item.value, 10)
					scoreData.value.push(item.value)
					if (!isNaN(scoreValue) && scoreValue >= 0 && scoreValue <= 10) {
						scoreData.totalResponses += 1

						if (scoreValue >= 9) {
							scoreData.promoters += 1
						} else if (scoreValue >= 7) {
							scoreData.passives += 1
						} else {
							scoreData.detractors += 1
						}
					}
					break
				}
				case 'rating': {
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							label: item.label,
							name: item.name,
							type: 'rating',
							total: 0,
							count: 0,
							ratings: [
								{ level: '5', count: 0 },
								{ level: '4', count: 0 },
								{ level: '3', count: 0 },
								{ level: '2', count: 0 },
								{ level: '1', count: 0 },
							],
						})
					}

					const starRatingData = responsesMap.get(item.name)
					const starValue = parseInt(item.value, 10)

					if (!isNaN(starValue) && starValue >= 1 && starValue <= 5) {
						starRatingData.total += starValue
						starRatingData.count += 1

						const ratingIndex = 5 - starValue
						starRatingData.ratings[ratingIndex].count += 1
					}

					starRatingData.average =
						starRatingData.count > 0 ? starRatingData.total / starRatingData.count : 0

					break
				}
				case 'date':
					if (!responsesMap.has(item.name)) {
						responsesMap.set(item.name, {
							name: item.name,
							label: item.label,
							type: 'date',
							responses: [],
						})
					}
					responsesMap.get(item.name).responses.push(item.value)
					break

				default:
					console.warn(`Unhandled question type: ${item.type}`)
			}
		})

		return Array.from(responsesMap.values()).map((response) => {
			if (response.type === 'radio') {
				response.options = Array.from(response.options.entries()).map(([label, count]) => ({
					label,
					count,
				}))
			} else if (response.type === 'rating') {
				response.average = response.count > 0 ? response.total / response.count : 0
				delete response.total
				delete response.count
			} else if (response.type === 'netpromoter' && response.totalResponses > 0) {
				response.npsScore = Math.round(
					((response.promoters - response.detractors) / response.totalResponses) * 100,
				)
			} else if (response.type === 'select' && !response.multiple) {
				if (response.options instanceof Map) {
					response.options = Array.from(response.options.entries()).map(
						([label, count]) => ({
							label,
							count,
						}),
					)
				} else if (typeof response.options === 'object' && response.options !== null) {
					response.options = Object.entries(response.options).map(([label, count]) => ({
						label,
						count,
					}))
				} else {
					response.options = []
				}
			}

			return response
		})
	}
	const { params } = useAppRouter()

	const [responseData, setResponseData] = useState(null)
	const [questionCount, setQuestionCount] = useState(null)
	const [fetchPublicForm, { data, isFetching }] = useLazyGetFormResponseQuery()
	useEffect(() => {
		if (params?.id) {
			fetchPublicForm(params?.id)
		}
	}, [params?.id])

	useEffect(() => {
		if (data?.status === 200 && Array.isArray(data?.data)) {
			const fields = data.data.flatMap((field) => field.form_response || [])
			const responses = transformFormData(fields)
			setFormData(responses)
		}
	}, [data])

	const openResponsePopup = (q, ind) => {
		setIsOpen(true)
		setResponseData(q)
		setQuestionCount(ind)
	}

	const onClose = () => {
		setIsOpen(false)
		setResponseData(null)
	}

	return (
		<>
			{responseData && (
				<ResponsePopup
					isOpen={isOpen}
					onClose={onClose}
					responseData={responseData}
					questionCount={questionCount}
				/>
			)}
			{isFetching ? (
				<Spinner />
			) : formData?.length > 0 ? (
				<div className='space-y-4'>
					<h3 className='mb-2 text-lg font-bold'>{data?.data?.[0]?.title}</h3>
					<p className='text-gray-600'>See the summary of form submissions</p>
					{formData.map((q, index) => (
						<Card key={index} className='p-4 shadow-md rounded-lg'>
							<div className='flex justify-between items-center'>
								<h4 className='text-md font-semibold mb-2'>
									{index + 1}. {q.label}
								</h4>
								<p
									className='text-blue-600 underline cursor-pointer hover:text-blue-800'
									onClick={() => openResponsePopup(q, index + 1)}>
									More details
								</p>
							</div>

							{/* Text Responses */}
							{q.type === 'text' && (
								<div className='flex justify-between w-full'>
									<div className='flex flex-col w-1/2 justify-center items-center'>
										<p className='text-xl font-bold'>{q.responses.length}</p>
										<p className='text-gray-500'>Responses</p>
									</div>
									<div className='flex flex-col w-1/2 justify-center items-center'>
										{q.responses.slice(0, 3).map((resp, i) => (
											<p key={i} className='text-gray-600 '>
												&quot;{resp}&quot;
											</p>
										))}
										{q.responses.length > 3 && (
											<p className='text-gray-600 font-bold text-[20px] m-0 p-0'>
												...
											</p>
										)}
									</div>
								</div>
							)}

							{/* Radio Responses */}
							{q.type === 'radio' && <RadioResponse q={q} />}

							{/* Select Responses */}
							{q.type === 'select' && <SelectResponse q={q} />}

							{/* Rating Responses */}
							{q.type === 'rating' && <RatingResponse q={q} />}

							{/* Net Promoter Score Responses */}
							{q.type === 'netpromoter' && <NPSGauge q={q} />}

							{/* Date Responses */}
							{q.type === 'date' && <DateResponse q={q} />}

							{/* Signature Responses */}
							{q.type === 'signature' && <SignatureResponse q={q} />}
						</Card>
					))}
				</div>
			) : (
				<p className='mt-[10px] text-center text-gray-600'>No response received yet</p>
			)}
		</>
	)
}

export default FormResponse
