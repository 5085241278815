import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as SendEmailIcon } from 'assets/svg/send-email-icon.svg'
import { Fragment } from 'react'
import SendEmailForm from './send-email-form'

const SendEmailPopup = ({ isOpen, onClose, selectedRows }) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full max-w-3xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white p-3 '>
										<SendEmailIcon height={30} width={30} />
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-7 flex flex-col p-3'>
									<div className='flex flex-col justify-start'>
										<h2 className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
											Send Email
										</h2>
									</div>
									<SendEmailForm onClose={onClose} selectedRows={selectedRows} />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SendEmailPopup
