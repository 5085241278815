import { Fragment, useMemo, useState } from 'react'

import { ReactComponent as RefreshIcon } from 'assets/svg/refresh-icon.svg'
import { SoftLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import { useUser } from 'hooks'
import WorkflowSignatureStatus from 'pages/Authentication/profile/components/workflow/WorkflowSignatureStatus'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import { useGetWorkflowDocumentsQuery } from '../assigned-workflow-apis'
import { DocumentList } from './documents-tab'

const AssignedWorkflowSignature = ({ workflow, isAvailableForSignature, signature_status }) => {
	const workflow_id = workflow?.id
	const [isSaving, setIsSaving] = useState(false)
	const { group } = useUser()

	const URL = useMemo(
		() => `${apiEndpoints.client.workflowDetails}/${workflow_id}/documents`,
		[workflow_id],
	)
	const { data, refetch, isUninitialized, isFetching } = useGetWorkflowDocumentsQuery(URL)
	const documents = useMemo(
		() => (Array.isArray(data?.documents) ? data?.documents : []),
		[data?.documents],
	)

	// const onSavePdf = async () => {
	// 	try {
	// 		setIsSaving(true)
	// 		const { documentViewer, annotationManager } = documentInstance.Core
	// 		const fieldManager = annotationManager.getFieldManager()

	// 		if (fieldManager.areRequiredFieldsFilled()) {
	// 			fieldManager.forEachField((field) => {
	// 				if (field.name === 'signature') {
	// 					field.children.forEach(async (_field) => {
	// 						_field.widgets.map((annot) => annot.fieldFlags.set('Required', false))
	// 						_field.widgets.map((annot) => annot.fieldFlags.set('ReadOnly', false))
	// 					})
	// 				}
	// 			})
	// 			const activeTab = documentInstance.UI.TabManager.getActiveTab()?.id
	// 			const document_step_id =
	// 				documentInstance.UI.TabManager.getActiveTab()?.options?.document_id

	// 			const doc = documentViewer.getDocument()
	// 			const xfdfString = await annotationManager.exportAnnotations()
	// 			const data = await doc.getFileData({ xfdfString })
	// 			const arr = new Uint8Array(data)
	// 			const blob = new Blob([arr], { type: 'application/pdf' })

	// 			await saveDocument(blob, document_step_id, workflow_id)
	// 			await removeTab(documentInstance, activeTab)
	// 			await getAllTabs(documentInstance)
	// 			// mutateWorkflow()
	// 			refetch()
	// 		} else {
	// 			notification('warn', 'Please insert your signatures')
	// 		}

	// 		setIsSaving(false)
	// 	} catch (error) {
	// 		setIsSaving(false)
	// 		notification('warning', 'Something went wrong while saving the document')
	// 	}
	// }

	return (
		<div>
			{isAvailableForSignature ? (
				<Fragment>
					{isSaving && <SoftLoader />}
					{isUninitialized || isFetching ? (
						<Spinner />
					) : (
						<Fragment>
							{documents.length > 0 ? (
								<Fragment>
									<div className='flex justify-between'>
										<div className='max-w-[80%]'>
											<h5>Ready for Signatures</h5>
											<p className='text-sm text-gray-500'>
												You and the parties that need to sign documents have
												been notified. If there are third parties on your
												documents, they will be provided a secure link to
												come sign these documents themselves
											</p>
										</div>
										{group?.slug !== 'client' ? (
											<Button
												type='button'
												variant='white'
												size='sm'
												onClick={() => refetch()}
												isLoading={isFetching}
												isLoadingText='Refreshing...'>
												<div className='flex items-center space-x-2'>
													<span>
														<RefreshIcon
															height={15}
															stroke='#5b73e8'
															color='#5b73e8'
														/>
													</span>
													<span className='text-gray-600'>Refresh</span>
												</div>
											</Button>
										) : undefined}
									</div>
									<h6>Click the Signature boxes to insert your Signature</h6>

									{documents.length > 0 && (
										<DocumentList
											documentList={documents}
											workflow_id={workflow_id}
											workflow={workflow}
											history={history}
											mutate={refetch}
											isSaving={isSaving}
											setIsSaving={setIsSaving}
										/>
									)}
								</Fragment>
							) : (
								<div className='prose mx-auto my-10 flex flex-col text-center'>
									<h3>No documents have been found.</h3>
									<p className='text-gray-500'>
										There are no documents currently in the workflow, or perhaps
										you have already signed all of them.
									</p>

									<Link
										to='/assigned-workflows'
										className='btn btn-primary btn-sm mx-auto max-w-fit text-sm'>
										Go back to the Workflows
									</Link>
								</div>
							)}
						</Fragment>
					)}
				</Fragment>
			) : (
				<div className='mx-auto max-w-md'>
					<WorkflowSignatureStatus message={signature_status} />
				</div>
			)}
		</div>
	)
}

export default AssignedWorkflowSignature
