import ColumnMapping from './column-mapping'

export const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_TASKS':
			return { ...state, [action.column]: action.tasks }
		case 'MOVE_TASK': {
			const { from, to, task, index } = action
			const updatedFrom = state[from].filter((_, i) => i !== index)
			const updatedTo = [...state[to], task]
			return { ...state, [from]: updatedFrom, [to]: updatedTo }
		}
		case 'ADD_COLUMN':
			return { ...state, [action.title]: [] }
		default:
			return state
	}
}

export default ColumnMapping
