import { useDroppable } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { snakeCase } from 'lodash'
import { Check, Ellipsis, GripVertical, Plus, X } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import Badge from 'theme/ui/badge'
import { Button, InputField } from 'theme/ui/forms'
import * as yup from 'yup'
import TaskCard from '../../task-card'

const columnUpdateValidationSchema = yup.object().shape({
	title: yup
		.string()
		.nonNullable()
		.max(30, 'Column name must be at most 30 characters')
		.required('Column name is required'),
})

const Column = ({
	column,
	tasks,
	accepts,
	handleDeleteColumn,
	handleUpdateColumn,
	isEditing,
	setEditingColumnId,
	onOpenCanvas,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isHovered, setIsHovered] = useState(false)
	const dropdownRef = useRef(null)
	// const columnId = snakeCase(column?.id)
	const {
		attributes,
		listeners,
		setNodeRef: setSortableRef,
		transform,
		transition,
	} = useSortable({
		id: column.id,
		data: {
			type: 'column',
		},
	})

	const { setNodeRef: setDroppableRef } = useDroppable({
		id: column?.id,
		data: {
			type: snakeCase(column?.name),
			accepts: accepts,
			status: column?.name,
		},
	})

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	const $tasks = Array.isArray(tasks) ? tasks : []

	const handleDropdownToggle = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setIsOpen((prev) => !prev)
	}

	const handleDropdownItemClick = (e, action) => {
		e.preventDefault()
		e.stopPropagation()
		action()
		setIsOpen(false)
	}

	const handleTitleClick = (e) => {
		e.stopPropagation()
		setEditingColumnId(column.id)
	}

	const handleCreateTaskClick = (e) => {
		e.stopPropagation()
		onOpenCanvas(null, column?.statuses[0]?.id)
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false)
			}
		}

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isOpen])

	const setNodeRef = (element) => {
		setSortableRef(element)
		setDroppableRef(element)
	}

	return (
		<div
			ref={setNodeRef}
			style={style}
			className='min-w-[270px] min-h-[88%] !border rounded-md'
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			<div className='bg-white rounded-t-md border-b px-1'>
				{isEditing ? (
					<div className='py-[3px]'>
						<Formik
							initialValues={{ title: column?.name || '' }}
							validationSchema={columnUpdateValidationSchema}
							onSubmit={(values, { setSubmitting }) => {
								if (values.title.trim() && handleUpdateColumn) {
									handleUpdateColumn(column.id, values.title)
								}
								setSubmitting(false)
								setEditingColumnId(null)
							}}>
							{({ isSubmitting }) => (
								<Form className='relative'>
									<Field
										type='text'
										name='title'
										className='form-control'
										autoFocus
										component={InputField}
										// onClick={(e) => e.stopPropagation()}
									/>
									<div className='absolute z-[9] right-0 top-full mt-1 flex gap-1'>
										<Button
											size='xs'
											variant='primary'
											type='submit'
											disabled={isSubmitting}
											className='p-1'>
											<Check size={14} />
										</Button>
										<Button
											size='xs'
											variant='white'
											type='button'
											onClick={(e) => {
												e.stopPropagation()
												setEditingColumnId(null)
											}}
											className='p-1'>
											<X size={14} />
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				) : (
					<div className='flex justify-between py-[6px]'>
						<div className='flex space-x-2 relative'>
							<span
								className='cursor-move p-1 text-gray-500 hover:text-gray-700'
								{...attributes}
								{...listeners}
								title='Drag to reorder'>
								<GripVertical size={16} />
							</span>
							{/* <ToolTip targetId={columnId} tooltipText={column?.name} /> */}
							<h2
								// id={columnId}
								className='m-0 line-clamp-1 overflow-hidden leading-[inherit] text-xs font-medium uppercase cursor-pointer hover:bg-gray-100 p-1 rounded'
								title={column?.name}
								onClick={handleTitleClick}>
								{column?.name}
							</h2>
							<Badge variant='primary-outline'>{column?.cards?.length}</Badge>
						</div>

						<div className='relative' ref={dropdownRef}>
							<Button
								size='sm'
								variant={classNames(isOpen ? 'white' : 'ghost')}
								onClick={handleDropdownToggle}>
								<Ellipsis size={16} />
							</Button>
							{isOpen && (
								<div
									className='absolute right-0 mt-2 max-w-fit px-0 py-2 shadow-md bg-white border rounded z-10'
									onClick={(e) => e.stopPropagation()}>
									<ul className='m-0 p-0 min-w-[120px]'>
										<li
											className={classNames(
												'exp-dropdown-item px-3 py-1 hover:bg-gray-100 cursor-pointer',
											)}
											onClick={(e) =>
												handleDropdownItemClick(e, () =>
													handleDeleteColumn(column?.id),
												)
											}>
											<span>Delete</span>
										</li>
									</ul>
								</div>
							)}
						</div>
					</div>
				)}
			</div>

			<div className='flex h-auto flex-col space-y-1 min-h-[91%] rounded bg-gray-300/20 p-1'>
				{$tasks.map((task, idx) => (
					<TaskCard key={idx} index={idx} task={task} onOpenCanvas={onOpenCanvas} />
				))}
				<Button
					type='button'
					variant='ghost'
					size='sm'
					block
					onClick={handleCreateTaskClick}
					className={isHovered ? 'block' : 'hidden'}>
					<div className='flex items-center gap-1'>
						<Plus size={14} />
						<span>Create task</span>
					</div>
				</Button>
			</div>
		</div>
	)
}

export default Column
