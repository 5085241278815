import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import {
	useDeleteDocumentTemplateMutation,
	useLazyDocumentDeleteEligibleQuery,
	useMarkDocumentAsStarMutation,
} from 'modules/documents/document-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import { useDispatch } from 'react-redux'
import * as actions from 'store/file-manager/actions'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import Spinner from 'theme/ui/spinner'
import { useCopyToClipboard } from 'usehooks-ts'
import notification from 'utilities/notification'
import AddToFolderPopup from '../add-to-folder-popup'

const OptionsList = ({ document, onRenameOpen, isCheckingRename }) => {
	const [markStarred, { isLoading: isMarking }] = useMarkDocumentAsStarMutation()
	const [documentDeleteEligible, { isLoading }] = useLazyDocumentDeleteEligibleQuery()
	const [deleteDocumentTemplate, { isLoading: isDeleting }] = useDeleteDocumentTemplateMutation()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const dispatch = useDispatch()
	const { navigate } = useAppRouter()
	const [_value, copy] = useCopyToClipboard()
	const [showSuccess, setShowSuccess] = useState(false)

	const onMarkStarred = async (id) => {
		try {
			const payload = { document_id: id }
			const response = await markStarred(payload).unwrap()
			if (response?.status === 200) {
				notification('success', response?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	const checkDocumentDeleteEligibility = async (id) => {
		const response = await documentDeleteEligible(id)
		if (response?.data?.status === 200 && response?.data?.data) {
			setIsDeleteOpen(true)
		}
	}

	const onDelete = async (id) => {
		try {
			const response = await deleteDocumentTemplate(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			setIsDeleteOpen(false)
		} catch (error) {
			setIsDeleteOpen(false)
			notification('error', error?.message)
		}
	}

	const attachInWorkflow = (data, type) => {
		const item = {
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}
		if (type === 'folder') {
			dispatch(actions.setSelectedFolders([item]))
			navigate(`/workflows/create?folder=true`)
		} else {
			dispatch(actions.setSelectedDocuments([item]))
			navigate(`/workflows/create?document=true`)
		}
	}

	const onSetSignature = () => {
		setShowSuccess(true)
		setTimeout(() => setShowSuccess(false), 1000)
	}
	const copyPublicLink = (uelId) => {
		const link = `${window.location.origin}/f/${uelId}`
		copy(link)
		onSetSignature()
	}
	const viewResponse = () => {
		navigate(`/form-response/${document?.id}`)
	}
	return (
		<Fragment>
			<DeleteConfirmAlert
				title='Move to Trash?'
				subtitle='Are you sure you want to move this Document Template to the trash?'
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				isLoading={isDeleting}
				onConfirm={() => onDelete(document?.id)}
			/>
			<AddToFolderPopup
				onClose={() => setIsAddToFolderOpen(false)}
				isOpen={isAddToFolderOpen}
				document={document}
			/>

			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown  inline-block'
				tag='li'>
				<DropdownToggle
					tag='button'
					type='button'
					className='m-0 !border !border-none bg-transparent p-0'>
					<i className='mdi mdi-dots-vertical text-[16px]'></i>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-md dropdown-menu-end  p-0 '>
					<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
						<div className='relative divide-y p-2'>
							<div className='flex flex-col gap-1'>
								<Link
									to={DOCUMENT_TEMPLATE_APP_ROUTES.findOne(document?.id)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-eye'></i>
									<span className='text-sm'>View</span>
								</Link>

								<Link
									to={DOCUMENT_TEMPLATE_APP_ROUTES.edit(document?.id)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-pen'></i>
									<span className='text-sm'>Edit</span>
								</Link>
								<span
									className='file-manager-action-dropdown-item'
									onClick={onRenameOpen}>
									{isCheckingRename ? (
										<span className='flex justify-start gap-3'>
											<Spinner />
											<span className='text-sm'>Processing...</span>
										</span>
									) : (
										<>
											<i className='uil-edit-alt'></i>
											<span className='text-sm'>Rename</span>
										</>
									)}
								</span>
								{!document?.shortUrlId && (
									<span
										onClick={() => {
											if (document?.status === 1) {
												attachInWorkflow(document, 'document')
											}
										}}
										className={classNames('file-manager-action-dropdown-item', {
											'cursor-not-allowed select-none opacity-50':
												document?.status !== 1,
										})}>
										<i className='uil-paperclip'></i>
										<span className='text-sm'>Attach in Workflow</span>
									</span>
								)}
								{document?.shortUrlId && document?.status === 1 && (
									<>
										<span
											onClick={() => copyPublicLink(document?.shortUrlId)}
											className='file-manager-action-dropdown-item'>
											<i
												className={
													showSuccess
														? 'uil-check text-green-500'
														: 'uil-clipboard'
												}></i>
											<span className='text-sm'>Copy public link</span>
										</span>
										<span
											onClick={() => viewResponse()}
											className='file-manager-action-dropdown-item'>
											<i className='uil-file'></i>
											<span className='text-sm'>View responses</span>
										</span>
									</>
								)}
								<span
									className='file-manager-action-dropdown-item'
									onClick={() => setIsAddToFolderOpen(true)}>
									<i className='uil-folder'></i>
									<span className='text-sm'>Add to folder</span>
								</span>
								<span
									onClick={() => onMarkStarred(document?.id)}
									className='file-manager-action-dropdown-item'>
									{isMarking ? (
										<span className='flex justify-start gap-3'>
											<Spinner />
											<span className='text-sm'>Processing...</span>
										</span>
									) : (
										<>
											{document?.isStarred ? (
												<Fragment>
													<i className='bx bxs-star text-main'></i>
													<span className='text-sm'>
														Remove from Starred
													</span>
												</Fragment>
											) : (
												<Fragment>
													<i className='uil-star'></i>
													<span className='text-sm'>Star</span>
												</Fragment>
											)}
										</>
									)}
								</span>
								<span
									className='file-manager-action-dropdown-item'
									onClick={() => checkDocumentDeleteEligibility(document?.id)}>
									{isLoading ? (
										<span className='flex justify-start gap-3'>
											<Spinner />
											<span className='text-sm'>Checking...</span>
										</span>
									) : (
										<>
											<i className='uil-trash text-red-600'></i>
											<span className='text-sm text-red-600'>
												Move to trash
											</span>
										</>
									)}
								</span>
							</div>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	)
}

export default OptionsList
