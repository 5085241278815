import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useUser } from 'hooks'
import { ASSIGN_WORKFLOW_APP_ROUTES } from 'modules/assigned-workflows/routes'
import { routes } from 'modules/workflows'
import { Fragment, useState } from 'react'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import { toSnakeCase } from 'utils/commonFunctions'
import {
	useCancelWorkflowOutstandingMutation,
	useDeleteWorkflowOutstandingMutation,
} from '../workflow-outstandings-apis'

const Actions = ({ workflow }) => {
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [cancelWorkflow, { isLoading: isCancelling }] = useCancelWorkflowOutstandingMutation()
	const [deleteWorkflow, { isLoading: isDeleting }] = useDeleteWorkflowOutstandingMutation()
	const { user } = useUser()
	const isCancellable =
		toSnakeCase(workflow?.status) === 'completed' ||
		toSnakeCase(workflow?.status) === 'cancelled'

	const isReviewAble = ![
		'assigned',
		'approved',
		'completed',
		'cancelled',
		'waiting_for_signatures',
		'rejected',
		'link',
	].includes(toSnakeCase(workflow?.status))

	const isReadAble = toSnakeCase(workflow?.status) === 'completed'
	const isDeleteAble = toSnakeCase(workflow?.status) === 'cancelled'
	const isFillable =
		toSnakeCase(workflow?.status) === 'assigned' && workflow?.user?.id == user?.id
	const isSignable =
		toSnakeCase(workflow?.status) === 'gathering_signatures' && workflow?.user?.id == user?.id
	const onCancel = async (id) => {
		const res = await Swal.fire({
			title: 'Are you sure you want to cancel this workflow?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		})
		if (res?.isConfirmed) {
			cancelWorkflow(id).then((response) => {
				if (response?.data?.status === 200) {
					notification('success', response?.message)
				}
			})
		}
	}
	const onDelete = async (id) => {
		try {
			const response = await deleteWorkflow(id).unwrap()
			if (response?.status === 200) {
				notification('success', response?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isDeleting={isDeleting}
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				onConfirm={() => onDelete(workflow?.id)}
			/>
			<div className='flex space-x-1 md:justify-end'>
				{isReadAble ? (
					<div
						className={classNames({
							'cursor-not-allowed': !isReadAble,
						})}>
						<Link
							className={classNames('action-btn btn-gray', {
								'pointer-events-none !bg-gray-500 opacity-50': !isReadAble,
							})}
							to={{
								pathname: isReadAble ? routes.view(workflow?.id) : '#',
							}}>
							View
						</Link>
					</div>
				) : undefined}
				{isFillable ? (
					<div
						className={classNames({
							'cursor-not-allowed': !isFillable,
						})}>
						<Link
							className={classNames('action-btn btn-gray', {
								'pointer-events-none !bg-gray-500 opacity-50': !isFillable,
							})}
							to={{
								pathname: isFillable
									? ASSIGN_WORKFLOW_APP_ROUTES.findOne(workflow?.id)
									: '#',
							}}>
							View
						</Link>
					</div>
				) : undefined}
				{isSignable ? (
					<div
						className={classNames({
							'cursor-not-allowed': !isSignable,
						})}>
						<Link
							className={classNames('action-btn btn-info-outline mx-1', {
								'pointer-events-none !bg-gray-500 opacity-50': !isSignable,
							})}
							to={{
								pathname: isSignable
									? ASSIGN_WORKFLOW_APP_ROUTES.findOne(workflow?.id)
									: '#',
							}}>
							Sign
						</Link>
					</div>
				) : undefined}

				{isReviewAble ? (
					<div
						className={classNames({
							'cursor-not-allowed': !isReviewAble,
						})}>
						<Link
							className={classNames('action-btn  btn-gray', {
								'pointer-events-none !bg-gray-500 opacity-50': !isReviewAble,
							})}
							to={{
								pathname: isReviewAble ? routes.review(workflow?.id) : '#',
							}}>
							Review
						</Link>
					</div>
				) : undefined}
				{!isCancellable ? (
					<div
						className={classNames({
							'cursor-not-allowed': isCancellable,
						})}>
						<button
							onClick={() => onCancel(workflow?.id)}
							disabled={isCancelling || isCancellable}
							className={classNames('action-btn  bg-red-500 text-white', {
								'pointer-events-none !border-gray-500 !bg-gray-500 opacity-50':
									isCancellable,
							})}>
							{isCancelling ? 'Cancelling...' : 'Cancel'}
						</button>
					</div>
				) : undefined}
				{isDeleteAble ? (
					<div>
						<button
							onClick={() => setIsDeleteOpen(true)}
							disabled={isDeleting}
							className={classNames('action-btn  bg-red-500 text-white')}>
							Delete
						</button>
					</div>
				) : undefined}
			</div>
		</Fragment>
	)
}

export default Actions
