import classNames from 'classnames'
import { useAppRouter, useUser } from 'hooks'
import { useLazyCheckSubscriptionLimitQuery } from 'modules/authentication/auth-api'
import CreateHouseholdModal from 'modules/households/household-create/household-create'
import UpdateSubscriptionPopup from 'modules/profile/my-subscriptions/update-subscription-popup/update-subscription-popup'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { CLIENT_APP_ROUTES } from '../routes'

const Header = () => {
	const location = useLocation()
	const isInvitePermission = isAllowTo(permissions?.client?.create)
	const isImportPermission = isAllowTo(permissions?.client?.import)
	const isGroupsPermission = isAllowTo(permissions?.groups?.create)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { user } = useUser()
	const { navigate } = useAppRouter()

	const [checkSubscriptionLimit, { isLoading: isFetching, data: limit }] =
		useLazyCheckSubscriptionLimitQuery()

	const tabs = useMemo(
		() => [
			{
				label: 'Clients',
				href: '/clients',
				permission: isAllowTo(permissions?.client?.list),
			},
			// {
			// 	label: 'Invited clients',
			// 	href: '/invited-clients',
			// 	permission: isAllowTo(permissions?.client?.invitedList),
			// },
			{
				label: 'Groups',
				href: '/groups',
				permission: isAllowTo(permissions?.groups?.list),
			},
			{
				label: 'Custodian Data Mapping',
				href: '/client-mapping',
				permission: isAllowTo(permissions?.custodianMapping?.list),
			},
		],
		[],
	)

	const activeTab = useMemo(
		() => tabs.find((tab) => tab?.href === location?.pathname),
		[location?.pathname],
	)

	useEffect(() => {
		if (user?.id) {
			checkSubscriptionLimit('client')
		}
	}, [user?.id])
	const [isOpen, setIsOpen] = useState(false)

	const handleClick = (type) => {
		if (limit?.data?.availableLimit <= 0) {
			setIsOpen(true)
		} else {
			if (type === 'create') {
				navigate(CLIENT_APP_ROUTES.invite())
			} else if (type === 'import') {
				navigate(CLIENT_APP_ROUTES.import())
			}
		}
	}

	return (
		<div className='flex w-full flex-col'>
			{isOpen && (
				<UpdateSubscriptionPopup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					title='Upgrade Your Plan'
					description='You’ve reached the client onboarding limit for your current subscription. Upgrade your plan to add more clients.'
				/>
			)}
			<div className='mb-3 flex w-full items-center justify-between gap-1  md:!hidden'>
				<div className='mb-3 max-w-[733px]'>
					<h4 className='page-title'>{activeTab?.label}</h4>
				</div>
				{activeTab?.href == '/groups' ? (
					<div className='flex items-center space-x-2'>
						{isGroupsPermission ? (
							<Button type='button' onClick={() => setIsModalOpen(true)}>
								Create Group
							</Button>
						) : undefined}
					</div>
				) : (
					<div className='flex items-center space-x-2'>
						{isImportPermission ? (
							<Button
								type='button'
								variant='primary-outline'
								onClick={() => handleClick('import')}
								disabled={isFetching}>
								Import CSV
							</Button>
						) : undefined}

						{isInvitePermission ? (
							<Button
								type='button'
								onClick={() => handleClick('create')}
								disabled={isFetching}>
								Create Client
							</Button>
						) : undefined}
					</div>
				)}
			</div>
			<div className='-mb-[12px] space-x-2'>
				{tabs
					.filter((item) => item?.permission)
					.map((tab, i) => (
						<Link
							to={tab.href}
							key={i}
							className={classNames(
								'border-b-2 px-[10px] py-[10px] text-sm font-bold text-gray-400',
								{
									'border-main text-main': tab?.href == location?.pathname,
								},
							)}>
							{tab.label}
						</Link>
					))}
			</div>
			<CreateHouseholdModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
		</div>
	)
}

export default Header
