import * as yup from 'yup'

const createOption = (type, value) => ({
	label: <span className='capitalize'>{type}</span>,
	value: value ? value : type,
})


export const formatDate = (fromDate) => {
	try {
		if (!fromDate) {
			return null
		}
		const originalDate = new Date(fromDate)

		const year = originalDate.getUTCFullYear()
		const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0')
		const day = String(originalDate.getUTCDate()).padStart(2, '0')
		const hours = String(originalDate.getUTCHours()).padStart(2, '0')
		const minutes = String(originalDate.getUTCMinutes()).padStart(2, '0')
		const seconds = String(originalDate.getUTCSeconds()).padStart(2, '0')

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
	} catch (error) {
		return null
	}
}

const dayMap = {
	MO: 'Mon',
	TU: 'Tue',
	WE: 'Wed',
	TH: 'Thu',
	FR: 'Fri',
	SA: 'Sat',
	SU: 'Sun',
}

const mapShortToFullDays = (shortDays) => shortDays.map((day) => dayMap[day] || day)

const frequencyMap = {
	DAILY: { label: 'Day', value: 'DAILY' },
	WEEKLY: { label: 'Week', value: 'WEEKLY' },
	MONTHLY: { label: 'Month', value: 'MONTHLY' },
	YEARLY: { label: 'Year', value: 'YEARLY' },
}

export const getInitialValues = (field) => {
	return {
		id: field?.id || undefined,
		host: field?.host || '',
		port: field?.port || '22',
		passphrase: field?.passphrase || '',
		file_path: field?.file_path || '',
		file_type_pattern: field?.file_type_pattern || '',
		username: field?.username || '',
		retention_policy: field?.retention_policy || '',
		logonType: field?.private_key
			? { label: 'Private key', value: 'privateKey' }
			: { label: 'Password', value: 'password' },
		protocol: { label: 'SFTP - SSH File Transfer Protocol', value: 'SFTP' },
		passkey: field?.password || field?.private_key || undefined,


		updateRecurringTasks: false,
		freq: ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']?.includes(
			field?.recurrence?.freq,
		)
		? { label: 'Custom', value: 'custom' }
		: { label: 'Does not repeat', value: 'NONE' },
		frequency_ids: field?.recurrence?.freq
			? frequencyMap[field?.recurrence?.freq]
			: frequencyMap.DAILY,
		repetition_ids:
			field?.recurrence?.end_type == 'BY_DATE'
				? { label: 'On a specific date', value: 'BY_DATE' }
				: { label: 'After X occurrences', value: 'BY_OCCURRENCES' },
		interval: field?.recurrence?.interval || 1,
		occurrence_count: field?.recurrence?.occurrence_count || null,
		by_day: field?.recurrence?.by_day
			? mapShortToFullDays(field?.recurrence?.by_day)
			: [],
		by_month_day: field?.recurrence?.by_month_day || null,
		end_date: field?.recurrence?.end_date
			? new Date(field?.recurrence?.end_date)
			: null,
	}
}


export const getPayload = (values) => {
	try {
		// Recurrence fields from the second payload
		const recurrence = values?.freq?.value
		const frequency = values?.frequency_ids?.value?.toUpperCase()

		const isCustomRecurrence = recurrence === 'custom'
		const isNoneRecurrence = recurrence === 'NONE'
		const isNeverRecurrence = recurrence === 'NEVER'
		const isWeekly = frequency === 'WEEKLY'
		const isMonthly = frequency === 'MONTHLY'

		const interval = isCustomRecurrence ? Number(values?.interval || 1) : 1
		const freq = isCustomRecurrence ? frequency || 'DAILY' : 'NONE'

		const by_day = isWeekly ? indexToDay(values.by_day) : undefined
		const by_month_day = isMonthly ? values?.by_month_day : undefined
		const end_type = values?.repetition_ids?.value?.toUpperCase() || 'BY_OCCURRENCES'
		const end_date = end_type === 'BY_DATE' ? formatDate(values?.end_date) : undefined
		const occurrence_count =
			end_type === 'BY_OCCURRENCES'
				? Number(values?.occurrence_count) || undefined
				: undefined

		// Main payload with recurrence fields included
		const payload = {
			host: values?.host,
			port: Number(values?.port),
			username: values?.username,
			custodian_id: values?.custodian_id?.value || undefined,
			file_path: values?.file_path || undefined,
			file_type_pattern: values?.file_type_pattern || undefined,
			retention_policy: values?.retention_policy ? Number(values?.retention_policy) : undefined,
		}

		if (values?.logonType?.value === 'password') {
			payload.password = values?.passkey
		}
		if (values?.logonType?.value === 'privateKey') {
			payload.private_key = values?.passkey
			payload.passphrase = values?.passphrase
		}
		if (isNoneRecurrence) {
			payload.end_type = 'NEVER'
			payload.interval = 0
			Object.assign(payload, {
				freq,
			})
		}
		if (isNeverRecurrence) {
			payload.end_type = 'NEVER'
			payload.interval = 0
			Object.assign(payload, {
				freq,
			})
		}
		if (isCustomRecurrence) {
			Object.assign(payload, {
				interval,
				freq,
				by_day,
				by_month_day,
				end_type,
				end_date,
				occurrence_count,
			})
		}
		return payload
	} catch (error) {
		return {
			host: undefined,
			port: 22,
			username: undefined,
			custodian_id: undefined,
			file_path: undefined,
			file_type_pattern: undefined,
			retention_policy: undefined,
			recurrence: 'NONE',
			interval: 1,
			freq: 'NONE',
			by_day: undefined,
			by_month_day: undefined,
			end_type: 'BY_OCCURRENCES',
			end_date: undefined,
			occurrence_count: undefined,
			password: undefined,
			private_key: undefined,
			passphrase: undefined,
		}
	}
}
const indexToDay = (weekDay) => {
	return weekDay.map((day) => day.slice(0, -1).toUpperCase())
}

export const validationSchema = yup.object().shape({
	host: yup.string().required('Title is required'),
	port: yup
		.number()
		.typeError('Port must be a number')
		.required('Port is required')
		.min(1, 'Port must be at least 1')
		.max(65535, 'Port must be less than 65536'),
	username: yup.string().required('Username is required'),
	logonType: yup.mixed().required('Logon type is required'),
	passkey: yup.string().required('This field is required'),
	file_path: yup.string().required('File path is required'),
	retention_policy: yup
		.number()
		.typeError('Port must be a number')
		.required('Port is required')
		.min(1, 'Port must be at least 1')
		.optional(),
})

export const pullFrequencyOptions = [
	createOption('hourly'),
	createOption('daily'),
	createOption('weekly'),
	createOption('monthly'),
]
export const logonTypeOptions = [
	createOption('password'),
	createOption('Private Key', 'privateKey'),
]
export const protocolOption = [createOption('SFTP - SSH File Transfer Protocol', 'SFTP')]