// --- START OF FILE src/components/Workflow/WorkflowEditor.js ---
import React, { useCallback, useState } from 'react'
import ReactFlow, {
	addEdge,
	Background,
	Controls,
	MarkerType,
	ReactFlowProvider,
	useEdgesState, // Needed for hooks like useReactFlow
	useNodesState,
} from 'reactflow'
import { v4 as uuidv4 } from 'uuid';

// Import CSS required by react-flow
import 'reactflow/dist/style.css';

// Import Child Components
import AddStatusModal from './AddStatusModal';
import AddTransitionModal from './AddTransitionModal';
import StatusNode from './StatusNode'; // Our custom node component
import WorkflowToolbar from './WorkflowToolbar'

// Default color for new statuses or if one is missing in initial data
const DEFAULT_NODE_COLOR = '#cccccc'; // Default Gray

// --- Updated Initial State with Color Included ---
const INITIAL_STATUSES_DATA = [
    // id, name, position, color
    { id: 'created', name: 'CREATED', position: { x: 50, y: 50 }, color: 'rgb(91, 115, 232)' },
    { id: 'draft', name: 'Draft', position: { x: 250, y: 50 }, color: 'rgb(204, 204, 204)' },
    { id: 'cancelled', name: 'Cancelled', position: { x: 600, y: 20 }, color: 'rgb(244, 106, 106)' },
    { id: 'deleted', name: 'Deleted', position: { x: 750, y: 20 }, color: 'rgb(108, 117, 125)' }, // Assuming gray for deleted
    { id: 'completed', name: 'Completed', position: { x: 950, y: 50 }, color: 'rgb(0, 180, 72)' },
    { id: 'assigned', name: 'Assigned', position: { x: 250, y: 250 }, color: 'rgb(91, 115, 232)' },
    { id: 'rejected', name: 'Rejected', position: { x: 550, y: 250 }, color: 'rgb(244, 106, 106)' },
    { id: 'in_review', name: 'In Review', position: { x: 250, y: 450 }, color: 'rgb(241, 180, 76)' },
    { id: 'approved', name: 'Approved', position: { x: 550, y: 450 }, color: 'rgb(52, 195, 143)' },
    { id: 'in_final_review', name: 'In Final Review', position: { x: 750, y: 450 }, color: 'rgb(241, 180, 76)' },
    { id: 'final_reviewer_approved', name: 'Final Reviewer Approved', position: { x: 950, y: 450 }, color: 'rgb(52, 195, 143)' },
    { id: 'final_reviewer_rejected', name: 'Final Reviewer Rejected', position: { x: 750, y: 325 }, color: 'rgb(244, 106, 106)' },
    { id: 'gathering_signatures', name: 'Gathering Signatures', position: { x: 950, y: 250 }, color: 'rgb(241, 180, 76)' },
];

const INITIAL_TRANSITIONS_DATA = [
    // Including placeholder 'name' properties
    { id: uuidv4(), from: 'created', to: 'draft', name: 'Begin Draft' },
    { id: uuidv4(), from: 'draft', to: 'assigned', name: 'Assign' },
    { id: uuidv4(), from: 'assigned', to: 'in_review', name: 'Submit Review' },
    { id: uuidv4(), from: 'assigned', to: 'rejected', name: 'Reject (No Reviewers)' },
    { id: uuidv4(), from: 'in_review', to: 'approved', name: 'Approve' },
    { id: uuidv4(), from: 'in_review', to: 'in_final_review', name: 'Needs Final Review' },
    { id: uuidv4(), from: 'in_review', to: 'final_reviewer_rejected', name: 'Reject (No Final)' },
    { id: uuidv4(), from: 'approved', to: 'in_final_review', name: 'Submit Final Review' },
    { id: uuidv4(), from: 'approved', to: 'final_reviewer_rejected', name: 'Reject (No Final)' },
    { id: uuidv4(), from: 'in_final_review', to: 'final_reviewer_approved', name: 'Final Approval' },
    { id: uuidv4(), from: 'in_final_review', to: 'final_reviewer_rejected', name: 'Final Rejection' },
    { id: uuidv4(), from: 'final_reviewer_approved', to: 'gathering_signatures', name: 'Gather Signatures' },
    { id: uuidv4(), from: 'final_reviewer_approved', to: 'completed', name: 'Complete (No Sign.)' },
    { id: uuidv4(), from: 'rejected', to: 'completed', name: 'Close (Rejected)' },
    { id: uuidv4(), from: 'final_reviewer_rejected', to: 'completed', name: 'Close (Final Rej.)' },
    { id: uuidv4(), from: 'gathering_signatures', to: 'completed', name: 'Complete (Signed)' },
];
// --- End Initial State ---

// --- Define Styles ---
const defaultEdgeStyle = { strokeWidth: 2, stroke: '#9ca3af' }; // gray-400 - Increased width
const selectedEdgeStyle = { strokeWidth: 3, stroke: '#3b82f6' }; // blue-500 - Thicker and blue when selected

const defaultMarkerColor = '#9ca3af';
const selectedMarkerColor = '#3b82f6';

// --- Map initial data to React Flow format ---
const initialNodes = INITIAL_STATUSES_DATA.map(status => ({
    // ... (node mapping code from previous step) ...
    id: status.id,
    type: 'statusNode',
    position: status.position,
    data: {
        name: status.name,
        color: status.color || DEFAULT_NODE_COLOR
    },
}));

const initialEdges = INITIAL_TRANSITIONS_DATA.map(transition => ({
    id: transition.id,
    source: transition.from,
    target: transition.to,
    label: transition.name,
    markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20, color: defaultMarkerColor },
    style: defaultEdgeStyle, // Apply default style
    // Add data attribute to store selected style (React Flow uses 'selected' prop internally for styling nodes/edges)
    // We can use this for more complex conditional styling if needed, but relying on RF's internal mechanism is better.
    // data: { selectedStyle: selectedEdgeStyle }, // Less common approach
    labelStyle: { fontSize: '10px', fill: '#4b5563' },
    labelBgPadding: [4, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: { fill: '#ffffff', fillOpacity: 0.7 },
}));
// --- End Initial State Mapping ---


// Define custom node types used in the graph
const nodeTypes = {
  statusNode: StatusNode, // Key ('statusNode') matches 'type' in initialNodes
};

// Define default options for edges created interactively or programmatically
const defaultEdgeOptions = {
    animated: false,
    style: defaultEdgeStyle, // Use the default style object
    markerEnd: { type: MarkerType.ArrowClosed, color: defaultMarkerColor },
    labelStyle: { fontSize: '10px', fill: '#4b5563' },
    labelBgPadding: [4, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: { fill: '#ffffff', fillOpacity: 0.7 },
    // React Flow automatically applies selection styling based on internal state,
    // but you could define interaction styles here if needed.
    // Example (though often not necessary as RF handles selection highlight):
    // interactionWidth: 20, // Makes edge easier to interact with (hover/select) - EXPERIMENTAL
};

// The main editor component logic using React Flow hooks
function WorkflowEditorComponent() {
  // State hooks for nodes and edges, managed by React Flow
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null); // Optional: Store instance if needed

  // UI State for Modals and Label Visibility
  const [isAddStatusModalOpen, setIsAddStatusModalOpen] = useState(false);
  const [isAddTransitionModalOpen, setIsAddTransitionModalOpen] = useState(false);
  const [showLabels, setShowLabels] = useState(true)

  // --- Callback for adding nodes (called from AddStatusModal) ---
  const handleAddNode = useCallback((newStatusData) => {
    // newStatusData contains { id, name, color }
    // Simple placement logic: tries to place new nodes below existing ones near the top-left
    const existingPositions = nodes.map(n => n.position);
    let newPosition = { x: 50, y: 50 };
    let attempts = 0;
    const MAX_ATTEMPTS = 20;
    const HORIZONTAL_CLEARANCE = 180;
    const VERTICAL_CLEARANCE = 80;
    const VERTICAL_STEP = 90;
    const X_RESET_THRESHOLD = 800;
    const X_STEP = 200;

    while (
      existingPositions.some(p =>
          Math.abs(p.x - newPosition.x) < HORIZONTAL_CLEARANCE &&
          Math.abs(p.y - newPosition.y) < VERTICAL_CLEARANCE
      ) && attempts < MAX_ATTEMPTS
    ) {
        newPosition.y += VERTICAL_STEP;
        if(newPosition.y > X_RESET_THRESHOLD) { // If too far down, move right and reset Y
            newPosition.x += X_STEP;
            newPosition.y = 50;
        }
        attempts++;
    }
     // Fallback if placement fails after many attempts
     if (attempts >= MAX_ATTEMPTS) {
         newPosition = {x: Math.random() * 100 + 20, y: Math.random() * 100 + 20 };
     }

    // Create the node object in React Flow format
    const nodeToAdd = {
      id: newStatusData.id, // ID from modal (uuid)
      type: 'statusNode',   // Use our custom node type
      position: newPosition,
      data: {
          name: newStatusData.name,  // Name from modal
          color: newStatusData.color || DEFAULT_NODE_COLOR // Color from modal
      },
    };
    // Add the new node to the state
    setNodes((nds) => nds.concat(nodeToAdd));
  }, [nodes, setNodes]); // Dependency ensures placement logic uses current nodes

  // --- Callback for adding edges (called from AddTransitionModal) ---
   const handleAddEdgeModal = useCallback((newEdgeData) => {
        // newEdgeData contains { id, from, to, name }
        // Basic duplicate check (source -> target)
        const exists = edges.some(e => e.source === newEdgeData.from && e.target === newEdgeData.to);
        if (exists) {
            alert('A transition from this source to this target already exists.');
            return;
        }
       // Create the edge object in React Flow format
       const edgeToAdd = {
           id: newEdgeData.id, // uuid from modal
           source: newEdgeData.from,
           target: newEdgeData.to,
           label: newEdgeData.name,
           // Inherits default styles/marker from defaultEdgeOptions automatically
       };
       // Use React Flow's addEdge helper to add the edge correctly
       setEdges((eds) => addEdge(edgeToAdd, eds));
   }, [edges, setEdges]); // Dependency ensures duplicate check uses current edges


  // --- Callback when user connects nodes via Handles interactively ---
  const onConnect = useCallback((params) => {
     // Check for duplicates before adding interactively
      const exists = edges.some(
          (e) => (e.source === params.source && e.target === params.target) ||
                 (e.source === params.target && e.target === params.source) // Check reverse too? Optional.
      );
       if (exists) {
           console.warn('Edge already exists between', params.source, 'and', params.target);
           return; // Prevent adding duplicate interactive edge
       }

    console.log('Connection created via UI:', params);
    // Create a new edge object for the interactive connection
    const newEdge = {
      ...params,
      id: uuidv4(), // Generate a new ID
      label: `Transition ${edges.length + 1}`, // Generate a default label
      // It will automatically use defaultEdgeOptions for style/marker
    };
    // Add the new edge
    setEdges((eds) => addEdge(newEdge, eds));
    // CONSIDER: Open the AddTransitionModal here, pre-filled with source/target,
    //           to force naming the transition immediately.
  }, [edges, setEdges]); // Dependency ensures duplicate check uses current edges

    // --- Deletion Callbacks (Optional: for logging or confirmation) ---
   const onNodesDelete = useCallback(
       (deletedNodes) => {
           // This callback fires *after* nodes have been removed internally by onNodesChange
           console.log('Nodes deleted:', deletedNodes);
           // Potential use case: Ask for confirmation before deletion if needed (more complex setup)
           // Or trigger cleanup actions related to deleted statuses
       },
       [] // No dependencies usually needed here
   );

   const onEdgesDelete = useCallback(
       (deletedEdges) => {
           // This callback fires *after* edges have been removed internally by onEdgesChange
           console.log('Edges deleted:', deletedEdges);
           // Potential use case: Trigger cleanup actions related to deleted transitions
       },
       []
   );

    // --- Toggle Label Visibility ---
    // Updates the style of all edges to show/hide labels
    const toggleLabels = useCallback(() => {
        const nextShowLabels = !showLabels;
        setShowLabels(nextShowLabels);
        setEdges(eds => eds.map(edge => ({
            ...edge,
            labelStyle: { ...edge.labelStyle, opacity: nextShowLabels ? 1 : 0 },
            labelBgStyle: { ...edge.labelBgStyle, opacity: nextShowLabels ? 0.7 : 0 }
        })));
    }, [showLabels, setEdges]); // Dependencies: current visibility state and setter

        // --- Update onEdgesChange to potentially apply selection style ---
    // NOTE: React Flow usually handles selection styling automatically via CSS classes (`.react-flow__edge.selected`)
    // Manually changing style might conflict. Rely on CSS first.
    // If CSS isn't working, this is a way to force style changes, but it's less ideal.
    const handleEdgesChange = useCallback((changes) => {
        // Apply the changes from React Flow first
        onEdgesChange(changes);

        // OPTIONAL/Alternative: Force style update based on selection change
        // setEdges(eds => {
        //     let changed = false;
        //     const newEdges = eds.map(e => {
        //         const selectionChange = changes.find(c => c.type === 'select' && c.id === e.id);
        //         if (selectionChange) {
        //             changed = true;
        //             const isSelected = selectionChange.selected;
        //             return {
        //                 ...e,
        //                 style: isSelected ? selectedEdgeStyle : defaultEdgeStyle,
        //                 markerEnd: { ...e.markerEnd, color: isSelected ? selectedMarkerColor : defaultMarkerColor },
        //             };
        //         }
        //         return e;
        //     });
        //     return changed ? newEdges : eds; // Only update if a selection actually changed
        // });

    }, [onEdgesChange, setEdges]);

  // --- Component Render ---
  return (
		// Main container with flex layout
		<div className='flex h-[80vh] flex-col p-4' style={{ minHeight: '600px' }}>
			{' '}
			{/* Ensure minimum height */}
			<h1 className='mb-4 text-2xl font-bold'>Workflow Diagram</h1>
			{/* Toolbar Component */}
			<WorkflowToolbar
				onAddStatus={() => setIsAddStatusModalOpen(true)}
				onAddTransition={() => setIsAddTransitionModalOpen(true)}
				showLabels={showLabels}
				onToggleLabels={toggleLabels}
			/>
			{/* React Flow Canvas Area */}
			<div className='relative flex-1 rounded border bg-gray-50 overflow-hidden'>
				{' '}
				{/* Changed bg */}
				<ReactFlow
					nodes={nodes} // Current nodes state
					edges={edges} // Current edges state
					onNodesChange={onNodesChange} // Handles node drag, select, remove
					onEdgesChange={handleEdgesChange} // Handles edge select, remove
					onConnect={onConnect} // Handles connecting nodes via handles
					onNodesDelete={onNodesDelete} // Callback after node deletion
					onEdgesDelete={onEdgesDelete} // Callback after edge deletion
					nodeTypes={nodeTypes} // Registers our custom StatusNode
					defaultEdgeOptions={defaultEdgeOptions} // Default style for new edges
					onInit={setReactFlowInstance} // Store the React Flow instance if needed
					fitView // Zoom/pan to fit nodes on initial render
					attributionPosition='top-right' // Position of React Flow attribution link
					deleteKeyCode={['Backspace', 'Delete']} // Keys that trigger node/edge deletion
					minZoom={0.2} // Set minimum zoom level
					maxZoom={2} // Set maximum zoom level
				>
					{/* Standard UI Controls */}
					<Controls />
					{/* Background Pattern */}
					<Background variant='dots' gap={16} size={1} color='#e0e0e0' />{' '}
					{/* Lighter dots */}
				</ReactFlow>
			</div>
			{/* --- Modals --- */}
			{/* Add Status Modal */}
			<AddStatusModal
				isOpen={isAddStatusModalOpen}
				onClose={() => setIsAddStatusModalOpen(false)}
				onAddStatus={handleAddNode} // Pass the correct handler
			/>
			{/* Add Transition Modal */}
			<AddTransitionModal
				isOpen={isAddTransitionModalOpen}
				onClose={() => setIsAddTransitionModalOpen(false)}
				onAddTransition={handleAddEdgeModal} // Pass the correct handler
				// Pass only the necessary status info for the dropdowns
				statuses={nodes.map((n) => ({ id: n.id, name: n.data.name }))}
			/>
			{/* --- Selection Panel (Future Implementation) --- */}
			{/*
           This is where you would add the panel seen in Jira.
           It would conditionally render based on selected nodes/edges.
           const selectedNodes = nodes.filter(n => n.selected);
           const selectedEdges = edges.filter(e => e.selected);
           if (selectedNodes.length === 1) { // Show status panel }
           if (selectedEdges.length === 1) { // Show transition panel }
       */}
		</div>
  )
}

// Wrap the main component with ReactFlowProvider for context/hooks
const WorkflowEditor = () => (
  <ReactFlowProvider>
    <WorkflowEditorComponent />
  </ReactFlowProvider>
);

export default WorkflowEditor;
// --- END OF FILE src/components/Workflow/WorkflowEditor.js ---