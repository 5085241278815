import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createClientOption } from '.'

const ClientsQuery = (search, formValues, client_applicants) => {
	const [debounceSearch] = useDebounce(search, 600)

	const params = useMemo(() => {
		const paramsObj = { page: 1, limit: 10, role: 'client', onBoardStatus: 'onboarded' }
		if (debounceSearch) {
			Object.assign(paramsObj, { search: debounceSearch })
		}
		return paramsObj
	}, [debounceSearch])

	const query = toQueryString(params)
	const url = `${apiEndpoints.clients}?${query}`

	const { data, isLoading } = useQuery(url)

	const options =
		data?.clients.map((client) =>
			createClientOption(client.user, formValues, client_applicants),
		) || []

	return {
		clients: options,
		isLoading,
	}
}

export default ClientsQuery
