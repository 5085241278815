import { Field, getIn } from 'formik'
import { FormDesignContainer } from 'modules/form-designer'
import Alert from 'theme/ui/alert'
import { SwitchField } from 'theme/ui/forms'

const FormDesignerTab = ({
	userOptions,
	fieldName,
	completed_fields,
	formik,
	fields,
	is_view_mode,
}) => {
	const is_score_able = formik?.values?.form_template_type === 'scoring'
	const document_type = formik?.values?.document_type
	const errors = getIn(formik?.errors, 'document_body')
	const document_body_errors = Array.isArray(errors) ? errors : []

	return (
		<div>
			<div className='mb-3 flex items-center justify-between'>
				<div>
					<h3 className='mb-2 text-lg font-bold'>Form Builder</h3>
					<p className='text-gray-600'>Build the form and assign field type</p>
				</div>

				{document_type !== 'document' ? (
					<Field
						component={SwitchField}
						disabled={completed_fields === 0}
						label='Preview'
						name='is_view_mode'
					/>
				) : undefined}
			</div>
			<div className=''>
				<FormDesignContainer
					formik={formik}
					is_score_able={is_score_able}
					userOptions={userOptions}
					fieldName={fieldName}
					fields={fields}
					is_view_mode={is_view_mode}
				/>
			</div>
			{document_body_errors.length > 0 ? (
				<div className='bg-white mt-3'>
					<Alert variant='warning'>
						<div className='flex items-center px-1 gap-1'>
							<span className='font-semibold text-base'>Note:</span>{' '}
							<p className='m-0'>
								{is_view_mode
									? 'Disable preview mode and fix all form errors before publishing.'
									: 'Fix all form errors before publishing.'}
							</p>
						</div>
					</Alert>
				</div>
			) : undefined}
		</div>
	)
}

export default FormDesignerTab
