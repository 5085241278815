import { FIELD_TYPES } from '../helpers'
import ChoiceField from './choice-field'
import DateField from './date-field'
import DropdownField from './dropdown-field'
import NetPromoterScoreField from './net-promoter-score'
import RatingField from './rating-field'
import SignatureField from './signature-field'
import TextField from './text-field'

const FieldRender = ({
	field,
	mode,
	name,
	onChange,
	onAddOption,
	onRemoveOption,
	onDragEnd,
	onDateChange,
	onSignatureTypeChange,
	onRatingChange,
	is_read_mode,
	userOptions,
	onNPSLabelChange,
}) => {
	const type = field?.type || FIELD_TYPES.TEXT
	switch (type) {
		case FIELD_TYPES.TEXT:
		case FIELD_TYPES.TEXTAREA:
		case FIELD_TYPES.RICHTEXT:
		case FIELD_TYPES.EMAIL:
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.TELPHONE:
		case FIELD_TYPES.PASSWORD:
		case FIELD_TYPES.DATE_TIME:
			return (
				<TextField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
					userOptions={userOptions}
				/>
			)

		case FIELD_TYPES.DATE:
			return (
				<DateField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
					onDateChange={onDateChange}
					userOptions={userOptions}
				/>
			)

		case FIELD_TYPES.RATING:
			return (
				<RatingField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onRatingChange={onRatingChange}
					userOptions={userOptions}
				/>
			)
		case FIELD_TYPES.NETPROMOTER:
			return (
				<NetPromoterScoreField
					mode={mode}
					name={name}
					field={field}
					userOptions={userOptions}
					onChange={onChange}
					onNPSLabelChange={onNPSLabelChange}
				/>
			)

		case FIELD_TYPES.DROPDOWN:
		case FIELD_TYPES.COMBOBOX:
		case FIELD_TYPES.LIST:
			return (
				<DropdownField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
					is_read_mode={is_read_mode}
					userOptions={userOptions}
				/>
			)

		case FIELD_TYPES.CHOICE:
			return (
				<ChoiceField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
					is_read_mode={is_read_mode}
					userOptions={userOptions}
				/>
			)
		case FIELD_TYPES.CHECKBOX:
			return (
				<ChoiceField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
					is_read_mode={is_read_mode}
					userOptions={userOptions}
				/>
			)
		case FIELD_TYPES.SIGNATURE:
			return (
				<SignatureField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
					onSignatureTypeChange={onSignatureTypeChange}
					is_read_mode={is_read_mode}
					userOptions={userOptions}
				/>
			)

		default:
			return (
				<TextField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
					userOptions={userOptions}
				/>
			)
	}
}

export default FieldRender
