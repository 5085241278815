import GroupsDetails from './groups-details'

export const groups = [
	{
		name: 'Experivise',
		clients: [
			{
				first_name: 'Emerald',
				last_name: 'Mayo',
				email: 'luliwuno@yopmail.com',
				uuid: 'c7cda0d2-f2b4-487e-9f80-381bd5190bb4',
			},
			{
				first_name: 'Harper',
				last_name: 'Sanders',
				email: 'vizotosow@yopmail.com',
				uuid: 'eb6adf5a-6e05-49e6-a2d1-1f8fb820cfab',
			},
			{
				first_name: 'Lacota',
				last_name: 'Foster',
				email: 'govefu@yopmail.com',
				uuid: '69b9fd6b-4b80-47dc-b4ea-13da5a820f4c',
			},
		],
	},
	{
		name: 'Notes',
		clients: [],
	},
	{
		name: 'EXP Workflows',
		clients: [
			{
				first_name: 'Emerald',
				last_name: 'Mayo',
				email: 'luliwuno@yopmail.com',
				uuid: 'c7cda0d2-f2b4-487e-9f80-381bd5190bb4',
			},
			{
				first_name: 'Harper',
				last_name: 'Sanders',
				email: 'vizotosow@yopmail.com',
				uuid: 'eb6adf5a-6e05-49e6-a2d1-1f8fb820cfab',
			},
		],
	},
	{
		name: 'Test Group',
		clients: [
			{
				first_name: 'Harper',
				last_name: 'Sanders',
				email: 'vizotosow@yopmail.com',
				uuid: 'eb6adf5a-6e05-49e6-a2d1-1f8fb820cfab',
			},
			{
				first_name: 'Lacota',
				last_name: 'Foster',
				email: 'govefu@yopmail.com',
				uuid: '69b9fd6b-4b80-47dc-b4ea-13da5a820f4c',
			},
		],
	},
]

export default GroupsDetails
