import { Fragment } from 'react'
import { TabContent, TabPane } from 'reactstrap'

import Documents from './Documents'
import Emails from './emails'
import InfoTab from './info/info-tab'
import Notes from './Notes'
import WorkflowFiles from './WorkflowFiles'
import WorkflowListingContainer from './Workflows'

const Tabs = ({ client, role, activeTab, isEditMode }) => {
	return (
		<Fragment>
			<TabContent activeTab={activeTab}>
				<TabPane tabId='1'>
					<InfoTab
						activeTab={activeTab}
						client={client}
						role={role}
						isEditMode={isEditMode}
					/>
				</TabPane>

				<TabPane tabId='2'>
					{parseInt(activeTab) === 2 ? (
						<Documents activeTab={activeTab} client={client} role={role} />
					) : undefined}
				</TabPane>

				<TabPane tabId='3'>
					{parseInt(activeTab) === 3 ? (
						<WorkflowListingContainer
							activeTab={activeTab}
							client={client}
							role={role}
						/>
					) : undefined}
				</TabPane>
				<TabPane tabId='4'>
					{parseInt(activeTab) === 4 ? (
						<WorkflowFiles activeTab={activeTab} client={client} role={role} />
					) : undefined}
				</TabPane>
				<TabPane tabId='5'>
					{parseInt(activeTab) === 5 ? (
						<Notes activeTab={activeTab} client={client} role={role} />
					) : undefined}
				</TabPane>
				<TabPane tabId='6'>
					{parseInt(activeTab) === 6 ? (
						<Emails activeTab={activeTab} client={client} role={role} />
					) : undefined}
				</TabPane>
			</TabContent>
		</Fragment>
	)
}

export default Tabs
