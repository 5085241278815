// Inside src/components/Workflow/StatusNode.js

import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

const nodeWidth = 160;

// data now contains { name: '...', color: 'rgb(...)' }
function StatusNode({ data, selected }) {

    // Determine text color based on background for contrast (simple version)
    const getTextColor = (bgColor) => {
        if (!bgColor) return '#000000'; // Default black
        try {
            // Basic check based on RGB sum (can be improved with luminance calculation)
            const rgb = bgColor.match(/\d+/g);
            if (!rgb || rgb.length < 3) return '#000000';
            const sum = parseInt(rgb[0], 10) + parseInt(rgb[1], 10) + parseInt(rgb[2], 10);
            return sum > 382 ? '#000000' : '#FFFFFF'; // Threshold might need adjustment
        } catch (e) {
            return '#000000' 
        }
    };

    const textColor = getTextColor(data.color);

    const nodeStyle = {
        backgroundColor: data.color || '#cccccc', // Apply color from data prop
        color: textColor, // Set text color for contrast
        // Remove fixed border color, selection handled by classes
        width: `${nodeWidth}px`,
        // Ensure padding/other styles allow background to show
    };


    const baseClasses = "react-flow__node-default"; // Base class
    // Removed border color from custom classes, using selection class instead
    const customClasses = "p-3 rounded shadow-md border"; // Keep border structure
    const selectedClasses = selected ? "border-2 border-blue-600 shadow-lg ring-2 ring-blue-300 ring-offset-1" : "border-transparent"; // Transparent border when not selected, blue when selected

    return (
        // Apply inline style for background/text color, use classes for border/shadow
        <div className={`${baseClasses} ${customClasses} ${selectedClasses}`} style={nodeStyle}>
            {/* Input Handle */}
            <Handle type="target" position={Position.Left} id="left-target" className="!bg-gray-500 !opacity-50" />

            {/* Node Content */}
            <div className="text-center text-sm font-medium">{data.name}</div>

            {/* Output Handle */}
            <Handle type="source" position={Position.Right} id="right-source" className="!bg-gray-500 !opacity-50" />

            {/* Optional Top/Bottom Handles */}
            {/* <Handle type="target" position={Position.Top} id="top-target" className="!bg-gray-500 !opacity-50" /> */}
            {/* <Handle type="source" position={Position.Bottom} id="bottom-source" className="!bg-gray-500 !opacity-50" /> */}
        </div>
    );
}

export default memo(StatusNode);