import { Redirect } from 'react-router-dom'
import Callback from '../Callback'
// Authentication related pages
import ForgetPwd from '../pages/Authentication/forgot-password/ForgetPassword'

import Logout from '../pages/Authentication/Logout'

import ResetPassword from '../pages/Authentication/reset-password/ResetPassword'
// team manage
// client workflows

// documents
// import DocumentsManage from '../pages/Documents/DocumentsManage'

import Pages404 from '../pages/StaticPages/Page404'

// client onboarding
import {
	DocumentList,
	SINNumber,
	TakeSelfie,
	TakeSelfieFromPhone,
	UploadDocuments,
	VerifyInformation,
} from 'pages/Authentication/client-document-upload'
import SelfieTokenExpired from 'pages/Authentication/client-document-upload/components/SelfieTokenExpired'
import ThankYouPage from 'pages/Authentication/client-document-upload/components/ThankYouPage'
import {
	StepFive,
	StepFour,
	StepOne,
	StepThree,
	StepTwo,
} from 'pages/Authentication/client-onboarding'

import { TasksKanbanView, TasksListing } from 'pages/Tasks'

import { SystemFieldsCreate, SystemFieldsEdit, SystemFieldsList } from 'pages/system-fields'

import {
	WorkflowLinksListing,
	WorkflowTemplateAssign,
	WorkflowTemplateCreate,
	WorkflowTemplateEdit,
	WorkflowTemplatesListing,
} from 'pages/Workflows/workflow-template'

import {
	WorkflowFinalReviewManage,
	WorkflowFinalReviewsListing,
} from 'pages/Workflows/workflow-final-review'

import { Login, Register } from 'pages/Auth2'

import {
	AssignedWorkflowManage,
	AssignedWorkflowsListing,
	WorkflowLinkManage,
} from 'pages/Workflows/assigned-workflows'

import { CompletedDocumentsListing, DocumentPackageListing } from 'pages/Documents'

import { CLIENT_APP_ROUTES } from 'modules/clients/routes'
import {
	ClientDetails,
	ClientImport,
	ClientInvite,
	ClientInviteEdit,
	ClientsListing,
	ClientVerify,
	InvitedClientsListing,
} from 'pages/Clients'

import { DOCUMENT_TEMPLATE_APP_ROUTES, DOCUMENT_VERSION_APP_ROUTES } from 'modules/documents/routes'

import ReviewInformation from 'pages/Authentication/client-document-upload/review-information'
import {
	CreateDocumentTemplate,
	DeletedDocumentTemplateListing,
	DocumentTemplatesListing,
	DocumentTemplateVersionDetails,
	EditDocumentTemplate,
	ViewDocumentTemplate,
} from 'pages/Documents/document-template'
import { RoleManage } from 'pages/Groups'
// import { NoteCreate, NoteEdit, NotesListing } from 'pages/Notes'
import { Profile, ProfileUpdate } from 'pages/profile'

import { TEAM_APP_ROUTES } from 'modules/teams'
import { TeamDetails, TeamsListing, TeamsSubscribe, TeamUserManage } from 'pages/Teams'

import { UserDetails, UsersListing } from 'pages/Users'
import {
	WorkflowOutstandingsDetails,
	WorkflowOutstandingsFormPreview,
	WorkflowOutstandingsReview,
	WorkflowsOutstandingsListing,
} from 'pages/Workflows/workflows-outstandings'

import { FEATURES_APP_ROUTES, SUBSCRIPTION_APP_ROUTES } from 'modules/subscriptions/routes'

import { ANNOUNCEMENTS_APP_ROUTES } from 'modules/announcements/routes'
import FormResponseLayout from 'modules/documents/document-template-form/tabs-content/form-response/form-response-layout'
import { EMAIL_TEMPLATES_APP_ROUTES } from 'modules/email-templates/routes'
import { FILE_MANAGER_ROUTES } from 'modules/file-manager/routes'
import { NOTIFICATIONS_TEMPLATES_APP_ROUTES } from 'modules/notification-templates/routes'
import { NOTIFICATIONS_APP_ROUTES } from 'modules/notifications/routes'
import { PENDING_SUB_APP_ROUTES } from 'modules/payments/pending-subscription/routes'
import { PAYMENTS_APP_ROUTES } from 'modules/payments/routes'
import { ROLES_APP_ROUTES } from 'modules/roles/routes'
import { BOARD_ROUTES } from 'modules/scrum-boards/routes'
import { SETTINGS_APP_ROUTES } from 'modules/settings/routes'
import { TEAM_MEMBER_APP_ROUTES } from 'modules/team-members/routes'
import { WORKFLOW_TEMAPLTE_ROUTES } from 'modules/workflows/workflow-template/routes'
import { AnnouncementCreate, AnnouncementEdit } from 'pages/announcements'
import AnnouncementsListing from 'pages/announcements/announcements-listing'
import { BoardEdit, BoardListView, BoardView } from 'pages/boards'
import BoardListing from 'pages/boards/board-listing'
import ClientMapping from 'pages/client-mapping'
import Dashboard from 'pages/Dashboard'
import Portfolio from 'pages/Dashboard/portfolio'
import FormDesigner from 'pages/Documents/document-template/form-designer'
import { EmailTemplatesEdit, EmailTemplatesListing } from 'pages/email-templates'
import {
	AllFolders,
	FileManager,
	FolderDetails,
	GlobalSearch,
	MyDocuments,
	ReadymadeTemplates,
	Starred,
	Trash,
} from 'pages/file-manager'
import RolesListing from 'pages/Groups/roles-listing'
import { HouseholdsListing } from 'pages/households'
import { Notifications } from 'pages/Notifications'
import {
	NotificationsTemplatesEdit,
	NotificationsTemplatesListing,
} from 'pages/notifications-templates'
import { PaymentDetails, PaymentListing } from 'pages/Payments'
import PaymentEdit from 'pages/Payments/payment-edit'
import PendingSubscriptionListing from 'pages/Pending-subscription/pending-subscription-listing'
import { PaymentConfirmation, PricingTable } from 'pages/pricing-table'
import { PublicForm } from 'pages/public-form'
import { SettingsList } from 'pages/settings'
import {
	SubscriptionCreate,
	SubscriptionDetails,
	SubscriptionEdit,
	SubscriptionLising,
} from 'pages/subscriptions'
import {
	FeatureCreate,
	FeatureDetails,
	FeatureEdit,
	FeatureListing,
} from 'pages/subscriptions/features'
import {
	TeamMemberInvite,
	TeamMembersInvitationListing,
	TeamMembersListing,
} from 'pages/team-members'
import { permissions } from 'utilities/permissions'

const userRoutes = [
	{ path: '/portfolio', component: Portfolio, isNoAuthorizationRequired: true },
	{ path: '/dashboard', component: Dashboard, isNoAuthorizationRequired: true },

	// //profile
	{
		path: '/profile',
		component: Profile,
		isNoAuthorizationRequired: true,
	},
	{
		path: '/profile-update/:token',
		component: ProfileUpdate,
		isNoAuthorizationRequired: true,
	},
	// { path: '/profile/create-team', component: TeamManage },

	// notes
	// {
	// 	path: NOTES_APP_ROUTES.findAll(),
	// 	component: NotesListing,
	// 	permission: permissions?.note?.list,
	// },
	// {
	// 	path: NOTES_APP_ROUTES.create(),
	// 	component: NoteCreate,
	// 	permission: permissions?.note?.create,
	// },
	// {
	// 	path: NOTES_APP_ROUTES.edit(':id'),
	// 	component: NoteEdit,
	// 	permission: permissions?.note?.edit,
	// },

	// clients
	{
		path: CLIENT_APP_ROUTES.findAll(),
		component: ClientsListing,
		permission: permissions?.client?.list,
	},
	{
		path: CLIENT_APP_ROUTES.findAllInvites(),
		component: InvitedClientsListing,
		permission: permissions?.client?.invitedList,
	},
	{
		path: CLIENT_APP_ROUTES.import(),
		component: ClientImport,
		permission: permissions?.client?.import,
	},
	{
		path: CLIENT_APP_ROUTES.invite(),
		component: ClientInvite,
		permission: permissions?.client?.create,
	},
	{
		path: CLIENT_APP_ROUTES.verifyClient(':id'),
		component: ClientVerify,
		permission: permissions?.client?.verify,
	},
	{
		path: CLIENT_APP_ROUTES.editInvitedClient(':id'),
		component: ClientInviteEdit,
		permission: permissions?.client?.verify,
	},
	{
		path: CLIENT_APP_ROUTES.findOne(':id'),
		component: ClientDetails,
		permission: permissions?.client?.view,
	},

	// groups
	{
		path: '/groups',
		component: HouseholdsListing,
		permission: permissions?.groups?.list,
	},
	//client mapping
	{
		path: '/client-mapping',
		component: ClientMapping,
		permission: permissions?.custodianMapping?.list,
	},

	// documents
	{
		path: '/form-designer',
		component: FormDesigner,
		permission: permissions?.documentTemplate?.list,
	},
	{
		path: DOCUMENT_TEMPLATE_APP_ROUTES.findAll(),
		component: DocumentTemplatesListing,
		permission: permissions?.documentTemplate?.list,
	},
	{
		path: DOCUMENT_TEMPLATE_APP_ROUTES.findAllDeleted(),
		component: DeletedDocumentTemplateListing,
		permission: permissions?.documentTemplate?.deletedList,
	},
	{
		path: DOCUMENT_TEMPLATE_APP_ROUTES.create(),
		component: CreateDocumentTemplate,
		permission: permissions?.documentTemplate?.create,
	},
	{
		path: DOCUMENT_TEMPLATE_APP_ROUTES.edit(':id'),
		component: EditDocumentTemplate,
		permission: permissions?.documentTemplate?.edit,
	},
	{
		path: '/form-response/:id',
		component: FormResponseLayout,
		permission: permissions?.documentTemplate?.list,
	},
	{
		path: DOCUMENT_TEMPLATE_APP_ROUTES.findOne(':id'),
		component: ViewDocumentTemplate,
		permission: permissions?.documentTemplate?.edit,
	},
	{
		path: DOCUMENT_VERSION_APP_ROUTES.view(':documentId', ':id'),
		component: DocumentTemplateVersionDetails,
		permission: permissions?.documentTemplate?.edit,
	},

	{
		path: '/document-packages',
		component: DocumentPackageListing,
		permission: permissions?.documentPackage?.list,
	},
	{
		path: '/file-manager/completed-documents',
		component: CompletedDocumentsListing,
		permission: permissions?.completedDocument?.list,
	},
	{
		path: '/completed-documents',
		component: CompletedDocumentsListing,
		permission: permissions?.completedDocument?.list,
	},

	// create documents
	// { path: '/new-documents/create', component: CreateDocumentTemplate },
	// { path: '/new-documents/:id/edit', component: EditDocumentTemplate },

	// workflow templates
	{
		path: WORKFLOW_TEMAPLTE_ROUTES.findAll(),
		component: WorkflowTemplatesListing,
		permission: permissions?.workflowTemplate?.list,
	},
	{
		path: WORKFLOW_TEMAPLTE_ROUTES.create(),
		component: WorkflowTemplateCreate,
		permission: permissions?.workflowTemplate?.create,
	},
	{
		path: WORKFLOW_TEMAPLTE_ROUTES.edit(':id'),
		component: WorkflowTemplateEdit,
		permission: permissions?.workflowTemplate?.edit,
	},
	{
		path: WORKFLOW_TEMAPLTE_ROUTES.links(':id'),
		component: WorkflowLinksListing,
		permission: permissions?.workflowTemplate?.assign,
	},
	{
		path: WORKFLOW_TEMAPLTE_ROUTES.assign(),
		component: WorkflowTemplateAssign,
		permission: permissions?.workflowTemplate?.assign,
	},
	{
		path: WORKFLOW_TEMAPLTE_ROUTES.createLink(':id'),
		component: WorkflowTemplateAssign,
		permission: permissions?.workflowTemplate?.assign,
	},

	// workflow outstandings
	{
		path: '/workflows-outstandings',
		component: WorkflowsOutstandingsListing,
		permission: permissions?.workflowOutstandings?.list,
	},
	{
		path: '/workflows-outstandings/:id',
		component: WorkflowOutstandingsDetails,
		permission: permissions?.workflowOutstandings?.view,
	},
	{
		path: '/workflows-outstandings/:id/review',
		component: WorkflowOutstandingsReview,
		permission: permissions?.workflowOutstandings?.view,
	},
	// {
	// 	path: '/workflows-outstandings/:id/forms/:formId',
	// 	component: WorkflowOutstandingsFormPreview,
	// 	permission: permissions?.workflowOutstandings?.view,
	// },

	// workflow outstandings
	{
		path: '/workflows-final-reviews',
		component: WorkflowFinalReviewsListing,
		permission: permissions?.workflowFinalReview?.list,
	},
	{
		path: '/workflows-final-reviews/:id/review',
		component: WorkflowFinalReviewManage,
		permission: permissions?.workflowFinalReview?.view,
	},

	// client workflows
	{
		path: '/w/:id',
		component: WorkflowLinkManage,
		permission: permissions?.assignedWorkflow?.list,
	},
	{
		path: '/assigned-workflows',
		component: AssignedWorkflowsListing,
		permission: permissions?.assignedWorkflow?.list,
	},
	{
		path: '/assigned-workflows/:id/fill',
		component: AssignedWorkflowManage,
		permission: permissions?.assignedWorkflow?.fill,
	},

	//tasks routes
	{
		path: '/tasks',
		component: TasksListing,
		permission: permissions?.task?.list,
	},
	{
		path: '/tasks-kanban',
		component: TasksKanbanView,
		permission: permissions?.task?.list,
	},
	{
		path: '/tasks/:id',
		component: TasksListing,
		permission: permissions?.task?.edit,
	},
	{
		path: '/tasks-kanban/:id',
		component: TasksKanbanView,
		permission: permissions?.task?.edit,
	},
	{
		path: BOARD_ROUTES.findAll(),
		component: BoardListing,
		permission: permissions?.task?.list,
	},
	{
		path: BOARD_ROUTES.findOne(':boardId'),
		component: BoardView,
		permission: permissions?.task?.list,
	},
	{
		path: BOARD_ROUTES.findOneList(':boardId'),
		component: BoardListView,
		permission: permissions?.task?.list,
	},
	{
		path: BOARD_ROUTES.edit(':boardId'),
		component: BoardEdit,
		permission: permissions?.task?.list,
	},

	// team members
	{
		path: TEAM_MEMBER_APP_ROUTES.findAll(),
		component: TeamMembersListing,
		permission: permissions?.teamMember?.list,
	},
	{
		path: TEAM_MEMBER_APP_ROUTES.findAllInvites(),
		component: TeamMembersInvitationListing,
		permission: permissions?.teamMember?.inviteList,
	},
	{
		path: TEAM_MEMBER_APP_ROUTES.invite(),
		component: TeamMemberInvite,
		permission: permissions?.teamMember?.invite,
	},

	// file-manager module
	{
		path: FILE_MANAGER_ROUTES.home(),
		component: FileManager,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.search(),
		component: GlobalSearch,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.myDocuments(),
		component: MyDocuments,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.allFolders(),
		component: AllFolders,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.findOneFolder(':id'),
		component: FolderDetails,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.readyMadeTemplate(),
		component: ReadymadeTemplates,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.starred(),
		component: Starred,
		permission: permissions?.teamMember?.list,
	},
	{
		path: FILE_MANAGER_ROUTES.trash(),
		component: Trash,
		permission: permissions?.teamMember?.list,
	},

	// groups
	{ path: ROLES_APP_ROUTES.findAll(), component: RolesListing },
	// { path: '/roles/create', component: GroupsManage },
	{ path: ROLES_APP_ROUTES.edit(':id'), component: RoleManage },

	// users
	{ path: '/users', component: UsersListing },
	// { path: '/users/create', component: UsersManage },
	{ path: '/users/:id/show', component: UserDetails },

	// teams
	{ path: TEAM_APP_ROUTES.findAll(), component: TeamsListing },
	{ path: TEAM_APP_ROUTES.findOne(':id'), component: TeamDetails },
	{ path: TEAM_APP_ROUTES.subscribe(':id'), component: TeamsSubscribe },
	{ path: TEAM_APP_ROUTES.editUser(':teamId', ':userId'), component: TeamUserManage },
	// { path: '/teams/users/invite', component: TeamUserManage },

	// system fields
	{ path: '/system-fields', component: SystemFieldsList },
	{ path: '/system-fields/create', component: SystemFieldsCreate },
	{ path: '/system-fields/:id/edit', component: SystemFieldsEdit },

	// subscriptions
	{ path: SUBSCRIPTION_APP_ROUTES.findAll(), component: SubscriptionLising },
	{ path: SUBSCRIPTION_APP_ROUTES.create(), component: SubscriptionCreate },
	{ path: SUBSCRIPTION_APP_ROUTES.edit(':id'), component: SubscriptionEdit },
	{ path: SUBSCRIPTION_APP_ROUTES.findOne(':id'), component: SubscriptionDetails },

	// Payments
	{ path: PAYMENTS_APP_ROUTES.findAll(), component: PaymentListing },
	{ path: PAYMENTS_APP_ROUTES.findOne(':id'), component: PaymentDetails },
	{ path: PAYMENTS_APP_ROUTES.edit(':id'), component: PaymentEdit },
	{ path: PENDING_SUB_APP_ROUTES.findAll(), component: PendingSubscriptionListing },

	// Notifications
	{
		path: NOTIFICATIONS_APP_ROUTES.findAll(),
		component: Notifications,
		isNoAuthorizationRequired: true,
	},

	// features
	{ path: FEATURES_APP_ROUTES.findAll(), component: FeatureListing },
	{ path: FEATURES_APP_ROUTES.create(), component: FeatureCreate },
	{ path: FEATURES_APP_ROUTES.edit(':id'), component: FeatureEdit },
	{ path: FEATURES_APP_ROUTES.findOne(':id'), component: FeatureDetails },

	//Settings
	{ path: SETTINGS_APP_ROUTES?.findAll(), component: SettingsList },

	//announcements
	{
		path: ANNOUNCEMENTS_APP_ROUTES?.findAll(),
		component: AnnouncementsListing,
	},
	{
		path: ANNOUNCEMENTS_APP_ROUTES?.create(),
		component: AnnouncementCreate,
	},
	{
		path: ANNOUNCEMENTS_APP_ROUTES?.edit(':id'),
		component: AnnouncementEdit,
	},
	//Email templates
	{
		path: EMAIL_TEMPLATES_APP_ROUTES?.findAll(),
		component: EmailTemplatesListing,
	},
	{
		path: EMAIL_TEMPLATES_APP_ROUTES?.findOne(':id'),
		component: EmailTemplatesEdit,
	},

	//Notifications templates
	{
		path: NOTIFICATIONS_TEMPLATES_APP_ROUTES?.findAll(),
		component: NotificationsTemplatesListing,
	},
	{
		path: NOTIFICATIONS_TEMPLATES_APP_ROUTES?.findOne(':id'),
		component: NotificationsTemplatesEdit,
	},

	// this route should be at the end of all other routes
	{ path: '/', exact: true, component: () => <Redirect to='/login' /> },
	{ path: '*', component: Pages404 },
]

const authRoutes = [
	{ path: '/logout', component: Logout },
	{ path: '/login', component: Login },
	{ path: '/forgot-password', component: ForgetPwd },
	{ path: '/reset-password', component: ResetPassword },
	{ path: '/set-password', component: ResetPassword },
	{ path: '/register', component: Register },
	{ path: '/callback', component: Callback },
	{ path: '/onboarding/step-one', component: StepOne },
	{ path: '/onboarding/step-two', component: StepTwo },
	{ path: '/onboarding/step-three', component: StepThree },
	{ path: '/onboarding/step-four', component: StepFour },
	{ path: '/onboarding/step-five', component: StepFive },
	{ path: '/onboarding/take-selfie-from-phone', component: TakeSelfieFromPhone },
	{ path: '/onboarding/page-expired', component: SelfieTokenExpired },
	{ path: '/onboarding/thank-you', component: ThankYouPage },
]

const clientOnBoardingRoutes = [
	// client onboarding document uploads
	{ path: '/onboarding/required-document-list', component: DocumentList },
	{ path: '/onboarding/input-sin-number', component: SINNumber },
	{ path: '/onboarding/upload-documents', component: UploadDocuments },
	{ path: '/onboarding/verify-information', component: VerifyInformation },
	{ path: '/onboarding/take-selfie', component: TakeSelfie },
	{ path: '/onboarding/review-information', component: ReviewInformation },
]

const publicRoutes = [
	{ path: '/pricing', component: PricingTable },
	{ path: '/payment-success', component: PaymentConfirmation },
	{
		path: '/workflows/:id/steps/:stepId/forms/:formId/teams/:teamId',
		component: WorkflowOutstandingsFormPreview,
	},
	{
		path: '/f/:id',
		component: PublicForm,
	},
]

export { authRoutes, clientOnBoardingRoutes, publicRoutes, userRoutes }
