import classNames from 'classnames'
import { CalendarDays, X } from 'lucide-react'
import PropTypes from 'prop-types'
import { createElement } from 'react'
import DatePicker from 'react-date-picker'
import { Label } from 'reactstrap'
import ToolTip from 'theme/ui/tool-tip'

const DatePickerInput = ({
	meta: { touched, error },
	label,
	input,
	variant,
	minDate,
	maxDate,
	dateFormat,
	disabled,
	isRequired,
	infoText,
	ignoreBottomMargin,
	isTooltip = false,
	tooltipIcon = null,
	tooltipText = '',
}) => {
	return (
		<div
			className={classNames('flex', {
				'flex-row items-center space-x-[10px]': variant === 'horizontal',
				'flex-col ': variant === 'default',
				'mb-3': !ignoreBottomMargin,
			})}>
			{label ? (
				<Label
					className={'flex items-center text-left text-[14px] font-bold text-[#495057]'}>
					{label}
					{isRequired && <span className='text-red-500'>&nbsp;*</span>}

					{isTooltip && (
						<>
							<ToolTip
								targetId={`date-tooltip-1`}
								tooltipText={tooltipText}
								placement='top'
							/>
							<i
								id={`date-tooltip-1`}
								className={`${
									tooltipIcon ? tooltipIcon : ''
								} ms-2 cursor-pointer`}></i>
						</>
					)}
				</Label>
			) : undefined}
			<div className='flex flex-col'>
				<DatePicker
					format={dateFormat || 'dd-MM-yyyy'}
					minDate={minDate}
					maxDate={maxDate}
					value={input?.value}
					className={classNames('form-control rounded-md ', {
						'is-invalid': touched && error,
						'cursor-not-allowed select-none !bg-gray-100': disabled,
					})}
					onChange={(date) => input.onChange(date)}
					clearIcon={createElement(X, {
						className: 'h-4 text-gray-500',
					})}
					calendarIcon={createElement(CalendarDays, {
						className: 'h-4 text-gray-500',
					})}
					disabled={disabled}
				/>
				{infoText ? (
					<span className='text-[13px] text-gray-400'>{infoText}</span>
				) : undefined}
				{touched && error ? (
					<span className=' mt-1 w-full text-[87.5%] text-[#f46a6a]'>{error}</span>
				) : undefined}
			</div>
		</div>
	)
}

DatePickerInput.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	variant: PropTypes.oneOf(['default', 'horizontal']),
	className: PropTypes.string,
}

DatePickerInput.defaultProps = {
	meta: null,
	variant: 'default',
	className: '',
}

export default DatePickerInput
