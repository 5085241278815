import { useLazyGetSettingsQuery } from 'modules/clients/client-apis'
import React, { useEffect, useState } from 'react'

import AddFieldPopup from './add-field-popup'
import CustomFieldForm from './custom-field-form'

const CustomFields = ({ client }) => {
	const [pagination, setPagination] = useState({ pageIndex: 1, pageSize: 10 })
	const onPageChange = (e, page) => {
		setPagination((prev) => ({ ...prev, pageIndex: page }))
	}
	const [fetchSettings, { isFetching, isUninitialized, data }] = useLazyGetSettingsQuery()
	useEffect(() => {
		if (pagination) {
			const params = {
				page: pagination.pageIndex,
				limit: pagination.pageSize,
			}
			fetchSettings({ params, client_id: client?.id })
		}
	}, [pagination, client])

	const fields = Array.isArray(data?.data?.userSettings)
		? data?.data?.userSettings.map((item) => ({
				id: item?.id,
				key: item?.user_key,
				value: item?.user_value,
		  }))
		: []

	const [isOpen, setIsOpen] = useState(false)
	const onAdd = () => setIsOpen(true)

	return (
		<div>
			<AddFieldPopup isOpen={isOpen} client={client} onClose={() => setIsOpen(false)} />
			<CustomFieldForm
				client={client}
				meta={data?.data?.meta}
				onAdd={onAdd}
				fields={fields}
				isLoading={isUninitialized || isFetching}
				onPageChange={onPageChange}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default CustomFields
