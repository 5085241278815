import { useState } from 'react'

import { useUser } from 'hooks'
import { getDuration } from 'modules/subscriptions/subscription-listing'
import Badge from 'theme/ui/badge'
import { Button } from 'theme/ui/forms'
import { dateFormat, formatPrice } from 'utilities/helpers'
import UpdateSubscriptionPopup from '../update-subscription-popup/update-subscription-popup'

const CurrentSubscription = ({ subscription }) => {
	const stripe_subscription = subscription?.stripeSubscriptionetails
	// const userDetails = subscription?.userHasSubscription
	const [isOpen, setIsOpen] = useState(false)
	const { group } = useUser()

	return (
		<div>
			<div className='!mb-3 flex items-center justify-between'>
				<h5 className='mb-6 font-semibold text-gray-700'>Current Subscription</h5>
				<UpdateSubscriptionPopup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					subscription={subscription}
				/>
				{group?.slug === 'owner' && (
					<Button
						size='sm'
						type='button'
						variant='primary-outline'
						onClick={() => setIsOpen(true)}>
						<div className='flex items-center space-x-2'>
							<i className='uil-pen' style={{ fontSize: 16 }} />
							<span>Change Plan</span>
						</div>
					</Button>
				)}
			</div>

			<div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
				<div className='rounded-lg !border bg-white !p-4 '>
					<div className='flex items-start justify-between gap-1'>
						<div className='flex flex-col'>
							<div className='flex items-center gap-2'>
								<span className='text-lg font-semibold capitalize text-gray-900'>
									{subscription?.userHasSubscription?.plan?.name}
								</span>
								<Badge variant='success'>{stripe_subscription?.status}</Badge>
							</div>
							<span className='mt-1 line-clamp-1 text-sm text-gray-600'>
								{subscription?.userHasSubscription?.plan?.description}
							</span>
						</div>

						<div className='flex flex-col items-end'>
							<span className='text-lg font-semibold text-gray-900'>
								{formatPrice(
									stripe_subscription?.plan?.amount_decimal / 100,
									stripe_subscription?.plan?.currency,
								)}
							</span>
							<span className='text-sm font-medium text-gray-500'>
								{getDuration(stripe_subscription?.plan?.interval)}
							</span>
						</div>
					</div>
				</div>

				<div className='rounded-lg !border bg-white !p-4 '>
					<div className='flex justify-between gap-4'>
						<div className='flex flex-col'>
							<span className='text-xs text-gray-500'>Started At</span>
							<span className='text-sm font-semibold text-gray-900'>
								{dateFormat(stripe_subscription?.current_period_start * 1000)}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='text-xs text-gray-500'>Expires At</span>
							<span className='text-sm font-semibold text-gray-900'>
								{dateFormat(stripe_subscription?.current_period_end * 1000)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CurrentSubscription
