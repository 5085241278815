import PdfPreviewTab from './pdf-preview-tab'

export function hasCommonElement(arr1, arr2) {
	const set1 = new Set(arr1)

	for (const item of arr2) {
		if (set1.has(item)) {
			return true
		}
	}

	return false
}

export function getCommonElements(arr1, arr2) {
	const set1 = new Set(arr1)
	return arr2.filter((item) => set1.has(item))
}

export default PdfPreviewTab
