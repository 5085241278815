import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { FormFeedback, Label } from 'reactstrap'

import ToolTip from 'theme/ui/tool-tip'
import { generateUniqueId } from 'utilities/helpers'

export const TexteditorField = ({
	meta: { touched, error },
	label,
	input,
	infoText,
	className: { formGroupClass, labelClass },
	ignoreBottomMargin = false,
	isRequired = false,
	isTooltip = false,
	tooltipIcon = null,
	tooltipText = '',
}) => {
	const id = generateUniqueId()
	return (
		<Fragment>
			<div className={classNames({ 'mb-3': !ignoreBottomMargin }, formGroupClass)}>
				{label && (
					<Label
						className={
							'flex items-center text-left text-[14px] font-bold text-[#495057]' +
							labelClass
						}>
						{label}
						{isRequired && <span className='text-red-500'>&nbsp;*</span>}
						{isTooltip && (
							<>
								<ToolTip targetId={id} tooltipText={tooltipText} placement='top' />
								<i
									id={id}
									className={`${
										tooltipIcon ? tooltipIcon : ''
									} ms-2 cursor-pointer`}></i>
							</>
						)}
					</Label>
				)}

				<div className='editor-wrapper' style={{ width: '100%' }}>
					<CKEditor
						editor={ClassicEditor}
						data={input.value}
						onChange={(event, editor) => {
							const data = editor.getData()
							input.onChange(data)
						}}
						config={{
							toolbar: {
								items: [
									'undo',
									'redo',
									'|',
									'heading',
									'|',
									'fontfamily',
									'fontsize',
									'fontColor',
									'fontBackgroundColor',
									'|',
									'bold',
									'italic',
									'|',
									'insertTable',
									'blockQuote',
									'insertMedia',
									'|',
									'alignment',
									'|',
									'numberedList',
									'bulletedList',
									'|',
									'outdent',
									'indent',
								],
							},
						}}
						// onBlur={input.onBlur}
					/>
				</div>

				{infoText && <span className='text-[13px]  text-gray-400'>{infoText}</span>}
				{touched && error && <FormFeedback>{touched && error}</FormFeedback>}
			</div>
		</Fragment>
	)
}

TexteditorField.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	isValidatePassword: PropTypes.bool,
	ignoreBottomMargin: PropTypes.bool,
}

TexteditorField.defaultProps = {
	meta: null,
	className: { formGroupClass: '', labelClass: '', inputClass: '' },
	isValidatePassword: false,
	ignoreBottomMargin: false,
}
