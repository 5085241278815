import { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useUser } from 'hooks'
import ClientInvitation from 'modules/dashboard/client-invitation'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { userRoutes } from 'routes/allRoutes'
import { matchRoutes } from 'routes/matchRoutes'
import * as actions from '../../../store/clients copy/actions'

import NoSubscription from 'pages/StaticPages/NoSubscription'
import { useErrorBoundary } from 'react-error-boundary'
import { useMediaQuery } from 'react-responsive'
import { dispatch } from 'store'
import { authorizeRequest } from '.'
import Header from './header'
import Sidebar from './sidebar'

const AppLayout = ({ children, meta, variant }) => {
	const metaTitle = meta?.title
	const metaKeywords = meta?.keywords
	const metaDescription = meta?.description
	const { team, permissions } = useUser()
	const location = useLocation()
	const { showBoundary } = useErrorBoundary()
	const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
	useEffect(() => {
		if (meta?.title) {
			dispatch(
				actions.headerTitle({
					title: meta?.title,
					hasBackButton: meta?.hasBackButton,
					redirectTo: meta?.redirectTo,
					variant: meta?.variant,
				}),
			)
		}
	}, [meta?.title])

	const [{ route }] = matchRoutes(userRoutes, location?.pathname)

	useEffect(() => {
		authorizeRequest(permissions, route)
			.then(() => {})
			.catch((error) => {
				showBoundary(error)
			})
	}, [route, permissions])

	useEffect(() => {
		if (variant === 'client' && isDesktop) {
			document.body.className = 'bg-white'
		}
		return () => {
			document.body.className = ''
		}
	}, [variant, isDesktop])

	if (team?.status === 0) {
		return <NoSubscription team={team} />
	}

	return (
		<HelmetProvider>
			<Helmet>
				{metaTitle ? <title>{metaTitle} | Experivise</title> : <title>Experivise</title>}
				{metaKeywords ? <meta name='keywords' content={metaKeywords} /> : undefined}
				{metaDescription ? (
					<meta name='description' content={metaDescription} />
				) : undefined}
			</Helmet>

			<div id='layout-wrapper'>
				<>
					<Header />
					<Sidebar />
					<div className='main-content'>
						<ClientInvitation />
						{children}
					</div>
				</>
			</div>
		</HelmetProvider>
	)
}

AppLayout.defaultProps = {
	variant: 'admin',
}

export default AppLayout
