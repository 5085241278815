import { Field, useFormikContext } from 'formik'
import { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CheckboxField, DatePickerField, InputField, RadioGroupField } from 'theme/ui/forms'
import ClientAdvisorDropdown from './Client-advisor-dropdown'

const PublicFormOptions = () => {
	const { values } = useFormikContext()
	const defaultGroup = useSelector((state) => state.Permissions.defaultGroup)
	const teamName = defaultGroup?.team?.team_name || 'Your Team'
	const params = useParams()

	return (
		<div className='public-form-options '>
			<h4 className='text-sm font-bold text-[#495057] my-2 '>Who can fill out this form</h4>
			<Field
				component={RadioGroupField}
				name='access_type'
				disabled={params?.id}
				options={[
					{ label: 'Anyone', value: 'public' },
					{ label: `Only people in ${teamName}`, value: 'in_team' },
					{ label: `Specific people in ${teamName}`, value: 'specific_people' },
				]}
				className='flex flex-col gap-2'
			/>
			{values.access_type === 'specific_people' && (
				<div className='mt-4 flex flex-row gap-6'>
					<div className='w-full'>
						<ClientAdvisorDropdown name='users' />
					</div>
				</div>
			)}

			<h4 className='mt-6 text-sm font-bold text-[#495057]'>Options for responses</h4>
			<div className='flex flex-col gap-6'>
				<div className='flex w-full flex gap-6 forced_break'>
					<div className='w-full'>
						<Field component={DatePickerField} name='start_date' label='Start date' />
					</div>
					<div className='w-full'>
						<Field component={DatePickerField} name='end_date' label='End date' />
					</div>
				</div>
				<div className='flex w-full flex-row gap-6'>
					<div className='flex w-full flex-col gap-2'>
						{/* <Field
							component={CheckboxField}
							name='accept_responses'
							label='Accept responses'
						/> */}
						<Field
							component={CheckboxField}
							name='shuffle_questions'
							label='Shuffle questions'
						/>
						{/* <Field
							component={CheckboxField}
							name='set_time_duration_enabled'
							label='Set time duration (in mins)'
						/> */}
						{/* {values.set_time_duration_enabled && (
							<Field
								component={InputField}
								type='number'
								name='time_duration'
								placeholder='Eg : 10'
							/>
						)} */}
					</div>
					<div className='flex w-full flex-col gap-2'>
						{/* <Field
							component={CheckboxField}
							name='show_progress_bar'
							label='Show progress bar'
						/> */}
						<Field
							component={CheckboxField}
							name='submit_another_response'
							label='Hide "Submit another response"'
						/>
						<Field
							component={CheckboxField}
							name='custom_thank_you_message_enabled'
							label='Customize thank you message'
						/>
						{values.custom_thank_you_message_enabled && (
							<Field
								component={InputField}
								name='custom_thank_you_message'
								placeholder='Enter custom message'
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

const FormTemplateInfo = ({ formik }) => {
	const values = useMemo(() => formik?.values || [], [formik?.values])
	const params = useParams()
	return (
		<Fragment>
			<div className='flex mt-2 flex-row gap-6'>
				<div className='template-purpose '>
					<Field
						label='Form Template Type'
						id='form_template_type'
						name='form_template_type'
						options={[
							{ label: 'QnA', value: 'qna' },
							{ label: 'Scoring', value: 'scoring' },
						]}
						disabled={params?.id}
						component={RadioGroupField}
					/>
				</div>
			</div>

			{values.document_type === 'public_form' && <PublicFormOptions />}
		</Fragment>
	)
}

export default FormTemplateInfo
