import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { getInitialValuesFileManager, WorkflowForm } from 'modules/workflows/workflow-template'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowTemplateCreate = () => {
	const { parseQuery } = useAppRouter()
	const { selectedFolders, selectedDocuments } = useSelector((state) => state?.fileManager)
	const initialValues = getInitialValuesFileManager(
		parseQuery,
		selectedFolders,
		selectedDocuments,
	)
	const [activeTabName, setActiveTabName] = useState('workflow')

	const tabs = [
		{ label: 'Workflow', value: 'workflow' },
		{ label: 'Diagram', value: 'diagram' },
	]
	return (
		<AppLayout
			meta={{
				title: 'Create Workflow Template',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Create Workflow'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>{' '}
					<Tab.Group as='div'>
						<Tab.List className='flex flex-row items-end justify-center gap-2 bg-white p-0 '>
							{tabs.map((tab) => (
								<Link
									key={tab.value}
									onClick={() => setActiveTabName(tab.value)}
									className={classNames(
										'border-b-2  px-[10px] py-[10px] text-sm font-bold cursor-pointer',
										{
											'border-main text-main': tab.value === activeTabName,
											'text-gray-400': tab.value !== activeTabName,
										},
									)}>
									{tab.label}
								</Link>
							))}
						</Tab.List>
					</Tab.Group>
					<div className='page-body'>
						<WorkflowForm initialValues={initialValues} activeTabName={activeTabName} />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplateCreate
