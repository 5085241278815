import { Field, FormikProvider, useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { SearchField, Button } from "theme/ui/forms";
import { PlusCircle } from "lucide-react";
import { getParseQueryString } from "utilities/helpers";
import { toQueryString } from "utils/commonFunctions";

const Filters = ({ setSearchQuery, openModal }) => {
  const history = useHistory();
  const location = useLocation();

  // Parse existing query params
  const parseQueryString = getParseQueryString(location?.search);
  const initialSearch = parseQueryString?.search || ""; 

  const formik = useFormik({
    initialValues: { search: initialSearch },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  // Debounce search input to prevent rapid updates
  const formValues = useMemo(() => formik.values, [formik.values]);
  const [debouncedValues] = useDebounce(formValues, 300);

  useEffect(() => {
    if (debouncedValues) {
      const filterObj = {};
  
      // Only add `search` if it has a value
      if (debouncedValues.search.trim() !== "") {
        filterObj.search = debouncedValues.search.toLowerCase();
      }
  
      const queryString = toQueryString(filterObj);
      
      // Only update the URL if there's a query string to add
      history.push(
        queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname
      );
  
      setSearchQuery(debouncedValues.search.toLowerCase()); 
    }
  }, [debouncedValues]);
  

  return (
    <FormikProvider value={formik}>
      <form className="mb-3" onSubmit={(e) => e.preventDefault()}>
        <div className="flex flex-col space-y-3">
          <div className="flex justify-between items-center">
            <div className="sm:min-w-[320px]">
              <Field
                className="form-control"
                type="search"
                name="search"
                component={SearchField}
                placeholder="Search Boards..."
                value={formik.values.search}
                onChange={formik.handleChange}
              />
            </div>

            {/* Open Modal Button */}
            <Button
              color="primary"
              className="min-w-max flex items-center gap-2 px-4 py-2"
              onClick={openModal}
            >
              <PlusCircle size={18} /> Add Board
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Filters;
