import { useAppRouter } from 'hooks'
import React, { useEffect, useState } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import AddBoardModal from '../../modules/scrum-boards/AddBoardModal'
import { useLazyGetBoardsQuery } from '../../modules/scrum-boards/boards-api'
import Filters from '../../modules/scrum-boards/filters/filter.jsx'
import ScrumBoardPagination from '../../modules/scrum-boards/scrum-board-pagination'
import ScrumBoardContent from '../../modules/scrum-boards/scrumboard-content'

const boardsPerPage = 6

const BoardListing = () => {
	const [boards, setBoards] = useState([])
	const [searchQuery, setSearchQuery] = useState('')
	const { location, navigate } = useAppRouter()

	const { page } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({
		pageIndex: page ? parseInt(page) - 1 : 0,
		pageSize: boardsPerPage,
	})

	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			if (location.search !== `?${queryString}`) {
				navigate(`${window.location.pathname}?${queryString}`, { replace: true })
			}
		}
	}, [pagination, location.search])

	const [fetchBoards, { data, isLoading }] = useLazyGetBoardsQuery()

	useEffect(() => {
		if (location?.search) {
			const params = getParseQueryString(location?.search)
			fetchBoards(params)
		}
	}, [location?.search])

	useEffect(() => {
		if (data?.boards) {
			setBoards(data.boards)
		}
	}, [data])

	const filteredBoards =
		boards.length > 0
			? boards.filter(
					(board) =>
						board.name.toLowerCase().includes(searchQuery) ||
						board.description.toLowerCase().includes(searchQuery),
				)
			: []

	// Function to change pages and update URL
	const handlePageChange = (newPage) => {
		setPagination((prev) => ({ ...prev, pageIndex: newPage - 1 }))
	}

	const [isModalOpen, setIsModalOpen] = useState(false)

	// Function to add a new board
	const addBoard = (newBoard) => {
		setBoards([...boards, newBoard])
	}

	// Function to delete a board
	const deleteBoard = (id) => {
		setBoards(boards.filter((board) => board.id !== id))
	}

	return (
		<AppLayout meta={{ title: 'Boards' }}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<div className='page-body'>
						<Filters
							setSearchQuery={setSearchQuery}
							setPagination={setPagination}
							openModal={() => setIsModalOpen(true)}
						/>

						{/* Render only paginated boards */}
						<ScrumBoardContent
							boards={filteredBoards.slice(
								pageIndex * boardsPerPage,
								(pageIndex + 1) * boardsPerPage,
							)}
							setPagination={setPagination}
							isLoading={isLoading}
							setBoards={setBoards}
							deleteBoard={deleteBoard}
						/>

						{/* Pagination Component */}
						{filteredBoards.length > boardsPerPage && (
							<ScrumBoardPagination
								totalBoards={filteredBoards.length}
								currentPage={pageIndex + 1}
								setCurrentPage={handlePageChange}
								boardsPerPage={boardsPerPage}
							/>
						)}
					</div>
				</div>
			</div>

			{/* Modal Component */}
			<AddBoardModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				addBoard={addBoard}
			/>
		</AppLayout>
	)
}

export default BoardListing
