import { useEffect } from 'react'

// import { SpinLoader } from 'components/Common'
import { FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import { FormDesignContainer } from 'modules/form-designer'
import {
	useLazyGetOneWorkflowOutstandingQuery,
	useLazyGetWorkflowFormTemplateQuery,
} from 'modules/workflows/workflows-outstandings/workflow-outstandings-apis'
// import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'

const WorkflowOutstandingsFormPreview = () => {
	const { params } = useAppRouter()
	const { id, stepId, formId, teamId } = params
	// console.log({ id, stepId, formId, teamId })
	// const [isLoading, setIsLoading] = useState(false)
	const workflowId = id
	const [fetchWorkflow, { data: workflowData }] = useLazyGetOneWorkflowOutstandingQuery()
	const [fetchWorkflowForm, { data: formData }] = useLazyGetWorkflowFormTemplateQuery()

	useEffect(() => {}, [workflowId])

	useEffect(() => {
		if (id && stepId && formId && teamId) {
			fetchWorkflowForm({ id, stepId, formId, teamId })
		} else if (id) {
			fetchWorkflow(id)
		}
	}, [id, stepId, formId, teamId])

	// const data = id && stepId && formId && teamId ? formData : workflowData

	// const documents = Array.isArray(data?.individual_document?.documents)
	// 	? data?.individual_document?.documents
	// 	: []
	// const document = documents.find((d) => d?.id === formId)
	const fields = Array.isArray(formData?.fields) ? formData?.fields : []
	// const fields = Array.isArray(document?.document_body?.document)
	// 	? document?.document_body?.document
	// 	: []

	const formik = useFormik({
		initialValues: { fields: fields },
	})

	return (
		<div className='flex min-h-screen items-center justify-center'>
			<div className='page-body w-full'>
				{/* {isLoading ? (
					<SpinLoader />
				) : document ? (
					<>
						
					</>
				) : (
					<ResourceNotFound
						title='Requested Workflow not found'
						description='The workflow you have requested is not found'
					/>
				)} */}
				<FormikProvider value={formik}>
					<FormDesignContainer
						document_title={formData?.document_title}
						formik={formik}
						is_score_able={false}
						fieldName='fields'
						fields={fields}
						is_view_mode={true}
						is_read_mode={true}
					/>
				</FormikProvider>
			</div>
		</div>
	)
}

export default WorkflowOutstandingsFormPreview
