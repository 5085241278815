import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from 'theme/ui/forms'
import BoardForm from '../board-form'
import AddStatusPopup from './add-status-popup'
import ColumnMapping from './column-mapping'

const BoardEditContainer = ({ board }) => {
	const { parseQuery } = useAppRouter()
	const activeTab = parseQuery?.tab || 'general'
	const tabs = useMemo(
		() => [
			{ label: 'General', value: 'general' },
			{ label: 'Columns', value: 'columns' },
		],
		[],
	)
	const [isOpen, setIsOpen] = useState(false)

	const columns = useMemo(() => (Array.isArray(board?.columns) ? board?.columns : []), [board])

	return (
		<div className='flex gap-4 w-full'>
			<div className='w-[150px]'>
				<ul className='flex-column space-y  text-sm font-medium text-gray-500 m-0 p-0'>
					{tabs.map((tab, idx) => (
						<li key={idx}>
							<Link
								to={`${window.location.pathname}?tab=${tab?.value}`}
								className={classNames(
									'inline-flex items-center px-3 py-2 rounded w-full hover:cursor-pointer transition-all',
									tab?.value === activeTab
										? 'text-white bg-main'
										: 'bg-transparent text-main hover:bg-main/20',
								)}>
								{tab?.label}
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div className='flex-1 overflow-hidden'>
				{activeTab === 'general' ? (
					<div className='rounded-md p-3 bg-white'>
						<h4 className='font-semibold text-lg mb-4'>General settings</h4>
						<BoardForm board={board} />
					</div>
				) : undefined}
				{activeTab === 'columns' ? (
					<div>
						<div className='mb-3 flex items-center justify-between'>
							<h4 className='font-semibold text-lg m-0'>Columns and statuses</h4>
							{columns.length > 0 ? (
								<Button
									type='button'
									onClick={() => setIsOpen(true)}
									size='sm'
									variant={'white'}>
									Add Status
								</Button>
							) : undefined}
						</div>
						<ColumnMapping board={board} />
						<AddStatusPopup
							board={board}
							onClose={() => setIsOpen(false)}
							open={isOpen}
						/>
					</div>
				) : undefined}
			</div>
		</div>
	)
}

export default BoardEditContainer
