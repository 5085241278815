import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import CustomFieldTable from './custom-field-table'

const CustomFieldsHistory = ({ isOpen, onClose }) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-3xl bg-white p-8 text-left align-middle shadow-xl transition-all'>
								{/* Modal Header */}
								<div className='flex items-center justify-between'>
									<h2 className='text-2xl font-semibold text-gray-800'>
										<i className='uil-history mr-2 text-blue-500'></i>
										Field history
									</h2>
									<button
										type='button'
										onClick={onClose}
										className='text-gray-500 hover:text-gray-800'>
										<i className='fas fa-times text-2xl' />
									</button>
								</div>

								{/* Modal Body */}
								<div className='mt-6'>
									<CustomFieldTable />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default CustomFieldsHistory
