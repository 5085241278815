import { useQuery } from 'hooks'
import { useDebounce } from 'use-debounce'
import CustodianDropdown from './custodian-dropdown'

export default CustodianDropdown

export const CustodianQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 500)
	const URL = `${'sftp/custodian'}?page=1&limit=10${
		debounceSearch ? `&search=${debounceSearch}` : ''
	}`
	const { data, isLoading } = useQuery(URL)

	return {
		custodian: data?.data?.custodianDetails || [],
		isLoading,
	}
}

const createOption = (value, label) => ({ label, value })

export const getCustodianOptions = (custodian) => {
	try {
		if (Array.isArray(custodian)) {
			return custodian.map((user) => createOption(user?.id, `${user?.name}`))
		} else {
			return []
		}
	} catch (error) {
		return []
	}
}
