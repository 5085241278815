import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import FileCheckIcon from '../../../assets/svg/file-check-2.svg';

import { PdfViewer } from '../document-preview'
import ToolTip from '../tool-tip'

const FilePreviewCard = ({ mime_type, path, name, title }) => {
	let [isOpen, setIsOpen] = useState(false)

	function openModal() {
		setIsOpen(true)
	}
	const toggle = () => setIsOpen(!isOpen)

	const [type, extension] = mime_type ? mime_type.split('/') : []

	useEffect(() => {
		const body = document.getElementsByTagName('body')[0].style
		if (isOpen) {
			body.overflow = 'hidden'
		} else {
			body.overflow = 'auto'
		}

		return () => {
			body.overflow = 'auto'
		}
	}, [isOpen])

	return (
		<Fragment>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-[1008]' onClose={toggle}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/30' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all lg:max-w-[45rem]'>
									<div className='relative flex items-center justify-between rounded-3xl bg-blue-50 p-3'>
										<div className='absolute left-2 rounded-full !border !border-gray-300 bg-white p-2'>
											<img src={FileCheckIcon} alt="File Check" className="w-6 h-6 text-blue-500" />
										</div>
										<div className=' ms-10 text-[20px] font-bold'>
											File Preview
										</div>
										<button type='button' className='right-0' onClick={toggle}>
											<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
										</button>
									</div>
									<div className=' flex flex-col'>
										<div className='ms-4 flex flex-col justify-start p-2'>
											<div>
												<p className='m-0 p-0 text-lg font-medium'>
													You are previewing the document:
													<span className='text-blue-400'>
														{' '}
														{title || 'Document'}
													</span>
												</p>
											</div>
										</div>
										<div className='flex flex-col justify-start'>
											<div className=''>
												{type === 'image' && (
													<div className='mx-auto flex items-center justify-center p-3'>
														<img
															src={path}
															className='h-auto w-[600px]'
														/>
													</div>
												)}

												{type === 'application' && extension === 'pdf' && (
													<PdfViewer src={path} />
												)}
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
			<div className='flex min-w-[200px] items-center justify-between space-x-3 rounded-md !border bg-white px-3 py-2'>
				<div className='flex items-center space-x-3 '>
					<div className='h-auto w-9'>
						<FileIcon extension={extension} {...defaultStyles[extension]} />
					</div>
					<div className='flex flex-col'>
						{title ? (
							<Fragment>
								<span className='line-clamp-1 font-semibold' id={`document-1`}>
									{title}
								</span>
								<ToolTip targetId={`document-1`} tooltipText={title} />
							</Fragment>
						) : undefined}
						{name ? (
							<span
								className='file-name line-clamp-1 text-xs text-gray-500'
								title={name}>
								{name}
							</span>
						) : undefined}
					</div>
				</div>

				{path && (
					<a
						onClick={(e) => {
							e.preventDefault()
							openModal()
						}}
						href={path}
						className='link'>
						View
					</a>
				)}
			</div>
		</Fragment>
	)
}

export default FilePreviewCard
