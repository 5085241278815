import * as yup from 'yup'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import ClientMappingContainer from './client-mapping-container'

export default ClientMappingContainer

export const validationSchema = yup.object().shape({
	clients: yup
		.array()
		.of(
			yup.object().shape({
				client: yup.object().required('Client is required'),
			}),
		)
		.min(1, 'At least one client must be selected'),
	sftp: yup
		.array()
		.of(
			yup.object().shape({
				client: yup.object().required('SFTP client is required'),
			}),
		)
		.min(1, 'At least one SFTP client must be selected'),
})

export const getPayload = (value, team) => {
	const mappings = value.clients.map((client, index) => ({
		user_id: client?.client?.value,
		client_id: value.sftp[index]?.client?.value,
		team_id: team?.id,
	}))

	return { mappings: mappings }
}

export const initialValues = (data) => {
	const values =
		data?.length > 0
			? {
					clients: data?.map((item) => ({
						client: {
							label: <CreateUserOptionLabel user={item?.user} />,
							value: item?.user.id,
							fullName: item?.user.fullName,
							email: item?.user.email,
							type: 'user',
							id: item?.id,
						},
					})),
					sftp: data?.map((item) => ({
						client: {
							label: <CreateUserOptionLabel user={item?.client} />,
							value: item?.client.id,
							fullName: item?.client.fullName,
							type: 'sftp',
						},
					})),
			  }
			: {
					clients: [{ client: '' }],
					sftp: [{ client: '' }],
			  }
	return values
}
