import classNames from 'classnames'
import { useAppRouter, useUser } from 'hooks'
import { useLazyCheckSubscriptionLimitQuery } from 'modules/authentication/auth-api'
import UpdateSubscriptionPopup from 'modules/profile/my-subscriptions/update-subscription-popup/update-subscription-popup'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { routes } from '..'

const Header = () => {
	const location = useLocation()
	const isCreatePermission = isAllowTo(permissions?.workflowTemplate?.create)
	const { navigate } = useAppRouter()

	const { user } = useUser()

	const [checkSubscriptionLimit, { isLoading: isFetching, data: limit }] =
		useLazyCheckSubscriptionLimitQuery()
	const tabs = useMemo(
		() => [
			{
				label: 'Templates',
				href: '/workflows',
				permission: isAllowTo(permissions?.workflowTemplate?.list),
			},
			{
				label: 'Assigned To Me',
				href: '/assigned-workflows',
				permission: isAllowTo(permissions?.assignedWorkflow?.list),
			},
			{
				label: 'In Review',
				href: '/workflows-final-reviews',
				permission: isAllowTo(permissions?.workflowFinalReview?.list),
			},
			{
				label: 'Summary',
				href: '/workflows-outstandings',
				permission: isAllowTo(permissions?.workflowOutstandings?.list),
			},
		],
		[],
	)

	const activeTab = useMemo(
		() => tabs.find((tab) => tab?.href === location?.pathname),
		[location?.pathname],
	)

	useEffect(() => {
		if (user?.id) {
			checkSubscriptionLimit('workflow')
		}
	}, [])

	const [isOpen, setIsOpen] = useState(false)

	const handleClick = () => {
		if (limit?.data?.availableLimit <= 0) {
			setIsOpen(true)
		} else {
			navigate(routes.create())
		}
	}

	return (
		<div className='flex w-full flex-col'>
			{isOpen && (
				<UpdateSubscriptionPopup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					title='Upgrade Your Plan'
					description='You’ve reached the workflow creation limit for your current subscription. Upgrade your plan to create more workflow.'
				/>
			)}
			<div className='mb-3 flex w-full justify-between md:hidden'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>{activeTab?.label}</h4>
					{/* <p className='text-xs text-[#74788D]'>
						The most important feature in the workflow section is the creating one. When
						assigning documents/packages here,
						<br />
						do not ignore to fill in all the required fields completely and follow the
						document/package adding rules.
					</p> */}
				</div>

				{isCreatePermission && (
					<Button
						type='button'
						variant='primary'
						onClick={handleClick}
						disabled={isFetching}>
						Create Workflow
					</Button>
				)}
			</div>
			<div className='smaller_alignment -mb-[12px] space-x-2'>
				{tabs
					.filter((item) => item?.permission)
					.map((tab, i) => (
						<Link
							to={tab.href}
							key={i}
							className={classNames(
								'remove_padding border-b-2 px-[10px] py-[10px] text-sm font-bold text-gray-400',
								{
									'border-main text-main': tab?.href == location?.pathname,
								},
							)}>
							{tab.label}
						</Link>
					))}
			</div>
		</div>
	)
}

export default Header
