import { useFormik } from 'formik'
import { useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'

// Define the enum as a plain object
const DeleteTaskOption = {
	ONLY_THIS_TASK: 'ONLY_THIS_TASK',
	THIS_AND_FUTURE: 'THIS_AND_FUTURE',
	ALL_FUTURE_EXCEPT_THIS: 'ALL_FUTURE_EXCEPT_THIS',
}

const DeleteConfirmAlert = ({
	title,
	subtitle,
	isOpen,
	onClose,
	onConfirm,
	isDeleting,
	variant = 'danger',
	isLoadingText = 'Deleting...',
	deleteBtnText,
}) => {
	const formik = useFormik({
		initialValues: {
			deleteOption: DeleteTaskOption.ONLY_THIS_TASK, // Default value
		},
		validationSchema: Yup.object({
			deleteOption: Yup.string()
				.oneOf(Object.values(DeleteTaskOption))
				.required('Please select an option'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			try {
				await onConfirm(values)
				Swal.fire({
					title: 'Done!',
					text: 'Action completed successfully.',
					icon: 'success',
					confirmButtonText: 'OK',
				})
			} catch (error) {
				Swal.fire({
					title: 'Error!',
					text: 'Something went wrong.',
					icon: 'error',
					confirmButtonText: 'OK',
				})
			} finally {
				setSubmitting(false)
				onClose() // Ensure dialog closes after submission
			}
		},
	})

	// Use a ref to track if the dialog has been confirmed
	const hasConfirmedRef = useRef(false)

	useEffect(() => {
		if (isOpen && !hasConfirmedRef.current) {
			Swal.fire({
				title: title || 'Are you sure?',
				text: subtitle || "You won't be able to revert this!",
				icon: 'warning',
				html: `
          <form id="delete-form">
            <div class="text-[medium]">              
              <div class="flex flex-col gap-2">
                <label class="flex items-center">
                  <input 
                    type="radio" 
                    name="deleteOption" 
                    value="${DeleteTaskOption.ONLY_THIS_TASK}" 
                    ${
						formik.values.deleteOption === DeleteTaskOption.ONLY_THIS_TASK
							? 'checked'
							: ''
					}
                    onchange="document.getElementById('deleteOption-hidden').value = this.value"
                    class="mr-2 form-check-input"
                  />
                  Only this task
                </label>
                <label class="flex items-center">
                  <input 
                    type="radio" 
                    name="deleteOption" 
                    value="${DeleteTaskOption.THIS_AND_FUTURE}" 
                    ${
						formik.values.deleteOption === DeleteTaskOption.THIS_AND_FUTURE
							? 'checked'
							: ''
					}
                    onchange="document.getElementById('deleteOption-hidden').value = this.value"
                    class="mr-2 form-check-input"
                  />
                  This task and all future tasks
                </label>
                <label class="flex items-center">
                  <input 
                    type="radio" 
                    name="deleteOption" 
                    value="${DeleteTaskOption.ALL_FUTURE_EXCEPT_THIS}" 
                    ${
						formik.values.deleteOption === DeleteTaskOption.ALL_FUTURE_EXCEPT_THIS
							? 'checked'
							: ''
					}
                    onchange="document.getElementById('deleteOption-hidden').value = this.value"
                    class="mr-2 form-check-input"
                  />
                  All future tasks except this one
                </label>
              </div>
              ${
					formik.touched.deleteOption && formik.errors.deleteOption
						? `<div class="text-red-600 text-sm mt-1">${formik.errors.deleteOption}</div>`
						: ''
				}
            </div>            
          </form>
        `,
				showCancelButton: true,
				confirmButtonText: isDeleting ? isLoadingText : deleteBtnText || 'Yes',
				cancelButtonText: 'No',
				customClass: {
					confirmButton: `swal2-confirm ${
						variant === 'danger'
							? 'bg-green-600 hover:bg-green-700'
							: 'bg-green-600 hover:bg-green-700'
					} rounded-md text-white`,
					cancelButton:
						'swal2-cancel bg-red-600 hover:bg-red-700 text-white border border-red-600 rounded-md',
				},
				showLoaderOnConfirm: isDeleting || formik.isSubmitting,
				allowOutsideClick: false,
				preConfirm: () => {
					if (isDeleting || formik.isSubmitting) return false
					const deleteOption =
						document.getElementById('deleteOption-hidden')?.value ||
						formik.values.deleteOption

					formik.setValues({ deleteOption })
					return formik.validateForm().then((errors) => {
						if (Object.keys(errors).length === 0) {
							hasConfirmedRef.current = true // Mark as confirmed
							formik.handleSubmit()
							return false // Prevent immediate closure by Swal
						} else {
							Swal.showValidationMessage('Please fix the errors')
							return false
						}
					})
				},
			}).then((result) => {
				if (result.dismiss) {
					onClose()
				}
			})

			// Add hidden input for deleteOption if not already present
			if (!document.getElementById('deleteOption-hidden')) {
				const hiddenInput = document.createElement('input')
				hiddenInput.id = 'deleteOption-hidden'
				hiddenInput.name = 'deleteOption'
				hiddenInput.type = 'hidden'
				hiddenInput.value = formik.values.deleteOption
				document.getElementById('delete-form')?.appendChild(hiddenInput)
			}
		}
	}, [isOpen, isDeleting]) // Removed `formik` from deps, relying on stable props

	// Reset confirmation flag when isOpen changes to false
	useEffect(() => {
		if (!isOpen) {
			hasConfirmedRef.current = false
		}
	}, [isOpen])

	return null
}

export default DeleteConfirmAlert
