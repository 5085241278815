import classNames from 'classnames'
import ToolTip from 'theme/ui/tool-tip'

const DocumentActions = ({ document, toggle, index, status }) => {
	const fileUrl = document?.file?.path

	const documentObj = {
		fileUrl,
		id: document?.id,
		workflow_step_document_id: document?.workflow_step?.id,
	}

	// const canDownload = isAllowTo(permissions?.completedDocument?.download) && fileUrl
	// const canView = isAllowTo(permissions?.completedDocument?.view) && fileUrl
	const canDownload = fileUrl
	const canView = fileUrl

	return (
		<div className='flex max-w-[200px] space-x-3'>
			{status ? (
				<span>{status}</span>
			) : (
				<>
					<ToolTip targetId={`view_${index}`} tooltipText='View Document' />
					<ToolTip targetId={`download_${index}`} tooltipText='Download Document' />
					<button
						type='button'
						id={`view_${index}`}
						disabled={!canView}
						className={classNames('action-btn', {
							'btn-primary-outline': canView,
							'btn-gray-outline': !canView,
						})}
						onClick={() => canView && toggle(documentObj)}>
						<i className='uil uil-eye' style={{ fontSize: 16 }} />
					</button>
					<a
						href={fileUrl}
						download
						id={`download_${index}`}
						className={`action-btn ${
							fileUrl && canDownload
								? 'btn-info-outline cursor-pointer'
								: 'btn-gray-outline'
						}`}>
						<i className='uil uil-import' style={{ fontSize: 16 }} />
					</a>
				</>
			)}
		</div>
	)
}

export default DocumentActions
