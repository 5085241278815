import * as yup from 'yup'
import CustomFields from './custom-fields'

export const validationSchema = yup.object().shape({
	fields: yup
		.array()
		.of(
			yup
				.object()
				.shape({
					key: yup.mixed().required('Please choose field key'),
					value: yup.string().required('Please enter field value'),
				})
				.required('Please enter field value'),
		)
		.min(1, 'Please enter field'),
})

export const getPayload = (values) => {
	return {
		user: values?.user,
		fields: [
			{
				values: values?.value,
				key: values?.key?.value,
			},
		],
	}
}

export default CustomFields
