import { Field, Form, FormikProvider, useFormik } from 'formik'
import {
	useCreateTaskStatusMutation,
	useLazyGetBoardColumnsByCategoryQuery,
} from 'modules/scrum-boards/boards-api'
import { TaskCategoryDropdown } from 'modules/shared'
import StatusQuery from 'modules/workflows/workflows-outstandings/filters/status-dropdown/status-query'
import React, { useEffect, useMemo } from 'react'
import { Button, CreatableSelectField, SelectField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'

const MapStatusForm = ({ board, onClose }) => {
	const { options } = StatusQuery('')
	const [createTaskStatus] = useCreateTaskStatusMutation()
	const formik = useFormik({
		initialValues: {
			name: null,
			category: null,
			column: null,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				const response = await createTaskStatus(payload).unwrap()
				if (response?.status === 201) {
					notification('success', response?.message)
					resetForm()
					onClose()
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const categoryId = formik?.values?.category?.value
	const [fetchColumn, { data, isFetching }] = useLazyGetBoardColumnsByCategoryQuery()
	useEffect(() => {
		if (categoryId && board?.id) {
			fetchColumn({ boardId: board?.id, categoryId: categoryId })
		}
	}, [categoryId, board?.id])

	const columnOptions = useMemo(
		() =>
			Array.isArray(data?.data)
				? data?.data.map((column) => ({
						label: column?.name,
						value: column?.id,
					}))
				: [],
		[data?.data],
	)

	return (
		<FormikProvider value={formik}>
			<Form className='space-y-4'>
				<Field
					label='Name'
					name='name'
					options={[
						{
							label: 'Workflow Statuses',
							options: options,
						},
					]}
					component={CreatableSelectField}
				/>

				<TaskCategoryDropdown label='Category' name='category' />

				<Field
					label='Column'
					name='column'
					options={columnOptions}
					component={SelectField}
					isLoading={isFetching}
				/>
				<div className='flex justify-center items-center'>
					<Button type='submit' isLoading={formik.isSubmitting}>
						Add Status
					</Button>
					<Button type='button' onClick={onClose} variant='ghost'>
						Cancel
					</Button>
				</div>
			</Form>
		</FormikProvider>
	)
}

export default MapStatusForm
