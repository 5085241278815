import classNames from 'classnames'
import { Field } from 'formik'

const DocumentTypeField = ({ name, formik, disabled = false }) => {
	const { errors, touched, setFieldValue } = formik
	const options = [
		{
			value: 'document',
			label: 'Mapped PDF',
			description:
				'The document template will require the PDF to be uploaded, with no option to create a form.',
		},
		{
			value: 'form',
			label: 'Form',
			description:
				'In the form template, you have the ability to create the form from the ground up.',
		},
		{
			value: 'public_form',
			label: 'Public Form',
			description:
				'In the public form template, you have the ability to create the public form from the ground up.',
		},
	]

	const handleChange = (field, value) => {
		setFieldValue(field, value)

		switch (value) {
			case 'public_form':
				setFieldValue('purpose', 'link')
				break
			case 'form':
			case 'document':
				setFieldValue('purpose', 'workflow')
				break
			default:
				setFieldValue('purpose', 'workflow')
				break
		}
	}

	return (
		<div>
			<ul className='m-0 grid w-full gap-6 p-0 md:grid-cols-3'>
				{options.map((option) => (
					<li key={option.value} className='flex'>
						<Field name={name}>
							{({ field }) => (
								<label
									htmlFor={option.value}
									className={classNames(
										'inline-flex w-full items-baseline gap-2.5 rounded-md !border border-gray-200 bg-white !px-3 !py-3 text-gray-500 active:bg-main/20 peer-checked:border-main peer-checked:text-main',
										disabled
											? 'cursor-not-allowed select-none opacity-80'
											: 'cursor-pointer hover:border-main',
										{
											'border-main bg-main/20 text-main':
												field.value === option.value,
											'hover:text-gray-600':
												field.value !== option.value && !disabled,
										},
									)}>
									<input
										type='radio'
										id={option.value}
										{...field}
										value={option.value}
										disabled={disabled}
										checked={field.value === option.value}
										className='form-check-input peer disabled:cursor-not-allowed'
										onChange={() => handleChange(name, option.value)}
									/>
									<div className='block'>
										<div className='w-full text-lg font-semibold'>
											{option.label}
										</div>
										{option.description && (
											<div className='w-full text-sm font-normal'>
												{option.description}
											</div>
										)}
									</div>
								</label>
							)}
						</Field>
					</li>
				))}
			</ul>
			{errors[name] && touched[name] && (
				<div className='ml-1 text-[13px] text-red-600'>{errors[name]}</div>
			)}
		</div>
	)
}

export default DocumentTypeField
