import { useState, useEffect } from "react";
import { useUser } from "hooks";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Spinner from 'theme/ui/spinner'
import {
    useLazyGetCommentsQuery,
    useCreateCommentMutation,
    useUpdateCommentMutation,
    useDeleteCommentMutation,
} from "./comments-api";

const TaskComments = ({ taskId }) => {
    const { user } = useUser();
    const [comments, setComments] = useState([]);
    const [editingComment, setEditingComment] = useState(null);
    const [editorData, setEditorData] = useState("");
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [loading, setLoading] = useState({ post: false, edit: false, delete: null });

    // API Hooks
    const [getComments, { data }] = useLazyGetCommentsQuery();
    const [createComment] = useCreateCommentMutation();
    const [updateComment] = useUpdateCommentMutation();
    const [deleteComment] = useDeleteCommentMutation();

    useEffect(() => {
        if (taskId) {
            getComments({ task: taskId });
        }
    }, [taskId, getComments]);

    useEffect(() => {
        if (data?.comments) {
            setComments(data.comments);
        }
    }, [data]);

    const openEditor = () => {
        setEditorData("");
        setIsEditorOpen(true);
    };

    const handleEdit = (id, text) => {
        setEditingComment(id);
        setEditorData(text);
        setIsEditorOpen(true);
    };

    const saveComment = async () => {
        if (editorData.trim() === "") return;

        setLoading({ ...loading, post: true });

        try {
            const response = await createComment({
                task: taskId,
                message: editorData,
                type: "task_comment",
                parent_comment_id: null,
            }).unwrap();

            setComments([...comments, { ...response, user: user || { fullName: "Unknown User" } }]);
            setIsEditorOpen(false);
            setEditorData("");
        } catch (error) {
            console.error("Error creating comment:", error);
        } finally {
            setLoading({ ...loading, post: false });
        }
    };

    const saveEditedComment = async () => {
        if (!editingComment || editorData.trim() === "") return;

        setLoading({ ...loading, edit: true });

        try {
            const response = await updateComment({ id: editingComment, payload: { message: editorData } }).unwrap();

            setComments(comments.map(comment =>
                comment.id === editingComment ? { ...comment, message: response.message } : comment
            ));

            setEditingComment(null);
            setIsEditorOpen(false);
            setEditorData("");
        } catch (error) {
            console.error("Error updating comment:", error);
        } finally {
            setLoading({ ...loading, edit: false });
        }
    };

    const handleDelete = async (id) => {
        setLoading({ ...loading, delete: id });

        try {
            await deleteComment(id).unwrap();
            setComments(comments.filter(comment => comment.id !== id));
        } catch (error) {
            console.error("Error deleting comment:", error);
        } finally {
            setLoading({ ...loading, delete: null });
        }
    };

    const [loadingComments, setLoadingComments] = useState(true);

    useEffect(() => {
        if (taskId) {
            setLoadingComments(true);
            getComments({ task: taskId }).finally(() => setLoadingComments(false));
        }
    }, [taskId, getComments]);

    return (
        <div className="mt-2">
            <div className="flex flex-col w-full items-center gap-2 bg-gray-100 p-3 rounded-md">
                {!isEditorOpen ? (
                    <div className="flex w-full items-center gap-2 cursor-pointer" onClick={openEditor}>
                        <div className="w-8 h-8 flex items-center justify-center rounded-full text-xs font-semibold bg-gray-300 text-gray-700">
                            {user?.avatar ? (
                                <img
                                src={user.avatar.path}
                                alt={user.fullName}
                                className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                (user?.fullName || "?")
                                .split(" ")
                                .map(word => word[0])
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()
                            )}
                        </div>
                        <div className="w-full border border-gray-300 p-2 rounded-md bg-white flex items-center">
                            <p className="text-sm text-gray-500 items-center mb-0 p-1">Add a comment...</p>
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex items-top gap-2">
                        <div className="w-8 h-8 flex items-center justify-center rounded-full text-xs font-semibold bg-gray-300 text-gray-700">
                        {user?.avatar ? (
                                <img
                                src={user.avatar.path}
                                alt={user.fullName}
                                className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                (user?.fullName || "?")
                                .split(" ")
                                .map(word => word[0])
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()
                            )}
                        </div>
                        <div className="flex flex-col w-full">
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: [
                                        "heading", "|",
                                        "bold", "italic", "underline", "strikethrough", "|",
                                        "blockQuote", "code", "|",
                                        "bulletedList", "numberedList", "indent", "outdent", "|",
                                        "insertTable", "|",
                                        "undo", "redo"
                                    ]
                                }}
                                data={editorData}
                                onChange={(event, editor) => setEditorData(editor.getData())}
                            />
                            <div className="flex justify-end gap-3 mt-2">
                                <button className="text-sm font-bold text-gray-700" onClick={() => setIsEditorOpen(false)}>
                                    Cancel
                                </button>
                                <button
                                    className="text-sm font-bold text-blue-500"
                                    onClick={editingComment ? saveEditedComment : saveComment}
                                    disabled={loading.post || loading.edit}
                                >
                                    {loading.post ? "Posting..." : loading.edit ? "Saving..." : editingComment ? "Save Changes" : "Post Comment"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Comments List */}
                {loadingComments && (
                    <div className="flex justify-start items-center">
                        <div className=""><Spinner label={'Loading Comments...'} /></div>
                    </div>
                )}
                <div className="max-h-[245px] w-full overflow-y-auto">
                    {comments.map(comment => (
                        <div key={comment.id} className="border-t pt-3 mt-3">
                            <div className="flex gap-2">
                            <div className="w-8 h-8 flex items-center justify-center rounded-full text-xs font-semibold bg-gray-300 text-gray-700 overflow-hidden">
                                {comment.user?.avatar ? (
                                    <img
                                    src={comment.user.avatar.path}
                                    alt={comment.user.fullName}
                                    className="w-full h-full object-cover rounded-full"
                                    />
                                ) : (
                                    (comment.user?.fullName || "?")
                                    .split(" ")
                                    .map(word => word[0])
                                    .slice(0, 2)
                                    .join("")
                                    .toUpperCase()
                                )}
                            </div>

                                <div className="flex flex-col w-full gap-2">
                                    <div className="text-sm font-bold text-gray-700">{comment.user?.fullName || "Unknown User"}</div>
                                    <div
                                        className="flex text-sm text-gray-700 p-2 bg-gray-200 rounded-3 items-center"
                                        dangerouslySetInnerHTML={{ __html: comment.message.replace(/<p>/g, "<div>").replace(/<\/p>/g, "</div>") }}
                                    />

                                    <div className="flex justify-between text-xs text-gray-500">
                                        <div>
                                            <span className="cursor-pointer text-blue-500" onClick={() => handleEdit(comment.id, comment.message)}>Edit</span> •
                                            <span
                                                className={`cursor-pointer ml-2 ${loading.delete === comment.id ? "text-gray-500" : "text-red-500"}`}
                                                onClick={() => handleDelete(comment.id)}
                                                disabled={loading.delete === comment.id}
                                            >
                                                {loading.delete === comment.id ? "Deleting..." : "Delete"}
                                            </span>
                                        </div>
                                        <span className='pr-2'>
                                        {new Date(comment.created_at).toLocaleString("en-US", {
                                            month: "long",
                                            day: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: true,
                                        })}
                                    </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default TaskComments;