/* eslint-disable react-hooks/exhaustive-deps */
import Gleap from 'gleap'
import { useLazyGetStepStatusQuery } from 'modules/authentication/auth-api'
import { fetchPermissions } from 'modules/authentication/login'
import { useLazyGetNotificationsQuery } from 'modules/notifications/notification-api'
import PropTypes from 'prop-types'
import React, { Component, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import store, { dispatch } from 'store'
import * as notificationActions from 'store/notifications/actions'
import * as onboardingActions from 'store/onBoardingStatus/actions'
import IsAuthenticated from 'utils/isAuthenticated'
import './assets/scss/theme.scss'
import ClientOnBoardingLayout from './components/ClientOnBoardingLayout'
import NonAuthLayout from './components/NonAuthLayout'
import { authRoutes, clientOnBoardingRoutes, publicRoutes, userRoutes } from './routes/allRoutes'
import Authmiddleware from './routes/middleware/Authmiddleware'

const App = () => {
	const { defaultGroup, isSuperAdmin } = useSelector((state) => state.Permissions)
	const [fetchNotifications, { data }] = useLazyGetNotificationsQuery()
	const isLoggedIn = IsAuthenticated()
	useEffect(() => {
		const groupSlug = defaultGroup?.defaultGroup?.group?.slug
		if (['owner', 'advisor'].includes(groupSlug) && process.env.REACT_APP_GLEAP_KEY) {
			Gleap.initialize(process.env.REACT_APP_GLEAP_KEY)
		}
	}, [defaultGroup])

	useEffect(() => {
		if (isLoggedIn) fetchPermissions().finally(() => {})
	}, [isLoggedIn])

	useEffect(() => {
		if (!isLoggedIn) return

		const fetchData = () => {
			fetchNotifications({ page: 1, limit: 10 })
			dispatch(notificationActions.setNotificationsProgress(0))
		}
		fetchData()

		const intervalId = setInterval(fetchData, 60000)
		return () => clearInterval(intervalId)
	}, [isLoggedIn])

	useEffect(() => {
		if (data?.notifications?.length) {
			dispatch(notificationActions.setNotifications(data))
		}
	}, [data])

	useEffect(() => {
		if (!isLoggedIn) return

		const progressInterval = setInterval(() => {
			const currentProgress = store.getState().notifications.notificationsProgress
			const newProgress = currentProgress >= 100 ? 100 : currentProgress + 10
			dispatch(notificationActions.setNotificationsProgress(newProgress))
		}, 6000)
		return () => clearInterval(progressInterval)
	}, [isLoggedIn])
	const [fetchStepStatus, { isLoading }] = useLazyGetStepStatusQuery()
	useEffect(() => {
		if (!isLoggedIn) return

		const fetchData = async () => {
			try {
				const response = await fetchStepStatus()
				const onboardStatus =
					defaultGroup?.group?.slug !== 'client' ? 'Approved' : response?.data?.onboardStatus
				const data = response?.data || []

				dispatch(onboardingActions.setOnBoardStatus(onboardStatus))
				dispatch(onboardingActions.setData(data))
			} catch (error) {
				console.error('Error fetching onboarding status:', error)
			}
		}

		fetchData()
		dispatch(onboardingActions.setIsLoading(isLoading))
	}, [isLoggedIn, fetchStepStatus, defaultGroup, isLoading])
	return (
		<React.Fragment>
			<Router>
				<Switch>
					{publicRoutes.map((route, idx) => (
						<Route key={idx} {...route} render={(props) => <Component {...props} />} />
					))}

					{authRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{clientOnBoardingRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={ClientOnBoardingLayout}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}

					{userRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}
				</Switch>
			</Router>
		</React.Fragment>
	)
}

App.propTypes = {
	layout: PropTypes.any,
}

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	}
}

export default connect(mapStateToProps, null)(App)
