import * as Yup from 'yup'
import BoardForm from './board-form'

export const validationSchema = Yup.object().shape({
	name: Yup.string().required('Scrum board name is required'),
	description: Yup.string().required('Description is required'),
	users: Yup.array().min(1, 'At least one person must be added'),
})

export default BoardForm
