import { Dialog, Transition } from '@headlessui/react'
import { SignatureFieldView } from 'modules/form-designer/field-render/signature-field'
import { Fragment, useState } from 'react'
import { Button } from 'theme/ui/forms'

const SignatureModal = ({ isOpen, onClose, signatureData, onSave, setSignatureId }) => {
	const [signatureValue, setSignatureValue] = useState(null)

	const handleSignatureChange = (name, value) => {
		if (value) {
			setSignatureValue(value)
		} else {
			onSave(null)
			setSignatureValue(null)
		}
	}

	const handleAddSignature = () => {
		onSave(signatureValue)
		onClose()
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-8 text-left align-middle shadow-xl transition-all'>
								<div className='flex items-center justify-between'>
									<h2 className='text-2xl font-semibold text-gray-800'>
										Signature
									</h2>
									<button
										type='button'
										onClick={onClose}
										className='text-gray-500 hover:text-gray-800'>
										<i className='fas fa-times text-2xl' />
									</button>
								</div>

								<div className='mt-6'>
									<SignatureFieldView
										name={signatureData?.name}
										field={signatureData}
										onChange={handleSignatureChange}
										isDocument={true}
										setSignatureId={setSignatureId}
									/>
								</div>

								<div className='mb-1 mt-3 flex w-full flex-col gap-2 '>
									<Button
										variant='primary'
										type='button'
										block={true}
										disabled={!signatureValue}
										onClick={handleAddSignature}>
										Add Signature
									</Button>
									<Button
										variant='white'
										type='button'
										block={true}
										onClick={onClose}>
										Cancel
									</Button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SignatureModal
