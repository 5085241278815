import { FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import {
	useLazyGetPublicFormQuery,
	useSubmitPublicFormMutation,
} from 'modules/documents/document-api'
import { FormDesignContainer } from 'modules/form-designer'
import { useEffect, useState } from 'react'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'
import PublicHeader from './public-header'

const PublicForm = () => {
	const { params } = useAppRouter()
	const [fetchPublicForm, { data, isFetching }] = useLazyGetPublicFormQuery()
	const [formData, setFormData] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [formSettings, setFormSettings] = useState(null)
	const [infoMessage, setInfoMessage] = useState(null)
	const [isLoading, setIsloading] = useState(true)
	const [submitForm] = useSubmitPublicFormMutation()
	useEffect(() => {
		setTimeout(() => {
			setIsloading(false)
		}, 1000)
	}, [])

	useEffect(() => {
		if (params?.id) {
			fetchPublicForm(params?.id)
		}
	}, [params?.id])

	const submitAnotherResponse = () => {
		formik.resetForm({ values: { fields: [] } })
		if (params?.id) {
			fetchPublicForm(params?.id)
			setIsSubmitted(false)
		}
	}

	useEffect(() => {
		if (data?.status === 200 && data?.data) {
			const fields = Array.isArray(data?.data?.document_body) ? data?.data?.document_body : []
			setFormData(fields)
		} else {
			if (data?.message) {
				setInfoMessage(data?.message)
			}
		}
	}, [data])

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: { fields: formData },
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setIsSubmitting(true)
			const payload = getPayload(values, data?.data)
			const response = await submitForm(payload)
			if (response?.data?.status === 200) {
				setIsSubmitting(false)
				setIsSubmitted(true)
				setFormSettings(response?.data?.data?.documentData?.formPublicLinkSettings[0])
				notification('success', response?.data?.message)
			}
		},
	})

	useEffect(() => {
		formik.setValues({ fields: formData })
	}, [formData])

	return (
		<>
			<PublicHeader />
			<div className='my-auto flex min-h-screen w-full flex-col space-y-6'>
				{isFetching || isLoading ? (
					<div className='mt-[100px] px-4'>
						<Spinner />
					</div>
				) : isSubmitted ? (
					<div className='page-container'>
						<div className='flex items-center justify-center page-body w-full min-h-screen bg-gray-100 px-4'>
							<div className='bg-white p-8 rounded-xl shadow-sm max-w-md text-center'>
								<div className='flex justify-center mb-4'>
									<svg
										className='w-16 h-16 text-green-500'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										viewBox='0 0 24 24'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M9 12l2 2 4-4M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
									</svg>
								</div>
								<h2 className='text-2xl font-semibold text-gray-800'>
									Form Submitted Successfully!
								</h2>
								<p className='mt-2 text-gray-600'>
									{formSettings?.custom_thank_you_message
										? formSettings?.custom_thank_you_message
										: 'Thank you for your submission. Your response has been recorded.'}
								</p>
								{!formSettings?.submit_another_response && (
									<p
										className='cursor-pointer underline text-blue-500 mt-2'
										onClick={submitAnotherResponse}>
										Submit Another Response
									</p>
								)}
							</div>
						</div>
					</div>
				) : formData?.length > 0 ? (
					<div className='mt-[70px] px-4'>
						<div className='page-container'>
							<div className='page-body w-full '>
								<FormikProvider value={formik}>
									<form onSubmit={formik.handleSubmit}>
										<FormDesignContainer
											document_title={data?.data?.title}
											formik={formik}
											is_score_able={false}
											fieldName='fields'
											fields={formik.values.fields}
											is_view_mode={true}
											is_read_mode={false}
										/>
										<div className='flex justify-end mt-3'>
											<Button
												type='submit'
												title={
													formik.errors.fields?.length > 0
														? 'Please fill all the required fields before submitting'
														: 'Submit Form'
												}
												isLoading={isSubmitting}
												disabled={formik.errors.fields?.length > 0}>
												Submit
											</Button>
										</div>
									</form>
								</FormikProvider>
							</div>
						</div>
					</div>
				) : (
					<div className='page-container'>
						<div className='flex items-center justify-center page-body w-full min-h-screen bg-gray-100 px-4'>
							<div className='bg-white p-8 rounded-xl shadow-sm max-w-md text-center'>
								<div className='flex justify-center mb-4'>
									<svg
										className='w-16 h-16 text-red-500'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										viewBox='0 0 24 24'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M12 9v2m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
										/>
									</svg>
								</div>
								<h2 className='text-2xl font-semibold text-gray-800'>
									{infoMessage
										? infoMessage
										: 'This form is currently not accepting responses.'}
								</h2>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default PublicForm
