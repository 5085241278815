const FieldsTable = ({ fields }) => {
	// Helper function to get the display value
	const getDisplayValue = (field) => {
		// Handle boolean values
		if (typeof field?.value === 'boolean') {
			return field.value ? 'Yes' : 'No'
		}

		// If no values array exists or value is empty, return the raw value
		if (!field?.values || !field?.value) {
			return field?.value?.toString() || ''
		}

		// Handle multiple values (e.g., checkbox with comma-separated values)
		if (field.type === 'checkbox' && field.value.includes(',')) {
			const selectedValues = field.value.split(',')
			const labels = selectedValues
				.map((val) => {
					const matchedOption = field.values.find((option) => option.value === val.trim())
					return matchedOption ? matchedOption.label : val.trim()
				})
				.join(', ')
			return labels
		}

		// Handle single value (select or radio)
		const matchedOption = field.values.find((option) => option.value === field.value)
		return matchedOption ? matchedOption.label : field.value.toString()
	}

	return (
		<div className='table-responsive'>
			<table className='table-border table-1 mb-0 border text-sm'>
				<thead>
					<tr className='divide-x'>
						<th>#</th>
						<th>Field Label</th>
						<th>Field Value</th>
					</tr>
				</thead>
				<tbody>
					{fields.length > 0 ? (
						fields.map((field, idx) => (
							<tr key={idx} className='divide-x'>
								<td className='vertical-middle'>{idx + 1}</td>
								<td className='vertical-middle capitalize'>{field?.label}</td>
								<td className='vertical-middle'>{getDisplayValue(field)}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>
								<p className='m-0 text-center'>No fields found here</p>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default FieldsTable
