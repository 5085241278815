import { FormBuilderProvider } from 'contexts/formbuilder-provider'
import { TabsProvider } from 'contexts/tabs-context'
import { useAppRouter } from 'hooks'
import {
	DocumentTemplateForm,
	DocumentTemplateFormSkeleton,
	DocumentWorkflows,
} from 'modules/documents'
import { useLazyGetOneDocumentTemplateQuery } from 'modules/documents/document-api'
import { extractPdfFields2 } from 'modules/documents/document-template-form/helpers'
import { DocumentViewerProvider } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import { ErrorCard } from 'pages/StaticPages'
import { useEffect, useMemo, useState } from 'react'
import { AppLayout, PageHeader } from 'theme/layouts'

const EditDocumentTemplate = () => {
	const { params, parseQuery } = useAppRouter()
	const [title, setTitle] = useState('Document')

	const [document, setDocument] = useState(null)
	const [isLoading, setIsLoading] = useState(false) // Added explicit loading state
	const [error, setError] = useState(null) // Added error state
	const ID = params?.id

	const [fetchDocumentTemplate, { data, isFetching }] = useLazyGetOneDocumentTemplateQuery()

	const workflows = useMemo(
		() => (Array.isArray(data?.document?.workflowAttach) ? data?.document?.workflowAttach : []),
		[data],
	)

	const fetchDocument = async (id) => {
		try {
			setIsLoading(true)
			setError(null)
			setDocument(null)

			const response = await fetchDocumentTemplate(id).unwrap()
			const fetchedDocument = response?.document

			if (response?.status !== 200) {
				throw new Error('Something went wrong while fetching the document.')
			}

			if (fetchedDocument?.document_type === 'document' && fetchedDocument?.s3_path) {
				const pdfResponse = await fetch(process.env.REACT_APP_EXTRACT_PDF_FIELD_API, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ s3_path: fetchedDocument.s3_path }),
				})
				const result = await pdfResponse.json()

				if (result.statusCode === 200 && result.body) {
					const extractedFields = Object.values(result.body)
					setDocument({
						...fetchedDocument,
						pdf_fields: !result?.body?.error ? extractPdfFields2(extractedFields) : [],
					})
				} else {
					throw new Error('Failed to extract PDF fields.')
				}
			} else {
				setDocument(fetchedDocument)
			}
		} catch (err) {
			setError(err.message || 'An error occurred while loading the document')
			setDocument(null)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (ID) {
			fetchDocument(ID)
		}
	}, [ID])

	const pageTitle =
		ID && !parseQuery?.isCreate ? `Edit ${title} Template` : `Create ${title} Template`

	return (
		<AppLayout
			meta={{
				title: pageTitle,
				hasBackButton: true,
				redirectTo: '/file-manager/documents?page=1&limit=10',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title={pageTitle}
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton
						innerClassName={'block sm:hidden'}
					/>
					<DocumentViewerProvider>
						<div style={{ height: 'calc(100vh - 70px)', overflow: 'auto' }}>
							{isLoading || isFetching ? (
								<DocumentTemplateFormSkeleton />
							) : error ? (
								<ErrorCard message={error} />
							) : document ? (
								workflows.length > 0 ? (
									<DocumentWorkflows
										id={ID}
										workflows={workflows}
										fetchDocument={fetchDocument}
										totalWorkflows={workflows.length}
									/>
								) : (
									<FormBuilderProvider>
										<TabsProvider>
											<DocumentTemplateForm
												document={document}
												setTitle={setTitle}
											/>
										</TabsProvider>
									</FormBuilderProvider>
								)
							) : (
								<ErrorCard message='No document data available' />
							)}
						</div>
					</DocumentViewerProvider>
				</div>
			</div>
		</AppLayout>
	)
}

export default EditDocumentTemplate
