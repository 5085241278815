import { snakeCase } from 'lodash'
import { WorkflowStatuses } from 'utilities/helpers'
import { findObjectByField } from 'utils/commonFunctions'
import K from 'utils/constants'

export const getFileLabel = (files, user_type) => {
	try {
		const items = JSON.parse(files)
		const $items = items
			.filter((item) => item?.user_type === user_type)
			.map((file) => ({
				...file,
				description: file?.description || '',
			}))

		return $items
	} catch (_) {
		return []
	}
}

export const getFieldValue = (field) => {
	switch (field?.type) {
		case 'text':
			return field.value
		case 'signature':
			return <img src={field?.value} className='block h-full w-auto' />

		default:
			return field.value
	}
}

export const getWorkflowUrl = (defaultGroup, id) => {
	const group = defaultGroup?.group
	const role = snakeCase(group?.name)
	const CLIENT_KEY = 'client'
	return role === CLIENT_KEY ? `/client/workflow/${id}` : `/advisor/workflow/${id}`
}

export const transformResponse = (response) => {
	const workflow = response?.workflow
	const steps = workflow?.workflow_steps || []
	const signPriority = response?.signPriority
	const signature_status = response?.signatureStatus
	const isNotAvailableForSignature =
		workflow === null && signPriority === false && signature_status !== ''

	return {
		data: {
			signPriority,
			isAllDocumentsSigned: workflow?.isAllDocumentsSigned,
			workflow,
			steps,
			uploads: findObjectByField(steps, 'type', K.STEPS.UPLOADS),
			individualDocument: findObjectByField(steps, 'type', K.STEPS.INDIVIDUAL_DOCUMENT),
			createdBy: workflow?.created_by,
		},
		isNotAvailableForSignature,
		signature_status,
	}
}

export const createTabs = (workflow, uploads) => {
	const user_type = workflow?.user_type
	const files = getFileLabel(uploads?.note, user_type)
	const uploadedFiles = uploads?.files || []
	const $tabs = files.map((f, idx) => {
		const isCompleted =
			uploadedFiles.find(($f) => snakeCase($f?.title) === snakeCase(f?.file)) &&
			workflow?.status !== WorkflowStatuses.DRAFT
				? true
				: false
		const isRejected = uploadedFiles.find(
			($f) => snakeCase($f?.title) === snakeCase(f?.file),
		)?.correction

		return {
			id: idx,
			title: f?.file,
			isCompleted: isRejected ? isRejected : isCompleted,
			isRejected: isRejected,
		}
	})
	$tabs.push({
		id: files.length,
		title: 'Fill Details',
		isCompleted: false,
		isRejected: false,
	})
	return $tabs
}
