import { Field, Form, FormikProvider, useFormik } from 'formik'
import React from 'react'

import { useCreateBoardColumnMutation } from 'modules/scrum-boards/boards-api'
import { TaskCategoryDropdown } from 'modules/shared'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'
import { MAX_COLUMNS } from '../..'

const CreateColumnForm = ({ columns, board, setColumns, localDispatch, onCloseAddForm }) => {
	const [createColumn, { isLoading }] = useCreateBoardColumnMutation()

	const formik = useFormik({
		initialValues: {
			column_name: '',
			category: null,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if (columns.length >= MAX_COLUMNS) {
				notification('error', 'Maximum number of columns reached')
				return
			}
			const payload = getPayload(values, board?.id)

			const response = await createColumn(payload).unwrap()
			if (response?.status === 201) {
				const newColumn = {
					id: response?.data?.id,
					name: values?.column_name.trim(),
					cards: [],
					statuses: [],
				}
				setColumns((prev) => [...prev, newColumn])
				localDispatch({
					type: 'ADD_COLUMN',
					name: values?.column_name.trim(),
				})

				notification('success', response?.message)
				onCloseAddForm()
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<Form className='space-y-4'>
				<Field
					label='Column Name'
					name='column_name'
					type='text'
					autoFocus={true}
					component={InputField}
					placeholder='Enter the column name'
				/>

				<TaskCategoryDropdown label='Category' name='category' />
				<hr className='my-3 bg-gray-300' />
				<div className='flex gap-2 my-3 justify-center'>
					<Button type='submit' variant='primary' isLoading={isLoading}>
						Create
					</Button>
					<Button
						type='button'
						variant='ghost'
						onClick={() => {
							onCloseAddForm(false)
							formik.resetForm()
						}}>
						Cancel
					</Button>
				</div>
			</Form>
		</FormikProvider>
	)
}

export default CreateColumnForm
