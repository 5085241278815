import { useEffect } from 'react'
import { Offcanvas } from 'reactstrap'
import Spinner from 'theme/ui/spinner'
import { useLazyGetTaskQuery } from '../task-api'
import TaskForm from '../task-form'

const TaskCanvas = ({
	isOpen,
	onClose,
	toggleCanvas,
	taskId,
	isTask,
	boardId,
	isSubtask,
	subtask,
	defaultStatusId,
}) => {
	const [fetchTask, { data, isLoading }] = useLazyGetTaskQuery()

	useEffect(() => {
        if (taskId && !isSubtask) {
            fetchTask(taskId)
        }
    }, [taskId, isSubtask , subtask])  

	const task = data?.data

	return (
		<Offcanvas
			backdrop={true}
			scrollable
			isOpen={isOpen}
			onClosed={onClose}
			toggle={toggleCanvas}
			direction='end'
			className='create-task-sidebar overflow-auto md:min-w-[50%]'>

			{isSubtask ? (
				<TaskForm boardId={boardId} isTask={isTask} onClose={onClose} task={subtask} taskId={taskId} isSubtask={isSubtask}/>
				) : taskId ? (
				isLoading ? (
					<div className='flex h-full items-center justify-center'>
						<Spinner label='Loading...' />
					</div>
					) : task ? (
						<TaskForm boardId={boardId} task={task} onClose={onClose} isTask={isTask} />
					) : (
						<p>Task Not found</p>
					)
					) : (
						<TaskForm boardId={boardId} isTask={isTask} onClose={onClose} defaultStatusId={defaultStatusId}/>
				)}


		</Offcanvas>
	)
}

export default TaskCanvas
