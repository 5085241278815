import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import Creatable from 'react-select/creatable'
import notification from 'utilities/notification'

const CreatableSelectField = ({
	id,
	form,
	label,
	field,
	variant,
	isMulti,
	isDisabled,
	options,
	isLoading,
	isClearable,
	isSearchable,
	onInputChange,
	isRequiredField,
	isHelperIcon,
	labelHelperText,
	className,
	getOptionLabel,
	filterOption,
	helperText,
	placeholder,
	onAutoSave,
	onCreateOption,
	onFocus,
	formValues,
}) => {
	const { name } = field
	const touched = useMemo(() => getIn(form?.touched, name), [form?.touched])
	const errors = useMemo(() => getIn(form?.errors, name), [form?.errors])
	const isInvalid = touched && errors

	const handleCreateOption = async (value) => {
		try {
			if (onCreateOption) {
				await onCreateOption(value)
			}
			if (isMulti) {
				const currentArray = Array.isArray(field?.value) ? field?.value : []
				form.setFieldValue(field?.name, [...currentArray, { label: value, value: value }])
			} else {
				form.setFieldValue(field?.name, { label: value, value: value })
			}
		} catch (error) {
			notification('warn', 'Fail to create option.')
		}
	}

	return (
		<div
			className={classNames('full_length flex', className, {
				'flex-row items-center space-x-[10px]': variant === 'horizontal',
				'flex-col space-y-[8px]': variant === 'default',
				'cursor-not-allowed': isDisabled,
			})}>
			{label ? (
				<label
					htmlFor={id}
					title={label}
					className={classNames(
						'mb-0 line-clamp-1 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
						{
							'opacity-50': isDisabled,
						},
					)}>
					<div className='flex space-x-2'>
						<div>
							{label}{' '}
							{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
						</div>
						{isHelperIcon ? (
							<span>
								<i className='bx bxs-info-circle mb-0 mt-1 text-sm text-gray-300' />
							</span>
						) : undefined}
					</div>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> required
						</span>
					) : undefined}
				</label>
			) : undefined}

			<div className='flex flex-col'>
				<Creatable
					{...field}
					options={options}
					onInputChange={onInputChange}
					onChange={(option) => form.setFieldValue(field?.name, option)}
					onBlur={() => {
						if (onAutoSave) {
							onAutoSave()
						}
						return field.onBlur
					}}
					onFocus={() => {
						if (onFocus) {
							onFocus()
						}
						return field.onFocus
					}}
					onCreateOption={handleCreateOption}
					isSearchable={isSearchable}
					isClearable={isClearable}
					isMulti={isMulti}
					isLoading={isLoading}
					isDisabled={isDisabled}
					classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
					filterOption={filterOption}
					getOptionLabel={getOptionLabel}
					className={classNames('select-dropdown-container')}
					placeholder={placeholder}
					styles={{
						menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
						menu: (provided) => ({ ...provided, zIndex: 9999 }),
					}}
					menuPortalTarget={document.body}
					menuPosition={'fixed'}
				/>

				{helperText ? (
					<span className='mt-1 text-[13px] text-gray-500'>{helperText}</span>
				) : undefined}

				{isInvalid ? (
					<>
						{Array.isArray(errors) ? (
							<div className='error mt-[3px]'>
								<ul className='list-disc text-xs'>
									{errors.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
						) : (
							<>
								{errors ? (
									<div className='error mt-[3px] text-[13px]'>{errors}</div>
								) : undefined}
							</>
						)}
					</>
				) : undefined}
			</div>
		</div>
	)
}
CreatableSelectField.propTypes = {
	meta: PropTypes.object,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
	isClearable: PropTypes.bool,
	className: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'horizontal']),
}

CreatableSelectField.defaultProps = {
	meta: null,
	options: [],
	label: '',
	name: '',
	isMulti: false,
	isClearable: true,
	variant: 'default',
	className: '',
}
export default CreatableSelectField
