import React, { useMemo } from "react";

const ScrumBoardPagination = ({ totalBoards, currentPage, setCurrentPage, boardsPerPage }) => {
  const totalPages = Math.ceil(totalBoards / boardsPerPage);

  const pageNumbers = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [totalPages]);

  return (
    <div className="flex items-center justify-between mt-4">
      <span className="text-sm">
        Showing {(currentPage - 1) * boardsPerPage + 1} -{" "}
        {Math.min(currentPage * boardsPerPage, totalBoards)} of {totalBoards}
      </span>

      <nav className="inline-flex -space-x-px rounded-md" aria-label="Pagination">
        <button
          className={`px-3 py-2 text-sm border border-gray-300 bg-white rounded-l-md hover:bg-gray-100 ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        >
          First
        </button>

        {pageNumbers.map((number) => (
          <button
            key={number}
            className={`px-3 py-2 text-sm border border-gray-300 ${
              currentPage === number ? "bg-blue-500 text-white" : "bg-white hover:bg-gray-100"
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </button>
        ))}

        <button
          className={`px-3 py-2 text-sm border border-gray-300 bg-white rounded-r-md hover:bg-gray-100 ${
            currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        >
          Last
        </button>
      </nav>
    </div>
  );
};

export default ScrumBoardPagination;
