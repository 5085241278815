import { useEffect, useState } from 'react'
import EmailCanvas from './email-canvas'
import EmailList from './email-list'

const Emails = ({ client }) => {
	const [activeEmail, setActiveEmail] = useState(null)
	const onEmailOpen = (emailId) => {
		setActiveEmail(emailId)
	}
	const onEmailClose = () => setActiveEmail(null)
	const isOpen = !!activeEmail
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isOpen])

	return (
		<div className='card !rounded-lg shadow-none'>
			<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
				<h5 className='mb-0 font-bold'>Emails</h5>
			</div>

			<EmailCanvas
				isOpen={!!activeEmail}
				toggleCanvas={onEmailClose}
				activeEmail={activeEmail}
				onEmailClose={onEmailClose}
				client_id={client?.id}
			/>

			<EmailList onEmailOpen={onEmailOpen} client_id={client?.id} />
		</div>
	)
}

export default Emails
