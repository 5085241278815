import { useAppRouter } from 'hooks'
import { BOARD_ROUTES } from 'modules/scrum-boards/routes'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactSwitch from 'react-switch'
import { Button } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { getMappedColumns } from '../kanban'
import TaskCanvas from '../task-canvas'

const Header = ({ board }) => {
	const { params, navigate, location } = useAppRouter()
	const pathname = location?.pathname
	const boardId = params?.boardId
	const [selectedTaskId, setSelectedTaskId] = useState(null) // Track selected task
	const [isCanvasOpen, setIsCanvasOpen] = useState(false) // Control canvas visibility

	const onOpenCanvas = (taskId) => {
		setSelectedTaskId(taskId)
		setIsCanvasOpen(true)
	}

	const onCloseCanvas = () => {
		setSelectedTaskId(null)
		setIsCanvasOpen(false)
	}

	useEffect(() => {
		if (isCanvasOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isCanvasOpen])

	const onSwitch = useCallback(() => {
		if (pathname === BOARD_ROUTES.findOneList(boardId)) {
			navigate(BOARD_ROUTES.findOne(boardId))
		} else {
			navigate(`${BOARD_ROUTES.findOneList(boardId)}?page=1&limit=10`)
		}
	}, [boardId, pathname])

	const $columns = useMemo(
		() => (Array.isArray(board?.columns) ? getMappedColumns(board?.columns) : []),
		[board?.columns],
	)

	return (
		<div className='page-header bg-white'>
			<div className='flex w-full items-center justify-between gap-3'>
				<div className='custom-switch flex max-w-fit items-center space-x-2'>
					<span>List view</span>
					<ReactSwitch
						uncheckedIcon={false}
						checkedIcon={false}
						onColor='#F1B44C'
						offColor='#626ed4'
						height={20}
						width={40}
						handleDiameter={12}
						onChange={onSwitch}
						checked={pathname === BOARD_ROUTES.findOne(boardId)}
					/>
					<span>Kanban view</span>
				</div>

				{isAllowTo(permissions?.task?.create) && $columns.length > 0 ? (
					<Button
						color='primary'
						className='min-w-max'
						onClick={() => onOpenCanvas(null)}>
						Create Task
					</Button>
				) : undefined}

				<TaskCanvas
					boardId={boardId}
					taskId={selectedTaskId}
					isOpen={isCanvasOpen}
					onClose={onCloseCanvas}
					toggleCanvas={() => onCloseCanvas()} // Toggle is same as close here
					isTask={false} // Set to false since this is for editing, not creating
				/>
			</div>
		</div>
	)
}

export default Header
