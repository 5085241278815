import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import CreatableSelect from 'react-select/creatable'
import { FormFeedback, Label } from 'reactstrap'
import ToolTip from 'theme/ui/tool-tip'
import { createSelectOption } from 'utilities/helpers'

export const CreatableSelectField = ({
	meta: { touched, error },
	isSearchable = true,
	isLoading,
	label,
	onInputChange,
	input,
	name,
	options,
	isMulti,
	ignoreBottomMargin = false,
	isRequired = false,
	infoText,
	max_selected_option,
	isTooltip = false,
	tooltipIcon = null,
	tooltipText = '',
	isDisabled,
	onCreateOption,
	...custom
}) => {
	const handleCreateOption = async (value) => {
		try {
			if (onCreateOption) {
				await onCreateOption(value)
			}

			input.onChange({ label: value, value: value })
		} catch (error) {
			//
		}
	}

	const fieldValue =
		typeof input?.value === 'string'
			? createSelectOption(input?.value)
			: typeof input?.value === 'object'
				? input?.value
				: null

	const isInvalid = touched && error
	const isOptionDisabled = () => input?.value?.length >= max_selected_option

	return (
		<Fragment>
			<div className={classNames({ 'mb-3': !ignoreBottomMargin })}>
				{label && (
					<Label className='flex items-center text-[14px] font-bold text-[#495057]'>
						{label}
						{isRequired && <span className='text-red-500'>&nbsp;*</span>}
						{isTooltip && (
							<>
								<ToolTip
									targetId={`select-tooltip-1`}
									tooltipText={tooltipText}
									placement='top'
								/>
								<i
									id={`select-tooltip-1`}
									className={`${
										tooltipIcon ? tooltipIcon : ''
									} ms-2 cursor-pointer`}></i>
							</>
						)}
					</Label>
				)}
				<CreatableSelect
					{...input}
					{...custom}
					name={name}
					options={options}
					onInputChange={onInputChange}
					// onChange={(value) => input.onChange(value)}
					onCreateOption={handleCreateOption}
					onBlur={(value) => input.onBlur(value.value)}
					value={fieldValue}
					isSearchable={isSearchable}
					isMulti={isMulti}
					isLoading={isLoading}
					isClearable
					isDisabled={isDisabled}
					classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
					className={classNames('select-dropdown-container')}
					isOptionDisabled={isOptionDisabled}
					menuPortalTarget={document.body}
				/>
				{infoText && <span className='text-[13px]  text-gray-400'>{infoText}</span>}
				{isInvalid ? (
					<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
				) : undefined}
			</div>
		</Fragment>
	)
}

CreatableSelectField.propTypes = {
	meta: PropTypes.object,
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
}

CreatableSelectField.defaultProps = {
	meta: null,
	options: [],
	label: '',
	name: '',
	isMulti: false,
}
