import { toQueryString } from 'utils/commonFunctions'
import DataTable from './data-table'

export function getPageNumbers(meta) {
	const pageNumbers = []
	const currentPage = meta?.currentPage
	const totalRecords = meta?.totalRecords || 0
	const numPages = Math.ceil(totalRecords / meta?.limit)
	const startIndex = (currentPage - 1) * meta?.limit + 1
	const endIndex = Math.min(currentPage * meta?.limit, totalRecords) || 0

	if (numPages < 2) {
		return { pageNumbers: [currentPage], startIndex, endIndex }
	}

	for (let pageNumber = currentPage - 1; pageNumber <= currentPage + 1; pageNumber++) {
		if (pageNumber >= 1 && pageNumber <= numPages) {
			pageNumbers.push(pageNumber)
		}
	}
	return {
		pageNumbers: Array.isArray(pageNumbers) ? pageNumbers : [],
		startIndex: startIndex || 0,
		endIndex: endIndex || 0,
		numPages: numPages || 0,
	}
}

export const resetFilters = (navigate) => {
	return new Promise((resolve) => {
		const filterObj = { page: 1, limit: 10 }
		const queryString = toQueryString(filterObj)
		navigate(`${window.location.pathname}?${queryString}`)
		resolve()
	})
}

export { default as ColumnVisibilityDropdown } from './column-visibility-dropdown'
export { default as DataTablePagination } from './data-table-pagination'
export { default as IndeterminateCheckbox } from './indeterminate-checkbox'
export { default as ReactDataTable } from './ReactDataTable'

export * from './table-context'

export default DataTable
