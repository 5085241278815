import * as yup from 'yup'
import CreateColumnForm from './create-column-form'

export const validationSchema = yup.object().shape({
	column_name: yup
		.string()
		.nonNullable()
		.max(30, 'Column name must be at most 30 characters')
		.required('Column name is required'),
	category: yup.mixed().nonNullable().required('Category is required'),
})

export const getPayload = (values, boardId) => {
	return {
		board_id: boardId,
		name: values?.column_name,
		category_id: values?.category?.value,
	}
}

export default CreateColumnForm
