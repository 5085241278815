import { Fragment, useEffect } from 'react'
import { Container } from 'reactstrap'
import Badge from 'theme/ui/badge'
import { formatDate } from 'utilities/helpers'
import errorImg from '../../assets/images/block-user.png'
import NoSubscriptionHeader from './components/NoSubscriptionHeader'

const NoSubscription = ({ team }) => {
	useEffect(() => {
		document.body.className = 'authentication-bg'
		return function cleanup() {
			document.body.className = ''
		}
	})

	return (
		<Fragment>
			<div className='relative z-10'>
				<NoSubscriptionHeader />
			</div>
			<div className='pt-sm-2 z-0 mt-28 sm:mt-20'>
				<Container>
					<div className='row d-flex justify-center'>
						<div className='col-lg-9 col-12'>
							<div className='d-flex flex-col justify-center rounded bg-white px-3 py-1 text-center '>
								<img
									src={errorImg}
									alt=''
									className='img-fluid d-block mx-auto h-24 w-24'
								/>
								<h1 className='m-0 font-bold'>{team?.team_name}</h1>
								<h5>Your team is disabled</h5>
								<p>
									To keep your account active, please contact the Admin at
									admin@experivise.com.
								</p>
								<div className='row g-5 d-flex justify-center'>
									<div className='col-12'>
										{/* <div className='d-flex my-2 flex-col justify-center rounded px-3 py-1.5 text-left '>
											{team?.userHasSubscriptions[0] ? (
												<CurrentSubscription
													subscription={team?.userHasSubscriptions[0]}
												/>
											) : (
												<p className='text-center'>
													The team currently has no active subscriptions
													here.
												</p>
											)}
										</div>
										<div className='rounded px-3 py-1 text-left'>
											<PaymentsDetails payments={payments} />
										</div> */}
										<div className='flex-col rounded px-3 py-1.5'>
											<div className='!mb-1 flex items-center '>
												<h3 className='m-0 mt-2 text-lg font-medium text-gray-600'>
													Team Details
												</h3>
											</div>

											<div className='mb-3 mt-2  flex-row justify-between rounded-lg !border !px-4 !py-2 sm:flex'>
												<div className='mt-1 flex items-center'>
													<div className='flex flex-col text-left'>
														<span className='text-sm text-gray-400'>
															Team name
														</span>
														<div className='flex flex-row'>
															<span className='mr-2 text-sm font-semibold text-gray-950'>
																{team?.team_name}
															</span>
															<Badge
																variant={
																	team?.status == 0
																		? 'danger'
																		: 'success'
																}>
																{team?.status == 0
																	? 'Inactive'
																	: 'Active'}
															</Badge>
														</div>
													</div>
												</div>
												<div className='mt-2 flex flex-col text-left'>
													<span className='text-sm text-gray-400'>
														Team owner
													</span>
													<span className='mb-1 text-sm font-semibold text-gray-950'>
														{team?.teamOwner?.fullName}
													</span>
												</div>
												<div className='mt-2 flex flex-col text-left'>
													<span className='text-sm text-gray-400'>
														Created At
													</span>
													<span className='text-sm font-semibold text-gray-950'>
														{formatDate(team?.created_at)}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</Fragment>
	)
}

export default NoSubscription
