import classNames from 'classnames'
import { Field, getIn } from 'formik'
import { useMemo } from 'react'
import Select from 'react-select'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'

const UserDropdown = ({
	name,
	label,
	isLabelHide = false,
	options,
	onAutoSave,
	isDisabled = false, // New prop
	initialValue = null, // New prop
	onChange, // New prop
}) => {
	return (
		<Field
			type='select'
			label={!isLabelHide ? label || 'Select User' : ''}
			name={name}
			className='!min-w-[30%]'
			component={SelectField}
			options={options}
			isClearable={false}
			onAutoSave={onAutoSave}
			isDisabled={isDisabled} // Pass to SelectField
			initialValue={initialValue} // Pass to SelectField
			onChange={onChange} // Pass to SelectField
		/>
	)
}

export default UserDropdown

export const SelectField = ({
	id,
	form,
	label,
	field,
	variant = 'default',
	isMulti,
	isDisabled,
	options,
	isLoading,
	isClearable,
	isSearchable,
	onInputChange,
	isRequiredField,
	isHelperIcon,
	labelHelperText,
	className,
	getOptionLabel,
	filterOption,
	helperText,
	placeholder,
	onAutoSave,
	initialValue, // New prop
	onChange, // New prop
}) => {
	const { name } = field
	const touched = useMemo(() => getIn(form?.touched, name), [form?.touched])
	const errors = useMemo(() => getIn(form?.errors, name), [form?.errors])
	const isInvalid = touched && errors

	// Determine the initial value or current field value
	const currentValue = useMemo(() => {
		if (initialValue && !field.value) {
			return options.find((option) => option.value === initialValue.value) || null
		}
		return field.value
			? options.find((option) => option.value === field.value.value) || field.value
			: null
	}, [field.value, initialValue, options])

	return (
		<div
			className={classNames('flex', className, {
				'flex-row items-center space-x-[10px]': variant === 'horizontal',
				'flex-col space-y-[8px]': variant === 'default',
				'cursor-not-allowed': isDisabled,
			})}>
			{label ? (
				<label
					htmlFor={id}
					className={classNames(
						'mb-0 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
						{
							'opacity-50': isDisabled,
						},
					)}>
					<div className='flex space-x-2'>
						<div>
							{label}{' '}
							{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
						</div>
						{isHelperIcon ? (
							<span>
								<i className='bx bxs-info-circle mb-0 mt-1 text-sm text-gray-300' />
							</span>
						) : undefined}
					</div>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> required
						</span>
					) : undefined}
				</label>
			) : undefined}

			<div className='flex flex-col'>
				<Select
					{...field}
					options={options}
					onInputChange={onInputChange}
					onChange={(option) => {
						form.setFieldValue(field?.name, option)
						if (onChange) onChange(option) // Call the custom onChange handler
					}}
					onBlur={() => {
						if (onAutoSave) {
							onAutoSave()
						}
						return field.onBlur
					}}
					value={currentValue} // Use computed value
					isSearchable={isSearchable}
					isClearable={isClearable}
					isMulti={isMulti}
					isLoading={isLoading}
					isDisabled={isDisabled}
					classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
					filterOption={filterOption}
					getOptionLabel={getOptionLabel}
					className={classNames('select-dropdown-container')}
					placeholder={placeholder}
					styles={{
						menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
						menu: (provided) => ({ ...provided, zIndex: 9999 }),
					}}
					menuPortalTarget={document.body}
					menuPosition={'fixed'}
					components={{
						DropdownIndicator: DropdownIndicator,
					}}
				/>

				{helperText ? (
					<span className='mt-1 text-[13px] text-gray-500'>{helperText}</span>
				) : undefined}

				{isInvalid ? (
					<>
						{Array.isArray(errors) ? (
							<div className='error mt-[3px]'>
								<ul className='list-disc text-xs'>
									{errors.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
						) : (
							<>
								{errors ? (
									<div className='error mt-[3px] text-[13px]'>{errors}</div>
								) : undefined}
							</>
						)}
					</>
				) : undefined}
			</div>
		</div>
	)
}
