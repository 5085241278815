/* eslint-disable no-case-declarations */
import { Fragment } from 'react'
import { Field } from 'redux-form'

import classNames from 'classnames'
import { CheckBox } from 'components/Forms/CheckBoxComponent'
import { CreatableSelectField } from 'components/Forms/CreatableSelectFieldComponent'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { SelectField } from 'components/Forms/SelectComponent'
import { TextareaField } from 'components/Forms/TextareaFieldComponent'
import { TexteditorField } from 'components/Forms/TexteditorComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import { DatePickerInput, RatingField } from 'components/Forms2'
import MultiCheckbox from 'components/Forms2/multi-checkbox'
import NetPromoterScore from 'components/Forms2/net-promoter-score'
import { WorkflowStatuses } from 'utilities/helpers'
import { required } from 'utils/formValidation'
import SignatureField from './signature-field'

const PdfFieldRender = ({ field, i, error, isReadOnly, currentStatus }) => {
	const $isReadOnly =
		currentStatus === WorkflowStatuses.REJECTED ? field?.status === 'approved' : isReadOnly

	return (
		<Fragment>
			<FieldType
				field={field}
				i={i}
				name={`fields[${i}].${field?.name}`}
				isReadOnly={$isReadOnly}
			/>
			{error && (
				<div className='invalid-feedback' style={{ display: 'block' }}>
					{error}
				</div>
			)}
		</Fragment>
	)
}

export default PdfFieldRender

const validate = {
	field: [required('field value', 'enter')],
}

export const FieldType = ({ field, i, name, isReadOnly, isWorkflowView = false }) => {
	const type = field?.type || 'text'

	switch (type) {
		case 'text':
		case 'email':
		case 'number':
		case 'tel':
		case 'password':
		case 'textarea':
		case 'timestamp':
		case 'richtext':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type={type}
					name={name}
					component={
						type === 'textarea'
							? TextareaField
							: type === 'richtext'
								? TexteditorField
								: TextField
					}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)
		case 'rating':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					name={name}
					component={RatingField}
					ratingCount={field?.rating?.value}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)
		case 'netpromoter':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					name={name}
					component={NetPromoterScore}
					npsLabels={field?.nps_label}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)
		case 'date':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type={'date'}
					name={name}
					component={DatePickerInput}
					dateFormat={field?.date_format?.value}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)
		case 'signature':
			return (
				<SignatureField
					field={field}
					name={name}
					disabled={isReadOnly}
					isWorkflowView={isWorkflowView}
					validate={field?.required ? validate.field : undefined}
				/>
			)

		case 'select':
		case 'combobox':
		case 'dropdown':
		case 'list':
			const options = Array.isArray(field?.values)
				? field.values.map((option) => ({ label: option?.label, value: option?.value }))
				: []

			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type='select'
					name={name}
					component={type === 'combobox' ? CreatableSelectField : SelectField}
					options={options}
					isRequired={field?.required}
					isMulti={field?.multiple || type === 'list'}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					isDisabled={isReadOnly}
					ignoreBottomMargin={true}
					max_selected_option={field?.max_selected_option}
				/>
			)

		case 'radio':
			const $radios = Array.isArray(field?.values) ? field.values : []

			return (
				<div className='form-group'>
					{!isWorkflowView && field?.label ? (
						<label className='mb-[1px] flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
							<span>
								{field?.label}{' '}
								{field?.required ? (
									<span className='text-red-500'>*</span>
								) : undefined}
							</span>
						</label>
					) : undefined}

					{!isWorkflowView && field?.description ? (
						<span className='text-[13px] text-gray-400'>{field?.description}</span>
					) : undefined}

					{field?.multiple ? (
						<Field
							type='checkbox'
							options={$radios}
							multiple={field?.multiple}
							name={name}
							validate={field?.required ? validate.field : undefined}
							component={CheckBox}
							readOnly={isReadOnly}
							ignoreBottomMargin={true}
							isWorkflowView={isWorkflowView}
							max_selected_option={field?.max_selected_option}
						/>
					) : (
						<div
							className={classNames(
								'mt-2 flex gap-2',
								isWorkflowView ? 'flex-wrap' : 'flex-col',
							)}>
							{$radios.map((item, idx) => {
								return (
									<Field
										key={idx}
										label={item?.label}
										id={item?.value}
										value={item?.value}
										type='radio'
										name={name}
										validate={field?.required ? validate.field : undefined}
										component={RadioComponent}
										readOnly={isReadOnly}
										ignoreBottomMargin={true}
									/>
								)
							})}
						</div>
					)}
				</div>
			)

		case 'checkbox':
			const checkboxOptions = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					{checkboxOptions.length > 1 ? (
						<div className='flex-col'>
							<label className='mb-2'>{field?.label} </label>

							{field?.description && (
								<i className='text-muted mb-2' style={{ fontSize: 12 }}>
									{field?.description}
								</i>
							)}
							<Field
								name={name}
								component={MultiCheckbox}
								value={[]}
								options={checkboxOptions}
								readOnly={isReadOnly}
							/>
						</div>
					) : (
						<div className='flex-col'>
							<Field
								label={field?.label}
								name={name}
								id={`fields[${i}].${field?.name}`}
								component={CheckBox}
								readOnly={isReadOnly}
							/>
						</div>
					)}
				</Fragment>
			)

		case 'radio-group':
			const radios = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						{!isWorkflowView && <label className='mb-2'>{field?.label}</label>}
						{!isWorkflowView && field?.description && (
							<i className='text-muted mb-2' style={{ fontSize: 12 }}>
								{field?.description}
							</i>
						)}
					</div>
					<div className='flex flex-wrap gap-x-3'>
						{radios.map((item, idx) => {
							return (
								<Fragment key={idx}>
									<Field
										label={item?.label}
										id={item?.value}
										value={item?.value}
										type='radio'
										name={name}
										component={RadioComponent}
										readOnly={isReadOnly}
									/>
								</Fragment>
							)
						})}
					</div>
				</Fragment>
			)

		case 'checkbox-group':
			const checkboxes = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						{!isWorkflowView && <label className='mb-2'>{field?.label}</label>}
						{!isWorkflowView && field?.description && (
							<Fragment>
								<i className='text-muted mb-2' style={{ fontSize: 12 }}>
									{field?.description}
								</i>
							</Fragment>
						)}
					</div>
					<Field
						type='checkbox'
						options={checkboxes}
						multiple={field?.multiple}
						name={name}
						component={CheckBox}
						readOnly={isReadOnly}
					/>
				</Fragment>
			)

		default:
			return (
				<Field
					label={field?.label}
					infoText={field?.description}
					type='text'
					name={name}
					component={TextField}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					disabled={isReadOnly}
				/>
			)
	}
}
