import TabsHeader from './tabs-header'

import activeUploadDocumentIcon from 'assets/svg/active-document-upload-icon.svg'
import activeFieldMapping from 'assets/svg/active-field-mapping.svg'
import activeFormBuilderIcon from 'assets/svg/active-form-builder-icon.svg'
// import activeSignatureIcon from 'assets/svg/active-signature-icon.svg'
import fieldMapping from 'assets/svg/field-mapping.svg'
import formBuilderIcon from 'assets/svg/form-builder-icon.svg'
// import signatureIcon from 'assets/svg/signature-icon.svg'

export const tabs = (isFormType) => [
	{
		id: 1,
		label: isFormType ? 'Form Setup' : 'Document Setup',
		activeIcon: activeUploadDocumentIcon,
	},
	{ id: 2, label: 'Field Mapping', activeIcon: activeFieldMapping, icon: fieldMapping },
	{
		id: 3,
		label: isFormType ? 'Form Builder Setup' : 'Customize Form Setup',
		activeIcon: activeFormBuilderIcon,
		icon: formBuilderIcon,
	},
]

export const getTabs = (isSignatureRequired, isFormType) => {
	return tabs(isFormType).filter((tab) =>
		isFormType ? ![2].includes(tab?.id) : ![3].includes(tab?.id),
	)
}

export default TabsHeader
