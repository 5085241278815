import { useHistory } from 'react-router-dom'
import { DropdownMenu, DropdownToggle } from 'reactstrap'

import { useSelector } from 'react-redux'
import notification from 'utilities/notification'
import { useReadAllMutation } from '../notification-api'
import NotificationsList from './notification-list'
const NotificationMenu = ({ setMenu, menu }) => {
	const history = useHistory()
	const { unreadNotificationCount } = useSelector((state) => state.notifications.notifications)
	const notificationsProgress = useSelector((state) => state.notifications.notificationsProgress)
	const [realAll] = useReadAllMutation()
	const onReadAllClick = async () => {
		try {
			const response = await realAll()
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<>
			<DropdownToggle
				className='btn header-item noti-icon waves-effect'
				tag='button'
				id='page-header-notifications-dropdown'>
				<i className='uil-bell p-2'></i>
				{unreadNotificationCount > 0 && (
					<>
						<span className='badge bg-danger  rounded-pill me-2'>
							{unreadNotificationCount}
						</span>
						<span className='badge bg-danger rounded-pill me-2 animate-ping'>
							{unreadNotificationCount}
						</span>
					</>
				)}
			</DropdownToggle>
			<DropdownMenu className='dropdown-menu-lg z-[1000] dropdown-menu-end p-0 '>
				<div className=''>
					<div className='relative h-1 w-full bg-gray-200'>
						<div
							className='absolute left-0 h-1 bg-blue-500 transition-all duration-500'
							style={{ width: `${notificationsProgress}%` }}></div>
					</div>
					<div className='!border-b p-3'>
						<div className='flex items-center justify-between'>
							<div>
								<h5 className=' m-0 font-semibold'> Notifications </h5>
							</div>
							<div className='col-auto'>
								<span
									className='mdi mdi-close noti-icon font-size-18 m-0 cursor-pointer font-bold text-black'
									onClick={() => setMenu(!menu)}></span>
							</div>
						</div>
					</div>

					{menu && <NotificationsList />}
					<div className='border-top grid grid-cols-2 gap-2 p-2'>
						<button
							className='rounded !border bg-white p-1 font-semibold'
							onClick={() => history.push('/notifications')}>
							<i className='uil-arrow-circle-right me-1'></i>
							View all
						</button>
						<button
							className='rounded !border bg-white p-1 font-semibold'
							disabled={unreadNotificationCount === 0}
							onClick={() => onReadAllClick()}>
							<span
								className='uil-check rounded !border-black bg-white p-0 px-1 '
								title='Mark all as read'></span>{' '}
							Mark all as read
						</button>
					</div>
				</div>
			</DropdownMenu>
		</>
	)
}

export default NotificationMenu
