import classNames from 'classnames'
import { useMemo } from 'react'
import { statuses } from '..'

const StatusBadge = ({ value }) => {
	const currentStatus = useMemo(
		() => statuses.find((status) => status?.name == value.status),
		[value],
	)

	return (
		<div className='flex items-center space-x-2'>
			<span
				className={classNames('h-3 w-3 rounded-full')}
				style={{ backgroundColor: currentStatus?.color || '#74788D' }}
			/>
			<span>{value?.signNow ? 'Sign Now' : value?.status}</span>
		</div>
	)
}

export default StatusBadge
