import {
	closestCenter,
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { Plus } from 'lucide-react'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import Swal from 'sweetalert2'

import {
	useDeleteBoardColumnMutation,
	useUpdateBoardColumnMutation,
} from 'modules/scrum-boards/boards-api'
import { BOARD_ROUTES } from 'modules/scrum-boards/routes'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getMappedColumns, MAX_COLUMNS, reducer } from '.'
import { useUpdateTaskStatusMutation } from '../task-api'
import TaskCanvas from '../task-canvas'
import Column from './column/column'
import CreateColumnPopup from './create-column-popup'

const Board = ({ board }) => {
	const $columns = useMemo(
		() => (Array.isArray(board?.columns) ? getMappedColumns(board?.columns) : []),
		[board?.columns],
	)

	const initialState = useMemo(() => {
		const obj = {}
		$columns.forEach((col) => {
			obj[col.name] = []
		})
		return obj
	}, [$columns])

	const [columns, setColumns] = useState($columns)
	const [updateStatus] = useUpdateTaskStatusMutation()
	const [deleteColumn] = useDeleteBoardColumnMutation()
	const [updateColumn] = useUpdateBoardColumnMutation()
	const [state, localDispatch] = useReducer(reducer, initialState)
	const [showAddForm, setShowAddForm] = useState(false)
	const [editingColumnId, setEditingColumnId] = useState(null)
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
	const [selectedTaskId, setSelectedTaskId] = useState(null)
	const [isCanvasOpen, setIsCanvasOpen] = useState(false)
	const [selectedColumnId, setSelectedColumnId] = useState(null) // State for column ID

	const onOpenCanvas = (taskId, columnId) => {
		setSelectedTaskId(taskId)
		setSelectedColumnId(columnId) // Set the column ID
		setIsCanvasOpen(true)
	}

	const onCloseCanvas = () => {
		setSelectedTaskId(null)
		setSelectedColumnId(null) // Reset column ID
		setIsCanvasOpen(false)
	}

	useEffect(() => {
		if (isCanvasOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isCanvasOpen])

	const initializeColumns = useCallback(() => {
		columns.forEach((column) => {
			localDispatch({ type: 'SET_TASKS', column: column.name, tasks: column.cards })
		})
	}, [columns])

	const handleDragEnd = useCallback(
		async (event) => {
			const { active, over } = event
			if (!over) return

			// Handle column reordering
			if (active.data.current?.type === 'column') {
				const oldIndex = columns.findIndex((col) => col.id === active.id)
				const newIndex = columns.findIndex((col) => col.id === over.id)
				const column = columns.find((col) => col.id === active.id)
				if (oldIndex !== newIndex) {
					const newColumns = arrayMove(columns, oldIndex, newIndex)
					setColumns(newColumns)

					try {
						const response = await updateColumn({
							id: column?.id,
							payload: {
								display_order: newIndex + 1,
							},
						}).unwrap()

						if (response?.status === 200) {
							notification('success', 'Column order updated successfully')
						}
					} catch (error) {
						notification('error', 'Failed to update column order')
					}
				}
				return
			}

			// Handle task dragging
			const activeColumn = active.data.current?.parent
			const overColumn = over.data.current?.status
			const task = active.data.current?.task
			const index = active.data.current?.index

			if (activeColumn && overColumn && activeColumn !== overColumn) {
				localDispatch({
					type: 'MOVE_TASK',
					from: activeColumn,
					to: overColumn,
					task,
					index,
				})

				// Uncomment and adjust if you need to update task status on drag
				// const response = await updateStatus({
				// 	payload: { status: overColumn },
				// 	id: task?.id,
				// }).unwrap()
				// if (response?.status === 200) {
				// 	notification('success', response?.message)
				// }
			}
		},
		[columns, updateStatus, board?.id],
	)

	useEffect(() => {
		if (columns.length > 0) initializeColumns()
	}, [columns, initializeColumns])

	const handleDeleteColumn = async (id) => {
		const result = await Swal.fire({
			title: 'Are you sure?',
			text: 'This column will be permanently deleted!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		})
		if (result?.isConfirmed) {
			const response = await deleteColumn(id).unwrap()
			if (response?.status === 200) {
				Swal.fire('Deleted!', 'Column has been deleted.', 'success')
				setColumns((prev) => prev.filter((col) => col.id !== id))
			}
		}
	}

	const handleUpdateColumn = async (columnId, newName) => {
		try {
			const response = await updateColumn({
				id: columnId,
				payload: { name: newName },
			}).unwrap()

			if (response?.status === 200) {
				setColumns((prevColumns) =>
					prevColumns.map((col) =>
						col.id === columnId ? { ...col, name: newName } : col,
					),
				)
				notification('success', 'Column name updated successfully')
				setEditingColumnId(null)
			}
		} catch (error) {
			notification('error', 'Failed to update column name')
		}
	}

	return (
		<div>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}>
				<SortableContext
					items={columns.map((col) => col.id)}
					strategy={horizontalListSortingStrategy}>
					<div className='w-full flex overflow-auto min-h-[75vh] pb-1 gap-2'>
						{columns.length > 0 ? (
							<>
								{columns.map((column, index) => (
									<Column
										key={column.id}
										column={column}
										handleDeleteColumn={handleDeleteColumn}
										handleUpdateColumn={handleUpdateColumn}
										onOpenCanvas={onOpenCanvas} // Pass onOpenCanvas to Column
										tasks={state[column.name]}
										accepts={columns
											.filter((col) => col.name !== column.name)
											.map((col) => col.name)}
										isLast={index === columns.length - 1}
										isEditing={editingColumnId === column.id}
										setEditingColumnId={setEditingColumnId}
									/>
								))}
								<div className={''}>
									<div className='flex h-auto flex-col space-y-1 rounded w-full  bg-gray-300/20 p-1'>
										<CreateColumnPopup
											board={board}
											isOpen={showAddForm}
											columns={columns}
											localDispatch={localDispatch}
											onCloseAddForm={() => setShowAddForm(false)}
											setColumns={setColumns}
										/>

										<Button
											variant='ghost'
											size='sm'
											block
											onClick={() => setShowAddForm(true)}
											disabled={columns.length >= MAX_COLUMNS}>
											<div className='flex items-center'>
												<Plus size={16} />
											</div>
										</Button>
									</div>
								</div>
							</>
						) : (
							<div className='w-full py-10'>
								<p className='text-center m-0 text-sm'>
									No column has been added. Click{' '}
									<Link
										to={`${BOARD_ROUTES.edit(board?.id)}?tab=columns`}
										className='link hover:underline'>
										here
									</Link>{' '}
									to configure the board.
								</p>
							</div>
						)}
					</div>
				</SortableContext>
			</DndContext>
			<TaskCanvas
				boardId={board?.id}
				taskId={selectedTaskId}
				isOpen={isCanvasOpen}
				onClose={onCloseCanvas}
				toggleCanvas={() => onCloseCanvas()}
				isTask={false}
				defaultStatusId={selectedColumnId} // Pass the column ID to TaskCanvas
			/>
		</div>
	)
}

export default Board
