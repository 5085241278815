import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { FormFeedback, Label } from 'reactstrap'
import ToolTip from 'theme/ui/tool-tip'
import PasswordValidationError from './PasswordValidationError'

export const TextareaField = ({
	meta: { touched, invalid, error },
	label,
	input,
	infoText,
	hideMessage = false,
	className: { formGroupClass, labelClass, inputClass },
	isValidatePassword,
	ignoreBottomMargin = false,
	isRequired = false,
	isTooltip = false,
	tooltipIcon = null,
	tooltipText = '',
	...rest
}) => {
	const [val] = useState('')

	return (
		<Fragment>
			<div className={classNames({ 'mb-3': !ignoreBottomMargin }, formGroupClass)}>
				{label && (
					<Fragment>
						<Label
							className={
								'flex items-center text-left text-[14px] font-bold text-[#495057]' +
								labelClass
							}>
							{label}
							{isRequired && <span className='text-red-500'>*</span>}
							{isTooltip && (
								<>
									<ToolTip
										targetId={`input-tooltip-1`}
										tooltipText={tooltipText}
										placement='top'
									/>
									<i
										id={`input-tooltip-1`}
										className={`${
											tooltipIcon ? tooltipIcon : ''
										} ms-2 cursor-pointer`}></i>
								</>
							)}
						</Label>
					</Fragment>
				)}

				<div
					className={classNames('form-control !p-0', {
						'is-invalid': touched && Boolean(invalid),
						'!bg-gray-100': rest?.disabled,
					})}>
					<textarea
						{...rest}
						{...input}
						value={val || input.value}
						className={classNames(
							'w-full !border-none placeholder:!text-[#B5B5C3] p-2 resize-y',
							inputClass,
							{
								'is-invalid': touched && Boolean(invalid),
							},
						)}
					/>
				</div>

				{infoText && <span className='text-[13px] text-gray-400'>{infoText}</span>}
				{touched && error && !hideMessage && (
					<Fragment>
						{isValidatePassword ? (
							<PasswordValidationError type={rest?.type} value={input?.value} />
						) : (
							<FormFeedback>{touched && error}</FormFeedback>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	)
}

TextareaField.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	isValidatePassword: PropTypes.bool,
	ignoreBottomMargin: PropTypes.bool,
}

TextareaField.defaultProps = {
	meta: null,
	className: { formGroupClass: '', labelClass: '', inputClass: '' },
	isValidatePassword: false,
	ignoreBottomMargin: false,
}
