import classNames from 'classnames'
import { generateUniqueId } from 'utilities/helpers'

const ChoiceFieldView = ({ name, field, onChange, is_read_mode }) => {
	const options = Array.isArray(field.values) ? field.values : []
	const tooltipId = generateUniqueId()

	const handleOptionChange = (optionValue) => {
		if (field.multiple) {
			const values = Array.isArray(field.value) ? field.value : []
			const newValue = values.includes(optionValue)
				? values.filter((val) => val !== optionValue)
				: [...values, optionValue]
			onChange(name, newValue)
		} else {
			onChange(name, optionValue)
		}
	}

	return (
		<div className='form-group'>
			{field?.label ? (
				<label
					htmlFor={tooltipId}
					className='mb-1 flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}
			{field?.description ? (
				<span className='text-[13px] text-gray-400'>{field?.description}</span>
			) : undefined}

			<div className='mt-3 flex flex-col gap-3'>
				{options.map((option, idx) => {
					const id = generateUniqueId()
					const isChecked = field.multiple
						? field?.value?.includes(option.value)
						: field?.value === option.value

					return (
						<div
							className={classNames('flex items-center gap-1 ', {
								'select-none ': is_read_mode,
							})}
							key={idx}>
							<input
								type={field?.multiple ? 'checkbox' : 'radio'}
								name={field?.name}
								readOnly={is_read_mode}
								disabled={is_read_mode}
								className='form-check-input !m-0 disabled:!opacity-100 '
								id={id}
								checked={isChecked}
								onChange={() => handleOptionChange(option.value)}
							/>
							<label
								htmlFor={id}
								className='m-0 cursor-pointer disabled:!cursor-default'>
								{option?.label}
							</label>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default ChoiceFieldView
