import React from 'react'

const DateResponse = ({ q }) => {
	const formattedDates = q?.responses?.map((date) => new Date(date).toISOString().split('T')[0])

	return (
		<div className='flex justify-between w-full'>
			<div className='flex flex-col  w-1/2 justify-center items-center'>
				<p className='text-xl font-bold'>{q.responses.length}</p>
				<p className='text-gray-500'>Responses</p>
			</div>

			<div className='flex flex-col w-1/2 justify-center items-center'>
				{formattedDates.slice(0, 3).map((date, index) => (
					<p key={index} className='text-gray-800 font-medium'>
						&quot;{date}&quot;
					</p>
				))}
				{q.responses.length > 3 && (
					<p className='text-gray-600 font-bold text-[20px] m-0 p-0'>...</p>
				)}
			</div>
		</div>
	)
}

export default DateResponse
