import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { ReactComponent as UserIcon } from 'assets/svg/field-icon.svg'
import RenameFileForm from './rename-file-form'

const RenameFilePopup = ({ isOpen, onClose, workflowFile }) => {
	return (
		<Fragment>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/30' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel
									className={`w-full max-w-sm transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
									<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
										<div></div>
										<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white p-3 '>
											<UserIcon height={30} width={30} />
										</div>
										<button type='button' className='right-0' onClick={onClose}>
											<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
										</button>
									</div>
									<div className='mt-10 flex flex-col '>
										<div className='flex flex-col justify-start px-4'>
											<Dialog.Title
												as='h2'
												className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
												Rename
											</Dialog.Title>
										</div>
										{/* <hr className='mx-4 my-3 bg-gray-300' /> */}
										<RenameFileForm
											onClose={onClose}
											workflowFile={workflowFile}
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	)
}

export default RenameFilePopup
