import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.taskStatus

export const taskStatusApi = createApi({
	reducerPath: 'TaskStatuses',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['TaskStatus'],
	endpoints: (builder) => ({
		getTaskStatuseByBoard: builder.query({
			query: (params) => ({
				params,
				url: `${API_URL}/by-board-and-category`,
				method: 'GET',
			}),
		}),

		getTaskStatus: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['TaskStatus'],
		}),
	}),
})

export const { useLazyGetTaskStatusQuery, useLazyGetTaskStatuseByBoardQuery } = taskStatusApi
