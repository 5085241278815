import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = '/sftp'

export const sftpConfigApi = createApi({
	reducerPath: 'SftpConfig',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['SftpConfig'],
	endpoints: (builder) => ({
		addSftpConfig: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['SftpConfig'],
		}),
		updateSftpConfig: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['SftpConfig'],
		}),
		deleteSftpConfig: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SftpConfig'],
		}),
		testSftpConfig: builder.mutation({
			query: (payload) => ({
				url: `${API_URL}/test-connection`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['SftpConfig'],
		}),
		getSftpList: builder.query({
			keepUnusedDataFor: 0,
			query: (payload) => ({
				url: API_URL,
				method: 'GET',
				params: payload,
			}),
			providesTags: ['SftpConfig'],
			transformResponse: (response, _, arg) => {
				const data = Array.isArray(response?.data?.sftpDetails)
					? response?.data?.sftpDetails.map((elem, idx) => ({
							...elem,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: elem?.id,
					  }))
					: []
				return {
					data,
					meta: response?.data?.meta,
				}
			},
		}),
		getSftp: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['SftpConfigInfo'],
			transformResponse: (response) => {
				const data = response?.data
				return {
					data,
				}
			},
		}),
		getCustodian: builder.query({
			query: (params = {}) => ({
				url: `${API_URL}/custodian`,
				method: 'GET',
				params,
			}),
			providesTags: ['SftpConfigInfo'],
			transformResponse: (response) => ({
				custodian: response?.data?.custodianDetails ?? [],
			}),
		}),
		getSftpHistory: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, id }) => ({
				url: `/sftp/client-import-logs/${id}`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['SftpConfigInfo'],
			transformResponse: (response, _, arg) => {
				const data = Array.isArray(response?.data?.logsDetails)
					? response?.data?.logsDetails.map((file, idx) => ({
							...file,
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
					  }))
					: []
				return {
					data,
					meta: response?.data?.meta,
				}
			},
		}),
	}),
})

export const {
	useAddSftpConfigMutation,
	useUpdateSftpConfigMutation,
	useDeleteSftpConfigMutation,
	useTestSftpConfigMutation,
	useLazyGetSftpListQuery,
	useLazyGetSftpQuery,
	useLazyGetSftpHistoryQuery,
	useLazyGetCustodianQuery,
} = sftpConfigApi
