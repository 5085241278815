import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.systemFields

export const systemFieldApi = createApi({
	reducerPath: 'SystemFields',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['SystemField'],
	endpoints: (builder) => ({
		getSystemFields: builder.query({
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['SystemField'],
		}),
		createSystemField: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['SystemField'],
		}),
		updateSystemField: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['SystemField'],
		}),
	}),
})

export const {
	useGetSystemFieldsQuery,
	useLazyGetSystemFieldsQuery,
	useCreateSystemFieldMutation,
	useUpdateSystemFieldMutation,
} = systemFieldApi
