import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'store/axios-base-query'
import { transformFormResponse, transformWorkflowResponse } from '.'

export const workflowOutstandingApi = createApi({
	reducerPath: 'WorkflowOutstanding',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['WorkflowOutstanding'],
	endpoints: (builder) => ({
		getWorkflowOutstandings: builder.query({
			query: (params) => ({
				url: '/workflow/assigned',
				method: 'GET',
				params: params,
			}),
			providesTags: ['WorkflowOutstanding'],
			transformResponse: (response) => {
				const outstandings = Array.isArray(response?.data?.workflowTemplate)
					? response?.data?.workflowTemplate.map((template) => ({
							...template,
							workflows: Array.isArray(template?.workflows)
								? template?.workflows.map((workflow) => ({
										...workflow,
										user: workflow?.client || workflow?.advisor,
									}))
								: [],
						}))
					: []
				return {
					outstandings,
					meta: response?.data?.meta,
				}
			},
		}),
		getOneWorkflowOutstanding: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `/workflow/review/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['WorkflowOutstanding'],
			transformResponse: (response) => transformWorkflowResponse(response),
		}),
		saveWorkflowOutstanding: builder.mutation({
			query: ({ payload, id }) => ({
				url: `/workflow/${id}/review`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['WorkflowOutstanding'],
		}),
		cancelWorkflowOutstanding: builder.mutation({
			query: (id) => ({
				url: `/workflow/cancel/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['WorkflowOutstanding'],
		}),
		deleteWorkflowOutstanding: builder.mutation({
			query: (id) => ({
				url: `/workflow/delete/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['WorkflowOutstanding'],
		}),

		getWorkflowFormTemplate: builder.query({
			keepUnusedDataFor: 0,
			query: ({ id, stepId, formId, teamId }) => ({
				url: `/workflow/${id}/form/${formId}/steps/${stepId}/team/${teamId}`,
				method: 'GET',
			}),
			transformResponse: (response) => transformFormResponse(response),
		}),
	}),
})

export const {
	useLazyGetWorkflowOutstandingsQuery,
	useLazyGetOneWorkflowOutstandingQuery,
	useSaveWorkflowOutstandingMutation,
	useCancelWorkflowOutstandingMutation,
	useDeleteWorkflowOutstandingMutation,
	useLazyGetWorkflowFormTemplateQuery,
} = workflowOutstandingApi
