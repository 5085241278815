import { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { COLUMN_OPTIONS } from '.'

const ColumnVisibilityToggle = ({ visibleColumns, setVisibleColumns }) => {
	const [isOpen, setIsOpen] = useState(false)

	const toggleDropdown = () => setIsOpen(!isOpen)

	const handleToggle = (key, event) => {
		event.stopPropagation()
		setVisibleColumns((prev) =>
			prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key],
		)
	}

	return (
		<Dropdown toggle={toggleDropdown} isOpen={isOpen} className='relative ms-2'>
			<DropdownToggle className='cursor-pointer flex items-center px-4 py-2 font-medium !border rounded-md bg-white !border-primary	'>
				Columns
				<span className='ml-2'>
					{isOpen ? <i className='uil-angle-down'></i> : <i className='uil-angle-up'></i>}
				</span>
			</DropdownToggle>
			{isOpen && (
				<DropdownMenu
					className='bg-white shadow-lg border mt-1 rounded-md'
					onClick={(e) => e.stopPropagation()}>
					{COLUMN_OPTIONS.map(({ key, label }) => (
						<DropdownItem
							key={key}
							className=''
							onClick={(event) => handleToggle(key, event)}>
							<div className='flex flex-row items-center gap-2'>
								<input
									type='checkbox'
									className='form-check-input'
									checked={visibleColumns.includes(key)}
								/>
								<label className='p-0 m-0'>{label}</label>
							</div>
						</DropdownItem>
					))}
				</DropdownMenu>
			)}
		</Dropdown>
	)
}

export default ColumnVisibilityToggle
