import React, { useState } from 'react'
import DocumentPreview from 'theme/ui/document-preview'
import WorkflowTable from './workflow-table'

const WorkflowListingContainer = ({ client, role }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [document, setDocument] = useState({
		fileUrl: null,
		id: 0,
		workflow_step_document_id: 0,
	})
	const toggle = async (document) => {
		setIsOpen(!isOpen)
		setDocument(document)
	}

	return (
		<div>
			<WorkflowTable client={client} role={role} toggle={toggle} />
			<DocumentPreview url={document?.fileUrl} toggle={toggle} isOpen={isOpen} />
		</div>
	)
}

export default WorkflowListingContainer
