import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import Actions from './actions'
import FiltersForm from './filters-form'
import systemFieldsListQuery from './system-fields-list-query'
import TeamsPopover from './teams-popover'

const SystemFieldsListing = () => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })

	const { fields, isLoading, meta, mutate } = systemFieldsListQuery(pagination)
	const columnHelper = createColumnHelper()
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	const columns = [
		columnHelper.accessor('index', {
			id: 'index',
			size: 50,
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'field_name',
			header: () => <span>Field Name</span>,
			cell: (info) => (
				<div className='flex flex-col'>
					<span className='line-clamp-1 min-w-[100px] max-w-[180px] text-sm font-bold !text-gray-700'>
						{info.getValue()?.field_name}
					</span>
					{info.getValue()?.is_system_field ? (
						<span className='rounded text-xs text-main'>(System Field)</span>
					) : undefined}
				</div>
			),
		}),

		columnHelper.accessor((row) => row.teams, {
			id: 'teams',
			header: () => <span>Teams</span>,
			cell: (info) => {
				const first_team = info.getValue()[0]

				return (
					<span className='max-w-[180px]'>
						{info.getValue()?.length > 0 ? (
							<div className='flex flex-row gap-2'>
								<Badge
									variant='primary-outline'
									className='line-clamp-1 cursor-pointer'
									rounded={false}>
									{first_team?.team_name}
								</Badge>
								{info.getValue()?.length > 1 ? (
									<TeamsPopover teams={info.getValue()} />
								) : undefined}
							</div>
						) : (
							<p className='m-0 text-xs text-gray-500'>No team assigned</p>
						)}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row.created_by, {
			id: 'created_by',
			header: () => <span>Created by</span>,
			cell: (info) => {
				const full_name = `${info.getValue()?.first_name} ${info.getValue()?.last_name}`
				const id = info.getValue()?.id
				return (
					<span className='text-sm !text-gray-700'>
						<Link to={`/users/${id}/show`} className='hover:!underline'>
							{full_name}{' '}
						</Link>
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 180,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				// <div className='!w-full'>
				// 	<span className='float-right capitalize'>{info.getValue()?.created_at}</span>
				// </div>
				<div className='w-full justify-end text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue()?.created_at)}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue()?.created_at)}
					</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 100,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => (
				<Actions key={info.getValue()?.id} field={info.getValue()} mutate={mutate} />
			),
		}),
	]

	return (
		<div>
			<FiltersForm />
			<div className={`${isMobile ? 'min-h-[75vh]' : ''}`}>
				<DataTable
					columns={columns}
					data={fields}
					isLoading={isLoading}
					pagination={pagination}
					setPagination={setPagination}
					meta={meta}
					isOverflow={isMobile ? true : false}
				/>
			</div>
		</div>
	)
}

export default SystemFieldsListing
