import { snakeCase } from 'lodash'
import { getFieldOptions } from 'modules/form-designer/helpers'
import * as yup from 'yup'
import DocumentTemplateForm from './document-template-form'
import { getInitialPdfFieldsMapping } from './helpers'

export const validationSchemaDocument = yup.object().shape({
	document_type: yup.string().required('Please choose the template type.'),
	title: yup.string().required('Please enter the document title.'),
	min_clients: yup.mixed().required('Please select number of users.'),
	users: yup
		.array()
		.typeError('Please select users')
		.of(yup.object().nonNullable().required('Required'))
		.when('access_type', {
			is: (value) => value === 'specific_people',
			then: (schema) =>
				schema.min(1, 'Please select at least 1 user').required('Please select a user'),
			otherwise: (schema) => schema.min(0),
		}),
	file: yup
		.mixed()
		.nullable()
		.typeError('File upload')
		.when('document_type', {
			is: (value) => value === 'document',
			then: (schema) => schema.required('Please select PDF file.'),
			otherwise: (schema) => schema.optional(),
		}),
	set_time_duration_enabled: yup.boolean(),
	time_duration: yup
		.number()
		.nullable()
		.when('set_time_duration_enabled', {
			is: true,
			then: (schema) =>
				schema
					.required('Please enter a time duration.')
					.positive('Time duration must be a positive number.')
					.integer('Time duration must be an integer.'),
			otherwise: (schema) => schema.optional(),
		}),

	custom_thank_you_message_enabled: yup.boolean(),
	custom_thank_you_message: yup
		.string()
		.nullable()
		.when('custom_thank_you_message_enabled', {
			is: true,
			then: (schema) => schema.required('Please enter a custom thank you message.'),
			otherwise: (schema) => schema.optional(),
		}),
})

export const validationSchemaMapping = yup.object().shape({
	document_mapping: yup.array().of(
		yup.object().shape({
			pdf_field: yup.string().required(),
			user: yup.mixed().required('Select user type'),
			type: yup.string().nullable().optional(),
			field: yup
				.mixed()
				.nullable()
				.typeError('CRM Field')
				.when(['type', 'sub_type'], {
					is: (type, sub_type) =>
						type === 'signature' || type === 'timestamp' || sub_type === 'timestamp',
					then: (schema) => schema.optional(),
					otherwise: (schema) => schema.required('Select system field'),
				}),
			values: yup
				.array()
				.typeError('field options')
				.when('type', {
					is: (value) => {
						return ['radio', 'combobox', 'select', 'dropdown', 'list'].includes(value)
					},
					then: (schema) => schema.min(1, 'This field must have at least one option'),
					otherwise: (schema) => schema.min(0),
				}),
		}),
	),
})

const optionsValidation = yup.object().shape({
	label: yup.string().required('Please enter the option label'),
	value: yup
		.string()
		.required('Please enter the option value')
		.test('unique-value', 'Option values must be unique.', function (value) {
			const { from } = this
			const parent = Array.isArray(from[1]?.value?.values) ? from[1]?.value?.values : []
			const duplicateCount = parent.filter((option) => option.value === value).length
			return duplicateCount === 1
		}),
	score: yup.string().optional(),
})

export const validationSchemaFormDesigner = yup.object().shape({
	document_body: yup
		.array()
		.of(
			yup.object().shape({
				type: yup.string().optional(),
				label: yup
					.string()
					.typeError('Please enter question label.')
					.when('type', {
						is: (value) => value !== 'signature',
						then: (schema) => schema.required('Please enter question label.'),
						otherwise: (schema) => schema.nullable().optional(),
					}),

				user_type: yup
					.string()
					.typeError('Please choose fillable by user')
					.when('$purpose', {
						is: (value) => value && value !== 'link',
						then: (schema) => schema.nonNullable().required('Please choose user.'),
						otherwise: (schema) => schema.nullable().optional(),
					}),
				values: yup.array().of(optionsValidation).optional(),
				max_selected_option: yup
					.number()
					.default(1)
					// .min(1)
					.test(
						'max-selected-option',
						'Max option selection cannot exceed total options',
						function (value) {
							const { values, multiple } = this.parent
							if (!multiple) {
								return true
							}
							const $options = Array.isArray(values) ? values : []

							return !$options || value <= $options.length
						},
					),
				name: yup
					.string()
					.required('Please provide a field key.')
					.test('unique', 'Field key must be unique.', function (value) {
						const { path, from } = this
						const parent = Array.isArray(from[1]?.value?.fields)
							? from[1]?.value?.fields
							: []
						const currentIndex = Number(path.match(/\d+/)?.[0])
						const isUnique =
							parent.filter(
								(field, index) => field.name === value && index !== currentIndex,
							).length === 0
						return isUnique
					}),
			}),
		)
		.min(1, 'Please add at least one field'),
})

export const getValidationSchema = (isFormTemplate) => {
	return {
		0: validationSchemaDocument,
		1: isFormTemplate ? validationSchemaFormDesigner : validationSchemaMapping,
	}
}

const createUserOption = (value) => ({
	label: value,
	value,
})
export const createOption = (value) => {
	return {
		label: `${value?.first_name} ${value?.last_name}`,
		value: value?.id || value?.value,
		fullName: `${value?.first_name} ${value?.last_name}`,
		email: value?.email,
	}
}

export const userListOptions = new Array(5).fill(0).map((_, idx) => createUserOption(idx + 1))

export const getInitialValues = (document) => {
	const settings = document?.form_public_link_settings
	const initialValues = {
		document_type: document?.document_type || 'document',
		purpose: document?.purpose || 'workflow',
		form_template_type: document?.form_template_type ? document?.form_template_type : 'qna',
		title: document?.title || '',
		min_clients: document?.min_clients
			? createUserOption(document?.min_clients)
			: createUserOption(1),
		signature_required: document?.signature_required ? 'yes' : 'no',
		file: document?.file || null,
		file_path: document?.file_path || null,
		status: document?.status || 1,
		document_body:
			Array.isArray(document?.document_body) && document?.document_body.length > 0
				? document?.document_body
				: [getFieldOptions(`Question 1`, snakeCase(`Question 1`))],
		documentBlob: null,
		document_mapping: getInitialPdfFieldsMapping(
			document?.document_mapping,
			document?.pdf_fields,
		),
		delete_document_mapping: Array.isArray(document?.delete_document_mapping)
			? document?.delete_document_mapping
			: [],
		// New fields added
		access_type: settings?.access_type || 'public',
		users: Array.isArray(settings?.users)
			? settings?.users?.map((watcher) => createOption(watcher))
			: [],
		accept_responses: settings?.accept_responses ?? true,
		start_date: settings?.start_date || '',
		end_date: settings?.end_date || '',
		shuffle_questions: settings?.shuffle_questions ?? false,
		show_progress_bar: settings?.show_progress_bar ?? false,
		set_time_duration_enabled: settings?.set_time_duration_enabled ?? false,
		time_duration: settings?.time_duration || '',
		custom_thank_you_message_enabled: settings?.custom_thank_you_message_enabled ?? false,
		custom_thank_you_message: settings?.custom_thank_you_message || '',
		submit_another_response: settings?.submit_another_response ?? false,
		pdf_fields: Array.isArray(document?.pdf_fields) ? document?.pdf_fields : [],
		new_fields: Array.isArray(document?.new_fields) ? document?.new_fields : [],
	}
	return initialValues
}

const formatDate = (date) => {
	if (!date) return null

	const nextDay = new Date(date)
	nextDay.setDate(nextDay.getDate() + 1)

	return nextDay.toISOString().split('T')[0]
}

export const getCreateablePayload = (values) => {
	const payload = {
		title: values?.title || '',
		status: 0,
		file: values?.file,
		min_clients: values?.min_clients?.value,
		document_type: values?.document_type,
		signature_required: values?.signature_required === 'yes' ? 1 : 0,
		purpose: values?.purpose,
		form_template_type: values?.form_template_type,
	}
	if (values?.purpose === 'link') {
		payload.form_settings = {
			access_type: values?.access_type,
			accept_responses: values?.accept_responses,
			start_date: formatDate(values?.start_date),
			end_date: formatDate(values?.end_date),
			shuffle_questions: values?.shuffle_questions,
			show_progress_bar: values?.show_progress_bar,
			set_time_duration_enabled: values?.set_time_duration_enabled,
			time_duration: values?.set_time_duration_enabled ? values?.time_duration : null,
			custom_thank_you_message_enabled: values?.custom_thank_you_message_enabled,
			custom_thank_you_message: values?.custom_thank_you_message_enabled
				? values?.custom_thank_you_message
				: null,
			submit_another_response: values?.submit_another_response,
		}
		if (values?.access_type === 'specific_people') {
			payload.form_settings.users = [...(Array.isArray(values?.users) ? values.users : [])]
				.map((user) => user?.value)
				.filter(Boolean)
		}
	}

	return payload
}

export const getPayload = (values) => {
	const payload = {}
	Object.assign(payload, values)

	Object.assign(payload, {
		min_clients: values?.min_clients?.value,
		document_mapping: JSON.stringify(values?.document_mapping),
		signature_required: values?.signature_required ? 1 : 0,
	})
	delete payload.file_path
	return payload
}

export const getDocumentPublishablePayload = (values, document, document_body) => {
	const signature_required =
		Array.isArray(values?.document_mapping) &&
		values?.document_mapping.some((f) => f?.type === 'signature')
			? 1
			: 0
	const signature_mapping = { total: 0 }
	const document_mapping = Array.isArray(values?.document_mapping) ? values?.document_mapping : []
	document_mapping.forEach((field) => {
		if (field?.type === 'signature') {
			const userType = field?.user?.value
			signature_mapping[userType] = (signature_mapping[userType] || 0) + 1
			signature_mapping.total += 1
		}
	})
	const $document_mapping = values?.document_mapping.map((m) => ({ ...m, user: m?.user?.value }))
	const $delete_document_mapping = values?.delete_document_mapping.map((m) => ({
		...m,
		user: m?.user?.value,
	}))

	const payload = new FormData()

	payload.append('title', values?.title)
	payload.append('status', 1)
	payload.append('document_body', JSON.stringify(document_body))
	payload.append('min_clients', values?.min_clients?.value)

	payload.append('documentBlob', null)
	payload.append('document_mapping', JSON.stringify($document_mapping))
	payload.append('delete_document_mapping', JSON.stringify($delete_document_mapping))
	payload.append('new_fields', JSON.stringify(values?.new_fields))
	payload.append('signature_mapping', JSON.stringify(signature_mapping))
	payload.append('file', JSON.stringify({ id: document?.file_id }))
	payload.append('signature_required', signature_required)

	return payload
}

export const getFormPublishablePayload = (values) => {
	return {
		title: values?.title,
		status: 1,
		document_body: JSON.stringify(values?.document_body),
		min_clients: values?.min_clients?.value,
		form_template_type: values?.form_template_type,
		form_settings: {
			access_type: values?.access_type,
			users: [...(Array.isArray(values?.users) ? values.users : [])]
				.map((user) => user?.value)
				.filter(Boolean),
			accept_responses: values?.accept_responses,
			start_date: values?.start_date || null,
			end_date: values?.end_date || null,
			shuffle_questions: values?.shuffle_questions,
			show_progress_bar: values?.show_progress_bar,
			set_time_duration_enabled: values?.set_time_duration_enabled,
			time_duration: values?.set_time_duration_enabled ? values?.time_duration : null,
			custom_thank_you_message_enabled: values?.custom_thank_you_message_enabled,
			custom_thank_you_message: values?.custom_thank_you_message_enabled
				? values?.custom_thank_you_message
				: null,
			submit_another_response: values?.submit_another_response,
		},
	}
}

export const getAutoSavePayload = (values) => {
	const $document_mapping = values?.document_mapping.map((m) => ({ ...m, user: m?.user?.value }))
	const $delete_document_mapping = values?.delete_document_mapping.map((m) => ({
		...m,
		user: m?.user?.value,
	}))
	const payload = {
		min_clients: values?.min_clients?.value,
		document_mapping: JSON.stringify($document_mapping),
		delete_document_mapping: JSON.stringify($delete_document_mapping),
		new_fields: JSON.stringify(values?.new_fields),
		signature_required: values?.signature_required ? 1 : 0,
	}

	return payload
}

export * from './signature-document-context'

export default DocumentTemplateForm
