import { Trash } from 'lucide-react'

const FieldActionButtons = ({ fieldName, onDelete, arrayHelpers }) => (
	<div style={{ position: 'absolute', top: '-25px', right: '0', display: 'flex', gap: '5px' }}>
		<button
			onClick={(e) => {
				e.stopPropagation()
				onDelete(fieldName, arrayHelpers)
			}}
			type='button'
			style={{
				background: '#E53E3E',
				color: 'white',
				border: 'none',
				borderRadius: '4px',
				padding: '3px 6px',
				cursor: 'pointer',
				fontSize: '12px',
				display: 'flex',
				alignItems: 'center',
				gap: '3px',
			}}>
			<Trash size={14} /> Delete
		</button>
	</div>
)

export default FieldActionButtons
