import classNames from 'classnames'
import { useMemo } from 'react'

const RadioGroupField = ({
	field,
	form,
	label,
	id,
	helperText,
	disabled = false,
	isRequiredField,
	options,
}) => {
	const touched = useMemo(() => form?.touched[field.name], [form?.touched])
	const error = useMemo(() => form?.errors[field.name], [form?.errors])

	return (
		<div className='flex flex-col space-y-3'>
			{label ? (
				<label htmlFor={id} className='m-0 flex cursor-pointer flex-col'>
					<span className='text-sm font-bold text-[#495057]'>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{helperText ? (
						<span className='text-xs font-normal text-gray-500'>({helperText})</span>
					) : undefined}
				</label>
			) : undefined}
			<div className='flex flex-wrap items-center gap-x-3 gap-y-2'>
				{options.map((option) => (
					<label key={option.value} className='flex items-center'>
						<input
							type='radio'
							{...field}
							disabled={disabled}
							value={option.value}
							checked={
								(typeof field === 'object'
									? field?.value?.toString()?.toLowerCase()
									: field?.toString()?.toLowerCase()) ===
								option?.value?.toString()?.toLowerCase()
							}
							className={classNames('form-check-input', {
								'is-invalid': touched && error ? true : false,
							})}
						/>
						&nbsp;
						<span className='ml-1 hover:cursor-pointer'>{option.label}</span>
					</label>
				))}
			</div>
			{touched && error ? <div className='text-xs text-red-500'>{error}</div> : undefined}
		</div>
	)
}

export default RadioGroupField
