import ClientMappingContainer from 'modules/client-mapping'
import Header from 'modules/clients/header'
import { AppLayout } from 'theme/layouts'

const ClientMapping = () => {
	return (
		<AppLayout
			meta={{
				title: 'Client Mapping',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<ClientMappingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientMapping
