import { Field } from 'formik'
import { useMemo, useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import { CustodianQuery, getCustodianOptions } from '.'

const CustodianDropdown = ({ isDisabled, isRequiredField }) => {
	const [search, setSearch] = useState('')
	const onInputChange = (value) => setSearch(value)
	const { custodian, isLoading } = CustodianQuery(search)
	const teamOptions = useMemo(() => getCustodianOptions(custodian), [custodian])

	return (
		<Field
			type='select'
			label='Select Custodian'
			name='custodian_id'
			id='custodian_id'
			options={teamOptions}
			isMulti={false}
			isLoading={isLoading}
			onInputChange={onInputChange}
			isSearchable={true}
			component={SelectField}
			isDisabled={isDisabled}
			isRequiredField={isRequiredField}
			isClearable={false}
		/>
	)
}

export default CustodianDropdown
