import classNames from 'classnames'
import { ChevronDown } from 'lucide-react'
import moment from 'moment'
import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const RecipientCard = ({ recipient, email, recipients }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDropdown = () => setIsOpen(!isOpen)

	return (
		<div className='flex items-center space-x-1'>
			<span className='text-xs text-gray-600'>
				to <span className='font-medium'>{recipient?.fullName}</span>
			</span>
			<Dropdown
				direction='down'
				isOpen={isOpen}
				className='max-h-fit'
				toggle={() => toggleDropdown()}>
				<DropdownToggle tag='button' type='button' className={classNames('z-10 max-w-fit')}>
					<span className='mt-[2px]'>
						<ChevronDown size={16} />
					</span>
				</DropdownToggle>

				<DropdownMenu className='mt-1 min-w-[320px] rounded-md border bg-white p-3 shadow-md'>
					<div className='flex flex-col space-y-2'>
						<div className='flex items-center gap-x-2'>
							<span className='w-16 text-sm text-gray-500'>From:</span>
							<span className='whitespace-nowrap text-sm font-medium text-gray-800'>
								{email?.sender?.fullName}
							</span>
							<span className='truncate text-sm text-gray-600'>{`<${email?.sender?.email}>`}</span>
						</div>

						<div className='flex gap-x-2'>
							<span className='w-16 text-sm text-gray-500'>To:</span>
							<div>
								<div className='flex items-center space-x-1'>
									<span className='whitespace-nowrap text-sm text-gray-800'>
										{recipient?.fullName}
									</span>
									<span className='truncate text-sm text-gray-600'>{`<${recipient?.email}>`}</span>
								</div>
								{recipients.map((r, idx) => (
									<div className='flex items-center space-x-1' key={idx}>
										<span className='whitespace-nowrap text-sm text-gray-800'>
											{r?.fullName}
										</span>
										<span className='truncate text-sm text-gray-600'>{`<${r?.email}>`}</span>
									</div>
								))}
							</div>
						</div>

						<div className='flex items-center gap-x-2'>
							<span className='w-16 text-sm text-gray-500'>Date:</span>
							<span className='text-sm text-gray-800'>
								{moment(email?.sent_at).format('ddd, MMM DD, YYYY [at] hh:mm A')}
							</span>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

export default RecipientCard
