import { FIELD_MODE } from '../../helpers'
import SignatureFieldEdit from './signature-field-edit'
import SignatureFieldView from './signature-field-view'

const SignatureField = ({
	field,
	mode = 'edit',
	name,
	onChange,
	onSignatureTypeChange,
	is_read_mode,
	userOptions,
}) => {
	switch (mode) {
		case FIELD_MODE.EDIT:
			return <SignatureFieldEdit name={name} field={field} userOptions={userOptions} />

		case FIELD_MODE.VIEW:
			return (
				<SignatureFieldView
					name={name}
					field={field}
					onChange={onChange}
					onSignatureTypeChange={onSignatureTypeChange}
					is_read_mode={is_read_mode}
				/>
			)
	}
}

export default SignatureField
