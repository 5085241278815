import { Fragment } from 'react'

import { Field } from 'formik'
import { SelectField } from 'theme/ui/forms'
import { createStatusOption } from '.'

const StatusDropdown = ({ isLoading, options, name = 'progress_status' }) => {
	const $options = Array.isArray(options) ? options.map((s) => createStatusOption(s)) : []

	return (
		<Fragment>
			<Field
				isSearchable={false}
				name={name}
				placeholder='Task status'
				isLoading={isLoading}
				options={$options}
				component={SelectField}
			/>
		</Fragment>
	)
}

export default StatusDropdown
