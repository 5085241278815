import apiEndpoints from 'helpers/apiEndpoints'
import request from 'utilities/request'
import AssignedWorkflowSignature from './assigned-workflow-signature'

export const saveDocument = (
	documentFields,
	document_step_id,
	workflow_id,
	signatureFileId,
	userType,
) => {
	return new Promise((resolve, reject) => {
		const payload = {
			documentFields,
			workflow_id,
			document_step_id,
			signatureTimeStamp: new Date().toUTCString(),
			signatureFileId,
			userType,
		}

		request.post(apiEndpoints.client.saveDocument, payload).then((response) => {
			if (response?.status === 200) {
				resolve(response)
			} else {
				reject()
			}
		})
	})
}

export const removeTab = (instance, activeTab) => {
	return Promise.resolve(instance.UI.TabManager.deleteTab(activeTab))
}

export const getAllTabs = (instance) => {
	return Promise.resolve(instance.UI.TabManager.getAllTabs())
}

export const toDataURL = (url) =>
	fetch(url)
		.then((response) => response.blob())
		.then(
			(blob) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.onloadend = () => resolve(reader.result)
					reader.onerror = reject
					reader.readAsDataURL(blob)
				}),
		)

export default AssignedWorkflowSignature
