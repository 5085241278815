import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { Fragment, useEffect, useState } from 'react'
import { Document, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import { saveDocument } from '..'
import PageFields from './page-fields'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DocumentList = ({ documentList, workflow, setIsSaving, isSaving, workflow_id }) => {
	const [numPages, setNumPages] = useState(null)
	const [fields, setFields] = useState(null)
	const [allDocuments, setAllDocuments] = useState([])
	const [signatureId, setSignatureId] = useState(null)
	const [userType, setUserType] = useState(null)
	const { navigate } = useAppRouter()
	const [activeTab, setActiveTab] = useState(documentList[0]?.workflow_step_document_id)
	useEffect(() => {
		if (documentList?.length > 0) {
			setAllDocuments(documentList)
			setActiveTab(documentList[0]?.workflow_step_document_id)
		} else {
			setAllDocuments([])
		}
	}, [documentList])

	const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

	const handleFieldUpdate = (updatedField) => {

		setFields((prevFields) => {
			const existingFieldIndex = prevFields?.findIndex(
				(field) => field?.pdf_field === updatedField?.pdf_field,
			)
			const linkedTimestampIndex = prevFields?.findIndex(
				(field) =>
					field?.pdf_field ===
					`signature_timestamp_${updatedField.pdf_field.split('_')[1]}`,
			)

			const updatedFieldWithTimestamp = {
				...updatedField,
				timeStamp: new Date().toLocaleString(),
			}

			const updatedFields = [...prevFields]

			if (existingFieldIndex !== -1) {
				updatedFields[existingFieldIndex] = updatedFieldWithTimestamp
			} else {
				updatedFields.push(updatedFieldWithTimestamp)
			}

			if (linkedTimestampIndex !== -1) {
				updatedFields[linkedTimestampIndex] = {
					...updatedFields[linkedTimestampIndex],
					value: updatedFieldWithTimestamp.timeStamp,
				}
			}

			return updatedFields
		})
	}
	useEffect(() => {
		if (workflow) {
			setUserType(workflow?.user_type)
			const individualDocument = workflow?.workflow_steps?.find(
				(step) => step?.type === 'individual_document',
			)
			if (individualDocument) {
				const fields = individualDocument?.documents?.find(
					(field) => allDocuments[0]?.workflow_step_document_id === field?.id,
				)
				if (fields?.clientDocument?.document?.length > 0) {
					setFields(fields?.clientDocument?.document)
					setActiveTab(allDocuments[0]?.workflow_step_document_id)
				}
			}
		}
	}, [workflow, allDocuments])

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
		const individualDocument = workflow?.workflow_steps?.find(
			(step) => step?.type === 'individual_document',
		)
		if (individualDocument) {
			const fields = individualDocument?.documents?.find((field) => tab === field?.id)
			if (fields?.clientDocument?.document?.length > 0) {
				setFields(fields?.clientDocument?.document)
			}
		}
	}

	const onSavePdf = async () => {
		try {
			setIsSaving(true)

			if (fields) {
				const currentField = fields?.filter((elem) => elem?.user_type === userType)
				const currentsignatureFields =
					currentField?.filter((field) => field?.type === 'signature') || []
				const isSigned = currentsignatureFields?.every((field) => field?.value)

				if (isSigned) {
					const signatureFields =
						currentField?.filter((field) => field?.type === 'signature') || []
					const documentFields = signatureFields
						.map((signatureField) => {
							const timeStampField = currentField?.find(
								(field) =>
									(field?.type === 'text' &&
										field?.linked_to === signatureField?.pdf_field) ||
									field?.type === 'timestamp',
							)

							const signatureData = {
								value: signatureField?.value?.pathname
									? signatureField?.value?.pathname?.charAt(0) === '/'
										? signatureField.value.pathname.slice(1)
										: signatureField.value.pathname
									: signatureField?.value?.file?.path,
								rects: signatureField?.rects,
								type: signatureField?.type,
								timeStamp: signatureField?.timeStamp,
								user_type: userType,
							}

							const timestampData = {
								type: 'timestamp',
								rects: timeStampField?.rects?.length
									? timeStampField?.rects
									: signatureField?.rects.map((rect) => ({
											...rect,
											y: rect.y + rect.height + 5,
											height: 15,
										})),
								value: timeStampField?.value || signatureField?.timeStamp,
								user_type: userType,
							}

							return [signatureData, timestampData]
						})
						.flat()

					const currentDocument = documentList?.find(
						(elem) => elem?.workflow_step_document_id === activeTab,
					)
					const signatureFileId = signatureFields[0]?.value?.fileID
					? signatureFields[0]?.value?.fileID
					: signatureId ? signatureId : null

					const response = await saveDocument(
						documentFields,
						currentDocument?.workflow_step_document_id,
						workflow_id,
						signatureFileId,
						userType,
					)
					if (response?.status === 200) {
						if (allDocuments.length === 1) {
							notification('success', 'All documents are saved successfully!')
							navigate(`/assigned-workflows`)
							return
						}
						const updatedDocumentList = allDocuments.filter(
							(doc) => doc.workflow_step_document_id !== activeTab,
						)
						setAllDocuments(updatedDocumentList)
						const nextTab = updatedDocumentList[0].workflow_step_document_id
						setActiveTab(nextTab)
						const individualDocument = workflow?.workflow_steps?.find(
							(step) => step?.type === 'individual_document',
						)
						if (individualDocument) {
							const nextFields = individualDocument?.documents?.find(
								(field) => nextTab === field?.id,
							)
							if (nextFields?.clientDocument?.document?.length > 0) {
								setFields(nextFields?.clientDocument?.document)
							} else {
								setFields([])
							}
						}
					} else {
						notification('warn', response?.data?.message)
					}
				} else {
					notification('warn', 'Please insert your signatures')
				}
			}

			setIsSaving(false)
		} catch (error) {
			setIsSaving(false)
			notification('warning', 'Something went wrong while saving the document')
		}
	}
	return (
		<Fragment>
			<Nav tabs className='nav-tabs-custom !flex flex-nowrap  mb-4'>
				{allDocuments?.map((tab, idx) => (
					<NavItem key={idx}>
						<NavLink
							className={classNames('px-2 font-bold', {
								active: activeTab === tab.workflow_step_document_id,
							})}
							onClick={() => toggleTab(tab.workflow_step_document_id)}>
							<span className='block font-bold text-[16px]'>{tab.title}</span>
						</NavLink>
					</NavItem>
				))}
			</Nav>

			<div className='document-content'>
				{allDocuments.map(
					(doc, index) =>
						doc.workflow_step_document_id === activeTab && (
							<Document
								key={index}
								file={doc.src}
								onLoadSuccess={onDocumentLoadSuccess}
								loading={<Spinner />}
								className='border rounded-md p-2 mb-4'>
								{Array.from({ length: numPages }, (_, index) => (
									<PageFields
										key={`page_${index + 1}`}
										pageIndex={index}
										fields={fields}
										userType={userType}
										onUpdateField={handleFieldUpdate}
										setSignatureId={setSignatureId}
									/>
								))}
							</Document>
						),
				)}
			</div>
			<div className='my-4 w-full border-t border-dashed' />

			<div className='actions clearfix'>
				<div className='m-0 flex justify-end'>
					<Button
						isLoading={isSaving}
						isLoadingText={'Saving...'}
						type='button'
						onClick={onSavePdf}>
						{allDocuments.length === 1 ? 'Submit' : 'Next'}
					</Button>
				</div>
			</div>
		</Fragment>
	)
}

export default DocumentList
