import classNames from 'classnames'
import { ChevronDown } from 'lucide-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { useUser } from 'hooks'
import UserQuery from 'hooks/UserQuery'
import { snakeCase } from 'lodash'
import {
	useEmailInboxCheckoutMutation,
	useLazyCheckNylasQuery,
	useLazyGetNylasQuery,
} from 'modules/authentication/auth-api'
import { useSelector } from 'react-redux'
import NylasModal from 'theme/ui/nylas-notification/nylas-modal'
import notification from 'utilities/notification'
import { POPUP_STATES } from '.'
import OnboardPopup from './onboard-popup'
import SendEmailPopup from './send-email-popup'
import SendInvitationPopup from './send-invitation-popup'

const BulkActions = ({
	selectedRows,
	assignWorkflow,
	checkedTags,
	selectedRecords,
	isAssignDisabled,
	isNonInvitedSelected,
}) => {
	const { team } = useUser()
	const { user } = UserQuery()
	const [isOpen, setIsOpen] = useState(false)
	const [fetchNylas, { isFetching: isNylasConnecting }] = useLazyGetNylasQuery()
	const [checkNylas, { isFetching: isCheckingNylas }] = useLazyCheckNylasQuery()
	const { defaultGroup } = useSelector((state) => state.Permissions)
	const currentGroup = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	const [emailInboxCheckout, { isLoading: isSpinning }] = useEmailInboxCheckoutMutation()
	const [isPopOpen, setPopIsOpen] = useState(false)

	const toggle = () => setIsOpen(!isOpen)
	const [popupStates, setPopupStates] = useState({
		[POPUP_STATES.ONBOARD_NOW]: false,
		[POPUP_STATES.SEND_INVITATION]: false,
		[POPUP_STATES.SEND_EMAIL]: false,
	})
	const onPopupOpen = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: true,
		}))
	}
	const onPopupClose = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: false,
		}))
	}

	const onConnectNylas = async () => {
		try {
			const response = await fetchNylas().unwrap()

			if (response?.status === 200 && response?.data) {
				window.location.href = response.data
			}
		} catch (error) {
			notification('error', error?.message || 'Failed to link email.')
		}
	}
	useEffect(() => {
		const fetchNylasStatus = async () => {}
		fetchNylasStatus()
	}, [])
	const onOpen = () => {
		setPopIsOpen(true)
	}
	const onClose = () => setPopIsOpen(false)
	const makePayment = async () => {
		try {
			const payload = {
				customerId: user?.stripe_customer_id,
			}
			const response = await emailInboxCheckout(payload).unwrap()
			const redirectUrl = response?.url
			if (redirectUrl) {
				window.location.href = redirectUrl
			} else {
				notification('warn', response?.message || 'Unable to proceed with payment.')
				onClose()
			}
		} catch (error) {
			notification('error', error?.Message)
			onClose()
		}
	}

	const handleAction = async () => {
		const response = await checkNylas()
		if (response?.data?.status !== 200 && currentGroup === 'owner') {
			onOpen()
		} else if (
			!team?.nylasGrantId &&
			response?.data?.status === 200 &&
			currentGroup === 'owner'
		) {
			onConnectNylas()
		} else if (currentGroup !== 'owner') {
			notification(
				'Please Contact your team owner to connect the email address to send emails',
			)
		} else {
			onPopupOpen(POPUP_STATES.SEND_EMAIL)
		}
	}

	const isLoadingEmail = isNylasConnecting || isCheckingNylas

	return (
		<Fragment>
			<OnboardPopup
				onClose={() => onPopupClose(POPUP_STATES.ONBOARD_NOW)}
				isOpen={popupStates[POPUP_STATES.ONBOARD_NOW]}
				selectedRows={selectedRows}
			/>
			<SendInvitationPopup
				onClose={() => onPopupClose(POPUP_STATES.SEND_INVITATION)}
				isOpen={popupStates[POPUP_STATES.SEND_INVITATION]}
				selectedRows={selectedRows}
			/>
			<SendEmailPopup
				onClose={() => onPopupClose(POPUP_STATES.SEND_EMAIL)}
				isOpen={popupStates[POPUP_STATES.SEND_EMAIL]}
				selectedRows={selectedRecords}
			/>
			<NylasModal
				isOpen={isPopOpen}
				onClose={onClose}
				makePayment={makePayment}
				isFetching={isSpinning}
			/>
			<Dropdown isOpen={isOpen} toggle={() => toggle()}>
				<DropdownToggle
					tag='button'
					type='button'
					className={classNames('btn btn-secondary max-w-fit', {
						'': isOpen,
					})}>
					<div className='flex items-center justify-between space-x-3'>
						<span>Actions</span>
						<span className=''>
							<ChevronDown size={16} />
						</span>
					</div>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-end mt-2 p-0 py-2 shadow-md'>
					<ul className='m-0 flex flex-col p-0'>
						<li className='exp-dropdown-item' onClick={handleAction}>
							{isLoadingEmail ? (
								<span className='flex items-center gap-1'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										viewBox='0 0 24 24'>
										<path
											fill={'#5b73e8'}
											d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'
											opacity='.25'
										/>
										<path
											fill={'#5b73e8'}
											d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z'>
											<animateTransform
												attributeName='transform'
												dur='0.75s'
												repeatCount='indefinite'
												type='rotate'
												values='0 12 12;360 12 12'
											/>
										</path>
									</svg>
									<span>Loading...</span>
								</span>
							) : (
								<span>Send Email</span>
							)}
						</li>
						{isNonInvitedSelected ? (
							<>
								<li
									className='exp-dropdown-item'
									onClick={() => onPopupOpen(POPUP_STATES.SEND_INVITATION)}>
									<span>Send Invitation</span>
								</li>
								<li
									className='exp-dropdown-item'
									onClick={() => onPopupOpen(POPUP_STATES.ONBOARD_NOW)}>
									<span>Onboard Now</span>
								</li>
							</>
						) : undefined}

						{checkedTags?.length > 0 &&
						isAssignDisabled &&
						selectedRecords?.length > 0 ? (
							<li className='exp-dropdown-item' onClick={assignWorkflow}>
								<span>Assign Workflow</span>
							</li>
						) : undefined}
					</ul>
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	)
}

export default BulkActions
