import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import errorImg from 'assets/images/404-error.png'

const NotFound = () => {
	return (
		<div className='flex flex-col items-center justify-center gap-16 px-4 py-24 md:gap-28 md:px-44 md:py-20 lg:flex-row lg:px-24 lg:py-24'>
			<div className='relative w-full pb-12 lg:pb-0 xl:w-1/2 xl:pt-24'>
				<div className='text-center'>
					<div>
						<Row className='row justify-content-center'>
							<Col sm={4}>
								<div className='error-img'>
									<img
										src={errorImg}
										alt=''
										className='img-fluid d-block mx-auto'
									/>
								</div>
							</Col>
						</Row>
					</div>
					<h4 className='text-uppercase mt-4'>Sorry, page not found</h4>
					<p className='text-muted'>
						The requested resource is not found in our system. Please ensure that the
						resource exists in the system.
					</p>
					<div className='mt-5'>
						<Link className='btn btn-primary waves-effect waves-light' to='/'>
							Go back
						</Link>
					</div>
				</div>
			</div>
			{/* <div>
				<img src='https://i.ibb.co/ck1SGFJ/Group.png' />
			</div> */}
		</div>
	)
}

export default NotFound
