import * as yup from 'yup'
import AddFieldPopup from './add-field-popup'

export const validationSchema = yup.object().shape({
	key: yup.mixed().required('Please choose field key'),
	value: yup.string().required('Please enter field value'),
})

export const getPayload = (values) => {
	return {
		user: values?.user,
		fields: [
			{
				value: values?.value,
				key: values?.key?.value,
			},
		],
	}
}

export default AddFieldPopup
