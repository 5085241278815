import { Dialog, Transition } from '@headlessui/react'
import { Image } from 'lucide-react'
import { Fragment, useState } from 'react'

const AttachmentViewModal = ({ isOpen, onClose, file }) => {
	const [isImageLoaded, setIsImageLoaded] = useState(false)
	const fileUrl = file.path

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1052]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white !p-3'>
										<Image />
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>

								<div className='relative mt-6 flex flex-col items-center'>
									{!isImageLoaded && (
										<div className='flex flex-col items-center justify-center'>
											<div className='h-8 w-8 animate-spin rounded-full border-4 border-gray-300 border-t-blue-500'></div>
											<p className='mt-2 text-gray-500'>Loading...</p>
										</div>
									)}
									<img
										src={file?.path}
										alt={file?.originalname}
										className={`max-h-[60vh] max-w-full object-contain ${
											!isImageLoaded ? 'hidden' : ''
										}`}
										onLoad={() => setIsImageLoaded(true)}
									/>
									{/* {isImageLoaded && (
										<a
											href={fileUrl}
											target='_blank'
											download
											className='absolute right-2 top-2 cursor-pointer rounded-full bg-white px-2 py-1 shadow-md'
											rel='noreferrer'>
											<i className='uil uil-import text-lg' />
										</a>
									)} */}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default AttachmentViewModal
