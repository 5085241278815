import React from 'react'

import Filters from '../task-listing/filters'
import Board from './board'

const BoardViewContainer = ({ board }) => {
	return (
		<React.Fragment>
			<Filters type='kanban' board={board} />
			<Board board={board} />
		</React.Fragment>
	)
}

export default BoardViewContainer
