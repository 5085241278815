import { FIELD_MODE } from '../../helpers';
import NetPromoterScoreEdit from './net-promoter-score-edit';
import NetPromoterScoreView from './net-promoter-score-view';

const NetPromoterScoreField = ({
	field,
	mode = 'edit',
	name,
	userOptions,
	onChange,
	onNPSLabelChange,
}) => {
	switch (mode) {
		case FIELD_MODE.EDIT:
			return (
				<NetPromoterScoreEdit
					name={name}
					field={field}
					userOptions={userOptions}
					onNPSLabelChange={onNPSLabelChange}
				/>
			)

		case FIELD_MODE.VIEW:
			return <NetPromoterScoreView name={name} field={field} onChange={onChange} />

		default:
			return null
	}
}

export default NetPromoterScoreField;