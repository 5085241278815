import { useLazyGetEmailsQuery } from 'modules/clients/client-email-api'
import { useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

const EmailsQuery = (page = 1, limit = 10, client_id, $search) => {
	const [search] = useDebounce($search, 200)
	const [fetchEmails, { data, isFetching }] = useLazyGetEmailsQuery()
	useEffect(() => {
		if (client_id) {
			const params = {
				page: page,
				limit: limit,
			}
			if (search) {
				Object.assign(params, {
					search: search,
				})
			}
			fetchEmails({ params, client_id })
		}
	}, [page, limit, client_id, search])

	const meta = data?.data?.meta || {}
	const emails = useMemo(
		() => (Array.isArray(data?.data?.emails) ? data?.data?.emails : []),
		[data?.data?.emails],
	)
	return {
		isLoading: isFetching,
		emails: emails,
		meta: meta,
	}
}

export default EmailsQuery
