import { FieldArray, FormikProvider, useFormik } from 'formik'
import { useUser } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { getPayload, initialValues, validationSchema } from '.'
import ClientDropdown from './client-dropdown/client-dropdown'
import {
	useAddClientMappingMutation,
	useDeleteClientMappingMutation,
	useLazyGetClientsMappingQuery,
} from './client-mapping-api'
import SftpDropdown from './sftp-dropdown/sftp-dropdown'

const ClientMappingForm = () => {
	const [getClientsMapping, { data, isLoading }] = useLazyGetClientsMappingQuery()
	const { team } = useUser()
	const [addClientMapping] = useAddClientMappingMutation()
	const [deleteClientMapping, { isLoading: isDeleting }] = useDeleteClientMappingMutation()
	const [mapData, setMapData] = useState([])
	useEffect(() => {
		getClientsMapping()
	}, [getClientsMapping])

	useEffect(() => {
		if (data) {
			setMapData(data?.data?.clientUsers)
		}
	}, [data])

	const formik = useFormik({
		initialValues: initialValues([]),
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				const payload = getPayload(values, team)
				const response = await addClientMapping(payload)

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
				}
			} catch (error) {
				notification('warn', 'Error submitting mapping')
			} finally {
				setSubmitting(false)
			}
		},
	})

	const isAddDisabled = useMemo(
		() =>
			formik.values.clients.some((client) => !client.client) ||
			formik.values.sftp.some((sftp) => !sftp.client),
		[formik?.values],
	)
	useEffect(() => {
		if (mapData && mapData.length > 0) {
			const updatedValues = initialValues(mapData)
			formik.setValues(updatedValues)
		}
	}, [mapData])

	const handleAddClient = (pushClients, pushSftp) => {
		pushClients({ client: '' })
		pushSftp({ client: '' })
	}
	const handleRemove = async (id, type) => {
		if (type === 'id') {
			const response = await deleteClientMapping(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		} else if (type === 'index') {
			const updatedClients = [...formik.values.clients]
			const updatedSftp = [...formik.values.sftp]
			updatedClients.splice(id, 1)
			updatedSftp.splice(id, 1)
			formik.setFieldValue('clients', updatedClients)
			formik.setFieldValue('sftp', updatedSftp)
			notification('success', 'Clients User Mapping removed successfully.')
		}
	}
	return (
		<div>
			{isLoading ? (
				<Spinner />
			) : (
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit}>
						<FieldArray
							name='clients'
							render={({ push: pushClients }) => (
								<FieldArray
									name='sftp'
									render={({ push: pushSftp }) => (
										<div className='space-y-4'>
											<div className='items-top grid grid-cols-9 gap-4 '>
												<div className='col-span-4 font-semibold'>
													Experivise Clients
												</div>
												<div className='col-span-4 font-semibold'>
													Clients from Custodian Data Source
												</div>
												<div className='col-span-1 font-semibold'>
													{formik.values.clients?.length > 1
														? 'Action'
														: ''}
												</div>
											</div>
											{formik.values.clients.map((item, index) => (
												<div
													key={index}
													className='items-top grid grid-cols-9 gap-4 '>
													<div className='col-span-4'>
														<ClientDropdown
															name={`clients[${index}].client`}
														/>
													</div>
													<div className='col-span-4'>
														<SftpDropdown
															name={`sftp[${index}].client`}
															selectedClients={formik.values.sftp.map(
																(sftp) => sftp.client?.value,
															)}
														/>
													</div>
													{formik.values.clients?.length > 1 && (
														<div className='col-span-1	'>
															{isAllowTo(
																permissions?.custodianMapping
																	?.delete,
															) ? (
																<Button
																	type='button'
																	variant='danger-outline'
																	disabled={isDeleting}
																	onClick={() => {
																		handleRemove(
																			item?.client?.id
																				? item?.client?.id
																				: index,
																			item?.client?.id
																				? 'id'
																				: 'index',
																		)
																	}}>
																	<i
																		className='uil-trash-alt'
																		style={{ fontSize: 14 }}
																	/>
																</Button>
															) : undefined}
														</div>
													)}
												</div>
											))}

											{isAllowTo(permissions?.custodianMapping?.create) ? (
												<Button
													disabled={isAddDisabled}
													type='button'
													variant='primary-outline'
													onClick={() =>
														handleAddClient(pushClients, pushSftp)
													}>
													Add
												</Button>
											) : undefined}
										</div>
									)}
								/>
							)}
						/>
						{isAllowTo(permissions?.custodianMapping?.create) ? (
							<div className='mt-6 flex justify-end'>
								<Button
									type='submit'
									disabled={isAddDisabled}
									isLoading={formik.isSubmitting}>
									Submit
								</Button>
							</div>
						) : undefined}
					</form>
				</FormikProvider>
			)}
		</div>
	)
}

export default ClientMappingForm
