import { Fragment } from 'react'

import SamePage from './same-page'

const ProfileFieldsTab = ({ docs, error, fields, isReadOnly, currentStatus }) => {
	return (
		<Fragment>
			<SamePage
				isReadOnly={isReadOnly}
				docs={docs}
				error={error}
				fields={fields}
				currentStatus={currentStatus}
			/>
		</Fragment>
	)
}

export default ProfileFieldsTab
