import { FIELD_MODE } from '../../helpers'
import TextFieldEdit from './text-field-edit'
import TextFieldView from './text-field-view'

const TextField = ({ field, mode = 'edit', name, onChange, is_read_mode, userOptions }) => {
	switch (mode) {
		case FIELD_MODE.EDIT:
			return <TextFieldEdit field={field} name={name} userOptions={userOptions} />

		case FIELD_MODE.VIEW:
			return (
				<TextFieldView
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
				/>
			)
	}
}

export default TextField
