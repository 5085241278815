import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { useAppRouter, useUser } from 'hooks'
import { useLazyCheckSubscriptionLimitQuery } from 'modules/authentication/auth-api'
import { OnBoardStatuses } from 'modules/clients/invited-clients'
import BulkActions from 'modules/clients/invited-clients/bulk-actions'
import { CLIENT_APP_ROUTES } from 'modules/clients/routes'
import UpdateSubscriptionPopup from 'modules/profile/my-subscriptions/update-subscription-popup/update-subscription-popup'
import { useMediaQuery } from 'react-responsive'
import { useDataTable } from 'theme/ui/data-table'
import { DateRange } from 'theme/ui/filters'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString, isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'
import StatusDropdown from './status-dropdown'
import TagsDropdown from './tags-dropdown/tags-dropdown'

const Filters = ({
	tagList,
	selectedTags,
	applyTags,
	createTag,
	assignWorkflow,
	setTagSearch,
	tagSearch,
	selectedRecords,
	checkedTags,
	isAssignDisabled,
	uniqueTagList,
	previousData,
	setPagination,
	rowSelection,
	setRowSelection,
	setSelectedRecords,
	isAssigning,
	isCreating,
}) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues, enableReinitialize: true })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 400)
	const $selectedRows = useMemo(
		() => Object.keys(rowSelection).map((k) => JSON.parse(k)?.id),
		[rowSelection],
	)
	const { navigate } = useAppRouter()
	const { user } = useUser()
	const [checkSubscriptionLimit, { isLoading: isFetching, data: limit }] =
		useLazyCheckSubscriptionLimitQuery()
	const selectedStatuses = useMemo(
		() => Object.keys(rowSelection).map((k) => JSON.parse(k)?.status),
		[rowSelection],
	)
	const isNonInvitedSelected =
		selectedStatuses.includes(OnBoardStatuses.NON_INVITED) ||
		selectedStatuses.includes(OnBoardStatuses.PENDING) ||
		selectedStatuses.includes(OnBoardStatuses.ONBOARDING) ||
		selectedStatuses.includes(OnBoardStatuses.REJECTED)

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const handleTagChange = (e, tag) => {
		const tagChecked = selectedTags[tag]?.checked
		if (selectedRecords?.length > 0) {
			setSelectedRecords((prevSelectedRecords) =>
				prevSelectedRecords?.map((record) => {
					const tags = record?.tags || []
					const hasTag = tags.includes(tag)

					if (tagChecked) {
						return {
							...record,
							tags: tags.filter((t) => t !== tag),
						}
					} else {
						return {
							...record,
							tags: hasTag ? tags : [...tags, tag],
						}
					}
				}),
			)
		} else {
			// const isChecked = e.target.checked

			const $groups = formik?.values?.groups
			const groups = $groups.includes(tag)
				? $groups.filter((selectedItem) => selectedItem !== tag)
				: [...$groups, tag]
			formik.setFieldValue('groups', groups)
		}
	}

	const activeFilters = getActiveFilters(parseQueryString)
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })
	const isInvitePermission = isAllowTo(permissions?.client?.create)
	const isImportPermission = isAllowTo(permissions?.client?.import)

	useEffect(() => {
		if (user?.id) {
			checkSubscriptionLimit('client')
		}
	}, [])

	const [isOpen, setIsOpen] = useState(false)

	const handleClick = (type) => {
		if (limit?.data?.availableLimit <= 0) {
			setIsOpen(true)
		} else {
			if (type === 'create') {
				navigate(CLIENT_APP_ROUTES.invite())
			} else if (type === 'import') {
				navigate(CLIENT_APP_ROUTES.import())
			}
		}
	}

	return (
		<>
			{isOpen && (
				<UpdateSubscriptionPopup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					title='Upgrade Your Plan'
					description='You’ve reached the client onboarding limit for your current subscription. Upgrade your plan to add more clients.'
				/>
			)}
			<FormikProvider value={formik}>
				<form className='mb-3 space-y-4'>
					<div className='flex items-end justify-between gap-2'>
						<div className='client-searchbar min-w-[320px]'>
							<Field
								className='form-control'
								type='search'
								name='search'
								component={SearchField}
								placeholder='Search clients...'
							/>
						</div>
						{!isMobile && (
							<div className='client-list float-right flex flex-wrap items-center justify-end gap-3'>
								{isImportPermission && (
									<Button
										type='button'
										variant='primary-outline'
										onClick={() => handleClick('import')}
										disabled={isFetching}
										className='import-csv'>
										Import CSV
									</Button>
								)}
								{isInvitePermission && (
									<Button
										type='button'
										variant='primary'
										onClick={() => handleClick('create')}
										disabled={isFetching}
										className='create-client'>
										Create Client
									</Button>
								)}
							</div>
						)}
					</div>

					<div className='flex flex-wrap justify-start gap-2'>
						<div className='filters-button flex flex-wrap  items-center gap-2'>
							<TagsDropdown
								formik={formik}
								setTagSearch={setTagSearch}
								tagSearch={tagSearch}
								tagList={tagList}
								selectedTags={selectedTags}
								handleTagChange={handleTagChange}
								applyTags={applyTags}
								assignWorkflow={assignWorkflow}
								selectedRecords={selectedRecords}
								createTag={createTag}
								checkedTags={checkedTags}
								isAssignDisabled={isAssignDisabled}
								uniqueTagList={uniqueTagList}
								previousData={previousData}
								isAssigning={isAssigning}
								isCreating={isCreating}
							/>
							<StatusDropdown name='onBoardStatus' />
							<DateRange name='date_range' label='Added On' />

							{$selectedRows.length > 0 && (
								<BulkActions
									assignWorkflow={assignWorkflow}
									checkedTags={checkedTags}
									isAssignDisabled={isAssignDisabled}
									selectedRecords={selectedRecords}
									selectedRows={$selectedRows}
									isNonInvitedSelected={isNonInvitedSelected}
								/>
							)}
						</div>
						<div className='flex items-center gap-2'>
							{activeFilters.length > 0 && (
								<Button size='sm' variant='ghost' onClick={onResetFilters}>
									Clear Filters
								</Button>
							)}

							<div className='flex items-center divide-x divide-gray-200'>
								{($selectedRows.length > 0 && isNonInvitedSelected) ||
								(checkedTags?.length > 0 &&
									isAssignDisabled &&
									selectedRecords?.length > 0) ? (
									<span className='pr-3 text-sm text-gray-500'>
										{$selectedRows.length} selected
									</span>
								) : null}

								{$selectedRows.length > 0 && (
									<div className='pl-3'>
										<span
											className='cursor-pointer text-sm font-semibold text-gray-600 hover:underline'
											onClick={() => setRowSelection({})}>
											Deselect
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</form>
			</FormikProvider>
		</>
	)
}

export default Filters
