import { OnBoardStatusSlice } from './slices'

const { actions } = OnBoardStatusSlice

export const setOnBoardStatus = (payload) => (dispatch) => {
	dispatch(actions.setOnBoardStatus(payload))
}
export const setIsLoading = (payload) => (dispatch) => {
	dispatch(actions.setIsLoading(payload))
}

export const setData = (payload) => (dispatch) => {
	dispatch(actions.setData(payload))
}