import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const Alert = ({ variant, children, className }) => {
	return (
		<Fragment>
			<div
				className={classNames('rounded !border px-3 py-2', className, {
					'border-yellow-700 bg-yellow-50 text-yellow-600': variant === 'warning',
					'border-main bg-main/20 text-main': variant === 'primary',
					'border-green-700 bg-green-100 text-green-700': variant === 'success',
					'border-red-700 bg-red-100 text-red-700': variant === 'danger',
					'border-cyan-700 bg-cyan-100 text-cyan-700': variant === 'info',
				})}>
				{children}
			</div>
		</Fragment>
	)
}
Alert.propTypes = {
	variant: PropTypes.oneOf(['primary', 'danger', 'success', 'warning']),
}

Alert.defaultProps = {
	variant: 'primary',
	classNames: '',
}

export default Alert
