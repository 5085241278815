import StatusDropdown from './status-dropdown'

export const createStatusOption = (s) => ({
	label: (
		<div className='flex items-center gap-2'>
			<span
				className='block h-3 min-w-3 rounded-sm'
				style={{ backgroundColor: s?.category?.color }}
			/>
			<span>{s?.name}</span>
		</div>
	),
	value: s?.id,
})

export default StatusDropdown
