import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import FileCheckIcon from '../../../assets/svg/file-check-2.svg';
import PdfViewer from './pdf-viewer'

const DocumentPreview = ({ title, url, isOpen, toggle }) => {
	useEffect(() => {
		const body = document.getElementsByTagName('body')[0].style
		if (isOpen) {
			body.overflow = 'hidden'
		} else {
			body.overflow = 'auto'
		}

		return () => {
			body.overflow = 'auto'
			body.paddingRight = 0
		}
	}, [isOpen])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex items-center justify-between rounded-3xl bg-blue-50 p-3'>
									<div className='absolute left-2 rounded-full !border !border-gray-300 bg-white p-2'>
										<img src={FileCheckIcon} alt="File Check" className="w-6 h-6 text-blue-500" />
									</div>
									<div className=' ms-10 text-[20px] font-bold'>File Preview</div>
									<button type='button' className='right-0' onClick={toggle}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className=' flex flex-col'>
									<div className='ms-4 flex flex-col justify-start p-2'>
										<div>
											<p className='m-0 p-0 text-lg font-medium'>
												You are previewing the document:
												<span className='text-blue-400'>
													{' '}
													{title || 'Document'}
												</span>
											</p>
										</div>
									</div>
									<div className='mx-4 mb-3 p-0'>
										<PdfViewer src={url} />
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default DocumentPreview
