import { useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import { generateUniqueId } from 'utilities/helpers'

const NetPromoterScore = ({
	input,
	meta: { touched, error },
	label,
	isRequired,
	infoText,
	isTooltip = false,
	tooltipIcon = null,
	tooltipText = '',
	npsLabels,
}) => {
	const [selectedScore, setSelectedScore] = useState(input.value || 0)
	const tooltipId = generateUniqueId()

	const handleNPSChange = (value) => {
		setSelectedScore(value)
		input.onChange(value)
	}

	return (
		<div className='form-group flex flex-col space-y-3'>
			{/* Label */}
			{label && (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label} {isRequired && <span className='text-red-500'>*</span>}
						{isTooltip && (
							<>
								<ToolTip
									targetId={`rating-tooltip`}
									tooltipText={tooltipText}
									placement='top'
								/>
								<i
									id={`rating-tooltip`}
									className={`${tooltipIcon || ''} ms-2 cursor-pointer`}></i>
							</>
						)}
					</span>
				</label>
			)}

			{/* NPS Rating Buttons (0-10) */}
			<div className='w-full space-y-2 rounded-md '>
				<div className='w-full space-y-2 rounded-md '>
					<div className='flex items-center justify-between overflow-hidden rounded-md border border-gray-300'>
						{Array.from({ length: 11 }, (_, index) => (
							<button
								key={index}
								type='button'
								onClick={() => handleNPSChange(index)}
								className={`flex-1 !border border-r py-2 text-center text-sm font-medium transition-colors ${
									index === 10 ? '!border-r-0' : ''
								} ${
									Number(selectedScore) === index
										? 'bg-primary text-white'
										: 'bg-white text-gray-700 hover:bg-gray-200'
								}`}>
								{index}
							</button>
						))}
					</div>

					<div className='mt-2 flex justify-between gap-2 text-sm text-gray-700'>
						{npsLabels?.low_score ? (
							<span
								className='line-clamp-1 rounded-md !border px-3 py-1'
								title={npsLabels?.low_score}>
								{npsLabels?.low_score}
							</span>
						) : (
							<span></span>
						)}
						{npsLabels?.high_score ? (
							<span
								className='line-clamp-1 rounded-md !border px-3 py-1'
								title={npsLabels?.high_score}>
								{npsLabels?.high_score}
							</span>
						) : (
							<span></span>
						)}
					</div>
				</div>
			</div>

			{/* Info Text */}
			{infoText && <span className='text-[13px] text-gray-400'>{infoText}</span>}

			{/* Error Message */}
			{touched && error && (
				<span className='mt-1 w-full text-[87.5%] text-[#f46a6a]'>{error}</span>
			)}
		</div>
	)
}

export default NetPromoterScore
