/* eslint-disable no-unsafe-optional-chaining */
import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { toQueryString } from 'utils/commonFunctions'
import { getFilters } from './filters-form'

const systemFieldsListQuery = (pagination) => {
	const { pageIndex, pageSize } = pagination
	const formValues = useSelector((state) => state.form.system_filters?.values || {})
	const filterValues = useMemo(() => getFilters(formValues), [formValues])

	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize, ...filterValues }
		return obj
	}, [pageIndex, pageSize, filterValues])

	const query = useMemo(() => toQueryString(filters), [filters])
	const URL = useMemo(() => `${apiEndpoints.systemFields}?${query}`, [query])

	const { data, isLoading, mutate } = useQuery(URL)

	// const $fields =
	// 	Array.isArray(data?.data?.documentFields[0]?.customFiled) &&
	// 	Array.isArray(data?.data?.documentFields[0]?.systemFields)
	// 		? [
	// 				...data?.data?.documentFields[0]?.customFiled,
	// 				...data?.data?.documentFields[0]?.systemFields,
	// 		  ]
	// 		: []

	// console.log('$fields', $fields)

	const fields = useMemo(
		() => (Array.isArray(data?.data?.documentFields) ? [] : []),
		[data?.data?.documentFields],
	)

	return {
		fields,
		meta: data?.data?.meta,
		mutate,
		isLoading,
	}
}

export default systemFieldsListQuery
