import {
	closestCenter,
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { Plus } from 'lucide-react'
import {
	useDeleteBoardColumnMutation,
	useUpdateBoardColumnMutation,
	useUpdateTaskStatusMutation,
} from 'modules/scrum-boards/boards-api'
import { MAX_COLUMNS } from 'modules/tasks/kanban'
import CreateColumnPopup from 'modules/tasks/kanban/create-column-popup'
import React, { Fragment, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import Swal from 'sweetalert2'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { reducer } from '.'
import Column from './column'

const ColumnMapping = ({ board }) => {
	const $columns = useMemo(
		() =>
			Array.isArray(board?.columns)
				? board?.columns.map((c) => ({
						id: c?.id,
						name: c?.name,
						category_id: c?.category_id,
						statuses: Array.isArray(c?.task_statuses)
							? c?.task_statuses.map((s) => ({
									id: s?.id,
									name: s?.name,
									parent: c?.name,
								}))
							: [],
						cards: Array.isArray(c?.tasks) ? c?.tasks : [],
					}))
				: [],
		[board],
	)

	const initialState = useMemo(() => {
		const obj = {}
		$columns.forEach((col) => {
			obj[col.name] = Array.isArray(col?.statuses) ? col?.statuses : []
		})
		return obj
	}, [$columns])

	const [columns, setColumns] = useState($columns)
	const [updateStatus] = useUpdateTaskStatusMutation()
	const [deleteColumn] = useDeleteBoardColumnMutation()
	const [updateColumn] = useUpdateBoardColumnMutation()
	const [state, localDispatch] = useReducer(reducer, initialState)
	const [showAddForm, setShowAddForm] = useState(false)
	const [editingColumnId, setEditingColumnId] = useState(null)
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

	useEffect(() => {
		setColumns($columns)
	}, [$columns])

	const initializeColumns = useCallback(() => {
		columns.forEach((column) => {
			localDispatch({
				type: 'SET_TASKS',
				column: column.name,
				tasks: column.statuses,
			})
		})
	}, [columns])

	const handleDragEnd = useCallback(
		async (event) => {
			const { active, over } = event
			const $over = over?.data?.current

			if (!over) return

			if (active.data.current?.type === 'column') {
				const oldIndex = columns.findIndex((col) => col.id === active.id)
				const newIndex = columns.findIndex((col) => col.id === over.id)
				const column = columns.find((col) => col.id === active.id)
				if (oldIndex !== newIndex) {
					const newColumns = arrayMove(columns, oldIndex, newIndex)
					setColumns(newColumns)

					try {
						const response = await updateColumn({
							id: column?.id,
							payload: {
								display_order: newIndex + 1,
							},
						}).unwrap()

						if (response?.status === 200) {
							notification('success', 'Column order updated successfully')
						}
					} catch (error) {
						notification('error', 'Failed to update column order')
					}
				}
				return
			}

			const activeColumn = active.data.current?.parent
			const task = active.data.current?.task
			const index = active.data.current?.index
			const overColumn = over.data.current?.status

			if (activeColumn && overColumn && activeColumn !== overColumn) {
				localDispatch({
					type: 'MOVE_TASK',
					from: activeColumn,
					to: overColumn,
					task,
					index,
				})

				const response = await updateStatus({
					payload: {
						board_column_id: $over?.board_column_id,
						category_id: $over?.category_id,
					},
					id: task?.id,
				}).unwrap()
				if (response?.status === 200) {
					notification('success', response?.message)
				}
			}
		},
		[columns, updateColumn, updateStatus], // Add dependencies
	)

	useEffect(() => {
		if (columns.length > 0) initializeColumns()
	}, [columns, initializeColumns])

	const handleDeleteColumn = async (id) => {
		const result = await Swal.fire({
			title: 'Are you sure?',
			text: 'This column will be permanently deleted!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		})
		if (result?.isConfirmed) {
			const response = await deleteColumn(id).unwrap()
			if (response?.status === 200) {
				Swal.fire('Deleted!', 'Column has been deleted.', 'success')
				setColumns((prev) => prev.filter((col) => col.id !== id))
			}
		}
	}

	const handleUpdateColumn = async (columnId, newName) => {
		try {
			const response = await updateColumn({
				id: columnId,
				payload: { name: newName },
			}).unwrap()

			if (response?.status === 200) {
				setColumns((prevColumns) =>
					prevColumns.map((col) =>
						col.id === columnId ? { ...col, name: newName } : col,
					),
				)
				notification('success', 'Column name updated successfully')
				setEditingColumnId(null)
			}
		} catch (error) {
			notification('error', 'Failed to update column name')
		}
	}

	return (
		<Fragment>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}>
				<SortableContext
					items={columns.map((col) => col.id)}
					strategy={horizontalListSortingStrategy}>
					<div className={`w-full flex overflow-auto min-h-[75vh] pb-1 gap-2`}>
						{columns.length > 0 ? (
							<>
								{columns.map((column) => (
									<Column
										key={column.id}
										column={column}
										handleDeleteColumn={handleDeleteColumn}
										handleUpdateColumn={handleUpdateColumn}
										statuses={state[column.name]}
										accepts={columns
											.filter((col) => col.name !== column.name)
											.map((col) => col.name)}
										isEditing={editingColumnId === column.id}
										setEditingColumnId={setEditingColumnId}
									/>
								))}
								<div className={''}>
									<div className='flex h-auto flex-col space-y-1 rounded w-full bg-white p-1'>
										<Button
											variant='ghost'
											size='sm'
											block
											onClick={() => setShowAddForm(true)}
											disabled={columns.length >= MAX_COLUMNS}>
											<div className='flex items-center'>
												<Plus size={16} />
												{columns.length === 0 ? (
													<span>Add Column</span>
												) : undefined}
											</div>
										</Button>
									</div>
								</div>
							</>
						) : (
							<div className='w-full py-10'>
								<p className='text-center m-0 text-sm'>
									No column has been added. Click{' '}
									<button
										type='button'
										className='text-main hover:underline hover:cursor-pointer'
										onClick={() => setShowAddForm(true)}>
										here
									</button>{' '}
									to add colum in the board.
								</p>
							</div>
						)}
					</div>
				</SortableContext>
			</DndContext>
			<CreateColumnPopup
				board={board}
				isOpen={showAddForm}
				columns={columns}
				localDispatch={localDispatch}
				onCloseAddForm={() => setShowAddForm(false)}
				setColumns={setColumns}
			/>
		</Fragment>
	)
}

export default ColumnMapping
