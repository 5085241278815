import { Field, Form, FormikProvider, useFormik } from 'formik'
import { useUpdateWorkflowFileMutation } from 'modules/clients/client-apis'
import React from 'react'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { validationSchema } from '.'

const RenameFileForm = ({ onClose, workflowFile }) => {
	const [updateFile] = useUpdateWorkflowFileMutation()
	const formik = useFormik({
		initialValues: {
			title: workflowFile?.title,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				setSubmitting(true)
				const response = await updateFile({
					payload: values,
					fileId: workflowFile?.id,
				}).unwrap()
				if (response?.status === 200) {
					notification('success', response?.message)
					onClose()
					resetForm()
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				//
			}
		},
	})
	return (
		<FormikProvider value={formik}>
			<Form className='px-4 pb-4'>
				<div className='mb-3 grid grid-cols-1 gap-1'>
					<Field name='title' label='Title' placeholder='Title' component={InputField} />
				</div>

				<div className=' grid grid-cols-2 gap-1'>
					<div className='text-right'>
						<Button type='button' variant='ghost' onClick={onClose}>
							Cancel
						</Button>
					</div>
					<Button type='submit' isLoading={formik.isSubmitting} isLoadingText='Saving...'>
						Save
					</Button>
				</div>
			</Form>
		</FormikProvider>
	)
}

export default RenameFileForm
