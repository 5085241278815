import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ClientAction from './clients-action'

const ClientsTab = ({ clients = [], team = {} }) => {
	const columns = useMemo(
		() => [
			{ label: '#', field: 'id', attributes: { style: { width: '50px' } } },
			{ label: 'Name', field: 'name', attributes: { style: { minWidth: '80px' } } },
			{ label: 'Email', field: 'email', attributes: { style: { minWidth: '100px' } } },
			{ label: 'Phone Number', field: 'phone', attributes: { style: { minWidth: '150px' } } },
			{ label: 'Status', field: 'status', attributes: { style: { minWidth: '70px' } } },
			{ label: 'Action', field: 'actions', attributes: { style: { minWidth: '50px' } } },
		],
		[],
	)

	const rows = useMemo(
		() =>
			clients
				.filter(
					(client) =>
						Array.isArray(client.groups) &&
						client.groups.length > 0 &&
						client.groups[0]?.group?.name !== 'Owner',
				)
				.map((user, idx) => ({
					id: idx + 1,
					name: user?.fullName || 'N/A',
					email: user?.email || 'N/A',
					phone: user?.phone_number || 'N/A',
					status: user?.status ? 'Active' : 'Inactive',
					actions: !user?.hasTeam ? <ClientAction team={team} user={user} /> : '—',
				})),
		[clients, team],
	)

	return (
		<div className=''>
			<h5 className='mb-6 font-semibold text-gray-700'>Clients</h5>
			<div className='relative overflow-x-auto !border sm:rounded-b-lg'>
				<table className='m-0 w-full text-left text-sm text-gray-500 rtl:text-right'>
					<thead className='text-xs uppercase text-gray-700 '>
						<tr className='border-b border-gray-200'>
							{columns.map((column, i) => (
								<th
									key={i}
									scope='col'
									className='px-6 py-3'
									{...column.attributes}>
									{column.label}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{rows.length > 0 ? (
							rows.map((row, idx) => (
								<tr key={idx} className='border-b border-gray-200'>
									{columns.map((column, i) => (
										<td className='!px-6 !py-4' key={i}>
											{row[column?.field]}
										</td>
									))}
								</tr>
							))
						) : (
							<tr>
								<td colSpan={columns.length} className='!px-6 !py-4'>
									<p className='m-0 text-center text-sm text-gray-400'>
										No Users found
									</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}

ClientsTab.propTypes = {
	clients: PropTypes.arrayOf(PropTypes.object),
	team: PropTypes.object,
}

export default ClientsTab
