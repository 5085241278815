import {
	Calendar,
	CircleDot,
	FileDigit,
	FileText,
	Gauge,
	List,
	Mail,
	Phone,
	RectangleEllipsis,
	Scroll,
	ScrollText,
	Signature,
	Star,
	Text,
	TextCursorInput,
} from 'lucide-react'
import * as yup from 'yup'

export const validationSchema = yup.object().shape({
	fields: yup.array().of(
		yup.object().shape({
			options: yup.array().of(yup.object()).optional(),
			max_selected_option: yup
				.number()
				.default(1)
				// .min(1)
				.test(
					'max-selected-option',
					'Max option selection cannot exceed total options',
					function (value) {
						const { options } = this.parent
						const $options = Array.isArray(options) ? options : []
						return !$options || value <= $options.length
					},
				),
			name: yup
				.string()
				.required('Please provide a field key.')
				.test('unique', 'Field key must be unique.', function (value) {
					const { path, from } = this
					const parent = Array.isArray(from[1]?.value?.fields)
						? from[1]?.value?.fields
						: []
					const currentIndex = Number(path.match(/\d+/)?.[0])
					const isUnique =
						parent.filter(
							(field, index) => field.name === value && index !== currentIndex,
						).length === 0
					return isUnique
				}),
		}),
	),
})

export const FIELD_MODE = {
	VIEW: 'view',
	EDIT: 'edit',
}

export const FIELD_TYPES = {
	TEXT: 'text',
	TEXTAREA: 'textarea',
	RICHTEXT: 'richtext',
	EMAIL: 'email',
	TELPHONE: 'tel',
	NUMBER: 'number',
	PASSWORD: 'password',
	DATE_TIME: 'timestamp',
	DATE: 'date',
	COMBOBOX: 'combobox',
	DROPDOWN: 'select',
	LIST: 'list',
	CHOICE: 'radio',
	CHECKBOX: 'checkbox',
	RATING: 'rating',
	SIGNATURE: 'signature',
	NETPROMOTER: 'netpromoter',
}

export const SIGNATURE_OPTIONS = [
	{
		label: 'Draw Signature',
		value: 'draw-signature',
	},
	{
		label: 'Use Profile Signature',
		value: 'use-profile-image',
	},
	{
		label: 'Upload Signature Image',
		value: 'upload-signature-image',
	},
]

export const OPTION_ABLE_FIELDS = [FIELD_TYPES.CHOICE, FIELD_TYPES.CHECKBOX]

export const getFieldOptions = (title = null, name = '', type = null, icon = null, options) => {
	const fieldOptions = {
		type: type,
		sub_type: 'text',
		label: '',
		className: '',
		name: name,
		title: title,
		multiple: false,
		signature_options: SIGNATURE_OPTIONS,
		signature_type: SIGNATURE_OPTIONS[0]?.value,
		signature_config: {},
		rating: { label: '5 Stars', value: 5 },
		date_format: { label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
		values: Array.isArray(options)
			? options
			: [
					{
						selected: false,
						label: `Option 1`,
						value: `option-1`,
					},
				],
		required: false,
		description: '',
		placeholder: '',
		user_type: '',
		pdf_field: '',
		is_document_bound: false,
		value: null,
		max_selected_option: 1,
		icon: icon,
		nps_label: {
			low_score: 'Not at all likely',
			high_score: 'Extremely likely',
		},
	}
	return fieldOptions
}

export const SUBTYPE_FORM_CONTROLS = ['text', 'tel', 'email', 'number', 'timestamp']

export const FORM_CONTROLS = [
	getFieldOptions('Text', '', FIELD_TYPES.TEXT, TextCursorInput),
	getFieldOptions('Textarea', '', FIELD_TYPES.TEXTAREA, Text),
	getFieldOptions('Rich Text', '', FIELD_TYPES.RICHTEXT, FileText),
	getFieldOptions('Email', '', FIELD_TYPES.EMAIL, Mail),
	getFieldOptions('Date', '', FIELD_TYPES.DATE, Calendar),
	getFieldOptions('Telephone Number', '', FIELD_TYPES.TELPHONE, Phone),
	getFieldOptions('Number', '', FIELD_TYPES.NUMBER, FileDigit),
	// getFieldOptions('Date & Time', '', FIELD_TYPES.DATE_TIME, CalendarClock),
	getFieldOptions('Password', '', FIELD_TYPES.PASSWORD, RectangleEllipsis),
	getFieldOptions('Combobox', '', FIELD_TYPES.COMBOBOX, Scroll),
	getFieldOptions('Dropdown', '', FIELD_TYPES.DROPDOWN, ScrollText),
	getFieldOptions('List', '', FIELD_TYPES.LIST, List),
	getFieldOptions('Choice', '', FIELD_TYPES.CHOICE, CircleDot),
	getFieldOptions('Rating', '', FIELD_TYPES.RATING, Star),
	getFieldOptions('Signature', '', FIELD_TYPES.SIGNATURE, Signature),
	getFieldOptions(
		<>
			<span className='relative'>Net Promoter Score</span>
		</>,
		'',
		FIELD_TYPES.NETPROMOTER,
		Gauge,
	),
	// getFieldOptions('Checkbox', '', FIELD_TYPES.RATING, SquareCheck),
]
