import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'theme/ui/forms'
import ButtonWithLoader from '../../../components/Common/ButtonWithLoader'
import Loader from '../../../components/Common/Loader'
import { RadioComponent } from '../../../components/Forms/RadioComponent'
import { SelectField } from '../../../components/Forms/SelectComponent'
import { TextField } from '../../../components/Forms/TextFieldComponent'
import apiEndpoints from '../../../helpers/apiEndpoints'
import * as actions from '../../../store/common/actions'
import { emailCheck, required } from '../../../utils/formValidation'
import roles from '../../../utils/roles'

const validate = {
	firstname: required('First Name'),
	lastname: required('Last Name'),
	role: required('Role', 'Select'),
	email: [required('Email'), emailCheck],
	phonenumber: required('Phone Number'),
	advisor: required('User', 'select'),
}

const TeamUserForm = (props) => {
	const { userId, handleSubmit, loading, initialValues, initialize, team } = props
	// const { params } = useAppRouter()
	// const teamId = parseInt(params?.teamId)
	const [IsUserNew, setIsUserNew] = useState('YES')
	const [advisorsData, setAdvisorsData] = useState([])
	const dispatch = useDispatch()
	const { data, loading: loader } = useSelector((state) => state.common)
	const rolesOptions = roles.map((role) => ({
		label: role.name,
		value: role.id,
	}))
	const history = useHistory()

	useEffect(() => {
		if (initialValues) {
			initialize(initialValues)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues])

	const handleChange = (e) => {
		setIsUserNew(e.target.value)
		if (e.target.value === 'NO') {
			dispatch(actions.all(`${apiEndpoints.user.list}?team_id=${team.id}`))
		}
	}

	useEffect(() => {
		if (data && data.length > 0) {
			const advisorOptions =
				data && data.length > 0
					? data.map((user) => ({
							label: `${user.first_name} ${user.last_name} (${user.email})`,
							value: user.id,
						}))
					: [{ label: 'Please Wait...', value: null }]
			setAdvisorsData(advisorOptions)
		}
	}, [data])

	return (
		<React.Fragment>
			{loading && <Loader />}
			<Card>
				<CardHeader>
					<CardTitle>User {userId ? 'Edit' : 'Invite'}</CardTitle>
					<Button onClick={() => history.goBack()}>Back</Button>
				</CardHeader>
				<CardBody>
					<form onSubmit={handleSubmit}>
						<Row>
							{!userId && (
								<React.Fragment>
									<Col xs={4} sm={4} md={4}>
										<div>
											<label>Do you want to create a new User ?</label>
											<div className='d-flex'>
												<Field
													type='radio'
													name='new_user'
													value='YES'
													label='Yes'
													id='yes'
													component={RadioComponent}
													onChange={handleChange}
													defaultChecked
												/>
												&nbsp;&nbsp;
												<Field
													type='radio'
													name='new_user'
													value='NO'
													label='No'
													id='no'
													component={RadioComponent}
													onChange={handleChange}
												/>
											</div>
										</div>
									</Col>
									<Col item xs={4} sm={4} md={4}>
										<Field
											name='group_id'
											label='Select Role *'
											component={SelectField}
											type='select'
											required
											validate={validate.role}
											autoComplete='off'
											options={rolesOptions}
										/>
									</Col>
								</React.Fragment>
							)}
							<Col xs={4} sm={4} md={4}>
								<Field
									type='text'
									name='team_name'
									component={TextField}
									label='Team Name'
									disabled
								/>
							</Col>
							{IsUserNew && IsUserNew === 'NO' && (
								<Col xs={4} sm={4} md={4}>
									<Field
										type='select'
										name='user_id'
										component={SelectField}
										isLoading={loader}
										label='Select User *'
										required
										options={advisorsData}
										validate={validate.advisor}
									/>
								</Col>
							)}
							{userId && (
								<React.Fragment>
									<Col xs={4} sm={4} md={4}>
										<Field
											type='text'
											name='first_name'
											component={TextField}
											label='First Name *'
											validate={validate.firstname}
										/>
									</Col>
									<Col xs={4} sm={4} md={4}>
										<Field
											type='text'
											name='last_name'
											component={TextField}
											label='Last Name *'
											validate={validate.lastname}
										/>
									</Col>
									<Col xs={4} sm={4} md={4}>
										<Field
											type='text'
											name='email'
											component={TextField}
											label='Email *'
											validate={validate.email}
											disabled={userId ? true : false}
										/>
									</Col>
									<Col xs={4} sm={4} md={4}>
										<Field
											type='text'
											name='phone_number'
											component={TextField}
											label='Phone Number'
										/>
									</Col>
								</React.Fragment>
							)}
							{!userId && IsUserNew && IsUserNew === 'YES' && (
								<React.Fragment>
									<Col sm={4}>
										<Field
											type='text'
											name='first_name'
											component={TextField}
											label='First Name *'
											validate={validate.firstname}
										/>
									</Col>
									<Col sm={4}>
										<Field
											type='text'
											name='last_name'
											component={TextField}
											label='Last Name *'
											validate={validate.lastname}
										/>
									</Col>
									<Col sm={4}>
										<Field
											type='text'
											name='email'
											component={TextField}
											label='Email *'
											validate={validate.email}
										/>
									</Col>
								</React.Fragment>
							)}
						</Row>
						<ButtonWithLoader loading={loading} color='primary' type='submit'>
							Save
						</ButtonWithLoader>
					</form>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

export default reduxForm({ form: 'team_user', enableReinitialize: true })(TeamUserForm)
