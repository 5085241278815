import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import Actions from './actions'
import StatusBadge from './status-badge'

const AssignedWorkflowsTable = ({ workflows, meta, isLoading, pagination, setPagination }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()

	const columns = [
		// columnHelper.accessor('index', {
		// 	size: 30,
		// 	enableSorting: false,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),

		columnHelper.accessor((row) => row.title, {
			id: 'title',
			size: 200,
			header: () => <span>Workflow Title</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm font-bold !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),
		columnHelper.accessor((row) => row.client, {
			id: 'client',
			size: 160,
			header: () => <span>Client</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm font-bold !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 160,
			header: () => <span>Status</span>,
			cell: (info) => <StatusBadge value={info.getValue()} />,
		}),
		columnHelper.accessor((row) => row, {
			id: 'last_updated',
			size: 160,
			header: () => (
				<div className='w-full text-right'>
					<span>Updated At</span>
				</div>
			),
			cell: (info) => (
				// <div className='w-full text-right'>
				// 	<span className=' text-sm  !text-gray-700'>
				// 		{info.getValue()?.last_updated}
				// 	</span>
				// </div>
				<div className='justify-end w-full text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue()?.last_updated)}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue()?.last_updated)}
					</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 160,
			header: () => (
				<div className='w-full text-right'>
					<span>Actions</span>
				</div>
			),
			cell: (info) => (
				<Actions
					last_updated={info.getValue()?.last_updated}
					id={info?.getValue()?.id}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={workflows}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default AssignedWorkflowsTable
