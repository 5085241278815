import { FieldWrapper } from '../components';
import NPSConfigurator from '../components/nps-configurator'

const NetPromoterScoreEdit = ({ name, field, onNPSLabelChange, userOptions }) => {
	return (
		<FieldWrapper name={name} userOptions={userOptions}>
			<NPSConfigurator field={field} name={name} onNPSLabelChange={onNPSLabelChange} />
		</FieldWrapper>
	)
}

export default NetPromoterScoreEdit;
