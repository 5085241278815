import { Field } from 'formik'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import ClientsQuery from './clients-query'

const ClientDropdown = ({ name }) => {
	const [search, setSearch] = useState('')

	const { clients, isLoading } = ClientsQuery(search)
	const clientOptions = clients.map((client) => ({
		label: <CreateUserOptionLabel user={client} />,
		value: client.value,
		fullName: client.fullName,
		email: client.email,
		type: 'client',
	}))

	const handleSearch = (value) => {
		setSearch(value)
	}

	return (
		<Field
			label=''
			type='select'
			name={name}
			options={clientOptions}
			isLoading={isLoading}
			onInputChange={(value) => handleSearch(value)}
			component={SelectField}
			placeholder='Experivise Client'
			getOptionLabel={(option) => option.label}
		/>
	)
}

export default ClientDropdown
