import { createColumnHelper } from '@tanstack/react-table'

import { ReactDataTable } from 'theme/ui/data-table'
import { dateFormat } from 'utilities/helpers'

const SftpHistoryList = ({ meta, isLoading, historyData, pagination, setPagination }) => {
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'imported_by',
			header: () => <span>Imported By</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 text-sm capitalize !text-gray-700'>
						{info.getValue()?.imported_by || 'N/A'}
					</span>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'import_status',
			header: () => <span>Imported Status</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 text-sm capitalize !text-gray-700'>
						{info.getValue()?.import_status || 'N/A'}
					</span>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'imported_on',
			header: () => <span>Imported On</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-full text-sm  !text-gray-700'>
						{dateFormat(info.getValue()?.imported_on) || 'N/A'}
					</span>
				)
			},
		}),
	]

	return (
		<div>
			<ReactDataTable
				columns={columns}
				data={historyData}
				meta={meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default SftpHistoryList
