const NPSConfigurator = ({ onNPSLabelChange, field }) => {
	return (
		<div className='w-full space-y-2 rounded-md '>
			<div className='flex items-center justify-between overflow-hidden rounded-sm !border !border-gray-300'>
				{Array.from({ length: 11 }, (_, i) => (
					<button
						key={i}
						type='button'
						className={`flex-1 !border-r !border-gray-300 bg-white py-2 text-center text-sm font-medium text-gray-700 transition-colors hover:bg-gray-200 ${
							i === 10 ? '!border-r-0' : ''
						}`}>
						{i}
					</button>
				))}
			</div>

			<div className='mt-2 flex justify-between text-sm text-gray-700'>
				<div className='flex flex-col items-center'>
					<input
						type='text'
						defaultValue={field?.nps_label?.low_score}
						onChange={(e) => onNPSLabelChange('lowScore', e.target.value)}
						placeholder='Low score label'
						className='w-52 rounded-sm !border bg-white px-3 py-1 text-center '
					/>
				</div>
				<div className='flex flex-col items-center'>
					<input
						type='text'
						defaultValue={field?.nps_label?.high_score}
						onChange={(e) => onNPSLabelChange('highScore', e.target.value)}
						placeholder='High score label'
						className='w-52 rounded-sm !border bg-white px-3 py-1  text-center'
					/>
				</div>
			</div>
		</div>
	)
}

export default NPSConfigurator
