import { Fragment, useState } from 'react'
import DeleteConfirmAlert from './delete-confirm-alert'
import ToolTip from 'theme/ui/tool-tip'
import TaskCanvas from 'modules/tasks/task-canvas'
import { useAppRouter } from 'hooks'
import notification from 'utilities/notification'
import { MessageSquarePlus } from 'lucide-react'

const TaskActions = ({ task , onDeleteTask }) => {
	const { params } = useAppRouter()
	const boardId = params?.boardId
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);

    const deletetask = async (values, id) => {
        try {
            onDeleteTask(values, id);
            onClose();
        } catch (error) {
            notification('warn', error?.message);
        }
    };
    // State for TaskCanvas
    const [isCanvasOpen, setIsCanvasOpen] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);

    const onOpenCanvas = (taskId) => {
        setSelectedTaskId(taskId);
        setIsCanvasOpen(true);
    };

    const onCloseCanvas = () => {
        setSelectedTaskId(null);
        setIsCanvasOpen(false);
    };
    
    return (
        <Fragment>
            <DeleteConfirmAlert
                isOpen={isOpen}
                isSubtask={task?.isSubtask}
                onClose={onClose}
                isDeleting={false}
                onConfirm={async (values) => await deletetask(values, task?.id)}
            />

            <div className='flex w-full justify-end space-x-2'>
                {/* Add Subtask Button */}
                { !task?.subtask_parent && (
                <>
                    <ToolTip targetId={`subtask`} tooltipText={"Add Subtask"} placement='top' />
                    <button
                        type='button'
                        className='action-btn btn-gray-outline'
                        id='subtask'
                        onClick={() => onOpenCanvas(task?.id)}
                    >
                        <MessageSquarePlus size={18} className="text-gray-500" />
                    </button>
                </>
                )}

                <button type='button' className='action-btn btn-red-outline' onClick={onOpen}>
                    <i className='uil-trash-alt' style={{ fontSize: 16 }} />
                </button>
            </div>

            {/* Task Canvas */}
            <TaskCanvas
				boardId={boardId}
				isSubtask={true}
                taskId={selectedTaskId}
                isOpen={isCanvasOpen}
                onClose={onCloseCanvas}
                toggleCanvas={onCloseCanvas}
                isTask={true} 
            />
        </Fragment>
    );
};

export default TaskActions;