import { Dialog, Transition } from '@headlessui/react'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { Fragment } from 'react'

import { ReactComponent as UserIcon } from 'assets/svg/field-icon.svg'
import { useClientSettingSaveMutation } from 'modules/clients/client-apis'
import { SystemFieldDropdown } from 'modules/shared'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'

const AddFieldPopup = ({ isOpen, onClose, client }) => {
	const [clientSettingSave] = useClientSettingSaveMutation()
	const formik = useFormik({
		initialValues: {
			key: null,
			value: null,
			user: client?.id,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				const response = await clientSettingSave(payload)
				if (response?.data?.status === 200 && Array.isArray(response?.data?.data)) {
					notification('success', response?.data?.message)
					onClose()
					resetForm()
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				//
			}
		},
	})

	return (
		<Fragment>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/30' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel
									className={`w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
									<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
										<div></div>
										<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white p-3 '>
											<UserIcon height={30} width={30} />
										</div>
										<button type='button' className='right-0' onClick={onClose}>
											<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
										</button>
									</div>
									<div className='mt-10 flex flex-col '>
										<div className='flex flex-col justify-start px-4'>
											<Dialog.Title
												as='h2'
												className='mb-3 text-left text-2xl font-bold capitalize leading-6 text-gray-900'>
												Add custom field
											</Dialog.Title>
										</div>
										{/* <hr className='mx-4 my-3 bg-gray-300' /> */}
										<FormikProvider value={formik}>
											<Form className='px-4 pb-4'>
												<div className='mb-3 grid grid-cols-2 gap-1'>
													<SystemFieldDropdown
														name='key'
														label='Key'
														isLabelValue={true}
													/>

													<Field
														name='value'
														label='Value'
														placeholder='Value'
														component={InputField}
													/>
												</div>

												<div className=' grid grid-cols-2 gap-1'>
													<div className='text-right'>
														<Button
															type='button'
															variant='ghost'
															onClick={onClose}>
															Cancel
														</Button>
													</div>
													<Button
														type='submit'
														isLoading={formik.isSubmitting}
														isLoadingText='Saving...'>
														Save
													</Button>
												</div>
											</Form>
										</FormikProvider>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	)
}

export default AddFieldPopup
