import { v4 } from 'uuid'
import ReadymadeTemplateListingContainer from './readymade-template-listing-container'

export const transformReadymadeTemplateResponse = (response, _, arg) => {
	const documents = Array.isArray(response?.data?.documents)
		? response?.data?.documents.map((d) => ({
				...d,
				uuid: v4(),
				type: d?.document_type,
		  }))
		: []

	const folders = Array.isArray(response?.data?.documentPackages)
		? response?.data?.documentPackages.map((folder) => {
				const documents = Array.isArray(folder?.documents)
					? folder?.documents.map((d) => ({
							...d,
							type: d?.document_type,
					  }))
					: []

				const folders = Array.isArray(folder?.childPackages)
					? folder?.childPackages.map((d) => ({
							...d,
							type: 'folder',
					  }))
					: []

				const children = [...folders, ...documents]
				return {
					...folder,
					uuid: v4(),
					type: 'folder',
					children: children,
				}
		  })
		: []
	const readymadeTemplates = [...folders, ...documents]
	return {
		data: readymadeTemplates.map((document, idx) => ({
			...document,
			index: parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) + idx + 1,
		})),
		meta: response?.data?.meta,
	}
}

export default ReadymadeTemplateListingContainer
