import { Field } from 'formik'
import ClientsQuery from 'modules/client-mapping/client-dropdown/clients-query'
import { createOption } from 'modules/documents/document-template-form'
import AdvisorQuery from 'modules/workflows/workflows-outstandings/filters/advisor-dropdown/advisor-query'
import { useState } from 'react'
import { SelectField } from 'theme/ui/forms'

const ClientAdvisorDropdown = ({ name }) => {
	const [search, setSearch] = useState('')

	const { clients, isLoading: isLoadingClients } = ClientsQuery(search)
	const { options: advisors, isLoading: isLoadingAdvisors } = AdvisorQuery(search)

	const clientOptions = clients.map((client) => createOption(client))
	const advisorOptions = advisors.map((advisor) => createOption(advisor))

	const groupedOptions = [
		{ label: 'Clients', options: clientOptions },
		{ label: 'Advisors', options: advisorOptions },
	]

	const handleSearch = (value) => {
		setSearch(value)
	}
	const filterOption = ({ data }, search) => {
		if (
			data?.fullName?.toLowerCase()?.includes(search?.toLowerCase()) ||
			data?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
			(typeof data?.label === 'string' &&
				data.label.toLowerCase().includes(search.toLowerCase()))
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Select Clients & Advisors'
			type='select'
			name={name}
			options={groupedOptions}
			isLoading={isLoadingClients || isLoadingAdvisors}
			isMulti
			onInputChange={handleSearch}
			component={SelectField}
			placeholder='Select clients or advisors'
			getOptionLabel={(option) => option.label}
			filterOption={filterOption}
		/>
	)
}

export default ClientAdvisorDropdown
