import classNames from 'classnames'
import { useUser } from 'hooks'
import { ArrowDownToLine } from 'lucide-react'
import { useLazyGetUserSignatureQuery } from 'modules/profile/profile/user-profile-api'
import { useEffect, useMemo, useState } from 'react'
import { Page } from 'react-pdf'
import SignatureModal from './signature-modal'

const PageFields = ({ pageIndex, fields, userType, onUpdateField, setSignatureId }) => {
	const [selectedField, setSelectedField] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useUser()
	const [signatureData, setSignatureData] = useState(null)
	const [fetchSignature, { data }] = useLazyGetUserSignatureQuery()
	const signature = useMemo(() => data?.signature, [data?.signature])
	useEffect(() => {
		if (user?.id) {
			fetchSignature(user?.id)
		}
	}, [user?.id])
	const pageFields = useMemo(
		() =>
			Array.isArray(fields)
				? fields.filter((field) =>
						field?.rects?.some((rect) => rect.page.index === pageIndex + 1),
					)
				: [],
		[fields, pageIndex],
	)

	const handleClick = (field, isUserTypeMatched) => {
		if (!isUserTypeMatched || field?.type !== 'signature') return

		setSelectedField((prevSelected) =>
			prevSelected === field?.pdf_field ? null : field?.pdf_field,
		)

		setSignatureData(field)

		if (signature?.path) {
			const $url = new URL(signature?.path)
			const updatedField = { ...field, value: $url }

			const linkedTimestampField = fields.find(
				(field) => field.linked_to === updatedField.pdf_field,
			)

			const timestampValue = new Date().toLocaleString()
			if (linkedTimestampField) {
				const updatedTimestampField = {
					...linkedTimestampField,
					value: timestampValue,
				}

				onUpdateField(updatedField)
				onUpdateField(updatedTimestampField)
			} else {
				const newTimestampField = {
					...updatedField,
					pdf_field: `${updatedField.pdf_field}_timestamp`,
					type: 'timestamp',
					value: timestampValue,
					rects: updatedField.rects.map((rect) => ({
						...rect,
						y: rect.y + rect.height + 5,
						height: 15,
					})),
				}
				onUpdateField(updatedField)
				onUpdateField(newTimestampField)
			}

			setSignatureData(updatedField)
			setSignatureId(signature.id)
		} else {
			setIsOpen(true)
		}
	}

	const handleSignatureSave = (value) => {
		const updatedField = { ...signatureData, value: value }

		const linkedTimestampField = fields.find(
			(field) => field.linked_to === updatedField.pdf_field,
		)

		const timestampValue = new Date().toLocaleString()
		if (linkedTimestampField) {
			const updatedTimestampField = {
				...linkedTimestampField,
				value: timestampValue,
			}

			onUpdateField(updatedField)
			onUpdateField(updatedTimestampField)
		} else {
			const newTimestampField = {
				...updatedField,
				pdf_field: `${updatedField.pdf_field}_timestamp`,
				type: 'timestamp',
				value: timestampValue,
				rects: updatedField.rects.map((rect) => ({
					...rect,
					y: rect.y + rect.height + 5,
					height: 15,
				})),
			}
			onUpdateField(updatedField)
			onUpdateField(newTimestampField)
		}

		setSignatureData(updatedField)
	}

	const onClose = () => {
		setSignatureData(null)
		setIsOpen(false)
		setSelectedField(null)
	}
	return (
		<>
			<SignatureModal
				isOpen={isOpen}
				onClose={onClose}
				signatureData={signatureData}
				onSave={handleSignatureSave}
				setSignatureId={setSignatureId}
			/>

			<div className='relative outline outline-1 outline-gray-200'>
				<Page pageNumber={pageIndex + 1} width={window.innerWidth * 0.8} />
				{pageFields
					.filter(
						(field) =>
							(field?.type === 'signature' ||
								field?.type === 'timestamp' ||
								field?.linked_to) &&
							field?.user_type === userType,
					)
					.flatMap((field, fieldIdx) =>
						field.rects
							.filter((rect) => rect.page.index === pageIndex + 1)
							.map((rect, rectIdx) => {
								const scale = (window.innerWidth * 0.8) / (rect.page.width || 612)
								const isSelected = selectedField === field.pdf_field
								const isRequired = field?.is_required || false
								const isUserTypeMatched = userType === field?.user_type
								const isSignatureType = field?.type === 'signature'
								return (
									<div
										onClick={() => handleClick(field, isUserTypeMatched)}
										className={classNames(
											'text-xs absolute cursor-pointer transition-all duration-50',
											{
												'outline outline-2 outline-blue-500 bg-blue-100':
													isSelected,
												'outline outline-1 outline-gray-300 bg-white':
													!isSelected,
											},
										)}
										style={{
											left: `${rect.x * scale}px`,
											top: `${rect.y * scale}px`,
											width: `${rect.width * scale}px`,
											height: `${rect.height * scale}px`,
										}}
										key={`${field.pdf_field}-${fieldIdx}-${rectIdx}`}>
										{isRequired && (
											<div className='absolute top-0 left-0 flex gap-1.5'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='12'
													height='12'
													fill='none'
													viewBox='0 0 12 12'>
													<path
														fill='#FF0000'
														d='M0 0h12v10a2 2 0 0 1-2 2H0z'
													/>
													<path
														stroke='#fff'
														strokeWidth='2'
														d='M6 2v4.308m0 0L3.538 10M6 6.308 8.462 10M6 6.308l4-1.231m-4 1.23-4-1.23'
													/>
												</svg>
											</div>
										)}
										<div
											className={classNames(
												'w-full !h-full flex items-center justify-center text-center overflow-hidden break-words select-none',
												{
													'!border !border-blue-500 bg-yellow-200':
														isUserTypeMatched && isSignatureType,
													'cursor-not-allowed': !isUserTypeMatched,
												},
											)}>
											{field.value ? (
												field?.type === 'signature' ? (
													<img
														src={field?.value?.path || field?.value}
														className='h-full w-auto'
													/>
												) : (
													<span className='line-clamp-1'>
														{field?.value}
													</span>
												)
											) : (
												<span className='line-clamp-1 text-center flex flex-col items-center justify-center'>
													{isUserTypeMatched && isSignatureType ? (
														<>
															{' '}
															<span className='font-medium'>
																Sign here
															</span>
															<ArrowDownToLine />
														</>
													) : (
														field?.pdf_field
													)}
												</span>
											)}
										</div>
									</div>
								)
							}),
					)}
			</div>
		</>
	)
}

export default PageFields
