import { useAppRouter } from 'hooks'
import { NotFound } from 'modules/error-sections'
import { useLazyGetBoardQuery } from 'modules/scrum-boards/boards-api'
import { BOARD_ROUTES } from 'modules/scrum-boards/routes'
import { Header } from 'modules/tasks'
import BoardViewContainer from 'modules/tasks/kanban/board-view-container'
import { useEffect } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import Spinner from 'theme/ui/spinner'

const BoardView = () => {
	const { params } = useAppRouter()
	const boardId = params?.boardId
	const [fetchBoard, { data, isUninitialized, isFetching }] = useLazyGetBoardQuery()
	useEffect(() => {
		if (boardId) {
			fetchBoard(boardId)
		}
	}, [boardId])

	const board = data?.data

	return (
		<AppLayout
			meta={{
				title: board?.name || '',
				hasBackButton: true,
				redirectTo: `${BOARD_ROUTES.findAll()}?page=1&limit=6`,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				{isUninitialized || isFetching ? (
					<div className='py-6'>
						<Spinner />
					</div>
				) : board ? (
					<div className='page-container'>
						<Header board={board} />

						<div className='page-body'>
							<BoardViewContainer board={board} />
						</div>
					</div>
				) : (
					<NotFound />
				)}
			</div>
		</AppLayout>
	)
}

export default BoardView
