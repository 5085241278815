import { Fragment } from 'react'

import { useTableFilters } from 'contexts/table-filter-context'
import ColumnVisibilityContainer from './column-visibility-container'

const ColumnVisibilityDropdown = ({ columnVisibility, setColumnVisibility }) => {
	const { filters } = useTableFilters()
	return (
		<Fragment>
			{Object.keys(filters).length > 0 ? (
				<ColumnVisibilityContainer
					table={filters}
					setColumnVisibility={setColumnVisibility}
					columnVisibility={columnVisibility}
				/>
			) : undefined}
		</Fragment>
	)
}
export default ColumnVisibilityDropdown
