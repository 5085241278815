import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

export const clientMappingApi = createApi({
	reducerPath: 'ClientMapping',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['ClientMapping'],
	endpoints: (builder) => ({
		getClientsMapping: builder.query({
			query: (params) => ({
				url: apiEndpoints.clientsMapping,
				method: 'GET',
				params: params,
			}),
			providesTags: ['ClientMapping'],
			transformResponse: (response) => {
				return {
					data: response?.data,
					meta: response?.data?.meta,
				}
			},
		}),
		addClientMapping: builder.mutation({
			query: (payload) => ({
				url: `${apiEndpoints.clientsMapping}`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ClientMapping'],
		}),
		deleteClientMapping: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.clientsMapping}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ClientMapping'],
		}),
	}),
})

export const {
	useLazyGetClientsMappingQuery,
	useDeleteClientMappingMutation,
	useAddClientMappingMutation,
} = clientMappingApi
