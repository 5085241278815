import * as yup from 'yup'
import MapStatusForm from './map-status-form'

const categories = [
	{ id: 'to_do', name: 'To Do', color: '#758195' },
	{ id: 'in_progress', name: 'In Progress', color: '#1D7AFC' },
	{ id: 'done', name: 'Done', color: '#22A06B' },
]

export const getCategoryOptions = () => {
	return categories?.map((category) => ({
		label: (
			<div className='flex items-center gap-2'>
				<span
					className='block h-3 w-3 rounded-sm'
					style={{ backgroundColor: category?.color }}
				/>
				<span>{category?.name}</span>
			</div>
		),
		value: category?.id,
	}))
}

export const validationSchema = yup.object().shape({
	name: yup.object().nonNullable().required('Status is required'),
	column: yup.object().nonNullable().required('Column is required'),
	category: yup.object().nonNullable().required('Category is required'),
})

export const getPayload = (values) => {
	return {
		name: values?.name?.value,
		board_column_id: values?.column?.value,
		category_id: values?.category?.value,
	}
}

export default MapStatusForm
