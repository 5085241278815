import { Fragment, useMemo } from 'react'

import { Disclosure, Tab } from '@headlessui/react'
import classNames from 'classnames'
import { getTabs } from '..'
import {
	AUTO_APPROVED,
	DOCUMENT_BOUND,
	MANUAL_APPROVED,
	getDocumentBoundFields,
	getReviewFields,
} from '../helpers'
import FieldsList from './fields-list'

const AssignedFields = ({ individual_document, user_type }) => {
	const fields = useMemo(
		() =>
			getReviewFields(individual_document?.documents).filter(
				(f) =>
					f?.is_document_bound === false || typeof f?.is_document_bound === 'undefined',
			),
		[individual_document],
	)

	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individual_document?.documents, user_type),
		[individual_document?.documents, user_type],
	)

	const currentUserFields = useMemo(
		() => fields.filter((field) => field?.user_type === user_type),
		[fields],
	)

	const autoApproved = useMemo(
		() => currentUserFields.filter((field) => field?.isDefaultApproved === true),
		[currentUserFields],
	)

	const manualFields = useMemo(
		() => currentUserFields.filter((field) => !field?.isDefaultApproved),
		[currentUserFields],
	)

	const tabs = useMemo(() => getTabs(documentBoundFields.length >= 1), [documentBoundFields])

	return (
		<div className='w-full overflow-auto'>
			<div className='flex flex-col'>
				<div className='mb-3'>
					<h3 className='mb-1 mt-4 text-lg'>Changed fields</h3>
					<p className='text-sm text-gray-500'>
						The following fields necessitate manual verification since they are entered
						manually by the client.
					</p>

					<Tab.Group>
						<Tab.List className='mb-[2px] flex divide-x !border-b'>
							{tabs.map((tab, idx) => (
								<Tab
									key={idx}
									className={({ selected }) =>
										classNames(
											'rounded-t !border !border-b-0 px-3 py-2 text-sm hover:bg-white',
											selected
												? 'border-slate-200 bg-white'
												: 'border-transparent',
										)
									}>
									{tab?.label}
								</Tab>
							))}
						</Tab.List>
						<Tab.Panels>
							<Tab.Panel>
								<FieldsList fields={manualFields} fieldName={MANUAL_APPROVED} />
							</Tab.Panel>
							<Tab.Panel>
								<div className='flex flex-col divide-y'>
									{documentBoundFields.map((doc, idx) => {
										return (
											<Fragment key={idx}>
												<Disclosure>
													{({ open }) => (
														<div
															className={classNames(
																'!mb-2 rounded-t !border',
																open
																	? '!border'
																	: '!border-transparent',
															)}>
															<Disclosure.Button
																as='button'
																className={classNames(
																	'!mb-1 flex w-full items-center justify-between rounded border-b bg-white px-3 py-1',
																	open ? '' : '',
																)}>
																<span className='text-base font-bold'>
																	{idx + 1}. {doc?.title}
																</span>
																<div
																	style={{
																		fontSize: 28,
																	}}>
																	{open ? (
																		<i className='uil-angle-down' />
																	) : (
																		<i className='uil-angle-up' />
																	)}
																</div>
															</Disclosure.Button>
															<Disclosure.Panel>
																<FieldsList
																	fields={doc?.fields}
																	fieldName={`${DOCUMENT_BOUND}[${idx}].fields`}
																/>
															</Disclosure.Panel>
														</div>
													)}
												</Disclosure>
											</Fragment>
										)
									})}
								</div>
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
				</div>

				<div>
					<h3 className='mb-1 mt-4 text-lg'>Unchanged fields</h3>
					<p className='text-sm text-gray-500'>
						The following fields are automatically populated based on the client&apos;s
						profile and remain unchange by the client.
					</p>
					<FieldsList fields={autoApproved} fieldName={AUTO_APPROVED} />
				</div>

				{/* <div className='mt-4'>
					<h3 className='mb-1 mt-4 text-lg'>Additional Notes for fields</h3>
					<p className='text-sm text-gray-500'>
						Add a note about the review for the client.
					</p>
					<Field type='textarea' name='fields_notes' component={TextField} rows={3} />
				</div> */}
			</div>
		</div>
	)
}

export default AssignedFields
