import { Field, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useAppRouter } from 'hooks'
import { Button, CheckboxField, InputField, SelectField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'
import { useSendClientInvitationMutation } from '../client-apis'
import RoleOptionsQuery from './RoleOptionsQuery'

const ClientInviteForm = ({ isForClient = false }) => {
	const { roles, isLoading } = RoleOptionsQuery()
	const [sendInvite] = useSendClientInvitationMutation()
	const history = useHistory()
	const { navigate } = useAppRouter()

	const rolesOptions = useMemo(() => {
		const $roles = roles
			.map((role) => ({
				label: role?.name,
				value: role?.id,
				slug: role?.slug,
			}))
			.filter((r) => r.slug !== 'owner')
		if (isForClient) {
			return $roles.filter((r) => r.slug === 'client')
		} else {
			return $roles.filter((r) => r.slug !== 'client')
		}
	}, [roles, isForClient])

	const clientOption = useMemo(
		() => rolesOptions.find((r) => isForClient && r?.slug === 'client'),
		[rolesOptions, isForClient],
	)

	const formik = useFormik({
		initialValues: {
			first_name: '',
			last_name: '',
			email: '',
			group_id: clientOption || '',
			resend: false,
			isEmailSend: true,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				const response = await sendInvite(payload)
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					if (window.location.pathname === '/team-members/invite') {
						navigate('/team-members-invites')
					} else {
						history.goBack()
					}
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='mobile_alignmentt flex w-full gap-10'>
					<div className='form-container'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>User Role</h3>
								<p>Select the role to invite here from here.</p>
							</div>

							<div className='form-fields-card space-y-4'>
								<div className='createclient-dropdown'>
									<Field
										name='group_id'
										label='Select Role'
										component={SelectField}
										type='select'
										isRequiredField
										placeholder='Select Role'
										options={rolesOptions}
										isLoading={isLoading}
										isSearchable={false}
									/>
								</div>

								{isForClient ? (
									<Field
										name='isEmailSend'
										id='isEmailSend'
										label='Would you like to send an invitation to join our system?'
										component={CheckboxField}
										type='checkbox'
									/>
								) : undefined}
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>Other Details</h3>
								<p>Please enter other information for user here.</p>
							</div>

							<div className='form-fields-card client-form space-y-3'>
								<Field
									name='first_name'
									label='First Name'
									component={InputField}
									placeholder='Enter first name'
									type='text'
									isRequiredField
								/>

								<Field
									name='last_name'
									label='Last Name'
									component={InputField}
									placeholder='Enter last name'
									type='text'
									isRequiredField
								/>

								<Field
									name='email'
									label='Email'
									type='email'
									component={InputField}
									placeholder='Enter email'
									isRequiredField
								/>
							</div>
						</div>
						<div className='mt-3 py-3 text-end'>
							<Button
								className=' createclient-button'
								type='submit'
								isLoading={formik.isSubmitting}
								isLoadingText='Sending...'>
								{isForClient ? 'Create Now' : 'Send Invite'}
							</Button>
						</div>
					</div>
					<div className='full_width_mobile max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							{/* <i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								Effortlessly streamline your client onboarding process with our
								intuitive invitation form page.
							</p> */}
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default ClientInviteForm
