import { Undo } from 'lucide-react'

const RestoreLabel = ({ onRestore }) => (
	<div
		style={{
			position: 'absolute',
			top: '-25px',
			right: '0',
			display: 'flex',
			gap: '5px',
		}}>
		<button
			onClick={(e) => {
				e.stopPropagation()
				onRestore()
			}}
			type='button'
			style={{
				background: '#4CAF50', // Green color for Restore
				color: 'white',
				border: 'none',
				borderRadius: '4px',
				padding: '3px 6px',
				cursor: 'pointer',
				fontSize: '12px',
				display: 'flex',
				alignItems: 'center',
				gap: '3px',
			}}>
			<Undo size={14} /> Restore
		</button>
	</div>
)

export default RestoreLabel
