import React, { useEffect } from 'react'

import { useCanvas } from 'contexts/canvas-context'
import { useUploadFileMutation } from 'modules/files/file-apis'
import { Button } from 'theme/ui/forms'

const DrawSignature = ({ onChange, name, isDocument }) => {
	const [uploadSignature, { isLoading }] = useUploadFileMutation()
	const {
		canvasRef,
		prepareCanvas,
		startDrawing,
		finishDrawing,
		draw,
		clearCanvas,
		isCanvasEmpty,
		saveCanvasAsImage,
	} = useCanvas()

	useEffect(() => {
		prepareCanvas()
	}, [])

	const onClear = () => {
		clearCanvas()
		onChange(name, null)
	}

	const onSave = async () => {
		try {
			const dataURL = saveCanvasAsImage()
			const blobBin = atob(dataURL.split(',')[1])
			const blobArray = []
			for (var i = 0; i < blobBin.length; i++) {
				blobArray.push(blobBin.charCodeAt(i))
			}
			const file = new Blob([new Uint8Array(blobArray)], {
				type: 'image/png',
			})
			const fileName = 'signature.png'
			const formData = new FormData()
			formData.append('file', file, fileName)
			const response = await uploadSignature({
				payload: formData,
			}).unwrap()
			if (isDocument) {
				onChange(name, response?.data)
			} else {
				onChange(name, response?.data?.path)
			}
		} catch (error) {
			//
		}
	}

	return (
		<div className=''>
			<canvas
				onMouseDown={startDrawing}
				onMouseUp={finishDrawing}
				onMouseMove={draw}
				ref={canvasRef}
			/>

			<div className='mt-2 flex justify-center space-x-3'>
				<Button
					type='button'
					disabled={isCanvasEmpty()}
					onClick={onClear}
					variant='danger-outline'
					size='sm'>
					Clear
				</Button>
				<Button
					type='button'
					disabled={isCanvasEmpty()}
					onClick={onSave}
					isLoading={isLoading}
					isLoadingText='Uploading...'
					variant='primary-outline'
					size='sm'>
					Confim & Upload
				</Button>
			</div>
		</div>
	)
}

export default DrawSignature
