import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'

const StatusQuery = () => {
	const { data, isLoading } = useQuery(apiEndpoints.taskStatusList)

	return {
		statuses: Array.isArray(data?.data) ? data?.data : [],
		isLoading,
	}
}

export default StatusQuery
