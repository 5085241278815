import BulkActions from './bulk-actions'

export const POPUP_STATES = {
	STATUS: 'STATUS',
	ASSIGNEE: 'ASSIGNEE',
	PRIORITY: 'PRIORITY',
}

export const ACTIONS = {
	[POPUP_STATES.ASSIGNEE]: {
		title: 'Change Assignee',
		key: 'assignee_id',
		buttonLabel: 'Save',
	},
	[POPUP_STATES.STATUS]: {
		title: 'Change Status',
		key: 'task_status_id',
		buttonLabel: 'Save',
	},
}

export default BulkActions
