import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

const RadioResponse = ({ q }) => {
	const COLORS = ['#8884d8', '#ff4384', '#00C49F', '#FFBB28', '#FF8042']

	return (
		<div className='flex justify-between w-full'>
			<div className='flex flex-col  w-1/2 justify-center items-center'>
				{q?.options?.map((opt, i) => (
					<p key={i} className='flex items-center text-gray-600'>
						<span
							className='w-3 h-3 inline-block mr-2 rounded-full'
							style={{
								backgroundColor: COLORS[i % COLORS.length],
							}}></span>
						{opt.label} <span className='ms-4'>{opt.count}</span>
					</p>
				))}
			</div>
			<div className='flex flex-col w-1/2 justify-center items-center'>
				<ResponsiveContainer width={150} height={150}>
					<PieChart>
						<Pie data={q.options} dataKey='count' outerRadius={50} nameKey='label'>
							{q.options.map((_, i) => (
								<Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
							))}
						</Pie>
						<Tooltip />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default RadioResponse
