import React from 'react'
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts'

const NPSGauge = ({ q }) => {
	const data = [{ value: q.npsScore }]
	const color = q.npsScore >= 0 ? '#0088FE' : '#FF4D4D'

	return (
		<div className='flex flex-col w-full p-4 '>
			<div className='flex justify-between items-center w-full'>
				<div className='w-1/4 flex flex-col items-start'>
					<p className='text-gray-700 flex justify-between w-full'>
						<span className='font-medium'>Promoters</span>
						<span>{q.promoters}</span>
					</p>
					<p className='text-gray-700 flex justify-between w-full'>
						<span className='font-medium'>Passives</span>
						<span>{q.passives}</span>
					</p>
					<p className='text-gray-700 flex justify-between w-full'>
						<span className='font-medium'>Detractors</span>
						<span>{q.detractors}</span>
					</p>
				</div>

				<div className='w-1/2 flex justify-center items-center'>
					<div className='relative flex items-center justify-center '>
						<RadialBarChart
							width={200}
							height={200}
							cx={100}
							cy={100}
							innerRadius='80%'
							outerRadius='100%'
							startAngle={180}
							endAngle={0}
							barSize={15}
							data={data}>
							<PolarAngleAxis
								type='number'
								domain={[-100, 100]}
								angleAxisId={0}
								tick={false}
							/>
							<RadialBar
								minAngle={15}
								background
								clockWise
								dataKey='value'
								fill={color}
							/>
						</RadialBarChart>

						{/* Score Text (Centered, Dynamic Color) */}
						<div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
							<p className='text-3xl font-bold' style={{ color }}>
								{q.npsScore}
							</p>
							<p className='text-sm text-gray-500'>NPS®</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NPSGauge
