import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	notifications: [],
	notificationsProgress: 0,
}

export const NotificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action.payload
		},
		setNotificationsProgress: (state, action) => {
			state.notificationsProgress = action.payload
		},
	},
})
