import React from 'react'
import AddressFormPopup from './address-form-popup'
import ClientDocuments from './client-documents'
import CustomFields from './custom-fields'
import SystemField from './system-field'

const InfoTab = ({ client }) => {
	return (
		<div>
			<div className='mb-3 rounded-lg bg-white'>
				<div className='flex items-center justify-between bg-[#F2F5FF] p-4 py-3'>
					<h5 className='m-0 font-bold '>Client Information</h5>
					<div className='flex items-center space-x-3'>
						<AddressFormPopup client={client} />
					</div>
				</div>
				<SystemField client={client} />
				<CustomFields client={client} />
			</div>
			<ClientDocuments client={client} />
		</div>
	)
}

export default InfoTab
