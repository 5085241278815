import { Field } from 'formik'
import { DocumentVersionPopup } from 'modules/documents/document-versions'
import { Fragment, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { InputField, SelectField } from 'theme/ui/forms'

import { userListOptions } from '../..'
import DocumentTypeField from './document-type-field'
import DocumentUploadCard from './document-upload-card'
import FormTemplateInfo from './form-template-info'

const UploadDocument = ({ formik, document, onAutoSave }) => {
	const [isOpen, setIsOpen] = useState(false)
	const useOptions = useMemo(() => userListOptions, [])
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const onClose = () => setIsOpen(false)
	const isDocumentTemplate = formValues?.document_type === 'document'
	const isForm =
		formValues?.document_type === 'form' || formValues?.document_type === 'public_form'
	const params = useParams()

	return (
		<Fragment>
			<DocumentVersionPopup id={document?.id} isOpen={isOpen} onClose={onClose} />
			<div className='flex flex-col gap-6'>
				<div className='upload_doc_alignment grid grid-cols-3 gap-10'>
					<div className='col-span-1'>
						<h3 className='text-lg font-bold'>Choose Template Type</h3>
					</div>
					<div className='col-span-2 rounded bg-white p-3 template-div'>
						<DocumentTypeField
							name='document_type'
							formik={formik}
							disabled={typeof document === 'object'}
						/>
					</div>
				</div>

				{formValues?.document_type ? (
					<div className='upload_doc_alignment grid grid-cols-3 gap-10'>
						<div className='col-span-1'>
							<h3 className='text-lg font-bold'>Document Title</h3>
						</div>
						<div className='col-span-2 rounded bg-white p-3 '>
							{formValues?.document_type === 'public_form' && (
								<div className='alert alert-warning'>
									<strong>Heads up:</strong> Public Form templates aren’t
									compatible with workflows, as certain advanced settings may not
									function as expected.
								</div>
							)}
							<div className='alignment grid grid-cols-2 gap-6'>
								<div className='doc-name'>
									<Field
										label='Title'
										name='title'
										component={InputField}
										isRequiredField={true}
										labelHelperText={true}
										onAutoSave={onAutoSave}
									/>
								</div>
								<div className='max-clients'>
									<Field
										label='Maximum Clients'
										name='min_clients'
										options={useOptions}
										component={SelectField}
										isRequiredField={true}
										isSearchable={false}
										onAutoSave={onAutoSave}
										helperText='This document can involve a maximum number of clients.'
									/>
								</div>
								{/* {isDocumentTemplate ? (
									<div className='select-radio'>
										<Field
											label='Signature Required'
											id='signature_required'
											name='signature_required'
											options={['yes', 'no'].map((option) => ({
												label: <span className='capitalize'>{option}</span>,
												value: option,
											}))}
											component={RadioGroupField}
										/>
									</div>
								) : undefined} */}

								{document?.version ? (
									<div className='flex items-center justify-between'>
										<div className='flex items-center space-x-2'>
											<span className='text-sm text-gray-600'>
												Current Version:
											</span>
											<span className='text-sm font-bold text-gray-700'>
												(V. {document?.version})
											</span>
										</div>
										<button
											type='button'
											onClick={() => setIsOpen(true)}
											className='cursor-pointer font-bold text-main hover:underline'>
											View All
										</button>
									</div>
								) : undefined}
							</div>
							{isForm ? <FormTemplateInfo formik={formik} /> : undefined}
						</div>
					</div>
				) : undefined}

				{isDocumentTemplate ? (
					<DocumentUploadCard formik={formik} document={document} />
				) : undefined}
			</div>
		</Fragment>
	)
}

export default UploadDocument
