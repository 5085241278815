import { FieldWrapper } from '../components'

const SignatureFieldEdit = ({ name, userOptions }) => {
	return (
		<FieldWrapper name={name} userOptions={userOptions} type='signature'>
			{/* <SignatureConfig name={name} field={field} /> */}
		</FieldWrapper>
	)
}

export default SignatureFieldEdit
