import classNames from 'classnames'
import React from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'

const DropdownFieldRender = ({
	field,
	options,
	onChange,
	name,
	tooltipId,
	isOptionDisabled,
	is_read_mode,
}) => {
	const isMulti = field?.type === 'list'
	if (field?.type === 'select' || field?.type === 'list') {
		return (
			<Select
				{...field}
				id={tooltipId}
				options={options}
				onChange={(option) => onChange(name, option)}
				isSearchable={true}
				isClearable={true}
				isMulti={isMulti}
				placeholder={field?.multiple ? 'Your Answers' : 'Your Answer'}
				// classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
				classNamePrefix={'select-dropdown'}
				className={classNames('select-dropdown-container')}
				styles={{
					menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
					menu: (provided) => ({ ...provided, zIndex: 9999 }),
				}}
				menuPortalTarget={document.body}
				menuPosition={'fixed'}
				components={{
					DropdownIndicator: DropdownIndicator,
				}}
				isOptionDisabled={isOptionDisabled}
				isDisabled={is_read_mode}
			/>
		)
	} else if (field?.type === 'combobox') {
		return (
			<Creatable
				{...field}
				id={tooltipId}
				options={options}
				onChange={(option) => onChange(name, option)}
				onCreateOption={(value) => onChange(name, { label: value, value: value })}
				isSearchable={true}
				isClearable={true}
				isMulti={field?.multiple}
				placeholder={field?.multiple ? 'Your Answers' : 'Your Answer'}
				// classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
				classNamePrefix={'select-dropdown'}
				className={classNames('select-dropdown-container')}
				styles={{
					menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
					menu: (provided) => ({ ...provided, zIndex: 9999 }),
				}}
				menuPortalTarget={document.body}
				menuPosition={'fixed'}
				components={{
					DropdownIndicator: DropdownIndicator,
				}}
				isOptionDisabled={isOptionDisabled}
				isDisabled={is_read_mode}
			/>
		)
	} else {
		return (
			<Select
				{...field}
				id={tooltipId}
				options={options}
				onChange={(option) => onChange(name, option)}
				isSearchable={true}
				isClearable={true}
				isMulti={field?.multiple}
				placeholder={field?.multiple ? 'Your Answers' : 'Your Answer'}
				// classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
				classNamePrefix={'select-dropdown'}
				className={classNames('select-dropdown-container')}
				styles={{
					menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
					menu: (provided) => ({ ...provided, zIndex: 9999 }),
				}}
				menuPortalTarget={document.body}
				menuPosition={'fixed'}
				components={{
					DropdownIndicator: DropdownIndicator,
				}}
				isOptionDisabled={isOptionDisabled}
				isDisabled={is_read_mode}
			/>
		)
	}
}

export default DropdownFieldRender
