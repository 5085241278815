import { Dialog, Transition } from '@headlessui/react'
import { Form, FormikProvider, useFormik } from 'formik'
import { SystemFieldDropdown } from 'modules/shared'
import { Fragment } from 'react'
import { Button } from 'theme/ui/forms'

const SystemFieldModal = ({ isOpen, setIsOpen }) => {
	const formik = useFormik({
		initialValues: { fields: [] },
		enableReinitialize: true,
	})

	const csvData = [
		[
			'First Name',
			'Last Name',
			'Email',
			'Phone Number',
			'Address 1',
			'Address 2',
			'Unit',
			'City',
			'Province',
			'Zip Code',
			'Country',
		],
		[
			'John',
			'Doe',
			'john.doe@example.com',
			'555-0101',
			'123 Elm St',
			'Building 2',
			'Apt 1',
			'Springfield',
			'IL',
			'62704',
			'USA',
		],
		[
			'Jane',
			'Smith',
			'jane.smith@example.com',
			'555-0202',
			'456 Oak Rd',
			'Building 2',
			'Suite 300',
			'Greenfield',
			'CA',
			'93927',
			'USA',
		],
		[
			'Alice',
			'Johnson',
			'alice.johnson@example.com',
			'555-0303',
			'789 Pine Ave',
			'',
			'',
			'River Town',
			'TX',
			'77571',
			'USA',
		],
		[
			'Bob',
			'Brown',
			'bob.brown@example.com',
			'555-0404',
			'321 Maple Blvd',
			'Office 6',
			'Unit 9',
			'Lake City',
			'FL',
			'32024',
			'USA',
		],
	]

	const handleDownload = () => {
		const updatedData = [...csvData]
		const selectedFields = formik.values.fields.map((field) => field.value)

		if (selectedFields.length) {
			updatedData[0].push(...selectedFields)
			for (let i = 1; i < updatedData.length; i++) {
				updatedData[i].push(...Array(selectedFields.length).fill(''))
			}
		}

		const csvString = updatedData.map((row) => row.join(',')).join('\n')
		const blob = new Blob([csvString], { type: 'text/csv' })
		const url = URL.createObjectURL(blob)

		const link = document.createElement('a')
		link.href = url
		link.download = 'csv-template.csv'
		link.click()
		setIsOpen(false)
		setTimeout(() => URL.revokeObjectURL(url), 100)
	}
	const handleClose = () => {
		formik.resetForm()
		setIsOpen(false)
	}
	

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 flex items-center justify-center overflow-y-auto p-4'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100'
						leaveTo='opacity-0 scale-95'>
						<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-4 text-left shadow-xl transition-all'>
							<div className='relative flex items-center justify-between rounded-3xl bg-blue-50 p-3'>
								<div className='text-[20px] font-bold'>Download Template</div>
								<button type='button' onClick={handleClose}>
									<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
								</button>
							</div>

							<FormikProvider value={formik}>
								<Form onSubmit={formik.handleSubmit} className='p-2'>
									<SystemFieldDropdown
										label='Custom Fields'
										name='fields'
										showSystemField={false}
										isMulti
										helperText='	If you want to add custom fields to the template, simply
										select them from the dropdown. Otherwise, you can download
										the sample template as-is.'
										className='!min-w-[55%]'
									/>
									<div className='mt-3 flex justify-end'>
										<Button type='button' onClick={handleDownload}>
											Download
										</Button>
									</div>
								</Form>
							</FormikProvider>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SystemFieldModal
