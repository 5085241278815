import { findObjectByField } from 'utils/commonFunctions'
import { findUploadSteps } from '../workflow-review/helpers'
import WorkflowOutstandingsContainer from './workflow-outstandings-container'

export const transformWorkflowResponse = (response) => {
	const steps = response?.data?.workflow_steps || []
	const uploads = findUploadSteps(steps) || []
	const individual_document = findObjectByField(steps, 'type', 'individual_document')

	return {
		workflow: response?.data,
		uploads,
		individual_document,
	}
}

export const transformFormResponse = (response) => {
	const fields = Array.isArray(response?.data?.fields) ? response?.data?.fields : []
	const document_title = response?.data?.document_title

	return {
		fields: fields,
		document_title,
	}
}

export default WorkflowOutstandingsContainer
