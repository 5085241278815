import classNames from 'classnames'
import { Field } from 'formik'
import { GripVertical, Plus, X } from 'lucide-react'
import ReactDragListView from 'react-drag-listview'

import { Button } from 'theme/ui/forms'

const FieldOptions = ({
	onDragEnd,
	options,
	onRemoveOption,
	onAddOption,
	name,
	field,
	is_choice = false,
	is_scored = false,
	showHeading = false,
}) => {
	const dragProps = {
		onDragEnd: onDragEnd,
		nodeSelector: '.document-item',
		handleSelector: '.drag-selector',
	}

	const isOtherOptionAdded = options.find((option) => option?.value === 'other')

	return (
		<div className='space-y-2'>
			{showHeading ? (
				<div className='grid grid-cols-2 gap-1 font-medium'>
					<div className='pl-12'>
						<span>Label</span>
					</div>
					<div>
						<span>Value</span>
					</div>
				</div>
			) : undefined}

			<ReactDragListView {...dragProps}>
				<div className='flex w-full flex-col gap-1'>
					{options.map((option, idx) => {
						const isDisabled = option?.value === 'other'
						return (
							<div
								className='document-item flex !w-full items-center space-x-2'
								key={idx}>
								<div className='min-w-9 text-center'>
									{options.length > 1 ? (
										<Button
											type='button'
											size='sm'
											variant='ghost-white'
											className='drag-selector hover:!cursor-grab active:!cursor-grabbing'>
											<GripVertical size={14} />
										</Button>
									) : undefined}
								</div>
								<div className='grid flex-1 grid-cols-2 gap-1'>
									<div
										className={classNames(
											is_choice
												? 'flex items-center gap-2 rounded !border !px-2 !py-1 !text-[13px] disabled:cursor-not-allowed disabled:bg-gray-100'
												: 'w-full',
											isDisabled ? 'cursor-not-allowed bg-gray-100' : '',
										)}>
										{is_choice ? (
											<Field name={`${name}.values[${idx}].selected`}>
												{({ field: $field }) => (
													<input
														{...$field}
														type={
															field?.multiple ? 'checkbox' : 'radio'
														}
														disabled={isDisabled}
														className='form-check-input !m-0'
													/>
												)}
											</Field>
										) : undefined}

										<Field name={`${name}.values[${idx}].label`}>
											{({ field }) => (
												<input
													{...field}
													type='text'
													disabled={isDisabled}
													placeholder='Enter Label'
													className={
														!is_choice
															? 'flex w-full items-center gap-2 rounded !border !px-2 !py-1 !text-[13px] disabled:cursor-not-allowed disabled:bg-gray-100'
															: 'w-full'
													}
												/>
											)}
										</Field>
									</div>

									<div
										className={classNames(
											'grid',
											is_scored ? 'grid-cols-2 gap-1' : 'grid-cols-1',
										)}>
										<Field name={`${name}.values[${idx}].value`}>
											{({ field, meta: { touched, error } }) => (
												<div className='flex flex-col'>
													<input
														{...field}
														type='text'
														disabled={isDisabled}
														placeholder='Enter Value'
														className={classNames(
															'rounded !border !px-2 !py-1 !text-[13px] disabled:cursor-not-allowed disabled:bg-gray-100',
															{
																'is-invalid': touched && error,
															},
														)}
													/>
													{touched && error ? (
														<span className='text-xs text-[#f46a6a]'>
															{error}
														</span>
													) : undefined}
												</div>
											)}
										</Field>
										{is_scored ? (
											<Field name={`${name}.values[${idx}].score`}>
												{({ field }) => (
													<input
														{...field}
														type='text'
														placeholder='Enter Score'
														className='rounded !border !px-2 !py-1 !text-[13px]'
													/>
												)}
											</Field>
										) : undefined}
									</div>
								</div>
								<div className='min-w-9'>
									{options.length > 1 ? (
										<Button
											type='button'
											size='sm'
											variant='ghost-white'
											onClick={() => onRemoveOption(name, field, idx)}>
											<X size={14} className='text-red-700' strokeWidth={2} />
										</Button>
									) : undefined}
								</div>
							</div>
						)
					})}
				</div>
			</ReactDragListView>
			<div className='flex justify-between'>
				<Button
					type='button'
					size='xs'
					variant='ghost-white'
					onClick={() => onAddOption(name, field)}>
					<div className='flex items-center space-x-1'>
						<Plus size={14} />
						<span>Add Option</span>
					</div>
				</Button>

				{!isOtherOptionAdded ? (
					<Button
						type='button'
						size='xs'
						variant='ghost-white'
						onClick={() => onAddOption(name, field, true)}>
						<div className='flex items-center space-x-1'>
							<Plus size={14} />
							<span>Add &quot;Other&quot; Option</span>
						</div>
					</Button>
				) : undefined}
			</div>
		</div>
	)
}

export default FieldOptions
