import { Field, FormikProvider, useFormik } from 'formik'
import { useMemo, useState } from 'react'

import WorkflowEditor from 'components/Workflow/WorkflowEditor'
import { useAppRouter } from 'hooks'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, getUserTypeOptions, validationSchema } from '.'
import {
	useCreateWorkflowTemplateMutation,
	useUpdateWorkflowTemplateMutation,
} from '../workflow-template-apis'
import AssignDocuments from './assign-documents'
import DocumentUploadSection from './document-upload-section'

const WorkflowForm = ({ initialValues, id, activeTabName }) => {
	const { navigate, goBack } = useAppRouter()
	const [createWorkflowTemplate] = useCreateWorkflowTemplateMutation()
	const [updateWorkflowTemplate] = useUpdateWorkflowTemplateMutation()
	const [loadingStates, setLoadingStates] = useState({
		save: false,
		save_assign: false,
	})

	const formik = useFormik({
		initialValues: {
			title: initialValues?.title || '',
			documents: initialValues?.documents || [],
			document_packages: initialValues?.document_packages || [],
			files: initialValues?.files || [],
			steps: initialValues?.steps || [],
			submitType: '',
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setLoadingStates((prev) => ({
					...prev,
					[values?.submitType]: true,
				}))
				const payload = getPayload(values)
				let response = null
				if (initialValues && id) {
					response = await updateWorkflowTemplate({ payload, id })
				} else {
					response = await createWorkflowTemplate(payload)
				}
				if (response?.data?.status === 200) {
					if (values?.submitType === 'save') {
						goBack()
						notification('success', response?.data?.message)
					} else if (values?.submitType === 'save_assign') {
						navigate(`/workflows/assign?workflow=${response?.data?.data?.id}`)
						notification('success', 'Workflow created successfully, Now you can assign')
					}
				}

				setLoadingStates((prev) => ({
					...prev,
					[values?.submitType]: false,
				}))
			} catch (error) {
				setLoadingStates((prev) => ({
					...prev,
					[values?.submitType]: false,
				}))
			}
		},
	})

	const formValues = useMemo(() => formik.values, [formik.values])
	const userTypeOptions = useMemo(() => getUserTypeOptions(formValues), [formValues])

	return (
		<>
			{activeTabName === 'diagram' && <WorkflowEditor />}
			{activeTabName === 'workflow' && (
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit}>
						<div className='flex w-full gap-10'>
							<div className='form-container mx-auto'>
								<div className='my-form-fields-card mx-auto space-y-6'>
									<Field
										label='Title'
										type='text'
										name='title'
										component={InputField}
										isRequiredField={true}
										labelHelperText={true}
										placeholder='For eg:- Alchemytech, Webosphere'
									/>
									<AssignDocuments formik={formik} />
								</div>

								<div className='my-form-fields-card mx-auto mt-3'>
									<DocumentUploadSection
										userTypeOptions={userTypeOptions}
										formValues={formValues}
									/>
								</div>

								<div className='mx-auto flex min-w-[440px] justify-end space-x-3 pt-3'>
									<Button
										type='submit'
										onClick={() => formik.setFieldValue('submitType', 'save')}
										variant='primary-outline'
										isLoading={loadingStates['save']}
										isLoadingText='Saving...'>
										Save
									</Button>
									<Button
										type='submit'
										onClick={() =>
											formik.setFieldValue('submitType', 'save_assign')
										}
										isLoading={loadingStates['save_assign']}
										isLoadingText='Processing...'>
										Save and Assign
									</Button>
								</div>
							</div>
						</div>
					</form>
				</FormikProvider>
			)}
		</>
	)
}

export default WorkflowForm
