import { flatten } from 'lodash'
import { getUserTypeOption } from './tabs-content'

export const CONSTANTS = {
	SELECTED_NONE: 'none',
}

export const FIELD_TYPE = {
	TEXT: 'text',
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	RADIO: 'radio',
}
export const getDocumentResponse = (document) => {
	return {
		...document,
		// pdf_fields: extractPdfFields(document?.file_data),
		pdf_fields: extractPdfFields2(document?.file_data),
	}
}

const getTextFields = (field, pageNumber) => {
	return {
		x: field.x,
		y: field.y,
		label: field?.id?.Id,
		isCheckbox: false,
		type: FIELD_TYPE.TEXT,
		pageNumber: pageNumber,
		options: [],
	}
}

const getSelectFields = (field, pageNumber) => {
	const labels = Array.isArray(field?.PL?.D) ? field?.PL?.D.map((l) => l) : []
	const values = Array.isArray(field?.PL?.V) ? field?.PL?.V.map((l) => l) : []

	const options = values.map((value, idx) => ({
		label: labels[idx],
		value: value,
		selected: false,
	}))

	return {
		x: field.x,
		y: field.y,
		label: field?.id?.Id,
		isCheckbox: false,
		type: FIELD_TYPE.SELECT,
		pageNumber: pageNumber,
		options: options,
	}
}

const getRadioField = (field, pageNumber) => {
	return {
		x: field?.boxes[0]?.x,
		y: field?.boxes[0]?.y,
		label: field?.id?.Id,
		isCheckbox: false,
		type: FIELD_TYPE.RADIO,
		pageNumber: pageNumber,
		options: Array.isArray(field.boxes)
			? field.boxes.map((f) => ({
					label: f?.id?.Id,
					value: f?.id?.Id,
					selected: false,
				}))
			: [],
	}
}

const getCheckboxField = (field, pageNumber) => {
	const $field = field.boxes[0]
	return {
		x: $field?.x,
		y: $field?.y,
		label: $field?.id?.Id,
		isCheckbox: true,
		type: FIELD_TYPE.CHECKBOX,
		pageNumber: pageNumber,
		checked: $field?.checked,
		options: [{ label: $field?.id?.Id, value: true }],
	}
}

const getAlphaFields = (fields, pageNumber) => {
	if (Array.isArray(fields)) {
		return fields
			.filter((f) => f?.T?.Name !== 'link')
			.filter((f) => !f?.id?.Id.startsWith('signature_'))
			.map((field) => {
				if (field?.PL) {
					return getSelectFields(field, pageNumber)
				} else {
					return getTextFields(field, pageNumber)
				}
			})
			.filter((f) => typeof f !== 'undefined')
	} else {
		return []
	}
}

const getBoxsetFields = (fields, pageNumber) => {
	if (Array.isArray(fields)) {
		return fields
			.map((field) => {
				if (field?.boxes.length > 1) {
					return getRadioField(field, pageNumber)
				} else {
					return getCheckboxField(field, pageNumber)
				}
			})
			.filter((f) => typeof f !== 'undefined')
	} else {
		return []
	}
}

export const extractPdfFields2 = (pdfData) => {
	return Array.isArray(pdfData)
		? pdfData.map((field) => {
				return {
					...field,
					label: field?.original_name,
				}
			})
		: []
}

export const extractPdfFields = (pdfData) => {
	const $pages = Array.isArray(pdfData?.Pages) ? pdfData?.Pages : []
	const pages = $pages.map((page, idx) => {
		const textFields = getAlphaFields(page.Fields, idx + 1)
		const boxsetFields = getBoxsetFields(page.Boxsets, idx + 1)
		return [...textFields, ...boxsetFields]
	})

	return flatten(pages)
}

export const getInitialPdfFieldsMapping = (documentMapping, pdf_fields) => {
	const userTypeOptions = getUserTypeOption(5)

	if (Array.isArray(documentMapping) && Array.isArray(pdf_fields)) {
		if (documentMapping.length > 0) {
			return documentMapping
				.filter((mapping) => mapping?.user?.value !== 'none')
				.map((mapping) => {
					const pdfField = pdf_fields.find((field) => field?.label == mapping?.pdf_field)
					const fieldType = mapping?.type || pdfField?.type
					const userType =
						userTypeOptions.find(
							(o) => o?.value === mapping?.user?.value || o?.value === mapping?.user,
						) || mapping?.user

					return {
						field: mapping?.field || null,
						user: userType,
						pdf_field: pdfField?.label || mapping?.pdf_field,
						type: fieldType,
						is_document_bound: mapping?.is_document_bound ? true : false,
						is_required: mapping?.is_required ? true : false,
						values: Array.isArray(mapping?.values) ? mapping?.values : [],
					}
				})
		} else {
			return pdf_fields.map((field, idx) => {
				const mapping = documentMapping[idx]
				const fieldType = mapping?.type || field?.type
				const userType =
					userTypeOptions.find(
						(o) => o?.value === mapping?.user?.value || o?.value === mapping?.user,
					) || mapping?.user
				return {
					field: mapping?.field || null,
					user: userType,
					type: fieldType,
					pdf_field: field?.label,
					is_document_bound:
						mapping?.is_document_bound ||
						field?.type === 'timestamp' ||
						field?.type === 'signature'
							? true
							: false,
					is_required: field?.is_required ? true : false,
					values: Array.isArray(field?.options)
						? field?.options.map((o) => ({ label: o, value: o, selected: false }))
						: [],
				}
			})
		}
	} else {
		return []
	}
}

export const getFieldType = (type) => {
	if (type === 'text') {
		return 'text'
	} else if (type === 'combobox' || type === 'dropdown' || type === 'list') {
		return 'select'
	} else if (type === 'radio') {
		return 'radio'
	} else if (type === 'checkbox') {
		return 'checkbox'
	} else if (type === 'signature') {
		return 'signature'
	} else if (type === 'timestamp') {
		return 'timestamp'
	} else if (type === 'email') {
		return 'email'
	} else if (type === 'tel') {
		return 'tel'
	} else if (type === 'textarea') {
		return 'textarea'
	} else if (type === 'date') {
		return 'date'
	} else if (type === 'button') {
		return 'button'
	} else {
		return 'text'
	}
}

// implement subtype field here also to populate in customize form setup
export const toFormBuilder = (fieldsMapping, pdfFields) => {
	try {
		let fieldsArray = []
		let fieldsArraySize = fieldsMapping.length

		for (let index = 0; index < fieldsArraySize; index++) {
			let item = fieldsMapping[index]
			const field = pdfFields.find((pf) => pf.original_name === item?.pdf_field)
			if (item?.user?.value !== CONSTANTS.SELECTED_NONE) {
				const fieldType = item?.type
				fieldsArray.push({
					type: fieldType,
					sub_type: fieldType,
					required: item?.is_required,
					label: item?.field?.label,
					className: 'form-control',
					name: item?.field?.value,
					user_type: item?.user?.value,
					multiple: fieldType === 'list',
					access: false,
					linked_to: field?.linked_to,
					rects: Array.isArray(field?.rects) ? field?.rects : [],
					values: Array.isArray(item?.values) ? item?.values : [],
				})
			}
		}
		return fieldsArray
	} catch (error) {
		return []
	}
}

export const disabledFieldActions = (actions) => {
	const fields = [
		'autocomplete',
		'button',
		'checkbox-group',
		'checkbox',
		'date',
		'file',
		'header',
		'hidden',
		'number',
		'paragraph',
		'radio-group',
		'select',
		'text',
		'textarea',
	]
	const disableFields = {}
	fields.forEach((field) => {
		Object.assign(disableFields, {
			[field]: actions,
		})
	})
	return disableFields
}

export const prepareFieldsForApi = (formData) => {
	// const arrayValues = ['checkbox', 'radio-group', 'checkbox-group', 'select']
	const transformedFields = formData.map((field) => ({
		...field,
		type: field?.type || field?.subtype,
		required: field?.required || false,
		values: Array.isArray(field?.values) ? field?.values : [],
		description: field?.description || '',
		placeholder: field?.placeholder || '',
	}))

	return transformedFields
}

export const getMappedFields = (document_body, fieldMapping) => {
	try {
		const formBuilderFields = prepareFieldsForApi(document_body)

		const preparedFields = []
		for (let i = 0; i < formBuilderFields.length; i++) {
			const formBuilderField = formBuilderFields[i]
			const pdfField = fieldMapping[i]

			formBuilderField['user_type'] = pdfField?.user?.value
			formBuilderField['pdf_field'] = pdfField?.pdf_field
			formBuilderField['is_document_bound'] = pdfField?.is_document_bound
			preparedFields.push(formBuilderField)
		}

		return preparedFields
	} catch (error) {
		return []
	}
}

// const decodeUTF16 = (encodedString) => {
// 	if (!containsNonBMPCharacters(encodedString)) {
// 		return encodedString
// 	}
// 	// Remove the BOM (þÿ or \u00FE\u00FF)
// 	const bomRemoved = encodedString.replace(/^\u00FE\u00FF/, '')

// 	// Decode the UTF-16 string
// 	let decoded = ''
// 	for (let i = 0; i < bomRemoved.length; i += 2) {
// 		const charCode = bomRemoved.charCodeAt(i + 1)
// 		decoded += String.fromCharCode(charCode)
// 	}

// 	return decoded
// }

// const containsNonBMPCharacters = (str) => {
// 	for (let i = 0; i < str.length; i++) {
// 		const code = str.charCodeAt(i)
// 		// Check if the character is a high surrogate (D800–DBFF)
// 		if (code >= 0xd800 && code <= 0xdbff) {
// 			// Check if the next character is a low surrogate (DC00–DFFF)
// 			if (i + 1 < str.length) {
// 				const nextCode = str.charCodeAt(i + 1)
// 				if (nextCode >= 0xdc00 && nextCode <= 0xdfff) {
// 					return true // The string contains a non-BMP character
// 				}
// 			}
// 		}
// 	}
// 	return false // No non-BMP characters found
// }

export const PDF_FIELD_TYPES = [
	{ label: 'Text', value: 'text' },
	{ label: 'Textarea', value: 'textarea' },
	{ label: 'Rich Text (HTML Editor)', value: 'richtext' },
	{ label: 'Email', value: 'email' },
	{ label: 'Date', value: 'date' },
	{ label: 'Telephone Number', value: 'tel' },
	{ label: 'Number', value: 'number' },
	{ label: 'Date & Time', value: 'timestamp' },
	{ label: 'Password', value: 'password' },
	{ label: 'Signature', value: 'signature' },
	{ label: 'Checkbox', value: 'checkbox' },
	{ label: 'Radio', value: 'radio' },
	{ label: 'Combobox', value: 'combobox' },
	{ label: 'Dropdown', value: 'dropdown' },
	{ label: 'List', value: 'list' },
]
