import { useLazyGetSystemFieldsQuery } from 'modules/system-fields/system-fields-api'
import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

const useSystemFieldQuery = ($search, isLabelValue, showSystemField = true) => {
	const [fetchFields, { data, isFetching }] = useLazyGetSystemFieldsQuery()
	const [search] = useDebounce($search, 800)

	useEffect(() => {
		const params = {
			page: 1,
			limit: 20,
		}
		if (search) {
			Object.assign(params, {
				search: search,
			})
		}
		fetchFields(params)
	}, [search])

	const customFields = Array.isArray(data?.data?.documentFields[0].customFiled)
		? data?.data?.documentFields[0].customFiled
		: []
	const systemFields = Array.isArray(data?.data?.documentFields[0].systemFields)
		? data?.data?.documentFields[0].systemFields
		: []
	const options = [
		{
			label: <span className='font-bold text-gray-900'>Custom Fields</span>,
			options: customFields.map((field) => ({
				label: field?.fieldName,
				value: isLabelValue ? field?.fieldName : field?.slug,
				type: 'custom_field',
			})),
		},
		showSystemField && {
			label: <span className='font-bold text-gray-900'>System Fields</span>,
			options: systemFields.map((field) => ({
				label: field?.fieldName,
				value: isLabelValue ? field?.fieldName : field?.slug,
				type: 'system_field',
			})),
		},
	].filter(Boolean)

	return {
		options: options,
		isLoading: isFetching,
	}
}

export default useSystemFieldQuery
