import { Disclosure, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useLazyGetClientGroupsQuery } from 'modules/clients/client-email-api'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import Spinner from 'theme/ui/spinner'

const GroupsDetails = ({ client_id }) => {
	const [fetchGroup, { data, isUninitialized, isLoading }] = useLazyGetClientGroupsQuery()
	useEffect(() => {
		if (client_id) {
			fetchGroup(client_id)
		}
	}, [client_id])

	const $groups = Array.isArray(data?.clientgroups) ? data?.clientgroups : []

	return (
		<div className='card !rounded-lg shadow-none'>
			<div className='card-header !border-none !bg-[#F2F5FF] px-4 py-3'>
				<h5 className='mb-0 font-bold'>Groups</h5>
			</div>
			<div className='card-body p-0'>
				{isUninitialized || isLoading ? (
					<div className='flex items-center justify-center py-3'>
						<Spinner label={'Loading...'} />
					</div>
				) : $groups.length > 0 ? (
					<div className='flex flex-col divide-y'>
						{$groups.map((group, idx) => (
							<Disclosure key={idx}>
								{({ open }) => (
									<div>
										<Disclosure.Button
											className={classNames(
												'flex w-full items-center justify-between border-b px-4 py-3 text-left text-sm font-semibold transition-all focus:outline-none focus-visible:ring focus-visible:ring-main/75',
												open ? 'border-gray-200' : 'border-transparent',
												group?.members.length > 0 ? 'hover:bg-gray-50' : '',
											)}
											disabled={group?.members.length === 0}>
											<span>{group?.name}</span>
											{group?.members.length > 0 && (
												<span>
													{open ? (
														<ChevronUp size={16} />
													) : (
														<ChevronDown size={16} />
													)}
												</span>
											)}
										</Disclosure.Button>
										<Transition
											show={open}
											enter='transition duration-300 ease-out'
											enterFrom='opacity-0 max-h-0'
											enterTo='opacity-100 max-h-screen'
											leave='transition duration-200 ease-in'
											leaveFrom='opacity-100 max-h-screen'
											leaveTo='opacity-0 max-h-0'
											className='overflow-hidden'>
											<Disclosure.Panel>
												{group?.members.length > 0 && (
													<ul className='m-0 divide-y divide-gray-100 p-0'>
														{group?.members.map((client, idx) => (
															<li
																key={idx}
																className='cursor-pointer py-1 pl-[28px] pr-3 hover:bg-gray-50'>
																<Link
																	to={`/clients/${client?.id}`}
																	target='_blank'>
																	<CreateUserOptionLabel
																		user={client}
																	/>
																</Link>
															</li>
														))}
													</ul>
												)}
											</Disclosure.Panel>
										</Transition>
									</div>
								)}
							</Disclosure>
						))}
					</div>
				) : (
					<div className='flex items-center justify-center py-3'>
						<p className='m-0 text-center text-gray-400'>No groups found</p>
					</div>
				)}
			</div>
		</div>
	)
}

export default GroupsDetails
