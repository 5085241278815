import classNames from 'classnames'
import { ChevronDown } from 'lucide-react'
import { Fragment, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { ACTIONS, POPUP_STATES } from '.'
import ChangeAssigneePopup from './change-assignee-popup'

const BulkActions = ({ selectedRows, boardId }) => {
	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => setIsOpen(!isOpen)
	const [popupStates, setPopupStates] = useState({
		[POPUP_STATES.ASSIGNEE]: false,
		[POPUP_STATES.PRIORITY]: false,
		[POPUP_STATES.STATUS]: false,
	})
	const onPopupOpen = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: true,
		}))
	}
	const onPopupClose = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: false,
		}))
	}

	return (
		<Fragment>
			<ChangeAssigneePopup
				onClose={() => onPopupClose(POPUP_STATES.ASSIGNEE)}
				isOpen={popupStates[POPUP_STATES.ASSIGNEE]}
				selectedRows={selectedRows}
				key={ACTIONS[POPUP_STATES.ASSIGNEE]?.key}
				buttonLabel={ACTIONS[POPUP_STATES.ASSIGNEE]?.buttonLabel}
				title={ACTIONS[POPUP_STATES.ASSIGNEE]?.title}
				actionType={POPUP_STATES.ASSIGNEE}
				boardId={boardId}
			/>
			<ChangeAssigneePopup
				onClose={() => onPopupClose(POPUP_STATES.STATUS)}
				isOpen={popupStates[POPUP_STATES.STATUS]}
				selectedRows={selectedRows}
				key={ACTIONS[POPUP_STATES.STATUS]?.key}
				buttonLabel={ACTIONS[POPUP_STATES.STATUS]?.buttonLabel}
				title={ACTIONS[POPUP_STATES.STATUS]?.title}
				actionType={POPUP_STATES.STATUS}
				boardId={boardId}
			/>

			<Dropdown isOpen={isOpen} toggle={() => toggle()}>
				<DropdownToggle
					tag='button'
					type='button'
					className={classNames('btn btn-secondary max-w-fit', {
						'': isOpen,
					})}>
					<div className='flex items-center justify-between space-x-3'>
						<span>Actions</span>
						<span className=''>
							<ChevronDown size={16} />
						</span>
					</div>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-end mt-2 p-0 py-2 shadow-md'>
					<ul className='m-0 flex flex-col p-0'>
						<li
							className='exp-dropdown-item'
							onClick={() => onPopupOpen(POPUP_STATES.ASSIGNEE)}>
							<span>Change Assignee</span>
						</li>
						<li
							className='exp-dropdown-item'
							onClick={() => onPopupOpen(POPUP_STATES.STATUS)}>
							<span>Change Status</span>
						</li>
					</ul>
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	)
}

export default BulkActions
