import { useDebounce } from 'use-debounce'

import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { toQueryString } from 'utils/commonFunctions'
import { createClientOption } from '.'

const SftpQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const params = useMemo(() => {
		const paramsObj = { page: 1, limit: 10 }
		if (debounceSearch) {
			Object.assign(paramsObj, { search: debounceSearch })
		}
		return paramsObj
	}, [debounceSearch])

	const query = toQueryString(params)
	const url = `${'sftp/clients'}?${query}`

	const { data, isLoading } = useQuery(url)
	const options = data?.data?.clientDetails?.map((client) => createClientOption(client)) || []
	return {
		clients: options,
		isLoading,
	}
}

export default SftpQuery
