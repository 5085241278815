import { FieldOptions, FieldWrapper } from '../components'

const DropdownFieldEdit = ({
	name,
	field,
	onAddOption,
	onRemoveOption,
	onDragEnd,
	userOptions,
}) => {
	const options = Array.isArray(field.values) ? field.values : []

	return (
		<FieldWrapper name={name} userOptions={userOptions}>
			<FieldOptions
				field={field}
				name={name}
				onAddOption={onAddOption}
				onDragEnd={onDragEnd}
				onRemoveOption={onRemoveOption}
				options={options}
				is_scored={field?.is_scored}
			/>
		</FieldWrapper>
	)
}

export default DropdownFieldEdit
