import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'

const CustomFieldTable = ({ values, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 150,
			header: () => <span className='min-w-[190px]'>Value</span>,
			cell: (info) => (
				<div className=''>
					<span className='file-title text-[13px] !text-gray-400'>{info.getValue()}</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 170,
			header: () => (
				<div className='w-full text-right'>
					<span>Added On</span>
				</div>
			),
			cell: (info) => (
				<div className='w-full justify-end text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue()?.created_at)}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue()?.created_at)}
					</span>
				</div>
			),
		}),
	]

	return (
		<>
			<DataTable
				columns={columns}
				data={values}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				setSorting={setSorting}
				sorting={sorting}
				meta={meta}
				isOverflow={false}
			/>
		</>
	)
}

export default CustomFieldTable
