import { Field, Form, FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { useAppRouter, useUser } from 'hooks'
import { FILE_MANAGER_ROUTES } from 'modules/file-manager/routes'
import { getPlaceholder } from 'modules/file-manager/search-bar'
import CreateFolder from 'modules/file-manager/search-bar/create-folder'
import SearchItemDropdown from 'modules/file-manager/search-bar/search-item-dropdown'
import ViewByDropdown from 'modules/file-manager/view-by-dropdown'
import Badge from 'theme/ui/badge'
import { useDataTable } from 'theme/ui/data-table'
import { DateRange } from 'theme/ui/filters'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'

const Filters = ({
	setPagination,
	heading,
	isDateRange,
	isViewBy,
	badgeName,
	searchTypeName = 'status',
}) => {
	const { location, navigate } = useAppRouter()
	const { group } = useUser()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString, searchTypeName)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const debounceValues = formValues
	// const [debounceValues] = useDebounce([formValues], 800)
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues, searchTypeName),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQueryString)

	return (
		<Fragment>
			<FormikProvider value={formik}>
				<Form className='alignment_holder flex-1'>
					{group?.slug !== 'client' ? (
						<div className='doc_listing_flex page-body flex items-center justify-between gap-3 !border bg-gray-50 !py-[15px]'>
							<div className='alignment_holder flex h-[56px] w-full max-w-[512px] items-center justify-between space-x-1 rounded bg-white px-2.5 shadow'>
								<div className='px-2'>
									<SearchIcon height={20} width={20} />
								</div>
								<Field name='search'>
									{({ field }) => (
										<input
											type='search'
											{...field}
											autoComplete='off'
											className='!w-full flex-1 truncate px-1 py-2 text-base font-normal text-gray2-dark'
											placeholder={getPlaceholder(formValues?.status)}
										/>
									)}
								</Field>
								<SearchItemDropdown formik={formik} name={searchTypeName} />
							</div>
							<CreateFolder onClose={onClose} isOpen={isOpen} type={'folder'} />
							{[FILE_MANAGER_ROUTES.allFolders()].includes(location?.pathname) ? (
								<div className='rounded bg-white'>
									<Button
										type='button'
										onClick={() => setIsOpen(true)}
										variant='primary-outline'
										className='bg-white '>
										<div className='flex items-center space-x-2 font-medium'>
											<span className='text-base'>+</span>
											<span>Create Folder</span>
										</div>
									</Button>
								</div>
							) : undefined}
						</div>
					) : undefined}

					<div className='page-body !pb-0'>
						<div className='flex w-full items-center justify-between gap-1'>
							{group?.slug === 'client' ? (
								<Field
									className='form-control'
									type='search'
									name='search'
									component={SearchField}
									placeholder='Search documents...'
								/>
							) : (
								<div className='flex items-center space-x-3'>
									<h3 className='m-0 mt-1 text-base font-semibold'>{heading}</h3>
									{badgeName ? (
										<Badge rounded={false}>{badgeName}</Badge>
									) : undefined}
								</div>
							)}
							<div className='flex items-center'>
								{isDateRange ? (
									<DateRange name='date_range' direction='start' />
								) : undefined}

								{isViewBy ? <ViewByDropdown /> : undefined}

								{activeFilters.length > 0 ? (
									<Button size='sm' variant='ghost' onClick={onResetFilters}>
										Clear filters
									</Button>
								) : undefined}
							</div>
						</div>
					</div>
				</Form>
			</FormikProvider>
		</Fragment>
	)
}

export default Filters
