import React from 'react'
import DataTable from 'theme/ui/data-table'

const DynamicResponseTable = ({ responseData }) => {
	const renderColumns = () => {
		switch (responseData?.type) {
			case 'text':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 300 },
					{ accessorKey: 'response', header: 'Responses', size: 300 },
				]
			case 'radio':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 300 },
					{ accessorKey: 'option', header: 'Selected Option', size: 200 },
				]
			case 'select':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 300 },
					{ accessorKey: 'option', header: 'Selected Option', size: 200 },
					{ accessorKey: 'count', header: 'Count', size: 100 },
				]
			case 'nps':
			case 'netpromoter':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 300 },
					{ accessorKey: 'score', header: 'Score', size: 100 },
					{ accessorKey: 'category', header: 'Category', size: 150 },
				]
			case 'date':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 150 },
					{ accessorKey: 'formattedDate', header: 'Date', size: 200 },
				]
			case 'rating':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 150 },
					{ accessorKey: 'level', header: 'Rating Level', size: 100 },
				]
			case 'signature':
				return [
					{ accessorKey: 'id', header: 'ID', size: 50 },
					{ accessorKey: 'name', header: 'Name', size: 150 },
					{
						accessorKey: 'response',
						header: 'Signature',
						size: 150,
						cell: ({ row }) => (
							<img
								src={row.original.response}
								alt='Signature'
								className='h-[50px] w-[100px] !border !border-gray-300 rounded-sm'
							/>
						),
					},
				]
			default:
				return []
		}
	}

	const formatData = () => {
		let idCounter = 1

		if (responseData?.type === 'text') {
			return responseData?.responses.map((resp) => ({
				id: idCounter++,
				name: 'Anonymous',
				response: resp,
			}))
		} else if (responseData?.type === 'signature') {
			return responseData?.responses.map((resp) => ({
				id: idCounter++,
				name: 'anonymous',
				response: resp,
			}))
		} else if (responseData?.type === 'radio') {
			return (responseData?.options || []).flatMap((option) =>
				Array.from({ length: option.count }, () => ({
					id: idCounter++,
					name: 'Anonymous',
					option: option.label,
				})),
			)
		} else if (responseData?.type === 'nps' || responseData?.type === 'netpromoter') {
			return responseData?.value?.map((score) => ({
				id: idCounter++,
				name: 'Anonymous',
				score: score,
				category: score >= 9 ? 'Promoters' : score >= 7 ? 'Passives' : 'Detractors',
			}))
		} else if (responseData?.type === 'date') {
			return responseData?.responses.map((dateString) => ({
				id: idCounter++,
				name: 'Anonymous',
				formattedDate: new Date(dateString).toLocaleDateString('en-GB', {
					day: '2-digit',
					month: 'short',
					year: 'numeric',
				}),
			}))
		} else if (responseData?.type === 'rating') {
			return responseData?.ratings.flatMap((rating) =>
				Array.from({ length: rating.count }, () => ({
					id: idCounter++,
					name: 'Anonymous',
					level: rating.level,
				})),
			)
		} else if (responseData?.type === 'select') {
			if (responseData?.multiple) {
				const optionsMap = {}
				;(responseData?.responses || []).forEach((resp) => {
					optionsMap[resp] = (optionsMap[resp] || 0) + 1
				})
		
				return Object.entries(optionsMap).map(([label, count]) => ({
					id: idCounter++,
					name: 'Anonymous',
					option: label,
					count,
				}))
			} else {
				return (responseData?.options || []).map(({ label, count }) => ({
					id: idCounter++,
					name: 'Anonymous',
					option: label,
					count,
				}))
			}
		
		}
		return []
	}

	return <DataTable columns={renderColumns()} data={formatData()} />
}

export default DynamicResponseTable
