import classNames from 'classnames'
import { ChevronDown } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import FilterButton from 'theme/ui/filter-button'

const TagsDropdown = ({
	tagSearch,
	setTagSearch,
	tagList,
	selectedTags,
	handleTagChange,
	applyTags,
	selectedRecords,
	createTag,
	isAssigning,
	isCreating,
	previousData,
	formik,
}) => {
	const [isTagOpen, setIsTagOpen] = useState(false)
	const [filteredTags, setFilteredTags] = useState(tagList)
	const values = Array.isArray(formik?.values?.groups) ? formik?.values?.groups : []

	const toggleTagSection = () => {
		setIsTagOpen(!isTagOpen)
	}
	const handleTagSearch = (e) => {
		setTagSearch(e.target.value)
	}

	useEffect(() => {
		if (tagSearch) {
			setFilteredTags(tagList.filter((tag) => tag.includes(tagSearch)))
		} else {
			setFilteredTags(tagList)
		}
	}, [tagSearch])

	function IndeterminateCheckbox({ checked, indeterminate, ...rest }) {
		const ref = React.useRef(null)

		useEffect(() => {
			if (ref.current) {
				ref.current.indeterminate = indeterminate
			}
		}, [indeterminate])

		return (
			<input
				{...rest}
				type='checkbox'
				className='form-check-input m-0'
				ref={ref}
				checked={checked}
			/>
		)
	}

	useEffect(() => {
		if (tagList) {
			setFilteredTags(tagList)
		}
	}, [tagList])
	const [tagsChanged, setTagsChanged] = useState(false)
	useEffect(() => {
		const hasTagsChanged = selectedRecords.some((item, index) => {
			const prevTags = previousData[index]?.tags || []
			const currentTags = item.tags || []
			return (
				prevTags.length !== currentTags.length ||
				prevTags.some((tag) => !currentTags.includes(tag)) ||
				currentTags.some((tag) => !prevTags.includes(tag))
			)
		})

		if (hasTagsChanged) {
			setTagsChanged(true)
		} else {
			setTagsChanged(false)
		}
	}, [selectedRecords])

	const isActive = values.length > 0

	return (
		<Dropdown isOpen={isTagOpen} toggle={() => toggleTagSection()}>
			<FilterButton onClear={() => formik.setFieldValue('groups', [])} isActive={isActive}>
				<DropdownToggle
					tag='button'
					type='button'
					className={classNames(
						'btn btn-secondary z-10 max-w-fit',
						isActive ? '!bg-main/20 !text-main' : '',
					)}>
					<div className='flex items-center'>
						<span className='!ml-[17px]'>Groups</span>
						{isActive ? (
							<div className='flex items-center space-x-1'>
								<span className='ml-1 text-gray-500'>|</span>
								<span className='line-clamp-1 space-x-1 font-bold'>
									{values.length <= 1 ? (
										<span>{values.join(', ')}</span>
									) : (
										<span>{values.length} selected</span>
									)}
								</span>
							</div>
						) : undefined}
						<span className='mt-[2px] pl-[2px]'>
							<ChevronDown size={16} />
						</span>
					</div>
				</DropdownToggle>
			</FilterButton>

			<DropdownMenu className='dropdown-menu-md dropdown-menu-start mt-2 p-0 pt-2 shadow-md'>
				<div className='full_placeholder rounded'>
					<div className='mt-2 px-3'>
						<input
							className='form-control'
							type='text'
							value={tagSearch || ''}
							onChange={handleTagSearch}
							placeholder={
								tagList?.length == 0 ? 'Enter Group name' : 'Search groups...'
							}
						/>
					</div>
					<div className='custom-scroll mb-2 mt-3 flex max-h-[154px] flex-col overflow-y-auto'>
						{filteredTags?.map((tag) => (
							<label
								key={tag}
								className={classNames(
									'm-0 flex items-center space-x-2',
									values.includes(tag)
										? 'exp-dropdown-item-active'
										: 'exp-dropdown-item',
								)}>
								<IndeterminateCheckbox
									checked={values.includes(tag) || selectedTags[tag]?.checked}
									indeterminate={selectedTags[tag]?.indeterminate}
									tag={tag}
									onChange={(e) => handleTagChange(e, tag)}
								/>
								<span className='line-clamp-1 cursor-pointer '>{tag}</span>
							</label>
						))}
					</div>
				</div>
				{tagSearch || (selectedRecords?.length > 0 && tagsChanged) ? (
					<div className='flex flex-col !border !border-t-gray-600 pb-3 pt-2'>
						{tagSearch && (
							<p
								className={`m-0 p-0 py-2 ps-3 hover:bg-gray-200 ${
									tagSearch && !isCreating
										? 'cursor-pointer  font-medium text-black'
										: ' font-medium text-gray-600  '
								} `}
								onClick={() => createTag(tagSearch)}>
								{isCreating ? 'Creating...' : 'Create New'}
							</p>
						)}

						{selectedRecords?.length > 0 && tagsChanged && (
							<p
								onClick={applyTags}
								className={`m-0 p-0 py-2 ps-3 hover:bg-gray-200 ${
									selectedRecords?.length > 0 && !isAssigning
										? 'cursor-pointer  font-medium text-black'
										: ' font-medium text-gray-600  '
								} `}>
								{isAssigning ? 'Applying...' : 'Apply'}
							</p>
						)}
					</div>
				) : (
					<></>
				)}
			</DropdownMenu>
		</Dropdown>
	)
}

export default TagsDropdown
