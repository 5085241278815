import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import classNames from 'classnames'
import { generateUniqueId } from 'utilities/helpers'

const TextFieldView = ({ name, field, onChange, is_read_mode }) => {
	const tooltipId = generateUniqueId()

	return (
		<div className='form-group'>
			{field?.label ? (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}
			{field?.type === 'textarea' ? (
				<textarea
					className={classNames(
						'form-control placeholder:!text-gray-400',
						is_read_mode ? 'cursor-default select-none' : '',
					)}
					id={tooltipId}
					name={field?.name}
					value={field?.value || ''}
					onChange={(e) => onChange(name, e.target.value)}
					placeholder={'Your Answer'}
					readOnly={is_read_mode}
				/>
			) : field?.type === 'richtext' ? (
				<div className='editor-wrapper' style={{ width: '100%' }}>
					<CKEditor
						editor={ClassicEditor}
						data={field?.value}
						onChange={(event, editor) => {
							const data = editor.getData()
							onChange(name, data)
						}}
						config={{
							toolbar: {
								items: [
									'undo',
									'redo',
									'|',
									'heading',
									'|',
									'fontfamily',
									'fontsize',
									'fontColor',
									'fontBackgroundColor',
									'|',
									'bold',
									'italic',
									'|',
									'insertTable',
									'blockQuote',
									'insertMedia',
									'|',
									'alignment',
									'|',
									'numberedList',
									'bulletedList',
									'|',
									'outdent',
									'indent',
								],
							},
						}}
					/>
				</div>
			) : (
				<input
					type={field?.type}
					className={classNames(
						'form-control placeholder:!text-gray-400',
						is_read_mode ? 'cursor-default select-none' : '',
					)}
					id={tooltipId}
					name={field?.name}
					value={field?.value || ''}
					onChange={(e) => onChange(name, e.target.value)}
					placeholder={'Your Answer'}
					readOnly={is_read_mode}
				/>
			)}

			{field?.description ? (
				<span className='text-[13px]  text-gray-400'>{field?.description}</span>
			) : undefined}
		</div>
	)
}

export default TextFieldView
