import './styles/common.css'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import axios from 'axios'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'

import FallbackRender from 'FallbackRender'
import { StepStatusQueryOptionsProvider } from 'pages/Authentication/client-document-upload/context/StepStatusContext'
import { StrictMode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store, { persistor } from './store'
import setupAxios from './store/setupAxios'

if (process.env.NODE_ENV !== 'development') {
	;(function (c, l, a, r, i, t, y) {
		c[a] =
			c[a] ||
			function () {
				;(c[a].q = c[a].q || []).push(arguments)
			}
		t = l.createElement(r)
		t.async = 1
		t.src = 'https://www.clarity.ms/tag/' + i
		y = l.getElementsByTagName(r)[0]
		y.parentNode.insertBefore(t, y)
	})(window, document, 'clarity', 'script', process.env.REACT_APP_CLARITY_API_KEY)
}

Sentry.init({
	dsn: 'https://e5958a30f17d4eb283df6defa193033d@o1262644.ingest.sentry.io/6441746',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
	enabled: process.env.NODE_ENV !== 'development',
})
setupAxios(axios, store)

const app = (
	<PersistGate loading={null} persistor={persistor}>
		<Provider store={store}>
			<BrowserRouter>
				<ErrorBoundary fallbackRender={FallbackRender}>
					<StepStatusQueryOptionsProvider>
						<StrictMode>
							<App />
						</StrictMode>
					</StepStatusQueryOptionsProvider>
				</ErrorBoundary>
				<ToastContainer
					position='bottom-left'
					autoClose={8000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange={false}
					draggable={false}
					pauseOnHover={true}
					transition={Slide}
				/>
			</BrowserRouter>
		</Provider>
	</PersistGate>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
