import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Globe } from 'lucide-react'
import React, { Fragment, useState } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'

const PublicLinkPopup = ({ isOpen, onClose, publicLink }) => {
	const [_value, copy] = useCopyToClipboard()
	const [copied, setCopied] = useState(false)
	const link = `${window.location.origin}/f/${publicLink}`

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full max-w-2xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-2 top-3 rounded-full !border !border-gray-300  bg-white p-1 '>
										<Globe height={30} width={30} />
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='p-4'>
									<div className='py-2'>
										<h4 className='m-0 text-sm font-bold'>Public access</h4>
										<p className='m-0 text-xs text-gray-500'>
											Anyone with the link can access the form
										</p>
									</div>
									<div className='mx-auto mt-2 flex items-center flex'>
										<div className='w-full'>
											<div className='relative w-full min-w-[100px]'>
												<input
													type='email'
													id='workflow-link'
													placeholder='Enter to copy'
													className='form-control !rounded-r-none !border-r-0 read-only:bg-gray-100'
													readOnly={true}
													value={link}
												/>
											</div>
										</div>
										<button
											data-clipboard-target='#workflow-link'
											className={classNames(
												'h-[38px]  !rounded !rounded-l-none !border  border-gray-300 bg-transparent px-2.5 py-1  hover:!bg-gray-100 active:!bg-gray-300 disabled:cursor-not-allowed disabled:!opacity-50',
												copied ? 'text-green-600' : 'text-gray-700',
											)}
											type='button'
											onClick={() => {
												copy(link)
												setCopied(true)
											}}>
											{copied ? (
												<div className='flex items-center space-x-2'>
													<span>
														<i className='uil-check' />
													</span>
													<span>Copied</span>
												</div>
											) : (
												<div className='flex items-center space-x-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth='1.5'
														stroke='currentColor'
														aria-hidden='true'
														className='h-4 w-4 text-gray-700'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'></path>
													</svg>
													<span>Copy</span>
												</div>
											)}
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default PublicLinkPopup
