import { useMemo, useState } from 'react'

import userPlaceholder from 'assets/images/users/avatar1.jpg'
import classNames from 'classnames'
import { Pagination } from 'components/Common'
import { Search } from 'lucide-react'
import { getPageNumbers } from 'theme/ui/data-table'
import Spinner from 'theme/ui/spinner'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate, stripHtmlTags } from 'utilities/helpers'
import EmailsQuery from './emails-query'

const EmailList = ({ onEmailOpen, client_id }) => {
	const [pagination, setPagination] = useState({ pageIndex: 1, pageSize: 10 })
	const [search, setSearch] = useState('')
	const { emails, meta, isLoading } = EmailsQuery(
		pagination?.pageIndex,
		pagination?.pageSize,
		client_id,
		search,
	)
	const { startIndex, endIndex } = useMemo(() => getPageNumbers(meta), [meta])
	const onPageChange = (e, page) => {
		setPagination((prev) => ({ ...prev, pageIndex: page }))
	}

	return (
		<div className=''>
			<div className='w-full p-3'>
				<div className='flex w-full appearance-none items-center space-x-3 rounded !border border-solid border-[#ced4da] bg-white bg-clip-padding px-[10px] py-[0.47rem] text-[0.9rem] font-normal leading-normal text-[#495057]'>
					<input
						onChange={(e) => setSearch(e.target?.value)}
						type='text'
						value={search}
						placeholder='Search'
						autoComplete='off'
						className={classNames('w-full placeholder:!text-[#B5B5C3]')}
					/>
					<span>
						<Search size={16} className='text-[#B5B5C3]' />
					</span>
				</div>
			</div>

			{isLoading ? (
				<div className='p-3'>
					<Spinner label='Loading...' />
				</div>
			) : emails.length > 0 ? (
				<div className='flex flex-col divide-y overflow-auto rounded-md border-y bg-white'>
					{emails.map((e, idx) => (
						<div
							key={idx}
							onClick={() => onEmailOpen(e?.id)}
							className='p-3 transition-all hover:cursor-pointer hover:bg-slate-50 active:bg-gray-50'>
							<div className='flex items-center justify-between'>
								<div className='flex max-w-[80%] items-center  space-x-2'>
									<img
										src={e?.sender?.avatar?.path || userPlaceholder}
										alt={''}
										className='aspect-square h-[32px] rounded-full'
									/>
									<div className='flex flex-col'>
										<span
											className='font-bold  text-gray-800'
											title={e?.sender?.fullName}>
											{e?.sender?.fullName}
										</span>
										<span
											className='line-clamp-1 text-sm font-medium text-gray-700'
											title={e?.subject}>
											{e?.subject}
										</span>
									</div>
								</div>

								<ToolTip
									targetId={`date-${e?.uuid}`}
									tooltipText={dateFormat(e?.created_at)}
									placement='top'
									offset={[0, 5]}
								/>
								<span className='text-xs text-gray-400' id={`date-${e?.uuid}`}>
									{formatDate(e?.created_at)}
								</span>
								{/* <span className='text-xs text-gray-400'>
									{moment(e?.created_at).format('MMM YY')}
								</span> */}
							</div>
							<p title={e?.message} className='m-0 line-clamp-2 text-gray-500'>
								{stripHtmlTags(e?.body)}
							</p>
						</div>
					))}
				</div>
			) : (
				<div className='p-3'>
					<p className='m-0 text-center text-gray-400'>No emails have been found yet.</p>
				</div>
			)}

			<div className='mt-4 flex items-center justify-between p-3'>
				<div className='flex flex-row gap-2'>
					<span className=''>
						Showing {startIndex} - {endIndex} of {meta?.totalRecords}
					</span>
					<div className='custom-select'>
						<select
							className='!w-14 rounded px-2 py-1 text-xs'
							value={meta?.limit}
							onChange={(e) => {
								setPagination((prev) => ({
									...prev,
									pageSize: Number(e.target.value),
									pageIndex: 1,
								}))
							}}>
							{[5, 10, 20, 40, 50, 100].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
				<Pagination
					total={meta?.totalRecords}
					limit={meta?.limit}
					currentPage={pagination?.pageIndex}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	)
}

export default EmailList
