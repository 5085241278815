import { useDroppable } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { Check, Ellipsis, GripVertical, TriangleAlert, X } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import Alert from 'theme/ui/alert'
import Badge from 'theme/ui/badge'
import { Button } from 'theme/ui/forms'
import StatusCard from './status-card'

const Column = ({
	column,
	accepts,
	handleDeleteColumn,
	handleUpdateColumn,
	isEditing,
	setEditingColumnId,
	statuses,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [editedTitle, setEditedTitle] = useState(column?.name || '')
	const dropdownRef = useRef(null) // Ref to track the dropdown element
	// const columnId = column?.id
	const {
		attributes,
		listeners,
		setNodeRef: setSortableRef,
		transform,
		transition,
	} = useSortable({
		id: column.id,
		data: {
			type: 'column',
		},
	})

	const { setNodeRef: setDroppableRef } = useDroppable({
		id: column?.id,
		data: {
			type: snakeCase(column?.name),
			accepts: accepts,
			status: column?.name,
			board_column_id: column?.id,
			category_id: column?.category_id,
		},
	})

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	const $statuses = Array.isArray(statuses) ? statuses : []

	const handleDropdownToggle = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setIsOpen((prev) => !prev)
	}

	const handleDropdownItemClick = (e, action) => {
		e.preventDefault()
		e.stopPropagation()
		action()
		setIsOpen(false)
	}

	const handleTitleClick = (e) => {
		e.stopPropagation()
		setEditingColumnId(column.id)
	}

	const handleTitleChange = (e) => {
		setEditedTitle(e.target.value)
	}

	const handleSave = (e) => {
		e.stopPropagation()
		if (editedTitle.trim() && handleUpdateColumn) {
			handleUpdateColumn(column.id, editedTitle)
		}
	}

	const handleCancel = (e) => {
		e.stopPropagation()
		setEditedTitle(column?.name || '')
		setEditingColumnId(null)
	}

	// Close dropdown when clicking outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false)
			}
		}

		// Add event listener when dropdown is open
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		// Cleanup event listener
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isOpen])

	// Combine refs for both sortable and droppable
	const setNodeRef = (element) => {
		setSortableRef(element)
		setDroppableRef(element)
	}

	return (
		<div
			ref={setNodeRef}
			style={style}
			className='min-w-[220px]  min-h-[88%] !border rounded-md'>
			<div className='bg-white rounded-t-md border-b px-1'>
				{isEditing ? (
					<div className='py-[3px]'>
						<div className='relative !z-[1001]'>
							<input
								type='text'
								value={editedTitle}
								onChange={handleTitleChange}
								className='form-control'
								autoFocus
								onClick={(e) => e.stopPropagation()}
							/>
							<div className='absolute right-0 top-full mt-1 flex gap-1'>
								<Button
									size='xs'
									variant='primary'
									onClick={handleSave}
									className='p-1'>
									<Check size={14} />
								</Button>
								<Button
									size='xs'
									variant='white'
									onClick={handleCancel}
									className='p-1'>
									<X size={14} />
								</Button>
							</div>
						</div>
					</div>
				) : (
					<div className='flex items-center justify-between py-[6px]'>
						<div className='flex items-center space-x-2 relative'>
							<span
								className='cursor-move p-1 text-gray-500 hover:text-gray-700 hover:bg-gray-200 rounded-md transition-all'
								{...attributes}
								{...listeners}
								title='Drag to reorder'>
								<GripVertical size={14} />
							</span>
							{/* <ToolTip targetId={columnId} tooltipText={column?.name} /> */}
							<h2
								// id={columnId}
								className='m-0 line-clamp-1 overflow-hidden leading-[inherit] text-xs font-medium uppercase cursor-pointer hover:bg-gray-100 p-1 rounded'
								onClick={handleTitleClick}
								title={column?.name}>
								{column?.name}
							</h2>
							<Badge variant='primary'>{column?.cards?.length}</Badge>
						</div>

						<div className='relative' ref={dropdownRef}>
							<Button
								size='sm'
								variant={classNames(isOpen ? 'white' : 'ghost')}
								onClick={handleDropdownToggle}>
								<Ellipsis size={14} />
							</Button>
							{isOpen && (
								<div
									className='absolute right-0 mt-2 max-w-fit px-0 py-2 shadow-md bg-white border rounded z-10'
									onClick={(e) => e.stopPropagation()}>
									<ul className='m-0 p-0 min-w-[120px]'>
										<li
											className={classNames(
												'exp-dropdown-item px-3 py-1 hover:bg-gray-100 cursor-pointer',
											)}
											onClick={(e) =>
												handleDropdownItemClick(e, () =>
													handleDeleteColumn(column?.id),
												)
											}>
											<span>Delete</span>
										</li>
									</ul>
								</div>
							)}
						</div>
					</div>
				)}
			</div>

			<div className='flex h-auto flex-col min-h-[91%] space-y-1 rounded bg-gray-300/20 p-1'>
				{$statuses.length > 0 ? (
					$statuses.map((status, idx) => (
						<StatusCard key={idx} index={idx} status={status} />
					))
				) : (
					<Alert variant='warning'>
						<div className='flex flex-col items-center'>
							<span>
								<TriangleAlert size={24} />
							</span>
							<span className='text-center'>
								Since no status is added in this column, it will not be visible on
								the board.
							</span>
						</div>
					</Alert>
				)}
			</div>
		</div>
	)
}

export default Column
