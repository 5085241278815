import { Field, Form, FormikProvider, useFormik } from 'formik'
import React from 'react'

import ClientAdvisorDropdown from 'modules/documents/document-template-form/tabs-content/upload-document/form-template-info/Client-advisor-dropdown'
import { Button, InputField, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { validationSchema } from '.'
import { useCreateboardsMutation, useUpdateboardsMutation } from '../boards-api'

const BoardForm = ({ onClose, board }) => {
	const [createBoard] = useCreateboardsMutation()
	const [updateBoard] = useUpdateboardsMutation()

	const initialValues = {
		name: board ? board.name : '',
		description: board ? board.description : '',
		users: board
			? board.users.map((user) => ({
					value: user.id,
					label: user.fullName,
				}))
			: [],
	}

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = {
					name: values.name,
					description: values.description,
					users: values.users.map((user) => user.value),
				}
				let response
				if (board) {
					response = await updateBoard({ id: board.id, payload }).unwrap()
				} else {
					response = await createBoard(payload).unwrap()
				}

				if (response?.status === 200) {
					notification('success', response?.message)
					onClose()
					resetForm()
				}

				setSubmitting(true)
			} catch (error) {
				setSubmitting(true)
				console.error('Error submitting board:', error)
			}
		},
	})
	return (
		<FormikProvider value={formik}>
			<Form className='space-y-4'>
				<Field label='Enter board name' name='name' type='text' component={InputField} />

				<Field
					label='Board description'
					name='description'
					type='textarea'
					component={TextareaField}
				/>
				<ClientAdvisorDropdown
					name='users'
					onChange={(selected) => formik.setFieldValue('users', selected)}
					value={formik.values.users}
				/>

				<hr className='my-3 bg-gray-300' />

				<div className='my-2 flex w-full justify-center gap-2'>
					<Button type='submit' isLoading={formik.isSubmitting}>
						Save
					</Button>
					<Button variant='ghost' type='button' onClick={onClose}>
						Cancel
					</Button>
				</div>
			</Form>
		</FormikProvider>
	)
}

export default BoardForm
