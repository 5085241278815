import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import { ReactComponent as FormIcon } from 'assets/svg/form-icon.svg'
import MyDocumentsListingContainer from './my-documents-listing-container'

export const getDocumentIcon = (type = 'document') => {
	if (type === 'form') {
		return <FormIcon height={20} />
	} else if (type === 'document') {
		return <DocumentIcon height={20} />
	} else if (type === 'folder') {
		return <FolderIcon height={15} />
	} else if (type === 'public_form') {
		return <FormIcon height={20} />
	} else {
		return <DocumentIcon height={20} />
	}
}

export { default as SearchDocumentsListingContainer } from './search-documents-listing-container'

export default MyDocumentsListingContainer
