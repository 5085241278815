import { AppLayout, PageHeader } from 'theme/layouts'
import FormResponse from './form-response'

const FormResponseLayout = () => {
	return (
		<AppLayout
			meta={{
				title: 'Form Response',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Form Response'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						<FormResponse />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FormResponseLayout
