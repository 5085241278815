import { Field } from 'formik'
import { useState } from 'react'

import { CreatableSelectField } from 'theme/ui/forms'
import useSystemFieldQuery from './use-system-field-query'

const SystemFieldDropdown = ({
	label,
	name,
	onAutoSave,
	onHighlight,
	onCreateOption,
	isRequired,
	className,
	isMulti = false,
	isLabelValue = false,
	helperText = '',
	showSystemField = true,
}) => {
	const [search, setSearch] = useState('')

	const { options, isLoading } = useSystemFieldQuery(search, isLabelValue, showSystemField)

	return (
		<Field
			name={name}
			type='select'
			label={label}
			placeholder={label}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			options={options}
			className={className}
			onAutoSave={onAutoSave}
			onFocus={onHighlight}
			onCreateOption={onCreateOption}
			component={CreatableSelectField}
			isRequiredField={isRequired}
			isClearable={false}
			isMulti={isMulti}
			helperText={helperText}
		/>
	)
}

export default SystemFieldDropdown
