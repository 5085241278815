import classNames from 'classnames'
import { Field, useFormikContext } from 'formik'

import FieldTypeField from './field-type-field'
import UserTypeField from './user-type-field'

const FieldWrapper = ({ name, field, children, userOptions, type }) => {
	const { values } = useFormikContext()

	return (
		<div className='grid w-full grid-cols-1 gap-2'>
			<div className='flex items-baseline gap-2'>
				<Field name={`${name}.label`}>
					{({ field, meta: { touched, error } }) => (
						<div className='flex flex-1 flex-col'>
							<input
								{...field}
								type='text'
								disabled={type === 'signature'}
								className={classNames(
									'form-control flex-1 placeholder:!text-gray-400  ',
									{
										'is-invalid': touched && error,
									},
								)}
								placeholder='Question'
							/>
							{touched && error ? (
								<span className='invalid-feedback'>{error}</span>
							) : undefined}
						</div>
					)}
				</Field>
				<div className='min-w-[200px]'>
					<FieldTypeField
						isSubtype={field?.type === 'text'}
						name={name}
						userOptions={userOptions}
					/>
				</div>
			</div>

			<Field name={`${name}.description`}>
				{({ field }) => (
					<input
						{...field}
						type='text'
						className='form-control placeholder:!text-gray-400'
						placeholder='Description'
					/>
				)}
			</Field>

			{children}

			{values?.purpose !== 'link' && <UserTypeField name={name} userOptions={userOptions} />}
		</div>
	)
}

export default FieldWrapper
