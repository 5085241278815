import { Field, Form, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'

import { useSendEmailMutation } from 'modules/clients/client-email-api'
import { Button, InputField, SelectField } from 'theme/ui/forms'
import TextEditor from 'theme/ui/forms/text-editor'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'

const SendEmailForm = ({ onClose, selectedRows }) => {
	const [sendEmail] = useSendEmailMutation()

	const options = useMemo(
		() =>
			Array.isArray(selectedRows)
				? selectedRows.map((r) => ({ value: r?.id, label: r?.email }))
				: [],
		[selectedRows],
	)

	const formik = useFormik({
		initialValues: {
			to: options,
			cc: [],
			bcc: [],
			subject: '',
			body: '',
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				const response = await sendEmail(payload).unwrap()
				if (response?.status === 200) {
					onClose()
					notification('success', 'Email sent successfully')
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})

	// const onCreateOption = (value) => {
	// 	return value
	// }

	return (
		<FormikProvider value={formik}>
			<Form>
				<div className='space-y-4'>
					<Field
						type='select'
						label='To'
						name='to'
						isMulti
						isRequiredField
						options={options}
						// onCreateOption={onCreateOption}
						component={SelectField}
					/>
					<Field
						type='text'
						name='subject'
						isRequiredField
						component={InputField}
						label='Subject'
					/>
					<Field label='Message' name='body' component={TextEditor} isRequiredField />
				</div>
				<hr className='my-3 bg-gray-300' />

				<div className='flex w-full justify-center gap-2'>
					<Button variant='ghost' type='button' onClick={onClose}>
						Cancel
					</Button>
					<Button
						type='submit'
						isLoadingText='Sending...'
						isLoading={formik?.isSubmitting}>
						Send Now
					</Button>
				</div>
			</Form>
		</FormikProvider>
	)
}

export default SendEmailForm
