import { createColumnHelper } from '@tanstack/react-table'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import TaskCanvas from '../task-canvas'
import StatusColumn from './status-column'
import TaskActions from './task-actions'
import { ChevronRight, ChevronUp, Minus } from 'lucide-react'

const TaskTable = ({
    data,
    meta,
    isLoading,
    pagination,
    setPagination,
    columnVisibility,
    setColumnVisibility,
    onDeleteTask,
    onOpenCanvas,
    selectedTaskId,
    isCanvasOpen,
    onCloseCanvas,
    boardId,
    rowSelection,
    setRowSelection,
}) => {
    const [sorting, setSorting] = useState([])
    const [expandedRows, setExpandedRows] = useState({})

    const toggleRow = (taskId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [taskId]: !prev[taskId],
        }))
    }

    const columnHelper = createColumnHelper()

    const columns = [
        {
            id: 'select',
            size: 20,
            header: ({ table }) => (
                <div className='px-1'>
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({ row }) => (
                <div className='px-1'>
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        columnHelper.accessor((row) => row, {
            id: 'summary',
            size: 180,
            header: () => <span className='pl-5'>Summary</span>,
            cell: (info) => {
                const task = info.getValue();
                const hasSubtasks = task.subtasks && task.subtasks.length > 0;
                const isExpanded = expandedRows[task.id];
                const isSubtask = task.isSubtask;

                return (
                    <div className={`flex items-start gap-2 relative ${isSubtask ? 'border-l-2 border-gray-300 ml-2' : ''}`}>
                        {/* Arrow Button for Expanding Subtasks */}
                        <div className="w-4 flex justify-center">
                            {!isSubtask && hasSubtasks ? (
                                <button
                                    onClick={() => toggleRow(task.id)}
                                    className="text-gray-500 hover:text-black focus:outline-none relative z-10"
                                    aria-label="Toggle Subtasks"
                                >
                                    {isExpanded ? (
                                        <ChevronUp size={16} className="text-gray-600" />
                                    ) : (
                                        <ChevronRight size={16} className="text-gray-600" />
                                    )}
                                </button>
                            ) : (
								<div className="w-4"></div>
							)}
                        </div>

                        {/* Task Title */}
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onOpenCanvas(task.id);
                            }}
                            className="link line-clamp-1 text-sm font-bold !text-main"
                            title={task.task_title}
                        >
                            {task.task_title}
                        </Link>
                    </div>
                );
            }
        }),
        columnHelper.accessor('due_date', {
            header: () => <span>Due Date</span>,
            cell: (info) => (
                <>
                    {info.getValue() ? (
                        <span
                            className='tag line-clamp-1 max-w-max'
                            title={new Date(info.getValue())
                                .toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                })
                                .replace(',', '')}>
                            {new Date(info.getValue())
                                .toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                })
                                .replace(',', '')}
                        </span>
                    ) : undefined}
                </>
            ),
        }),
        columnHelper.accessor('workflow', {
            header: () => <span>Workflow</span>,
            cell: (info) => (
                <>
                    {info.getValue()?.title ? (
                        <span
                            className='tag tag-info line-clamp-1 max-w-max'
                            title={info.getValue()?.title}>
                            {info.getValue()?.title}
                        </span>
                    ) : undefined}
                </>
            ),
        }),
        columnHelper.accessor((row) => row, {
            id: 'progress_status',
            size: 90,
            header: () => <span>Progress Status</span>,
            cell: (info) => <StatusColumn task={info.getValue()} />,
        }),
        columnHelper.accessor('assignee', {
            header: () => <span>Assignee</span>,
            cell: (info) => (
                <>
                    {info.getValue() ? (
                        <CreateUserOptionLabel user={info.getValue()} showEmail={false} />
                    ) : undefined}
                </>
            ),
        }),
        columnHelper.accessor((row) => row, {
            id: 'actions',
            size: 70,
            enableSorting: false,
            header: () => (
                <div className='w-full text-right'>
                    <span>Actions</span>
                </div>
            ),
            cell: (info) => <TaskActions task={info.getValue()} onDeleteTask={onDeleteTask} />,
        }),
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={Array.isArray(data) ? data.flatMap((task) => [
                    { ...task, isSubtask: false },  // Main Task
                    ...(expandedRows[task.id] ? (task.subtasks || []).map(subtask => ({ ...subtask, isSubtask: true })) : []), // Subtasks
                ]) : []}
                isLoading={isLoading}
                pagination={pagination}
                setPagination={setPagination}
                meta={meta}
                setSorting={setSorting}
                sorting={sorting}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setRowSelection={setRowSelection}
                rowSelection={rowSelection}
            />
            <TaskCanvas
                boardId={boardId}
                taskId={selectedTaskId}
                isOpen={isCanvasOpen}
                onClose={onCloseCanvas}
                toggleCanvas={() => onCloseCanvas()} 
                isTask={false} 
            />
        </>
    )
}

export default TaskTable
