import React from 'react'

const SignatureResponse = ({ q }) => {
	return (
		<div className='flex justify-between w-full'>
			<div className='flex flex-col  w-1/2 justify-center items-center'>
				<p className='text-xl font-bold'>{q.responses.length}</p>
				<p className='text-gray-500'>Responses</p>
			</div>

			<div className='flex flex-col w-1/2 justify-center items-center'>
				{q.responses.slice(0, 1).map((data, ind) => (
					<img src={data} className='block h-[100px] w-[200px] !border !border-gray-300 rounded-sm' key={ind} />
				))}
				{q.responses.length > 1 && (
					<p className='text-gray-600 font-bold text-[20px] m-0 p-0'>...</p>
				)}
			</div>
		</div>
	)
}

export default SignatureResponse
