import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { useLazyGetWorkflowsQuery } from 'modules/clients/client-apis'

const useWorkflowViewQuery = (pagination, client) => {
	const values = useSelector((state) => state.form?.workflow_view_filter?.values)
	const [debounceSearch] = useDebounce(values?.search, 600)
	const [fetchWorkflow, { data: workflow, isUninitialized, isFetching }] =
		useLazyGetWorkflowsQuery()
	const client_id = client?.id
	const { pageIndex, pageSize } = pagination
	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		if (debounceSearch) {
			Object.assign(obj, { search: debounceSearch })
		}

		return obj
	}, [values, debounceSearch, pageIndex, pageSize])

	useEffect(() => {
		if (filters && client_id) {
			fetchWorkflow({ params: filters, client_id })
		}
	}, [filters, client_id])

	const responseData = workflow?.data?.workflows

	const response = useMemo(
		() =>
			Array.isArray(responseData)
				? responseData.map((workflow, idx) => ({
						index: idx + 1 + pageIndex * pageSize,
						id: workflow?.id,
						title: workflow?.workflow_template?.title,
						status: workflow?.status,
				  }))
				: [],
		[responseData],
	)

	return {
		data: response || [],
		meta: workflow?.data?.meta,
		isLoading: isUninitialized || isFetching,
	}
}

export default useWorkflowViewQuery
