import { createColumnHelper } from '@tanstack/react-table'

import { useState } from 'react'
import { ReactDataTable } from 'theme/ui/data-table'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate, isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { useDeleteSftpConfigMutation, useLazyGetSftpQuery } from '../sftp-config-api'
import SftpConfigForm from '../sftp-config-form/sftp-config-form'
import SftpHistoryModal from './sftp-history-list'

const SftpList = ({ meta, isLoading, sftpData, pagination, setPagination }) => {
	const columnHelper = createColumnHelper()
	const [fetchSftpInfo] = useLazyGetSftpQuery()
	const [isOpen, setIsOpen] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [formData, setFormData] = useState(null)
	const handleForm = () => {
		setIsOpen(!isOpen)
	}
	const [isHistoryOpen, setIsHistoryOpen] = useState(false)

	const editSftp = async (id) => {
		setIsOpen(true)
		setIsFetching(true)
		const response = await fetchSftpInfo(id)
		if (response?.isSuccess) {
			setIsFetching(false)
			setFormData(response?.data?.data)
		} else {
			setIsFetching(false)
			setIsOpen(false)
		}
	}
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const onClose = () => setIsDeleteOpen(false)

	const [deleteSftp, { isLoading: isDeleting }] = useDeleteSftpConfigMutation()
	const onDeleteSftp = async (id) => {
		try {
			const response = await deleteSftp(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}
	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'host',
			header: () => <span>Host</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-full text-sm font-bold !text-gray-700'>
						{info.getValue()?.host || 'N/A'}
					</span>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'port',
			header: () => <span>Port</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 text-sm capitalize !text-gray-700'>
						{info.getValue()?.port || 'N/A'}
					</span>
				)
			},
		}),
		// columnHelper.accessor((row) => row, {
		// 	id: 'pull_frequency',
		// 	header: () => <span>Pull Frequency</span>,
		// 	cell: (info) => {
		// 		return (
		// 			<span className='line-clamp-1 text-sm capitalize !text-gray-700'>
		// 				{info.getValue()?.pull_frequency || 'N/A'}
		// 			</span>
		// 		)
		// 	},
		// }),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => (
				<div className='!w-full'>
					<span className='float-left'>Created at</span>
				</div>
			),
			cell: (info) => {
				return (
					<div className='w-full justify-start text-left'>
						<span
							className='line-clamp-1 text-sm  capitalize !text-gray-700'
							title={dateFormat(info.getValue()?.created_at)}>
							{info.getValue()?.created_at
								? formatDate(info.getValue()?.created_at)
								: 'N/A'}
						</span>
					</div>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => {
				return (
					<div className='float-right flex justify-end space-x-2'>
						{/* <ToolTip targetId={`edit-sftp-1`} tooltipText='Edit' placement='top' />
						<button
							id={`edit-sftp-1`}
							type='button'
							onClick={() => editSftp(info.getValue()?.id)}
							className='action-btn btn-primary-outline'>
							<i className='uil-pen' style={{ fontSize: 16 }} />
						</button> */}
						<ToolTip
							targetId={`history-sftp-1`}
							tooltipText='History'
							placement='top'
						/>
						<button
							id={`history-sftp-1`}
							type='button'
							onClick={() => setIsHistoryOpen(info.getValue()?.id)}
							className='action-btn btn-gray-outline'>
							<i className='uil-history' style={{ fontSize: 16 }} />
						</button>
						{isAllowTo(permissions?.sftp?.delete) ? (
							<>
								<ToolTip
									targetId={`delete-sftp-1`}
									tooltipText='Delete'
									placement='top'
								/>

								<button
									id={`delete-sftp-1`}
									type='button'
									onClick={() => setIsDeleteOpen(info.getValue()?.id)}
									className='action-btn btn-red-outline'>
									<i className='uil-trash-alt' style={{ fontSize: 16 }} />
								</button>
							</>
						) : undefined}
					</div>
				)
			},
		}),
	]

	return (
		<div>
			<ReactDataTable
				columns={columns}
				data={sftpData}
				meta={meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
			<SftpConfigForm
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				handleForm={handleForm}
				details={formData}
				isFetching={isFetching}
			/>
			<DeleteConfirmAlert
				isOpen={isDeleteOpen}
				onClose={onClose}
				isLoading={isDeleting}
				onConfirm={() => onDeleteSftp(isDeleteOpen)}
			/>
			<SftpHistoryModal isOpen={isHistoryOpen} setIsHistoryOpen={setIsHistoryOpen} />
		</div>
	)
}

export default SftpList
