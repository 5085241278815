export const DOCUMENT_TEMPLATE_APP_ROUTES = {
	findAll: () => '/documents',
	findAllDeleted: () => '/deleted-document-templates',
	create: () => '/documents/create',
	edit: (id) => `/documents/${id}/edit`,
	createEdit: (id) => `/documents/${id}/edit?isCreate=true`,
	findOne: (id) => `/documents/${id}`,
	response: (id) => `/documents/${id}/form-response`,
}

export const DOCUMENT_VERSION_APP_ROUTES = {
	view: (id, versionId) => `/documents/${id}/versions/${versionId}`,
}
