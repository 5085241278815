import { ChevronDown, Eye, Trash } from 'lucide-react'
import { useState } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { Dropdown, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap'
import Spinner from 'theme/ui/spinner'
import { formatDate } from 'utilities/helpers'
import AttachmentViewModal from './attachment-view-modal'

const AttachmentItem = ({ attachment, onRemove, isDeleting }) => {
	const [isViewModalOpen, setIsViewModalOpen] = useState(false)
	const mime_type = attachment?.file?.mime_type
	const [type, extension] = mime_type ? mime_type.split('/') : []
	const file = attachment?.file
	const [isOpen, setIsOpen] = useState(false)
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggleDropdown = () => {
		setIsOpen(!isOpen)
		setTooltipOpen(isOpen)
	}

	const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

	return (
		<div>
			<Tooltip
				isOpen={tooltipOpen}
				target={`invited-${attachment?.id}`}
				toggle={toggleTooltip}
				placement='auto'
				className='!z-[1051]'
				container={document.querySelector('.offcanvas')}>
				<div className='flex flex-col divide-y divide-white'>
					<span className='pb-2 font-medium'>{file?.originalname}</span>
					<span className='pt-2 font-normal'>
						Uploaded {formatDate(file?.created_at)}
					</span>
				</div>
			</Tooltip>

			<div
				id={`invited-${attachment?.id}`}
				onMouseLeave={() => setIsOpen(false)}
				className='image-container flex h-[66px] w-[85px] cursor-pointer items-center space-x-2 rounded-lg  !border border-gray-300 bg-cover bg-center px-1 transition-all hover:border-gray-400'>
				{type !== 'image' ? (
					<div className='mx-auto h-auto w-9'>
						<FileIcon extension={extension} {...defaultStyles[extension]} />
					</div>
				) : (
					<img src={file?.path} alt='' className='image' />
				)}

				<div className='overlay'>
					<Dropdown isOpen={isOpen} toggle={toggleDropdown}>
						<DropdownToggle className='btn btn-sm bg-secondary' type='button'>
							<ChevronDown size={16} />
						</DropdownToggle>

						<DropdownMenu className='mt-2 px-0 py-2 shadow-md'>
							<ul className='m-0 p-0'>
								{file?.type === 'photo' && (
									<li
										className='exp-dropdown-item'
										onClick={() => setIsViewModalOpen(true)}>
										<span className='flex items-center space-x-1'>
											<Eye size={15} />
											<span>View</span>
										</span>
									</li>
								)}
								<li
									className='exp-dropdown-item'
									onClick={() => onRemove(attachment?.id)}>
									{isDeleting ? (
										<Spinner label='Deleting...' />
									) : (
										<span className='flex items-center space-x-1'>
											<Trash size={15} />
											<span>Delete</span>
										</span>
									)}
								</li>
							</ul>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>

			<AttachmentViewModal
				isOpen={isViewModalOpen}
				onClose={() => setIsViewModalOpen(false)}
				file={file}
				// onDownload={handleDownload}
			/>
		</div>
	)
}

export default AttachmentItem
