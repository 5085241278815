import WebViewer from '@pdftron/webviewer'
import { useEffect, useMemo, useRef } from 'react'

import { documentEventLog } from 'modules/document-event-log'
import { useCreateDocumentEventLogMutation } from 'modules/document-event-log/document-event-log-api'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import { useSetDocumentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import { roundToDecimal } from 'pages/Authentication/profile/components/workflow/documents-tab'
import notification from 'utilities/notification'
import { signatureViewDisabledElement } from '.'

const SignatureTab = ({ formik, documentId }) => {
	const viewerRef = useRef(null)
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [createDocumentEventLog] = useCreateDocumentEventLogMutation()
	const FILE_URL = useMemo(() => formValues?.file_path, [formValues?.file_path])
	const no_of_users = useMemo(() => formValues?.min_clients?.value || 0, [formValues])
	const [getSetting] = useLazyGetSettingQuery()
	const setDocumentViewer = useSetDocumentViewer()

	const createInstance = async () => {
		const response = await getSetting('pdf_tron_apikey')
		const settingValue = response?.data?.setting
		try {
			if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
				const instance = await WebViewer(
					{
						path: '/lib',
						initialDoc: FILE_URL,
						fullAPI: true,
						disabledElements: signatureViewDisabledElement,
						custom: JSON.stringify({ total_users: no_of_users }),
						licenseKey: settingValue?.data?.setting_value,
					},
					viewerRef.current,
				)

				return instance
			}
		} catch (error) {
			notification(error)
		}
	}

	useEffect(() => {
		if (FILE_URL) {
			createInstance().then((instance) => {
				if (instance?.Core) {
					if (documentId) {
						createDocumentEventLog({
							document_id: documentId,
							event_type: documentEventLog.eventTypes.SignatureFieldCreation,
							module_type: documentEventLog.moduleTypes.DocumentTemplateCreation,
						})
					}

					const { annotationManager, Annotations, documentViewer } = instance.Core
					const { WidgetFlags, Forms, TextWidgetAnnotation, Border, Color } = Annotations
					instance.UI.enableFeatures([instance.UI.Feature.Initials])

					const formFieldCreationManager = annotationManager.getFormFieldCreationManager()
					formFieldCreationManager.startFormFieldCreationMode()

					documentViewer.addEventListener('documentLoaded', () => {
						documentViewer.getAnnotationsLoadedPromise().then(() => {
							annotationManager.addEventListener('annotationChanged', () => {
								setDocumentViewer(instance)
							})
						})
					})

					// start timestamp field insertion with signature field
					annotationManager.addEventListener(
						'annotationChanged',
						(annotations, action, { imported }) => {
							if (imported) {
								return
							}

							const selectedAnnotation = annotations[0]
							if (action === 'modify') {
								const fieldName = selectedAnnotation?.tj['trn-form-field-name']
								if (fieldName) {
									const [$fieldName, $userRole, $fieldTye] = fieldName.split('.')
									if ($fieldName === 'signature' && $fieldTye === 'signature') {
										const timestampFieldName = [
											$fieldName,
											$userRole,
											'timestamp',
										].join('.')

										// start insertion of timestamp form textfield
										const timestampWidgetflags = new WidgetFlags()
										const field = new Forms.Field(timestampFieldName, {
											type: 'Tx',
											defaultValue: 'timestamp goes here',
											flags: timestampWidgetflags,
										})

										const border = new Border()
										border.color = new Color(0, 0, 0)
										border.width = 1

										const widgetAnnot = new TextWidgetAnnotation(field)
										widgetAnnot.PageNumber = selectedAnnotation.PageNumber
										widgetAnnot.X = selectedAnnotation.X
										widgetAnnot.Y =
											selectedAnnotation.Y +
											roundToDecimal(selectedAnnotation.Height, 2)
										widgetAnnot.Width = 140
										widgetAnnot.Height = 15
										widgetAnnot.border = border

										annotationManager.getFieldManager().addField(field)
										annotationManager.addAnnotation(widgetAnnot)
										annotationManager.drawAnnotationsFromList([widgetAnnot])
										// end insertion of timestamp form textfield
									}
								}
							}
							// // add timestamp annotation here on signature field insertion
							// if (
							// 	selectedAnnotation instanceof
							// 		Annotations.SignatureWidgetAnnotation &&
							// 	action === ACTIONS.ADD
							// ) {
							// 	const fieldName = selectedAnnotation.fieldName

							// 	const [$fieldName, $userRole] = fieldName.split('.')

							// 	const timestampFieldName = [
							// 		$fieldName,
							// 		$userRole,
							// 		'timestamp',
							// 	].join('.')
							// 	// start insertion of timestamp form textfield
							// 	const timestampWidgetflags = new WidgetFlags()

							// 	const field = new Forms.Field(timestampFieldName, {
							// 		type: 'Tx',
							// 		defaultValue: 'timestamp goes here',
							// 		flags: timestampWidgetflags,
							// 	})

							// 	const border = new Border()
							// 	border.color = new Color(0, 0, 0)
							// 	border.width = 1

							// 	const widgetAnnot = new TextWidgetAnnotation(field)
							// 	widgetAnnot.PageNumber = selectedAnnotation.PageNumber
							// 	widgetAnnot.X = selectedAnnotation.X
							// 	widgetAnnot.Y =
							// 		selectedAnnotation.Y +
							// 		roundToDecimal(selectedAnnotation.Height, 2)
							// 	widgetAnnot.Width = 140
							// 	widgetAnnot.Height = 15
							// 	widgetAnnot.border = border

							// 	annotationManager.getFieldManager().addField(field)
							// 	annotationManager.addAnnotation(widgetAnnot)
							// 	annotationManager.drawAnnotationsFromList([widgetAnnot])
							// 	// end insertion of timestamp form textfield
							// }

							// setDocumentViewer(instance)
						},
					)
					// end timestamp field insertion with signature field

					// const { start } = initializeWalkthrough(
					// 	viewerRef.current,
					// 	steps,
					// 	() => {
					// 		console.log('tutorial complete...')
					// 	},
					// 	{
					// 		backgroundColor: '#dee3fa',
					// 		textColor: '#37458b',
					// 	},
					// )
					// start()
				}
			})
		}
	}, [FILE_URL, documentId])

	return (
		<div>
			<div className='signature_div_height rounded-md bg-white p-6'>
				<p>In this step you can add signature field to the pdf.</p>
				<div
					className='webviewer signature_height'
					ref={viewerRef}
					style={{ height: 'calc(100vh - 71px)' }}
				/>
			</div>
		</div>
	)
}

export default SignatureTab
