import React from "react";

const ScrumBoardSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[...Array(6)].map((_, index) => (
        <div
          key={index}
          className="bg-gray-200 animate-pulse rounded-lg p-4 min-h-[230px] flex flex-col justify-between relative"
        >
          {/* Header Placeholder */}
          <div className="h-6 bg-gray-300 rounded w-3/4 mb-3"></div>
          <div className="h-4 bg-gray-300 rounded w-1/2 mb-3"></div>

          {/* Description Placeholder */}
          <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
          <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
          <div className="h-4 bg-gray-300 rounded w-2/3 mb-4"></div>

          {/* Footer Placeholder */}
          <div className="flex justify-between items-center">
            <div className="flex -space-x-2">
              {[...Array(3)].map((_, i) => (
                <div
                  key={i}
                  className="w-8 h-8 bg-gray-300 rounded-full border-2 border-white"
                ></div>
              ))}
            </div>
            <div className="h-4 bg-gray-300 rounded w-1/3"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScrumBoardSkeleton;