import { Pagination } from 'components/Common'
import { Field } from 'formik'
import {
	useClientSettingSaveMutation,
	useDeleteClientSettingMutation,
} from 'modules/clients/client-apis'
import React, { useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { getPageNumbers } from 'theme/ui/data-table'
import { Button, InputField } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import CustomFieldsHistory from './custom-fields-history'

const CustomFieldArray = ({
	remove,
	fields,
	name,
	meta,
	setPagination,
	onPageChange,
	isLoading,
	onAdd,
	formik,
}) => {
	const [selected, setSelected] = useState([])
	const { startIndex, endIndex } = useMemo(() => getPageNumbers(meta), [meta])
	const [deleteClientSetting] = useDeleteClientSettingMutation()
	const [clientSettingSave] = useClientSettingSaveMutation()
	const [submitting, setSubmitting] = useState(false)

	const onSave = async () => {
		try {
			setSubmitting(true)
			const payload = {
				...formik?.values,
				fields: Array.isArray(formik?.values?.fields)
					? formik?.values?.fields.filter((f) => selected.includes(f?.id))
					: [],
			}

			const response = await clientSettingSave(payload)
			if (response?.data?.status === 200 && Array.isArray(response?.data?.data)) {
				notification('success', response?.data?.message)
			}
			setSubmitting(false)
		} catch (error) {
			setSubmitting(false)
			//
		}
	}

	const toggleSelectAll = () => {
		if (selected.length === fields.length) {
			setSelected([])
		} else {
			const allIds = fields?.map((field) => field?.id)
			setSelected(allIds)
		}
	}

	const toggleSelect = (id) => {
		if (selected.includes(id)) {
			setSelected(selected.filter((selectedId) => selectedId !== id))
		} else {
			setSelected([...selected, id])
		}
	}

	const onDelete = async (e, id, idx) => {
		e.preventDefault()
		if (id) {
			Swal.fire({
				title: 'Are you sure you want to Delete ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const button = e.target
					button.disabled = true
					const payload = {
						ids: [id],
					}
					const response = await deleteClientSetting(payload)
					if (response?.data?.status === 200) {
						notification('success', response?.data?.message)
					}
				}
			})
		} else {
			remove(idx)
		}
	}

	const onBulkDelete = async (ids) => {
		if (ids) {
			Swal.fire({
				title: 'Are you sure you want to Delete ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const payload = {
						ids: ids,
					}
					const response = await deleteClientSetting(payload)
					if (response?.data?.status === 200) {
						setSelected([])
						notification('success', response?.data?.message)
					}
				}
			})
		}
	}
	const [showHistoryModal, setShowHistoryModal] = useState(false)
	const showHistory = (e, id) => {
		e.preventDefault()
		if (id) {
			setShowHistoryModal(true)
		}
	}

	return (
		<>
			<CustomFieldsHistory
				isOpen={showHistoryModal}
				onClose={() => setShowHistoryModal(false)}
			/>
			<div className='p-[0.75rem]'>
				<div className='mb-2 flex items-center justify-between gap-4 px-2.5'>
					<div>
						{selected?.length ? (
							<span className='text-sm font-semibold'>
								{selected?.length} Selected
							</span>
						) : undefined}
					</div>
					<div className='flex gap-1'>
						{selected?.length > 0 ? (
							<>
								<Button
									variant='danger-outline'
									size='sm'
									type='button'
									onClick={() => onBulkDelete(selected)}>
									<i className='uil-trash-alt' /> Delete
								</Button>
								<Button
									variant='primary-outline'
									size='sm'
									type='button'
									isLoading={submitting}
									isLoadingText='Saving...'
									onClick={onSave}>
									<i className='uil uil-save' /> Save
								</Button>
							</>
						) : undefined}
						<Button variant='primary-outline' size='sm' onClick={onAdd} type='button'>
							<i className='uil uil-plus' /> Add Field
						</Button>
					</div>
				</div>
				<table className='table-1 mb-0'>
					<thead>
						<tr>
							<th>
								<input
									type='checkbox'
									className='form-check-input '
									checked={selected.length === fields.length && fields.length > 0}
									onChange={toggleSelectAll}
								/>
							</th>
							<th>Key</th>
							<th>Value</th>
							<th className='text-end'>Actions</th>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={4}>
									<Spinner label={'Loading...'} />
								</td>
							</tr>
						) : fields.length > 0 ? (
							fields.map((field, idx) => (
								<tr className='identification_document hover:!bg-white' key={idx}>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<input
											className='form-check-input !mt-3'
											type='checkbox'
											checked={selected.includes(field?.id)}
											onChange={() => toggleSelect(field?.id)}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											type='hidden'
											name={`${name}[${idx}].id`}
											component='input'
										/>
										<Field
											name={`${name}[${idx}].key`}
											component={InputField}
											placeholder='Field key'
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											name={`${name}[${idx}].value`}
											placeholder='Field value'
											component={InputField}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<div className='flex w-full justify-end gap-2'>
											<Button
												variant='primary-outline'
												type='button'
												size='sm'
												onClick={(e) => showHistory(e, field?.id)}>
												<i className='uil-history' />
											</Button>
											<Button
												variant='danger-outline'
												type='button'
												size='sm'
												onClick={(e) => onDelete(e, field?.id, idx)}>
												<i className='uil-trash-alt' />
											</Button>
										</div>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={4}>
									<p className='m-0 text-center text-sm'>No Field Found</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className='mt-4 flex items-center justify-between px-2.5'>
					<div className='flex flex-row gap-3'>
						<span className=''>
							Showing {startIndex} - {endIndex} of {meta?.totalRecords}
						</span>
						<div className='custom-select'>
							<select
								className='!w-14 rounded px-2 py-1 text-xs'
								value={meta?.limit}
								onChange={(e) => {
									setPagination((prev) => ({
										...prev,
										pageSize: Number(e.target.value),
										pageIndex: 1,
									}))
								}}>
								{[5, 10, 20, 40, 50, 100].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
						</div>
					</div>
					<Pagination
						total={meta?.totalRecords}
						limit={meta?.limit}
						currentPage={meta?.currentPage}
						onPageChange={onPageChange}
					/>
				</div>
			</div>
		</>
	)
}

export default CustomFieldArray
