import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'; // Added useFormikContext
import { useUser } from 'hooks'
import { snakeCase } from 'lodash'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { PDF_FIELD_TYPES } from 'modules/documents/document-template-form/helpers'
import { FieldOptions } from 'modules/form-designer/field-render/components'
import { SystemFieldDropdown } from 'modules/shared'
import { useCreateSystemFieldMutation } from 'modules/system-fields/system-fields-api'
import { useCallback, useState } from 'react'
import Select from 'react-select'
import { Button } from 'theme/ui/forms'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'
import ToolTip from 'theme/ui/tool-tip'
import { createSelectOption } from 'utilities/helpers'
import UserDropdown from '../../upload-document/field-mapping/user-dropdown'
import BulkSettingsForm from './bulk-setting-form'

const FieldSettingsForm = ({
	field,
	selectedFields,
	fieldName,
	onSave,
	userOptions,
	onClose,
	isAutoSaving,
	isSignatureField = false,
	isTimestampField = false,
	linkedSignatureUser = null,
}) => {
	const isBulk = selectedFields.length > 1
	const [settings] = useState(field)
	const isMultipleFields = field.original_name.includes('Multiple fields')
	const { team } = useUser()
	const [createSystemField] = useCreateSystemFieldMutation()
	const [bulkValues, setBulkValues] = useState({
		user: null,
		is_required: false,
		is_document_bound: false,
	})
	const formik = useFormikContext() // Access Formik context

	const isOptionAbleField =
		field?.type === 'radio' ||
		field?.type === 'dropdown' ||
		field?.type === 'select' ||
		field?.type === 'list' ||
		field?.type === 'combobox'

	const isRadioOrCheckbox =
		field?.type === 'radio' ||
		field?.type === 'checkbox' ||
		field?.type === 'dropdown' ||
		field?.type === 'select' ||
		field?.type === 'list' ||
		field?.type === 'combobox'

	const getOptionDisabled = (option) => {
		if (isRadioOrCheckbox) {
			return !['radio', 'checkbox', 'dropdown', 'select', 'list', 'combobox'].includes(
				option.value,
			)
		} else {
			return ['radio', 'checkbox', 'dropdown', 'select', 'list', 'combobox'].includes(
				option.value,
			)
		}
	}

	const optionError = getIn(formik?.errors, `${fieldName}.values`)

	const onCreateOption = async (value) => {
		const team_ids = [team?.id]
		const payload = {
			documentFields: {
				field_name: value,
				is_system_field: team_ids.length > 0 ? false : true,
				field_type: field?.type,
			},
		}
		await createSystemField(payload)
	}

	const isDisabled = field?.is_new ? false : true

	// Function to find the linked timestamp field index
	const getLinkedTimestampIndex = () => {
		if (isSignatureField) {
			const timestampField = formik.values.document_mapping.find((f) => {
				return f.linked_to === field.original_name && f.type === 'timestamp'
			})

			if (timestampField) {
				return formik.values.document_mapping.findIndex(
					(f) => f.pdf_field === timestampField.pdf_field,
				)
			}
		}
		return -1
	}

	const mapping_field = getIn(formik?.values, fieldName)
	const options = Array.isArray(mapping_field?.values) ? mapping_field?.values : []

	// function to add options to select, checkbox, and radio fields.
	const onAddOption = useCallback(
		(name, field, isOther = false) => {
			if (isOther) {
				const otherIndex = options.findIndex((option) => option.value === 'other')
				if (otherIndex === -1) {
					options.push({ selected: false, label: 'Other', value: 'other' })
				}
			} else {
				const newOption = {
					selected: false,
					label: `Option ${options.length + 1}`,
					value: `option-${options.length + 1}`,
				}
				const otherIndex = options.findIndex((option) => option.value === 'other')
				if (otherIndex !== -1) {
					options.splice(otherIndex, 0, newOption)
				} else {
					options.push(newOption)
				}
			}

			formik.setFieldValue(`${name}.values`, options)
		},
		[options, formik],
	)

	// function to remove options to select, checkbox, and radio fields.
	const onRemoveOption = useCallback(
		(name, field, index) => {
			if (index >= 0 && index < options.length) {
				const updatedOptions = options.filter((_, i) => i !== index)
				formik.setFieldValue(`${name}.values`, updatedOptions)
			} else {
				console.warn(`Invalid index ${index}. Unable to remove option.`)
			}
		},
		[options, formik],
	)

	// function to re-arrange the options order
	const onDragEnd = useCallback(
		(fromIndex, toIndex) => {
			const option = options.splice(fromIndex, 1)[0]
			options.splice(toIndex, 0, option)
			formik.setFieldValue(`${fieldName}.values`, options)
		},
		[options, formik],
	)

	return (
		<div className='flex flex-col gap-3'>
			{!isMultipleFields && (
				<div className={classNames('min-w-[140px]')}>
					<label
						className={classNames(
							'mb-2 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
						)}>
						Field Type
					</label>
					<Field name={`${fieldName}.type`}>
						{({ field, form }) => (
							<Select
								{...field}
								value={field?.value ? createSelectOption(field?.value) : null}
								options={PDF_FIELD_TYPES}
								onChange={(option) =>
									form.setFieldValue(field?.name, option?.value)
								}
								isDisabled={isDisabled || isSignatureField || isTimestampField}
								isSearchable={false}
								isClearable={false}
								isOptionDisabled={getOptionDisabled}
								isMulti={field?.multiple}
								placeholder={'Field Type'}
								classNamePrefix={'select-dropdown'}
								className={classNames('select-dropdown-container')}
								styles={{
									menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
									menu: (provided) => ({ ...provided, zIndex: 9999 }),
								}}
								menuPortalTarget={document.body}
								menuPosition={'fixed'}
								components={{
									DropdownIndicator: DropdownIndicator,
								}}
							/>
						)}
					</Field>
				</div>
			)}
			{!isMultipleFields && !isSignatureField && !isTimestampField ? (
				<SystemFieldDropdown
					label='System Field'
					name={`${fieldName}.field`}
					onCreateOption={onCreateOption}
				/>
			) : undefined}

			{!isMultipleFields && isOptionAbleField ? (
				<Disclosure>
					{({ open }) => (
						<div className='space-y-2'>
							<Disclosure.Button className='flex w-full justify-between  py-2 text-left text-sm border-transparent border-b hover:border-gray-300 focus:outline-none rounded-sm'>
								<span className='flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]'>
									Options ({options.length})
								</span>
								{open ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
							</Disclosure.Button>
							{!optionError ? (
								<span className='text-red-500 text-sm text-left'>
									{optionError}
								</span>
							) : undefined}
							<Disclosure.Panel className=''>
								<FieldOptions
									showHeading={true}
									field={field}
									name={fieldName}
									onAddOption={onAddOption}
									onDragEnd={onDragEnd}
									onRemoveOption={onRemoveOption}
									options={options}
									is_scored={field?.is_scored}
								/>
							</Disclosure.Panel>
						</div>
					)}
				</Disclosure>
			) : undefined}

			{isBulk ? (
				<>
					<BulkSettingsForm
						userOptions={userOptions}
						bulkValues={bulkValues}
						setBulkValues={setBulkValues}
					/>
				</>
			) : (
				<>
					<Field
						type='hidden'
						name={`${fieldName}.pdf_field`}
						value={field?.pdf_field}
						component='input'
					/>
					<UserDropdown
						label='Fillable By'
						name={`${fieldName}.user`}
						options={userOptions}
						isDisabled={isTimestampField && field?.linked_to}
						initialValue={
							isTimestampField && linkedSignatureUser ? linkedSignatureUser : null
						}
						onChange={(selected) => {
							if (isTimestampField && field.linked_to) {
								// Sync timestamp change to signature
								const linkedFieldIdx = formik.values.document_mapping.findIndex(
									(f) => f.pdf_field === field.linked_to,
								)
								if (linkedFieldIdx !== -1) {
									formik.setFieldValue(
										`document_mapping[${linkedFieldIdx}].user`,
										selected,
									)
								}
							} else if (isSignatureField) {
								// Sync signature change to timestamp
								const linkedTimestampIdx = getLinkedTimestampIndex()

								if (linkedTimestampIdx !== -1) {
									formik.setFieldValue(
										`document_mapping[${linkedTimestampIdx}].user`,
										selected,
									)
								}
							}
						}}
					/>
					<div className='grid grid-cols-1 gap-2'>
						<Field name={`${fieldName}.is_document_bound`}>
							{({ field, form }) => (
								<div className='flex flex-wrap gap-2'>
									<input
										{...field}
										checked={
											isSignatureField || isTimestampField
												? true
												: field?.value || false
										}
										className='form-check-input'
										id={`${fieldName}.is_document_bound`}
										type='checkbox'
										disabled={isSignatureField || isTimestampField}
										onChange={(e) => {
											if (!(isSignatureField || isTimestampField)) {
												form.setFieldValue(field.name, e.target.checked)
											}
										}}
									/>
									<div className='flex items-center'>
										<label
											htmlFor={`${fieldName}.is_document_bound`}
											className='!mb-0 cursor-pointer'>
											Document Specific
										</label>
										<div
											id={`${snakeCase(fieldName)}_info`}
											className='ml-1 cursor-pointer'>
											<i className='bx bx-info-circle mb-0 mt-1 text-sm text-gray-400' />
											<ToolTip
												placement='top'
												tooltipText={`Switch on if the value of this field is exclusively
												saved within the scope of this document and not at a
												client&lsquo;s profile scope.`}
												targetId={`${snakeCase(fieldName)}_info`}
											/>
										</div>
									</div>
								</div>
							)}
						</Field>
						{!isTimestampField && (
							<Field name={`${fieldName}.is_required`}>
								{({ field }) => (
									<div className='flex flex-wrap gap-2'>
										<input
											{...field}
											checked={field?.value || false}
											type='checkbox'
											className='form-check-input'
											id={`${fieldName}.is_required`}
										/>
										<label
											className='!mb-0 cursor-pointer'
											htmlFor={`${fieldName}.is_required`}>
											Required
										</label>
									</div>
								)}
							</Field>
						)}
					</div>
				</>
			)}

			<div className='grid grid-cols-2 gap-2'>
				<Button onClick={onClose} variant='white' block>
					Cancel
				</Button>
				{isBulk ? (
					<Button onClick={() => onSave(bulkValues)} disabled={!bulkValues?.user} block>
						Done
					</Button>
				) : (
					<Button
						onClick={() => onSave(settings)}
						isLoading={isAutoSaving}
						isLoadingText='Saving...'
						block>
						Done
					</Button>
				)}
			</div>
		</div>
	)
}

export default FieldSettingsForm
