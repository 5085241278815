import { useAppRouter } from 'hooks'
import { NotFound } from 'modules/error-sections'
import BoardEditContainer from 'modules/scrum-boards/board-edit'
import { useLazyGetBoardQuery } from 'modules/scrum-boards/boards-api'
import { BOARD_ROUTES } from 'modules/scrum-boards/routes'
import { useEffect } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import Spinner from 'theme/ui/spinner'

const BoardEdit = () => {
	const { params } = useAppRouter()
	const boardId = params?.boardId
	const [fetchBoard, { data, isUninitialized, isLoading }] = useLazyGetBoardQuery()
	useEffect(() => {
		if (boardId) {
			fetchBoard(boardId)
		}
	}, [boardId])

	const board = data?.data

	return (
		<AppLayout
			meta={{
				title: board?.name ? `Settings for ${board?.name}` : '',
				hasBackButton: true,
				redirectTo: BOARD_ROUTES.findOne(board?.id),
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<div className='page-body'>
						{isUninitialized || isLoading ? (
							<Spinner />
						) : board ? (
							<BoardEditContainer board={board} />
						) : (
							<NotFound />
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default BoardEdit
