import * as yup from 'yup'
import SendEmailForm from './send-email-form'

export const validationSchema = yup.object().shape({
	to: yup
		.array(
			yup.object({
				label: yup
					.string()
					.email('Please enter valid email address')
					.required('Please enter address'),
				value: yup.string().required('Please enter address'),
			}),
		)
		.min(1, 'Please add atleast one email address')
		.required('Please enter a valid email address'),
	subject: yup.string().required('Please enter subject'),
	body: yup.string().required('Please enter message'),
})

export const getPayload = (values) => {
	return {
		...values,
		to: Array.isArray(values?.to) ? values?.to.map((t) => t?.value) : [],
	}
}

export default SendEmailForm
