import { Field } from 'formik'
import { Fragment } from 'react'

import { SwitchField } from 'theme/ui/forms'

const MultipleToggleField = ({ name }) => {
	return (
		<Fragment>
			<Field component={SwitchField} label='Multiple Choice?' name={`${name}.multiple`} />
		</Fragment>
	)
}

export default MultipleToggleField
