import { createColumnHelper } from '@tanstack/react-table'
import { getDocumentIcon } from 'modules/file-manager/my-documents-listing'
import { useState } from 'react'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import Actions from '../actions'

const ReadymadeTemplateExpandableTable = ({ meta, isLoading, pagination, data, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'expander',
			size: 10,
			header: () => null,
			cell: ({ row, getValue }) => {
				return row.getCanExpand() &&
					getValue()?.children?.length > 0 &&
					getValue()?.type === 'folder' ? (
					<button
						{...{
							onClick: row.getToggleExpandedHandler(),
							style: { cursor: 'pointer' },
						}}>
						{row.getIsExpanded() ? (
							<i className='uil-angle-down mt-1 align-middle text-[15px]'></i>
						) : (
							<i className='uil-angle-right mt-1 align-middle text-[15px]'></i>
						)}
					</button>
				) : (
					<span className='w-[15px]'></span>
				)
			},
		}),

		columnHelper.accessor((row) => row, {
			id: 'info',
			size: 270,
			header: () => <span>Title</span>,
			cell: (info) => (
				<div className='flex items-center'>
					{getDocumentIcon(info.getValue()?.type)}
					<span className='my-1 ml-2 text-sm font-medium'>{info.getValue()?.title}</span>

					{info.getValue()?.isStarred ? (
						<span className='ml-1'>
							<i className='bx bxs-star text-main' />
						</span>
					) : undefined}
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'dir',
			size: 170,
			header: () => <span style={{ minWidth: '60px' }}>Folder</span>,
			cell: (info) => (
				<div className='flex flex-wrap gap-1'>
					<span>{info.getValue()?.childPackages?.length || 0} Folders,</span>
					<span>{info.getValue()?.documents?.length || 0} Files</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row.updated_at, {
			id: 'updated_at',
			size: 160,
			header: () => (
				<div className='w-full text-right'>
					<span>Updated At</span>
				</div>
			),
			cell: (info) => (
				<div className='w-full justify-end text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue())}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue())}
					</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			header: () => (
				<div className='w-full text-right'>
					<span>Actions</span>
				</div>
			),
			cell: ({ getValue, row }) => (
				<Actions
					item={getValue()}
					type={getValue()?.type}
					isActionActive={
						row?.original?.uuid
							? actionActiveId === row?.original?.uuid
							: actionActiveId === row?.original?.id
					}
				/>
			),
		}),
	]

	const renderSubComponent = ({ row }) => {
		const children = Array.isArray(row?.original?.children) ? row?.original?.children : []

		return (
			<div
				style={{
					paddingLeft: '50px',
					backgroundColor: '#f5f6f8',
				}}>
				{children.length > 0 && (
					<DataTable
						columns={columns}
						data={children}
						hideHeader
						meta={null}
						isLoading={false}
						isCompact={true}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						variant='secondary'
						getRowCanExpand={() => true}
						isOverflow={false}
						isChildren
						renderSubComponent={renderSubComponent}
					/>
				)}
			</div>
		)
	}

	return (
		<DataTable
			columns={columns}
			data={data}
			meta={meta}
			isLoading={isLoading}
			setSorting={setSorting}
			sorting={sorting}
			variant='secondary'
			pagination={pagination}
			setPagination={setPagination}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			getRowCanExpand={() => true}
			isOverflow={false}
			renderSubComponent={renderSubComponent}
		/>
	)
}

export default ReadymadeTemplateExpandableTable
