import { Star } from 'lucide-react'
import React from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

const RatingResponse = ({ q }) => {
	const starColor = q.average >= 3 ? 'text-blue-600' : 'text-red-500'

	return (
		<div className='flex justify-between items-center p-4'>
			{/* Left: Rating Summary */}
			<div className='flex flex-col items-center w-1/2'>
				<p className={`text-4xl font-bold ${starColor}`}>{q.average.toFixed(2)}</p>
				<p className='text-gray-500 text-sm'>Average Rating</p>
				<div className='flex mt-2'>
					{[...Array(5)].map((_, i) => (
						<Star
							key={i}
							className={`w-6 h-6 ${i < Math.round(q.average) ? starColor : 'text-gray-300'}`}
							fill={i < Math.round(q.average) ? 'currentColor' : 'none'}
						/>
					))}
				</div>
			</div>

			{/* Right: Bar Chart */}
			<div className='w-1/2'>
				<ResponsiveContainer width='100%' height={150}>
					<BarChart data={q.ratings} layout='vertical'>
						<YAxis
							type='category'
							dataKey='level'
							width={50}
							tick={{ fill: '#4A4A4A' }}
							interval={0} // Show all levels
						/>
						<XAxis type='number' hide />
						<Bar dataKey='count' fill='#667CFF'  barSize={12} />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default RatingResponse
