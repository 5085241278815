import { Field, Form, FormikProvider, useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { useAppRouter, useUser } from 'hooks'
import { useLazyCheckSubscriptionLimitQuery } from 'modules/authentication/auth-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import UpdateSubscriptionPopup from 'modules/profile/my-subscriptions/update-subscription-popup/update-subscription-popup'
import { Button } from 'theme/ui/forms'
import { useDebounce } from 'use-debounce'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { getFormValues, getPlaceholder, searchTypes } from '.'
import { FILE_MANAGER_ROUTES } from '../routes'
import CreateFolder from './create-folder/create-folder'
import SearchItemDropdown from './search-item-dropdown'

const SearchBar = () => {
	const { location, navigate, parseQuery } = useAppRouter()
	const { isSuperAdmin } = useUser()
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const { user } = useUser()

	const [checkSubscriptionLimit, { isLoading: isFetching, data: limit }] =
		useLazyCheckSubscriptionLimitQuery()
	const currentStatus = searchTypes.find((t) => t.value === parseQuery?.status)

	const formik = useFormik({
		initialValues: {
			search: parseQuery?.search || '',
			status: !parseQuery?.status ? searchTypes[0] : currentStatus,
		},
		enableReinitialize: true,
	})

	const formValues = useMemo(() => getFormValues(formik?.values), [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)

	const onSearch = useCallback(
		async (debounceValues) => {
			try {
				if (location.pathname !== '/file-manager/search') {
					localStorage.setItem('redirectBack', location.pathname)
				}
				const payload = debounceValues
				navigate(`${FILE_MANAGER_ROUTES.search()}?${toQueryString(payload)}`)
			} catch (error) {
				notification('error', error?.message)
			}
		},
		[debounceValues],
	)

	useEffect(() => {
		if (Object.keys(debounceValues).length) {
			onSearch(debounceValues)
		} else if (
			Object.keys(debounceValues).length === 0 &&
			location.pathname === '/file-manager/search'
		) {
			const redirectBack = localStorage.getItem('redirectBack')
			navigate(redirectBack)
			localStorage.setItem('redirectBack', null)
		}
	}, [debounceValues])

	useEffect(() => {
		if (user?.id) {
			checkSubscriptionLimit('document')
		}
	}, [user?.id])

	const [isPricingOpen, setIsPricingOpen] = useState(false)

	const handleClick = () => {
		if (limit?.data?.availableLimit <= 0) {
			setIsPricingOpen(true)
		} else {
			navigate(DOCUMENT_TEMPLATE_APP_ROUTES.create())
		}
	}

	return (
		<div className='doc_listing_flex page-body flex items-center justify-between gap-3 !border bg-gray-50 !py-[15px]'>
			{isOpen && (
				<UpdateSubscriptionPopup
					isOpen={isPricingOpen}
					onClose={() => setIsPricingOpen(false)}
					title='Upgrade Your Plan'
					description='You’ve reached the document creation limit for your current subscription. Upgrade your plan to create more documents.'
				/>
			)}
			<FormikProvider value={formik}>
				<Form className='alignment_holder flex-1'>
					<div className='alignment_holder flex h-[56px] w-full max-w-[512px] items-center justify-between space-x-1 rounded bg-white px-2.5 shadow'>
						<div className='px-2'>
							<SearchIcon height={20} width={20} />
						</div>
						<Field name='search'>
							{({ field }) => (
								<input
									type='search'
									{...field}
									autoComplete='off'
									className='!w-full flex-1 truncate px-1 py-2 text-base font-normal text-gray2-dark'
									placeholder={getPlaceholder(formValues?.status)}
								/>
							)}
						</Field>

						{/* <div className='flex items-center space-x-3'>
							{formik?.values?.search !== '' ? (
								<Button
									type='button'
									variant='ghost'
									onClick={() => formik.setFieldValue('search', '')}>
									<CrossIcon height={16} width={16} />
								</Button>
							) : undefined}

							</div> */}
						<SearchItemDropdown formik={formik} />
					</div>
				</Form>
			</FormikProvider>
			<CreateFolder onClose={onClose} isOpen={isOpen} type={'folder'} />
			{[FILE_MANAGER_ROUTES.allFolders()].includes(location?.pathname) ? (
				<div className='rounded bg-white'>
					<Button
						type='button'
						onClick={() => setIsOpen(true)}
						variant='primary-outline'
						className='bg-white '>
						<div className='flex items-center space-x-2 font-medium'>
							<span className='text-base'>+</span>
							<span>Create Folder</span>
						</div>
					</Button>
				</div>
			) : undefined}

			{!isSuperAdmin &&
			[FILE_MANAGER_ROUTES.myDocuments(), FILE_MANAGER_ROUTES.search()].includes(
				location?.pathname,
			) ? (
				<div className='rounded bg-white'>
					<Button
						type='button'
						variant='primary-outline'
						onClick={handleClick}
						disabled={isFetching}
						className='bg-white '>
						<div className='flex items-center space-x-2 font-medium'>
							<span className='text-base'>+</span>
							<span>Create Document</span>
						</div>
					</Button>
				</div>
			) : undefined}

			{isSuperAdmin &&
			[FILE_MANAGER_ROUTES.readyMadeTemplate()].includes(location?.pathname) ? (
				<div className='rounded bg-white'>
					<Button
						type='button'
						variant='primary-outline'
						onClick={() => navigate(DOCUMENT_TEMPLATE_APP_ROUTES.create())}
						className='bg-white '>
						<div className='flex items-center space-x-2 font-medium'>
							<span className='text-base'>+</span>
							<span>Create Document</span>
						</div>
					</Button>
				</div>
			) : undefined}
		</div>
	)
}

export default SearchBar
