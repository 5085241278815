import { useEffect, useMemo, useState } from 'react'
import { Button } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { useLazyGetSftpListQuery } from './sftp-config-api'
import SftpConfigForm from './sftp-config-form/sftp-config-form'
import SftpList from './sftp-list'

const SftpConfig = () => {
	const [isOpen, setIsOpen] = useState(false)
	const handleForm = () => {
		setIsOpen(!isOpen)
	}

	const [fetchMySftpList, { data, isLoading }] = useLazyGetSftpListQuery()

	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { pageIndex, pageSize } = pagination
	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		return obj
	}, [pageIndex, pageSize])

	useEffect(() => {
		fetchMySftpList(filters)
	}, [])

	const { meta, sftpData } = useMemo(
		() => ({
			sftpData: data?.data || [],
			meta: data?.meta,
		}),
		[data],
	)

	return (
		<div>
			<SftpConfigForm isOpen={isOpen} setIsOpen={setIsOpen} handleForm={handleForm} />
			<div className='mb-3 flex justify-end'>
				{isAllowTo(permissions?.sftp?.create) ? (
					<Button type='button' onClick={handleForm}>
						Create New
					</Button>
				) : undefined}
			</div>
			<SftpList
				meta={meta}
				isLoading={isLoading}
				sftpData={sftpData}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default SftpConfig
