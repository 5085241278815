import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import FilePreviewPopup from 'theme/ui/file-preview-popup'
import ToolTip from 'theme/ui/tool-tip'
import RenameFilePopup from './rename-file-popup'

const Actions = ({ file, workflowFile }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isEditOpen, setIsEditOpen] = useState(false)
	const [url, setUrl] = useState(null)

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}
	const ID = file?.id

	return (
		<Fragment>
			<FilePreviewPopup
				path={url}
				toggle={toggle}
				isOpen={isOpen}
				mime_type={file?.mime_type}
			/>
			<RenameFilePopup
				workflowFile={workflowFile}
				isOpen={isEditOpen}
				onClose={() => setIsEditOpen(false)}
			/>
			<ToolTip targetId={`file-edit-tooltip-${ID}`} tooltipText='Rename' placement='top' />
			<ToolTip targetId={`file-view-tooltip-${ID}`} tooltipText='View' placement='top' />
			<ToolTip
				targetId={`file-download-tooltip-${ID}`}
				tooltipText='Download'
				placement='top'
			/>
			<div className='flex space-x-1'>
				<button
					type='button'
					id={`file-edit-tooltip-${ID}`}
					onClick={() => setIsEditOpen(true)}
					className='action-btn btn-primary-outline'>
					<i className='uil-pen' style={{ fontSize: 16 }} />
				</button>
				<button
					type='button'
					id={`file-view-tooltip-${ID}`}
					onClick={() => toggle(file?.path)}
					className='action-btn btn-primary-outline'>
					<i className='uil uil-eye' style={{ fontSize: 16 }} />
				</button>
				<Link
					to={file?.path}
					id={`file-download-tooltip-${ID}`}
					className='action-btn btn-primary-outline'
					target='_blank'>
					<i className='uil uil-import' style={{ fontSize: 16 }} />
				</Link>
			</div>
		</Fragment>
	)
}

export default Actions
