/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { getModuleName } from '.'
import Spinner from 'theme/ui/spinner'
import apiEndpoints from '../../../../helpers/apiEndpoints'
import * as actions from '../../../../store/common/actions'

const LoginHistory = ({ client }) => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const getLoginHistory = () => {
		setLoading(true)
		dispatch(actions.all(`${apiEndpoints.clients}/login-history/${client?.id}?page=1&limit=5`))
			.then((response) => {
				setLoading(false)

				if (response?.status === 200 && Array.isArray(response?.data?.userLoginHistory)) {
					const $data = response?.data?.userLoginHistory
					setData($data)
				}
			})
			.catch(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (client?.id) {
			getLoginHistory()
		}
	}, [client])

	return (
		<Fragment>
			<div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] px-4 py-3'>
					<h5 className='mb-0 font-bold'>Login History</h5>
				</div>
				<div className='card-body p-0'>
					{loading ? (
						<div className='flex items-center justify-center py-3'>
							<Spinner label='Loading...' />
						</div>
					) : data.length > 0 ? (
						<div className='flex flex-col divide-y'>
							{data.map((history, idx) => (
								<div key={idx} className='flex px-4 py-3'>
									<strong className='w-[30px]'>{idx + 1}.</strong>
									<span>
										{moment(history.login_at).format('MMMM DD, yy, hh:mm A')}
									</span>
								</div>
							))}
						</div>
					) : (
						<div className='flex items-center justify-center py-3'>
							<p className='m-0 text-center text-gray-400'>No login history found</p>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default LoginHistory
