import { useUploadFileMutation } from 'modules/files/file-apis'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Button } from 'theme/ui/forms'

import notification from 'utilities/notification'

const UploadSignatureImage = ({ onChange, name }) => {
	const [files, setFiles] = useState([])
	const [uploadSignature, { isLoading }] = useUploadFileMutation()
	const onRemove = () => setFiles([])
	const onDropAccepted = (files) => {
		setFiles(
			files.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				}),
			),
		)
	}
	const onDropRejected = () => {
		return notification('warn', 'Kindly choose only .png file formats in this section.')
	}
	const file = files[0]
	const onSetSignature = async (file) => {
		try {
			const formData = new FormData()
			formData.append('file', file)
			const response = await uploadSignature({
				payload: formData,
			}).unwrap()
			onChange(name, response?.data)
		} catch (error) {
			//
		}
	}

	return (
		<div>
			{file ? (
				<div>
					<div className='flex cursor-pointer flex-col items-center rounded-lg !border border-dashed text-center transition-all hover:border-main hover:bg-main/10 active:bg-main/20 disabled:!cursor-not-allowed max-h-[153px]'>
						<img
							src={file.preview}
							className='block max-h-[153px] max-w-full w-auto'
							onLoad={() => {
								URL.revokeObjectURL(file.preview)
							}}
						/>
					</div>
				</div>
			) : (
				<Dropzone
					onDropAccepted={onDropAccepted}
					onDropRejected={onDropRejected}
					accept={['image/png']}
					disabled={files.length > 0}
					maxFiles={1}>
					{({ getRootProps, getInputProps }) => (
						<div
							className='flex cursor-pointer flex-col items-center rounded-lg !border border-dashed p-6 text-center transition-all hover:border-main hover:bg-main/10 active:bg-main/20 disabled:!cursor-not-allowed max-h-[153px]'
							{...getRootProps()}>
							<input {...getInputProps()} multiple={false} />
							<div className='mb-3'>
								<svg
									fill='rgb(91 115 232 / 0.5)'
									width='30px'
									height='30px'
									viewBox='0 0 32 32'
									version='1.1'
									xmlns='http://www.w3.org/2000/svg'>
									<title>upload-cloud</title>
									<path d='M0 16v-1.984q0-3.328 2.336-5.664t5.664-2.336q1.024 0 2.176 0.352 0.576-2.752 2.784-4.544t5.056-1.824q3.296 0 5.632 2.368t2.368 5.632q0 0.896-0.32 2.048 0.224-0.032 0.32-0.032 2.464 0 4.224 1.76t1.76 4.224v2.016q0 2.496-1.76 4.224t-4.224 1.76h-0.384q0.288-0.8 0.352-1.44 0.096-1.312-0.32-2.56t-1.408-2.208l-4-4q-1.76-1.792-4.256-1.792t-4.224 1.76l-4 4q-0.96 0.96-1.408 2.24t-0.32 2.592q0.032 0.576 0.256 1.248-2.72-0.608-4.512-2.784t-1.792-5.056zM10.016 22.208q-0.096-0.96 0.576-1.6l4-4q0.608-0.608 1.408-0.608 0.832 0 1.408 0.608l4 4q0.672 0.64 0.608 1.6-0.032 0.288-0.16 0.576-0.224 0.544-0.736 0.896t-1.12 0.32h-1.984v6.016q0 0.832-0.608 1.408t-1.408 0.576-1.408-0.576-0.576-1.408v-6.016h-2.016q-0.608 0-1.088-0.32t-0.768-0.896q-0.096-0.288-0.128-0.576z'></path>
								</svg>
							</div>
							<h4 className='!mb-1 text-lg text-gray-800'>Upload signature</h4>
							<p className='!m-0 text-gray-500'>
								Drag & drop a signature image or a scan
							</p>
							<p className='!m-0 text-xs text-gray-400'>
								Only .png files are allowed to upload
							</p>
						</div>
					)}
				</Dropzone>
			)}
			<div className='mt-2 flex justify-center space-x-3'>
				<Button
					type='button'
					onClick={onRemove}
					disabled={!file}
					variant='danger-outline'
					size='sm'>
					Clear
				</Button>
				<Button
					type='button'
					disabled={!file}
					isLoading={isLoading}
					isLoadingText='Uploading...'
					onClick={() => onSetSignature(file)}
					variant='primary-outline'
					size='sm'>
					Confim & Upload
				</Button>
			</div>
		</div>
	)
}

export default UploadSignatureImage
