import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import Actions from './actions'

const CompletedDocumentsTable = ({
	documents,
	meta,
	isLoading,
	pagination,
	setPagination,
	toggle,
}) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		// columnHelper.accessor('index', {
		// 	size: 20,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),

		columnHelper.accessor((row) => row.title, {
			id: 'title',
			size: 200,
			header: () => <span>Document Title</span>,
			cell: (info) => (
				<span className='line-clamp-1 text-sm font-bold !text-gray-700'>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.workflow_title, {
			id: 'workflow_title',
			size: 200,
			header: () => <span>Workflow Title</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm font-medium !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.advisor, {
			id: 'advisor',
			size: 150,
			header: () => <span>Advisor</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm capitalize !text-gray-500'
					title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.final_reviewer, {
			id: 'final_reviewer',
			size: 150,
			header: () => <span>Final Reviewer</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm capitalize !text-gray-500'
					title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.client, {
			id: 'client',
			size: 120,
			header: () => <span>Client</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm capitalize !text-gray-500'
					title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'updated_at',
			size: 200,
			header: () => <span>Updated At</span>,
			cell: (info) => (
				<div className='w-full justify-end'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue()?.updated_at)}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue()?.updated_at)}
					</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 100,
			header: () => (
				<div className='w-full text-center'>
					<span>Actions</span>
				</div>
			),
			cell: (info) => (
				<Actions
					index={info.getValue()?.index}
					toggle={toggle}
					document={info?.row?.original}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={documents}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default CompletedDocumentsTable
