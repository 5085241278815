import { FIELD_MODE } from '../../helpers'
import DateFieldEdit from './date-field-edit'
import DateFieldView from './date-field-view'

const DateField = ({
	field,
	mode = 'edit',
	name,
	onChange,
	onDateChange,
	is_read_mode,
	userOptions,
}) => {
	switch (mode) {
		case FIELD_MODE.EDIT:
			return (
				<DateFieldEdit
					name={name}
					onDateChange={onDateChange}
					field={field}
					userOptions={userOptions}
				/>
			)

		case FIELD_MODE.VIEW:
			return (
				<DateFieldView
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
				/>
			)
	}
}

export default DateField
