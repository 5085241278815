import { Fragment } from 'react'
import ClientMappingForm from './client-mapping-form'

const ClientMappingContainer = () => {
	return (
		<Fragment>
			<div className='w-full rounded-md !border bg-white p-4 shadow-sm'>
				<div className='mb-4 rounded-md bg-blue-50 p-3'>
					<h2 className='text-lg font-semibold text-gray-700'>
						Experivise Clients - Custodian Clients Mapping
					</h2>
					<p className='text-sm text-gray-600'>
						This enables seamless mapping of clients’ investment information from
						Custodian SFTP files to their profiles on the Experivise platform.
					</p>
				</div>
				<ClientMappingForm />
			</div>
		</Fragment>
	)
}

export default ClientMappingContainer
