import * as yup from 'yup'
export { default as PublicForm } from './public-form'

export const validationSchema = yup.object().shape({
	fields: yup.array().of(
		yup.object().shape({
			value: yup
				.mixed()
				.nullable()
				.when('required', {
					is: true,
					then: (schema) => schema.required('This field is required.'),
					otherwise: (schema) => schema.notRequired(),
				}),
		}),
	),
})

export const getPayload = (values, formData) => {
	const payload = {
		document_template_id: formData?.document_template_id,
		title: formData?.title,
		team_id: formData?.team_id,
		document_body: values?.fields,
	}
	return payload
}
