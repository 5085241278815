import { Trash, Undo } from 'lucide-react'
import React from 'react'

const MultiSelectToolbar = ({
	selectedFields,
	updateSelectedFields,
	onClearSelectedFields,
	selected_deleted_field,
	selected_available_field,
	onRestore,
}) => {
	return (
		<div className='fixed bottom-5 left-1/2 -translate-x-1/2 bg-[#4A6EE0] text-white px-3 py-2 rounded-lg shadow-lg flex items-center gap-4 z-[990]'>
			<span>{selectedFields.length} fields selected</span>
			<div className='h-5 w-px bg-white/50' />
			{selected_available_field.length > 0 && (
				<button
					type='button'
					onClick={() => updateSelectedFields(true)}
					className='bg-transparent text-white border-none cursor-pointer flex items-center gap-1 p-1.5 px-2.5 rounded'>
					<Trash size={14} /> <span>Delete ({selected_available_field.length})</span>
				</button>
			)}
			{selected_deleted_field.length > 0 && (
				<button
					type='button'
					onClick={onRestore}
					className='bg-transparent text-white border-none cursor-pointer flex items-center gap-1 p-1.5 px-2.5 rounded'>
					<Undo size={14} /> Restore ({selected_deleted_field.length})
				</button>
			)}
			<button
				type='button'
				onClick={onClearSelectedFields}
				className='bg-white/20 text-white border-none cursor-pointer flex items-center gap-1 p-1.5 px-2.5 rounded'>
				Clear
			</button>
		</div>
	)
}

export default MultiSelectToolbar
