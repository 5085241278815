import { SignatureFieldView } from 'modules/form-designer/field-render/signature-field'
import React from 'react'
import { Field } from 'redux-form'

const SignatureField = ({ field, validate, name, isWorkflowView, disabled }) => {
	return (
		<Field
			name={name}
			label={field?.label}
			validate={validate}
			component={(props) => {
				// console.log('props', props, field)
				const { input, meta } = props
				const { touched, error } = meta

				return (
					<>
						<SignatureFieldView
							disabled={disabled}
							isWorkflowView={isWorkflowView}
							field={{
								...field,
								value: isWorkflowView ? input?.value : input?.value?.value,
							}}
							onChange={async (name, value) => {
								input.onChange({
									value: value,
									signature_type: field?.signature_type,
								})
							}}
							onSignatureTypeChange={(name, type) => {
								input.onChange({ ...input.value, signature_type: type })
							}}
						/>
						{touched && error ? (
							<span className=' mt-3 ms-[9rem] w-full text-[87.5%]  text-[#f46a6a]'>
								{error}
							</span>
						) : undefined}
					</>
				)
			}}
		/>
	)
}

export default SignatureField
