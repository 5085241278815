import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	currentOnboardingStatus: true,
	onBoardStatus: 0,
	isLoading: false,
	steps: [],
	data: {},
}

export const OnBoardStatusSlice = createSlice({
	name: 'onBoardStatus',
	initialState,
	reducers: {
		setOnBoardStatus: (state, action) => {
			state.onBoardStatus = action.payload
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload
		},
		setData: (state, action) => {
			state.data = action.payload
		},
	},
})
