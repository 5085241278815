import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react' // Import React to use forwardRef

const Button = React.forwardRef(
	(
		{
			type,
			isLoading,
			hideLoadingText = false,
			isLoadingText = 'Processing...',
			disabled,
			children,
			variant,
			size,
			className,
			block,
			...rest
		},
		ref, // Add ref as a parameter
	) => {
		return (
			<button
				ref={ref} // Pass the ref to the button element
				type={type}
				className={classNames(
					className,
					'h-fit min-w-fit rounded transition-all disabled:cursor-not-allowed disabled:!opacity-50',
					{
						'!border border-main bg-main text-white': variant === 'primary',
						'hover:!bg-gray-150 !border border-transparent bg-transparent text-main hover:!bg-gray-100 active:!bg-gray-200':
							variant === 'ghost',
						'hover:!bg-gray-150 !border border-transparent bg-transparent hover:!bg-gray-100 active:!bg-gray-200':
							variant === 'ghost-white',
						'!border border-main bg-transparent text-main hover:!bg-main/10 active:!bg-main/20':
							variant === 'primary-outline',
						'!border border-red-600 bg-red-600 text-white': variant === 'danger',
						'!border border-green-600 bg-green-600 text-white': variant === 'success',
						'!border border-red-600 bg-white text-red-600 hover:!bg-red-50 active:!bg-red-100':
							variant === 'danger-outline',
						'!border border-gray-300 bg-transparent text-black hover:!bg-gray-100 active:!bg-gray-300':
							variant === 'white',
						'!py-3 px-3.5 font-bold': size === 'lg',
						'px-3 py-2': size === 'md',
						'px-2.5 py-1': size === 'sm',
						'px-1.5 py-0.5 text-[13px]': size === 'xs',
					},
					block ? '!w-full' : 'max-w-fit ',
				)}
				disabled={isLoading || disabled}
				{...rest}>
				{isLoading ? (
					<div className='mx-auto flex items-center justify-center space-x-2'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 24 24'>
							<path
								fill={variant === 'primary' ? 'white' : '#5b73e8'}
								d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'
								opacity='.25'
							/>
							<path
								fill={variant === 'primary' ? 'white' : '#5b73e8'}
								d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z'>
								<animateTransform
									attributeName='transform'
									dur='0.75s'
									repeatCount='indefinite'
									type='rotate'
									values='0 12 12;360 12 12'
								/>
							</path>
						</svg>
						{!hideLoadingText ? <span>{isLoadingText}</span> : undefined}
					</div>
				) : (
					<>{children}</>
				)}
			</button>
		)
	},
)

Button.propTypes = {
	variant: PropTypes.oneOf([
		'primary',
		'primary-outline',
		'danger-outline',
		'danger',
		'success',
		'warning',
		'white',
		'ghost',
		'ghost-white',
	]),
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
	type: PropTypes.oneOf(['button', 'submit']),
	block: PropTypes.bool,
}

Button.defaultProps = {
	variant: 'primary',
	size: 'md',
	type: 'button',
	block: false,
}

Button.displayName = 'Button'

export default Button
