import { Menu, Transition } from '@headlessui/react'
import { Field } from 'formik'
import { Fragment, useMemo } from 'react'

import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevron-down.svg'
import { searchTypes } from '.'

const SearchItemDropdown = ({ name = 'status' }) => {
	const options = useMemo(() => searchTypes, [searchTypes])

	return (
		<Field name={name}>
			{(props) => {
				const { field, form } = props
				// console.log('field', field)

				return (
					<Menu as='div' className='relative inline-block text-left'>
						<Menu.Button
							as='button'
							type='button'
							className='file_searchbar_button flex w-full min-w-[112px] justify-between rounded !border bg-gray-50 px-3 py-2 text-sm font-medium text-gray2  '>
							<span>{field?.value?.label}</span>
							<span>
								<ChevronDownIcon />
							</span>
						</Menu.Button>
						<Transition
							as={Fragment}
							enter='transition ease-out duration-100'
							enterFrom='transform opacity-0 scale-95'
							enterTo='transform opacity-100 scale-100'
							leave='transition ease-in duration-75'
							leaveFrom='transform opacity-100 scale-100'
							leaveTo='transform opacity-0 scale-95'>
							<Menu.Items className='absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
								<div className='p-2'>
									{options.map((option, idx) => (
										<Menu.Item
											key={idx}
											as='button'
											type='button'
											onClick={() => {
												form.setFieldValue(field?.name, option)
												// if (option) {
												// 	const payload = getFormValues(formik?.values)
												// 	navigate(
												// 		`${FILE_MANAGER_ROUTES.search()}?${toQueryString(
												// 			payload,
												// 		)}`,
												// 	)
												// }
											}}
											className='flex w-full items-center space-x-1 rounded px-2 py-2 text-sm hover:bg-main/10 hover:text-main'>
											<span>{option?.icon}</span>
											<span className='text-[15px] font-medium'>
												{option?.label}
											</span>
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Transition>
					</Menu>
				)
			}}
		</Field>
	)
}

export default SearchItemDropdown
