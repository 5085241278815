import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

const SelectResponse = ({ q }) => {
	const COLORS = ['#8884d8', '#ff4384', '#00C49F', '#FFBB28', '#FF8042']

	let optionsArray = []

	if (q.multiple) {
		const responseCounts = new Map()

		q.responses.forEach((response) => {
			responseCounts.set(response, (responseCounts.get(response) || 0) + 1)
		})

		optionsArray = Array.from(responseCounts.entries()).map(([label, count]) => ({
			label,
			count,
		}))
	} else {
		optionsArray = Array.isArray(q.options)
			? q.options.map((opt) => ({
					label: opt.label,
					count: typeof opt.count === 'object' ? opt.count.count : opt.count,
				}))
			: []
	}


	return (
		<div className='flex justify-between w-full'>
			<div className='flex flex-col w-1/2 justify-center items-center'>
				{optionsArray.map((opt, i) => (
					<p key={i} className='flex items-center text-gray-600'>
						<span
							className='w-3 h-3 inline-block mr-2 rounded-full'
							style={{ backgroundColor: COLORS[i % COLORS.length] }}></span>
						{opt.label} <span className='ms-4'>{opt.count}</span>
					</p>
				))}
			</div>
			<div className='flex flex-col w-1/2 justify-center items-center'>
				<ResponsiveContainer width={150} height={150}>
					<PieChart>
						<Pie data={optionsArray} dataKey='count' outerRadius={50} nameKey='label'>
							{optionsArray.map((_, i) => (
								<Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
							))}
						</Pie>
						<Tooltip />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default SelectResponse
