import { Field } from 'formik'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import SftpQuery from './sftp-query'

const SftpDropdown = ({ name, selectedClients = [] }) => {
	const [search, setSearch] = useState('')

	const { clients, isLoading } = SftpQuery(search)

	const clientOptions = clients.map((client) => ({
		label: <CreateUserOptionLabel user={client} />,
		value: client.value,
		team_id: client.team_id,
		type: 'sftp',
	}))

	const filterOption = ({ value }) => !selectedClients.includes(value)

	const handleSearch = (value) => {
		setSearch(value)
	}

	return (
		<Field
			label=''
			type='select'
			name={name}
			options={clientOptions}
			isLoading={isLoading}
			onInputChange={(value) => handleSearch(value)}
			component={SelectField}
			placeholder='Select Client from Custodian Data Source'
			getOptionLabel={(option) => option.label}
			filterOption={filterOption}
		/>
	)
}

export default SftpDropdown
