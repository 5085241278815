import classNames from 'classnames'
import { CanvasProvider } from 'contexts/canvas-context'
import { useUser } from 'hooks'
import { SIGNATURE_OPTIONS } from 'modules/form-designer/helpers'
import { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Button } from 'theme/ui/forms'
import { generateUniqueId } from 'utilities/helpers'
import DrawSignature from './draw-signature'
import UploadSignatureImage from './upload-signature-image'
import UseProfileImage from './use-profile-image'

const SignatureFieldView = ({
	name,
	field,
	onChange,
	isWorkflowView = false,
	isDocument = false,
	disabled = false,
	setSignatureId,
}) => {
	const tooltipId = generateUniqueId()
	const { user } = useUser()
	const filteredSignatureOptions = !user?.avatar?.path
		? SIGNATURE_OPTIONS.filter((option) => option.value !== 'use-profile-image')
		: SIGNATURE_OPTIONS
	const [activeTab, setActiveTab] = useState(filteredSignatureOptions[0]?.value)
	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
			// onSignatureTypeChange(name, tab)
		}
	}
	return (
		<div className='form-group '>
			{field?.label && !isWorkflowView ? (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}
			{field?.description && !isWorkflowView ? (
				<span className='mb-2 text-[13px] text-gray-400'>{field?.description}</span>
			) : undefined}

			<div className='max-w-[430px]'>
				{field?.value ? (
					<div>
						<div className='flex flex-col items-center rounded-lg !border border-dashed p-6 text-center transition-all  disabled:!cursor-not-allowed'>
							<img
								// src={URL.createObjectURL(field?.value)}
								src={field?.value?.path || field?.value}
								className='block h-full w-auto'
								// onLoad={() => {
								// 	URL.revokeObjectURL(file.preview)
								// }}
							/>
						</div>
						{!disabled && !isWorkflowView ? (
							<div className='mt-2 flex justify-center space-x-3'>
								<Button
									type='button'
									onClick={() => onChange(name, null)}
									variant='danger-outline'
									size='sm'>
									Clear
								</Button>
							</div>
						) : undefined}
					</div>
				) : !isWorkflowView && !disabled ? (
					<>
						<Nav tabs className='nav-tabs-custom !flex flex-nowrap'>
							{filteredSignatureOptions.map((tab, idx) => (
								<NavItem key={idx}>
									<NavLink
										className={classNames('px-2 font-medium', {
											active: activeTab === tab?.value,
										})}
										onClick={() => toggleTab(tab?.value)}>
										<span className='d-sm-block d-none whitespace-nowrap'>
											<span className='block whitespace-nowrap'>
												{tab?.label}
											</span>
										</span>
									</NavLink>
								</NavItem>
							))}
						</Nav>
						<TabContent activeTab={activeTab} className='pt-1'>
							<TabPane tabId={SIGNATURE_OPTIONS[0]?.value}>
								<CanvasProvider
									options={{
										classNames: classNames(
											'rounded-lg !border border-dashed border-main',
										),
										height: 153,
										width: 430,
									}}>
									<DrawSignature
										isDocument={isDocument}
										field={field}
										name={name}
										onChange={onChange}
									/>
								</CanvasProvider>
							</TabPane>
							{user?.avatar?.path && (
								<TabPane tabId='use-profile-image'>
									<UseProfileImage
										field={field}
										name={name}
										onChange={onChange}
										setSignatureId={setSignatureId}
									/>
								</TabPane>
							)}
							<TabPane tabId={SIGNATURE_OPTIONS[2]?.value}>
								<UploadSignatureImage
									field={field}
									name={name}
									onChange={onChange}
								/>
							</TabPane>
						</TabContent>
					</>
				) : (
					<span className='text-xs italic text-gray-400'>No Signatures</span>
				)}
			</div>
		</div>
	)
}

export default SignatureFieldView
