import { FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { useAppRouter } from 'hooks'
import { routes } from 'modules/workflows'
import { useHistory } from 'react-router-dom'
import { dispatch } from 'store'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import {
	assignValidationSchema,
	assignWorkflow,
	getWorkflowAssignPayload,
	getWorkflowLink,
	validateWorkflow,
} from '.'
import * as actions from '../../../../store/clients/actions'
import PublicLinkPopup from '../public-link-popup'
import { WORKFLOW_TEMAPLTE_ROUTES } from '../routes'
import WorkflowQuery from '../workflow-query'
import { useCreateWorkflowLinkMutation } from '../workflow-template-apis'
import { default as Steps } from './steps/steps'

const WorkflowAssignForm = ({ selectedIndex, initialValues }) => {
	const { parseQuery, navigate, location, params } = useAppRouter()
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false)
	const [link, setLink] = useState(null)
	const [copied, setCopied] = useState(false)
	const onPopupClose = () => history.goBack()
	const onPopupOpen = () => setIsOpen(true)
	const [createWorkflowLink] = useCreateWorkflowLinkMutation()
	const isCreateLink = useMemo(
		() => WORKFLOW_TEMAPLTE_ROUTES.createLink(params?.id) === location.pathname,
		[params, location],
	)
	const households = useMemo(
		() => (typeof parseQuery?.households === 'string' ? parseQuery?.households.split(',') : []),
		[parseQuery?.households],
	)

	const formik = useFormik({
		initialValues: {
			workflow: initialValues?.workflow || null,
			advisor: null,
			client: {
				user: initialValues?.client || null,
				screen_type: 'same_page',
				signPriority: 1,
			},
			co_applicants: [],
			reviewers: [],
			receiver_emails: [],
			clientGroups: households,
			final_reviewer: null,
			userCount: 1,
			isCreateLink: isCreateLink,
			createPublicURL: false,
			isTaskAutomationEnabled: true,
		},
		enableReinitialize: true,
		validationSchema: assignValidationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				setLink(null)
				const payload = getWorkflowAssignPayload(values)
				if (isCreateLink) {
					const response = await createWorkflowLink(payload)
					if (response?.data?.status === 200) {
						onPopupOpen()
						notification('success', response?.data?.message)
						const workflowLink = getWorkflowLink(response?.data?.data?.url)
						setLink(workflowLink)
						setCopied(false)
						dispatch(actions.selectedClients([]))
					}
				} else {
					await validateWorkflow(payload?.workflowTemplate?.id)
					await assignWorkflow(payload)
					navigate(routes.outstandings())
					dispatch(actions.selectedClients([]))
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const { user_count, steps } = WorkflowQuery(formValues.workflow?.value)

	useEffect(() => {
		if (user_count > 1) {
			formik.setFieldValue('userCount', user_count)
			formik.setFieldValue(
				'co_applicants',
				Array(user_count - 1).fill({ user: null, screen_type: 'same_page' }),
			)
		} else {
			formik.setFieldValue('userCount', 1)
			formik.setFieldValue('co_applicants', [])
		}
	}, [user_count])

	const documents = useMemo(() => steps?.documents?.raw_documents || [], [steps])
	const document_packages = useMemo(() => steps?.documents?.document_packages || [], [steps])
	const upload_files = useMemo(() => {
		const files = steps?.uploads?.note || '[]'
		return JSON.parse(files)
	}, [steps])

	const advisor_reviewer = useMemo(() => {
		const reviewers = formValues?.reviewers.map((reviewer) => reviewer?.value)
		return [formValues?.advisor?.value, formValues?.final_reviewer?.value, ...reviewers].filter(
			(user) => typeof user !== 'undefined',
		)
	}, [formValues])

	return (
		<Fragment>
			<PublicLinkPopup
				isOpen={isOpen}
				onClose={onPopupClose}
				onOpen={onPopupOpen}
				link={link}
				setCopied={setCopied}
				copied={copied}
			/>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='flex w-full flex-col gap-1'>
						<Steps
							formValues={formValues}
							formik={formik}
							selectedIndex={selectedIndex}
							documents={documents}
							document_packages={document_packages}
							upload_files={upload_files}
							advisor_reviewer={advisor_reviewer}
							isCreateLink={isCreateLink}
						/>
						<div className='mx-auto mt-3 flex w-full justify-end space-x-3 !py-3'>
							<Button
								type='submit'
								isLoading={formik.isSubmitting}
								isLoadingText={isCreateLink ? 'Creating...' : 'Assigning...'}>
								Assign Workflow
							</Button>
						</div>
					</div>
				</form>
			</FormikProvider>
		</Fragment>
	)
}

export default WorkflowAssignForm
