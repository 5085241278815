import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = '/comments'

export const commentApi = createApi({
    reducerPath: 'Comments',
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: axiosBaseQuery,
    tagTypes: ['Comments'],
    endpoints: (builder) => ({
        getComments: builder.query({
            keepUnusedDataFor: 0,
            query: (params) => ({
                url: API_URL,
                params: params,  
                method: 'GET',
            }),
            providesTags: ['Comments'],
            transformResponse: (response) => ({
                comments: response?.data?.comments || [],
                meta: response?.data?.meta,
            }),
        }),

        getComment: builder.query({
            query: (id) => ({
                url: `${API_URL}/${id}`,
                method: 'GET',
            }),
            providesTags: ['Comments'],
        }),

        createComment: builder.mutation({
            query: (payload) => ({
                url: API_URL,
                method: 'POST',
                data: payload, 
            }),
            invalidatesTags: ['Comments'],
        }),

        updateComment: builder.mutation({
            query: ({ id, payload }) => ({
                url: `${API_URL}/${id}`,
                method: 'PATCH',
                data: payload, 
            }),
            invalidatesTags: ['Comments'],
        }),

        deleteComment: builder.mutation({
            query: (id) => ({
                url: `${API_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Comments'],
        }),
    }),
})

export const {
    useLazyGetCommentsQuery,
    useLazyGetCommentQuery,
    useCreateCommentMutation,
    useUpdateCommentMutation,
    useDeleteCommentMutation,
} = commentApi