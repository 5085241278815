import classNames from 'classnames'
import { Fragment } from 'react'
import Select from 'react-select'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'
import ToolTip from 'theme/ui/tool-tip'

const BulkSettingsForm = ({ userOptions, bulkValues, setBulkValues }) => {
	const onChange = (key, value) => {
		setBulkValues((prev) => ({ ...prev, [key]: value }))
	}
	return (
		<Fragment>
			<div className={classNames('flex flex-col space-y-[8px]')}>
				<label
					className={classNames(
						'mb-0 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
					)}>
					Fillable By
				</label>

				<div className='flex flex-col'>
					<Select
						options={userOptions}
						onChange={(option) => onChange('user', option)}
						isSearchable={false}
						value={bulkValues['user']}
						isClearable={false}
						classNamePrefix={'select-dropdown'}
						className={classNames('select-dropdown-container')}
						styles={{
							menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
							menu: (provided) => ({ ...provided, zIndex: 9999 }),
						}}
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
						components={{
							DropdownIndicator: DropdownIndicator,
						}}
					/>
				</div>
			</div>

			<div className='grid grid-cols-1 gap-2'>
				<div className='flex flex-wrap gap-2'>
					<input
						checked={bulkValues['is_document_bound']}
						onChange={(e) => onChange('is_document_bound', e.target.checked)}
						className='form-check-input'
						id={`is_document_bound`}
						type='checkbox'
					/>
					<div className='flex items-center'>
						<label htmlFor={`is_document_bound`} className='!mb-0 cursor-pointer'>
							Document specific
						</label>

						<div id={`is_document_bound_info`} className='ml-1 cursor-pointer'>
							<i className='bx bx-info-circle mb-0 mt-1 text-sm text-gray-400' />
							<ToolTip
								placement='top'
								targetId={`is_document_bound_info`}
								tooltipText={`Switch on if the value of this field is exclusively saved within the
								scope of this document and not at a client&apos;s profile scope.`}
							/>
						</div>
					</div>
				</div>
				<div className='flex flex-wrap gap-2'>
					<input
						checked={bulkValues['is_required']}
						type='checkbox'
						onChange={(e) => onChange('is_required', e.target.checked)}
						className='form-check-input'
						id={`is_required`}
					/>
					<label className='!mb-0 cursor-pointer' htmlFor={`is_required`}>
						Required
					</label>
				</div>
			</div>
		</Fragment>
	)
}

export default BulkSettingsForm
