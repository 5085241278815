import { NotificationsSlice } from './slices'

const { actions } = NotificationsSlice

export const setNotifications = (payload) => (dispatch) => {
	dispatch(actions.setNotifications(payload))
}

export const setNotificationsProgress = (payload) => (dispatch) => {
	dispatch(actions.setNotificationsProgress(payload))
}