import { Menu, Transition } from '@headlessui/react'
import { useResizeObserver } from '@wojtekmaj/react-hooks'
import checMarkIcon from 'assets/svg/green-check-mark.png'
import classNames from 'classnames'
import { FieldArray } from 'formik'
import { capitalize } from 'lodash'
import { ChevronDown, Group, X } from 'lucide-react'
import { useAutoSaveDocumentMutation } from 'modules/documents/document-api'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Document, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import Alert from 'theme/ui/alert'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import { getCommonElements, hasCommonElement } from '.'
import { getAutoSavePayload } from '../..'
import { PDF_FIELD_TYPES } from '../../helpers'
import FieldSettingsForm, { findIndexByMatch } from './field-setting-form'
import MultiSelectToolbar from './multi-select-toolbar'
import PageFields from './page-fields'
import { useFieldSelection } from './use-field-selection'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url,
).toString()

const DEFAULT_FIELD_WIDTH = 152
const DEFAULT_FIELD_HEIGHT = 24
const DEFAULT_FONT_SIZE = '14px'

const PdfPreviewTab = ({ formik, document, userOptions }) => {
	const file = document?.file_path
	const [numPages, setNumPages] = useState(0)
	const [containerRef, setContainerRef] = useState(null)
	const [containerWidth, setContainerWidth] = useState(0)
	const $fields = Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []
	const new_fields = Array.isArray(formik?.values?.new_fields) ? formik?.values?.new_fields : []
	const [fields, setFields] = useState([...$fields, ...new_fields])
	const [draggingFieldType, setDraggingFieldType] = useState(null)
	const [isDragging, setIsDragging] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [showAlert, setShowAlert] = useState($fields.length === 0)
	const stickyHeaderRef = useRef(null)
	const [dragPreview, setDragPreview] = useState(null)

	const [autoSaveDocument] = useAutoSaveDocumentMutation()
	const [isSaving, setIsSaving] = useState(false)
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const document_mapping = useMemo(
		() => (Array.isArray(formValues?.document_mapping) ? formValues?.document_mapping : []),
		[formValues],
	)
	const {
		selectedFields,
		setSelectedFields,
		isMultiSelectMode,
		setIsMultiSelectMode,
		selectedField,
		setModifierKeys,
		handleFieldSelection: originalHandleFieldSelection,
		activeFieldSettings,
		setActiveFieldSettings,
	} = useFieldSelection(fields, document_mapping)

	const handleFieldSelection = useCallback(
		(fieldName, arrayHelpers) => {
			const field = fields.find((f) => f.original_name === fieldName)
			let newSelectedFields = [...selectedFields]

			if (field.type === 'signature') {
				const linkedTimestamp = fields.find((f) => f.linked_to === fieldName)
				if (linkedTimestamp && !newSelectedFields.includes(linkedTimestamp.original_name)) {
					newSelectedFields.push(linkedTimestamp.original_name)
				}
			} else if (field.type === 'timestamp' && field.linked_to) {
				const parentSignature = fields.find((f) => f.original_name === field.linked_to)
				if (parentSignature && !newSelectedFields.includes(parentSignature.original_name)) {
					newSelectedFields.push(parentSignature.original_name)
				}
			}

			if (!newSelectedFields.includes(fieldName)) {
				newSelectedFields.push(fieldName)
			}

			setSelectedFields(newSelectedFields)
			originalHandleFieldSelection(fieldName, arrayHelpers)
		},
		[fields, selectedFields, originalHandleFieldSelection, setSelectedFields],
	)

	useEffect(() => {
		const handleKeyDown = (e) =>
			setModifierKeys((prev) => ({ ...prev, [e.key.toLowerCase()]: true }))
		const handleKeyUp = (e) =>
			setModifierKeys((prev) => ({ ...prev, [e.key.toLowerCase()]: false }))
		const handleBlur = () => setModifierKeys({ shift: false, ctrl: false })

		window.addEventListener('keydown', handleKeyDown)
		window.addEventListener('keyup', handleKeyUp)
		window.addEventListener('blur', handleBlur)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
			window.removeEventListener('keyup', handleKeyUp)
			window.removeEventListener('blur', handleBlur)
		}
	}, [setModifierKeys])

	const onResize = useCallback((entries) => {
		const [entry] = entries
		if (entry) {
			setContainerWidth(entry.contentRect.width)
		}
	}, [])

	useResizeObserver(containerRef, {}, onResize)

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages)
	}

	const toggleMultiSelectMode = useCallback(() => {
		setIsMultiSelectMode((prev) => {
			if (prev) {
				setSelectedFields([])
				setActiveFieldSettings(null)
			}
			return !prev
		})
	}, [])

	const delete_document_mapping = useMemo(
		() =>
			Array.isArray(formValues?.delete_document_mapping)
				? formValues?.delete_document_mapping
				: [],
		[formValues],
	)

	const updateSelectedFields = useCallback(
		(deleted) => {
			if (!selectedFields.length) return

			if (deleted) {
				const newFieldsToDelete = new_fields.filter((f) =>
					selectedFields.includes(f.original_name),
				)
				const existingFieldsToDelete = document_mapping.filter(
					(f) =>
						selectedFields.includes(f?.pdf_field) &&
						!newFieldsToDelete.some((nf) => nf.original_name === f.pdf_field),
				)

				if (newFieldsToDelete.length > 0) {
					const updatedNewFields = new_fields.filter(
						(f) => !selectedFields.includes(f.original_name),
					)
					const updatedFields = fields.filter(
						(f) => !selectedFields.includes(f.original_name) || !f.is_new,
					)
					formik.setFieldValue('new_fields', updatedNewFields)
					setFields(updatedFields)
				}

				if (existingFieldsToDelete.length > 0) {
					const updated_document_mapping = document_mapping.filter(
						(f) =>
							!selectedFields.includes(f?.pdf_field) ||
							newFieldsToDelete.some((nf) => nf.original_name === f.pdf_field),
					)
					formik.setFieldValue('document_mapping', [...updated_document_mapping])
					formik.setFieldValue('delete_document_mapping', [
						...delete_document_mapping,
						...existingFieldsToDelete,
					])
				}
			} else {
				const updated_delete_document_mapping = delete_document_mapping.filter(
					(f) => !selectedFields.includes(f?.pdf_field),
				)
				const restored_fields = delete_document_mapping.filter((f) =>
					selectedFields.includes(f?.pdf_field),
				)
				formik.setFieldValue('delete_document_mapping', [
					...updated_delete_document_mapping,
				])
				formik.setFieldValue('document_mapping', [...document_mapping, ...restored_fields])
			}

			setSelectedFields([])
			setActiveFieldSettings(null)
		},
		[selectedFields, document_mapping, delete_document_mapping, new_fields, fields],
	)

	const onDelete = useCallback(
		(fieldName, arrayHelpers) => {
			const fieldIndex = document_mapping.findIndex((f) => f?.pdf_field === fieldName)
			const isNewField = new_fields.some((f) => f.original_name === fieldName)

			if (isNewField) {
				const linkedTimestamp = fields.find((f) => f.linked_to === fieldName)
				const fieldsToDelete = linkedTimestamp
					? [fieldName, linkedTimestamp.original_name]
					: [fieldName]

				const updatedNewFields = new_fields.filter(
					(f) => !fieldsToDelete.includes(f.original_name),
				)
				const updatedFields = fields.filter(
					(f) => !fieldsToDelete.includes(f.original_name),
				)
				const updatedDocumentMapping = document_mapping.filter(
					(f) => !fieldsToDelete.includes(f.pdf_field),
				)

				arrayHelpers.form.setFieldValue('new_fields', updatedNewFields)
				arrayHelpers.form.setFieldValue('document_mapping', updatedDocumentMapping)
				setFields(updatedFields)
				arrayHelpers.remove(fieldIndex)

				setSelectedFields([])
				setActiveFieldSettings(null)
			} else {
				arrayHelpers.remove(fieldIndex)
				const field = document_mapping.find((f) => f.pdf_field === fieldName)
				formik.setFieldValue('delete_document_mapping', [...delete_document_mapping, field])

				setSelectedFields([])
				setActiveFieldSettings(null)
			}
		},
		[
			document_mapping,
			delete_document_mapping,
			new_fields,
			fields,
			setSelectedFields,
			setActiveFieldSettings,
			formik,
		],
	)

	const onRestore = useCallback(
		async (fieldNames) => {
			const $updated_delete_document_mapping = delete_document_mapping.filter(
				(f) => !fieldNames.includes(f.pdf_field),
			)
			formik.setFieldValue('delete_document_mapping', [...$updated_delete_document_mapping])

			const fieldsToRestore = delete_document_mapping.filter((f) =>
				fieldNames.includes(f.pdf_field),
			)
			formik.setFieldValue('document_mapping', [...document_mapping, ...fieldsToRestore])

			setSelectedFields([])
			setActiveFieldSettings(null)
		},
		[document_mapping, delete_document_mapping],
	)

	const onAutoSave = useCallback(async () => {
		try {
			const payload = getAutoSavePayload(formValues)
			await autoSaveDocument({ payload, id: document?.id })
			setSelectedFields([])
			setActiveFieldSettings(null)
		} catch (error) {
			//
		}
	}, [
		formValues,
		getAutoSavePayload,
		autoSaveDocument,
		setSelectedFields,
		setActiveFieldSettings,
	])

	const onClickAutoSave = async () => {
		try {
			setIsSaving(true)
			const payload = getAutoSavePayload(formValues)
			await autoSaveDocument({ payload, id: document?.id })
			setSelectedFields([])
			setActiveFieldSettings(null)
		} catch (error) {
			setIsSaving(false)
		} finally {
			setIsSaving(false)
		}
	}

	const onSetMultiple = useCallback(
		(bulk_mapping) => {
			if (bulk_mapping) {
				const selectedFieldsIndex = findIndexByMatch(selectedFields, document_mapping)
				selectedFieldsIndex.forEach((idx) => {
					const field = document_mapping[idx]
					const isSignature = field.type === 'signature'
					const isTimestamp = field.type === 'timestamp'

					formik.setFieldValue(`document_mapping[${idx}].user`, bulk_mapping?.user)
					formik.setFieldValue(
						`document_mapping[${idx}].is_document_bound`,
						isSignature || isTimestamp ? true : bulk_mapping?.is_document_bound,
					)
					formik.setFieldValue(
						`document_mapping[${idx}].is_required`,
						bulk_mapping?.is_required,
					)
				})
			}
			setSelectedFields([])
			setActiveFieldSettings(null)
			setIsMultiSelectMode(false)
		},
		[selectedFields, document_mapping],
	)

	const deleted_fields = delete_document_mapping.map((d) => d?.pdf_field)
	const available_fields = document_mapping.map((d) => d?.pdf_field)
	const is_deleted_field_selected = hasCommonElement(selectedFields, deleted_fields)
	const selected_deleted_field = getCommonElements(selectedFields, deleted_fields)
	const selected_available_field = getCommonElements(selectedFields, available_fields)

	const SettingsSidebar = useMemo(() => {
		if (!is_deleted_field_selected && activeFieldSettings && selectedFields.length > 0) {
			const isSignature = activeFieldSettings.type === 'signature'
			const isTimestamp = activeFieldSettings.type === 'timestamp'
			const linkedSignature = isTimestamp
				? document_mapping.find((f) => f.pdf_field === activeFieldSettings.linked_to)
				: null

			return (
				<div className='p-3'>
					<h3 className='mb-3 text-lg font-bold' style={{ wordWrap: 'break-word' }}>
						{activeFieldSettings.original_name.includes('Multiple fields')
							? activeFieldSettings.original_name
							: `Field: ${activeFieldSettings.original_name}`}
					</h3>
					<FieldSettingsForm
						formik={formik}
						fieldName={selectedField}
						onAutoSave={onAutoSave}
						userOptions={userOptions}
						field={activeFieldSettings}
						selectedFields={selectedFields}
						document_mapping={document_mapping}
						isAutoSaving={isSaving}
						isSignatureField={isSignature}
						isTimestampField={isTimestamp}
						linkedSignatureUser={linkedSignature?.user}
						onSave={(e) => {
							if (selectedFields.length > 1) {
								onSetMultiple(e)
							} else {
								onClickAutoSave()
							}
						}}
						onClose={() => {
							setSelectedFields([])
							setActiveFieldSettings(null)
						}}
					/>
				</div>
			)
		}
		return null
	}, [
		activeFieldSettings,
		selectedFields,
		document_mapping,
		selectedField,
		is_deleted_field_selected,
		onClickAutoSave,
		isSaving,
	])

	const onClearSelectedFields = () => {
		setSelectedFields([])
		setIsMultiSelectMode(false)
		setActiveFieldSettings(null)
	}

	const multiSelectToolbar = useMemo(
		() =>
			(isMultiSelectMode || selectedFields.length > 1) && (
				<MultiSelectToolbar
					fields={fields}
					onClearSelectedFields={onClearSelectedFields}
					selectedFields={selectedFields}
					updateSelectedFields={updateSelectedFields}
					onRestore={() => onRestore(selected_deleted_field)}
					selected_deleted_field={selected_deleted_field}
					selected_available_field={selected_available_field}
				/>
			),
		[
			isMultiSelectMode,
			selectedFields,
			fields,
			updateSelectedFields,
			selected_deleted_field,
			selected_available_field,
			onRestore,
		],
	)

	const unMappedFields = document_mapping.filter(
		(mapping) =>
			(!mapping?.field && mapping?.type !== 'signature' && mapping?.type !== 'timestamp') ||
			!mapping?.user ||
			!mapping?.pdf_field,
	).length
	const mappedFields = document_mapping.filter(
		(mapping) =>
			(!!mapping?.field || mapping?.type === 'signature' || mapping?.type === 'timestamp') &&
			!!mapping?.user &&
			!!mapping?.pdf_field,
	)

	const isRequiredFieldExists = document_mapping.some((m) => m?.is_required)

	const getAssignedColors = useMemo(() => {
		const assignedUsers = new Set(mappedFields.map((mapping) => mapping?.user?.value))
		return userOptions
			.filter((option) => assignedUsers.has(option.value))
			.map((option) => ({ color: option?.color, user: capitalize(option?.user) }))
	}, [mappedFields, userOptions])

	const fieldInfo = useMemo(
		() => [
			{
				label: 'Assigned',
				value: mappedFields.length,
				classNames: 'outline outline-1 outline-green-400 text-green-600',
				colors: getAssignedColors,
			},
			{
				label: 'Unassigned',
				value: unMappedFields,
				classNames: 'outline outline-1 outline-blue-600 text-gray-500 bg-white/80',
			},
			{
				label: 'Deleted',
				value: delete_document_mapping.length,
				classNames: 'outline outline-1 outline-gray-500 text-gray-600 bg-gray-300',
			},
		],
		[mappedFields, unMappedFields, delete_document_mapping, fields, userOptions],
	)

	return (
		<div>
			<div>
				<h3 className='text-lg font-bold'>Field Mapping</h3>
				{showAlert && $fields.length === 0 && (
					<div className='w-full'>
						<Alert variant='info'>
							<div className='flex items-center justify-between'>
								<p className='m-0'>
									No fields are extracted from this PDF. You can add new fields
									using the &quot;Add Field&quot; button.
								</p>
								<button
									onClick={() => setShowAlert(false)}
									className='text-cyan-700 hover:text-cyan-900 focus:outline-none'>
									<X size={18} />
								</button>
							</div>
						</Alert>
					</div>
				)}
			</div>
			<div
				className='flex flex-wrap gap-3 -mx-[10px] px-[10px] pb-4 !sticky pt-[20px] top-[0.010rem] z-[998] bg-[#f5f6f8]'
				ref={stickyHeaderRef}>
				<span className='font-semibold'>Total Fields: {fields.length}</span>
				{fieldInfo.map((item, idx) => (
					<div
						key={idx}
						className={classNames(
							'flex gap-1 text-sm rounded py-0.5 px-1.5',
							item?.classNames,
						)}>
						{item.label === 'Assigned' && item?.colors?.length > 0 ? (
							<div className='flex items-center w-full'>
								<div className='flex h-5 mr-2 min-w-[95px] w-full overflow-hidden rounded'>
									{item.colors.map((color, colorIdx) => (
										<div
											title={color?.user}
											key={colorIdx}
											className='h-full'
											style={{
												backgroundColor: color?.color,
												width: `${100 / item.colors.length}%`,
											}}
										/>
									))}
								</div>
								<div className='flex gap-1'>
									<span className='font-medium'>{item?.label}: </span>
									<span>{item?.value}</span>
								</div>
							</div>
						) : (
							<>
								<span className={classNames('font-medium')}>{item?.label}:</span>
								<span>{item?.value}</span>
							</>
						)}
					</div>
				))}

				{isRequiredFieldExists || mappedFields.length > 0 ? (
					<span className='border-l border-slate-300'></span>
				) : undefined}

				{isRequiredFieldExists ? (
					<div className='flex items-center gap-1.5'>
						<span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='12'
								fill='none'
								viewBox='0 0 12 12'>
								<path fill='#F46D6D' d='M0 0h12v10a2 2 0 0 1-2 2H0z' />
								<path
									stroke='#fff'
									strokeWidth='2'
									d='M6 2v4.308m0 0L3.538 10M6 6.308 8.462 10M6 6.308l4-1.231m-4 1.23-4-1.23'
								/>
							</svg>
						</span>
						<span>Required Fields</span>
					</div>
				) : undefined}

				{mappedFields.length > 0 ? (
					<div className='flex items-center gap-1.5'>
						<span>
							<img src={checMarkIcon} alt='check-mark' className='h-auto w-[20px]' />
						</span>

						<span className='border-l-4 border-green-500 px-1'>Mapped Fields</span>
					</div>
				) : undefined}
			</div>

			<div className={classNames('flex w-full gap-3')}>
				<FieldArray
					name='document_mapping'
					render={(arrayHelpers) => (
						<div
							ref={setContainerRef}
							className={classNames(
								'bg-white rounded-md p-3 shadow w-[70%] max-h-fit min-h-[70vh] !sticky top-[68px]',
							)}
							style={{
								position: 'relative',
								margin: '0 auto',
								overflow: 'auto',
							}}
							onDragOver={(e) => {
								e.preventDefault()
								const rect = e.currentTarget.getBoundingClientRect()
								setDragPreview({
									x: e.clientX - rect.left - DEFAULT_FIELD_WIDTH / 2,
									y: e.clientY - rect.top - DEFAULT_FIELD_HEIGHT / 2,
									type: draggingFieldType,
								})
							}}
							onDragLeave={() => setDragPreview(null)}>
							{dragPreview && (
								<div
									style={{
										position: 'absolute',
										left: `${dragPreview.x}px`,
										top: `${dragPreview.y}px`,
										width: `${DEFAULT_FIELD_WIDTH}px`,
										height: `${DEFAULT_FIELD_HEIGHT}px`,
										backgroundColor: 'rgba(66, 153, 225, 0.3)',
										border: '1px dashed #4299e1',
										pointerEvents: 'none',
										zIndex: 1000,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										fontSize: DEFAULT_FONT_SIZE,
									}}>
									{/* {dragPreview.type} */}
								</div>
							)}
							<Document
								file={file}
								onLoadSuccess={onDocumentLoadSuccess}
								loading={<Spinner />}>
								{Array.from({ length: numPages }, (_, index) => (
									<PageFields
										key={`page_${index + 1}`}
										pageIndex={index}
										fields={fields}
										containerWidth={containerWidth}
										isMultiSelectMode={isMultiSelectMode}
										selectedFields={selectedFields}
										onFieldSelect={handleFieldSelection}
										onDelete={onDelete}
										onRestore={onRestore}
										arrayHelpers={arrayHelpers}
										formValues={formValues}
										onAutoSave={onAutoSave}
										setFields={setFields}
										userOptions={userOptions}
										dragPreview={dragPreview}
										setDragPreview={setDragPreview}
									/>
								))}
							</Document>
						</div>
					)}
				/>
				<div
					className={classNames('w-[30%] bg-white rounded-md shadow max-h-fit !sticky')}
					style={{
						top: stickyHeaderRef?.current?.offsetHeight || 68,
					}}>
					<div className='flex justify-center gap-2 p-3 workflow_columns'>
						<Menu as='div' className='relative inline-block text-left'>
							{() => (
								<>
									<Menu.Button
										as={Button}
										size='xs'
										variant='white'
										onClick={() => setIsMenuOpen(!isMenuOpen)}>
										<div className='flex items-center gap-1'>
											<span>Add Field</span>
											<ChevronDown size={14} />
										</div>
									</Menu.Button>

									<Transition
										as={Fragment}
										show={isMenuOpen}
										enter='transition ease-out duration-100'
										enterFrom='transform opacity-0 scale-95'
										enterTo='transform opacity-100 scale-100'
										leave='transition ease-in duration-75'
										leaveFrom='transform opacity-100 scale-100'
										leaveTo='transform opacity-0 scale-95'>
										<Menu.Items
											className={classNames(
												'absolute right-0 w-56 mt-2 origin-top-right divide-y divide-gray-100 rounded-md  ring-1 ring-black ring-opacity-5 focus:outline-none z-10 bg-white shadow-lg',
											)}>
											<div className='max-h-[300px] py-1 overflow-y-auto custom-scroll'>
												{PDF_FIELD_TYPES.filter(
													(f) => f?.value !== 'timestamp',
												).map((type) => (
													<Menu.Item key={type.value}>
														{({ active }) => (
															<div
																className={classNames(
																	active
																		? 'bg-gray-100 text-gray-900'
																		: 'text-gray-700',
																	'block px-4 py-2 text-sm',
																	isDragging &&
																		draggingFieldType ===
																			type.value
																		? 'cursor-grabbing'
																		: 'cursor-grab ',
																	isDragging &&
																		draggingFieldType ===
																			type.value
																		? 'opacity-[0.5] bg-transparent'
																		: '', // Hide when dragging
																)}
																draggable
																onDragStart={(e) => {
																	setDraggingFieldType(type.value)
																	setIsDragging(true)
																	e.dataTransfer.setData(
																		'fieldType',
																		type.value,
																	)

																	// Create a custom drag image
																	const dragImage =
																		document.createElement(
																			'div',
																		)
																	dragImage.innerHTML = type.label
																	dragImage.style.position =
																		'absolute'
																	dragImage.style.top = '-9999px'
																	dragImage.style.left = '-9999px'
																	dragImage.style.width = `${DEFAULT_FIELD_WIDTH}px`
																	dragImage.style.height = `${DEFAULT_FIELD_HEIGHT}px`
																	dragImage.style.fontSize =
																		DEFAULT_FONT_SIZE
																	dragImage.style.backgroundColor =
																		'rgba(0, 0, 255, 0.1)'
																	dragImage.style.border =
																		'1px dashed #666'
																	dragImage.style.display = 'flex'
																	dragImage.style.alignItems =
																		'center'
																	dragImage.style.justifyContent =
																		'center'
																	dragImage.style.padding =
																		'0 8px'

																	document.body.appendChild(
																		dragImage,
																	)

																	// Position the drag image centered under the cursor
																	const offsetX =
																		DEFAULT_FIELD_WIDTH / 2
																	const offsetY =
																		DEFAULT_FIELD_HEIGHT / 2
																	e.dataTransfer.setDragImage(
																		dragImage,
																		offsetX,
																		offsetY,
																	)

																	// Cleanup
																	setTimeout(
																		() =>
																			document.body.removeChild(
																				dragImage,
																			),
																		0,
																	)
																}}
																onDragEnd={() => {
																	setDraggingFieldType(null)
																	setIsDragging(false)
																	setIsMenuOpen(false)
																	setDragPreview(null)
																}}>
																{type.label}
															</div>
														)}
													</Menu.Item>
												))}
											</div>
										</Menu.Items>
									</Transition>
								</>
							)}
						</Menu>

						<Button
							size='xs'
							disabled={selectedFields.length === 0}
							onClick={toggleMultiSelectMode}
							variant={isMultiSelectMode ? 'primary' : 'white'}>
							<div className='flex items-center gap-1'>
								<Group size={14} />{' '}
								{isMultiSelectMode ? 'Exit Multi-Select' : 'Multi-Select'}
							</div>
						</Button>
					</div>
					{!is_deleted_field_selected &&
					activeFieldSettings &&
					selectedFields.length > 0 ? (
						SettingsSidebar
					) : (
						<div className='p-3'>
							<p className='text-sm text-gray-500 text-center m-0'>
								Please select field from pdf
							</p>
						</div>
					)}
				</div>
			</div>

			{multiSelectToolbar}
		</div>
	)
}

export default PdfPreviewTab
