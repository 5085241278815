/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper } from '@tanstack/react-table'
import base64 from 'base-64'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'

import { useLazyGetWorkflowDocumentsQuery } from 'modules/clients/client-apis'
import { ReactDataTable } from 'theme/ui/data-table'
import DocumentActions from './document-actions'
import FilterForm from './FilterForm'
import useWorkflowViewQuery from './useWorkflowViewQuery'

const WorkflowTable = ({ client, role, toggle }) => {
	const user = {
		id: client?.id,
		label: `${client?.first_name} ${client?.last_name} (${client?.email})`,
	}
	const columnHelper = createColumnHelper()
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const [expandedRowData, setExpandedRowData] = useState({})
	const [expandedRowLoading, setExpandedRowLoading] = useState({})
	const { data, meta, isLoading } = useWorkflowViewQuery(pagination, client, role)
	const [fetchDoc] = useLazyGetWorkflowDocumentsQuery()

	const fetchDocuments = async (rowId) => {
		try {
			setExpandedRowLoading((prev) => ({ ...prev, [rowId]: true }))
			const response = await fetchDoc(rowId).unwrap()
			const documents = Array.isArray(response?.data) ? response?.data : []
			setExpandedRowData((prev) => ({ ...prev, [rowId]: documents }))
			setExpandedRowLoading((prev) => ({ ...prev, [rowId]: false }))
		} catch (error) {
			setExpandedRowLoading((prev) => ({ ...prev, [rowId]: false }))
			setExpandedRowData((prev) => ({ ...prev, [rowId]: [] }))
		}
	}

	const columns = useMemo(
		() => [
			// columnHelper.accessor((row) => row, {
			// 	id: 'expander',
			// 	header: () => null,
			// 	cell: ({ row, getValue }) => {
			// 		return row.getCanExpand() && getValue()?.status === 'Completed' ? (
			// 			<div className='flex '>
			// 				<button
			// 					{...{
			// 						onClick: () => {
			// 							row.getToggleExpandedHandler()()
			// 							if (!row.getIsExpanded()) {
			// 								fetchDocuments(row?.original?.id)
			// 							}
			// 						},
			// 						style: { cursor: 'pointer' },
			// 					}}>
			// 					{row.getIsExpanded() ? (
			// 						<i className='uil-angle-down mt-1 align-middle text-[15px]'></i>
			// 					) : (
			// 						<i className='uil-angle-right mt-1 align-middle text-[15px]'></i>
			// 					)}
			// 				</button>
			// 			</div>
			// 		) : (
			// 			<div className='w-[15px]'></div>
			// 		)
			// 	},
			// }),

			columnHelper.accessor((row) => row, {
				id: 'title',
				header: () => <span>Name</span>,
				cell: ({ row, getValue }) => {
					return (
						<div className='flex items-center '>
							{row.getCanExpand() && getValue()?.status === 'Completed' ? (
								<div className='flex'>
									<button
										{...{
											onClick: () => {
												row.getToggleExpandedHandler()()
												if (!row.getIsExpanded()) {
													fetchDocuments(row?.original?.id)
												}
											},
											style: { cursor: 'pointer' },
										}}>
										{row.getIsExpanded() ? (
											<i className='uil-angle-down mt-1 align-middle text-[15px]'></i>
										) : (
											<i className='uil-angle-right mt-1 align-middle text-[15px]'></i>
										)}
									</button>
								</div>
							) : (
								<div className='w-[15px]'></div>
							)}
							<span>{getValue()?.title}</span>
						</div>
					)
				},
			}),

			columnHelper.accessor((row) => row.status, {
				id: 'status',
				header: () => <span>Status</span>,
				cell: (info) => (
					<DocumentActions
						index={info.getValue()?.index}
						status={info.getValue()}
						toggle={toggle}
						document={info?.row?.original}
					/>
				),
			}),
		],
		[columnHelper, fetchDocuments],
	)

	const renderSubComponent = useCallback(
		({ row }) => {
			const $doc = Array.isArray(expandedRowData[row?.original?.id])
				? expandedRowData[row?.original?.id]
				: []
			const $isLoading = expandedRowLoading[row?.original?.id] || false

			return (
				<div>
					<ReactDataTable
						columns={columns}
						data={$doc}
						hideHeader={true}
						meta={null}
						isLoading={$isLoading}
						isCompact={true}
						variant='secondary'
						getRowCanExpand={() => true}
						isOverflow={false}
						isChildren={true}
						isFixedSize={true}
						renderSubComponent={renderSubComponent}
					/>
				</div>
			)
		},
		[columns, expandedRowData, expandedRowLoading],
	)

	return (
		<Fragment>
			<Card className='!rounded-lg shadow-none'>
				<CardHeader className='!border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Workflows</h5>
					<div>
						<Link
							className='btn btn-sm btn-outline-primary'
							to={`/workflows/assign?user=${base64.encode(JSON.stringify(user))}`}>
							<i className='uil uil-plus' /> Assign Workflow
						</Link>
					</div>
				</CardHeader>
				<CardBody>
					<FilterForm />
					<ReactDataTable
						columns={columns}
						data={data}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						meta={meta}
						getRowCanExpand={() => true}
						isFixedSize={true}
						renderSubComponent={renderSubComponent}
					/>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default WorkflowTable
