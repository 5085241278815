import classNames from 'classnames'
import { useMemo } from 'react'

import { Tab } from '@headlessui/react'
import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import {
	getDocumentBoundFields,
	getFieldsByUser,
	getFieldsList,
} from '../assigned-workflow-form/helpers'
import { getTabs } from '../assigned-workflow-form/tabs-content/fill-details-tab'
import FileCard from '../assigned-workflow-form/tabs-content/fill-details-tab/uploaded-files/file-card'
import { getFieldValue } from '../helpers'
import PdfFileCard from './pdf-file-card'

const CompletedWorkflowDetails = ({ uploads, individualDocument, workflow }) => {
	const uploadedFiles = useMemo(
		() =>
			Array.isArray(uploads?.files)
				? uploads?.files.map((f) => ({ ...f, extension: f?.files?.name.split('.')[1] }))
				: [],
		[uploads?.files],
	)
	const user_type = workflow?.user_type
	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individualDocument?.documents, user_type),
		[individualDocument?.documents, user_type],
	)
	const tabsList = useMemo(() => getTabs(documentBoundFields.length > 0), [documentBoundFields])
	const fieldsArray = useMemo(
		() => getFieldsList(individualDocument?.documents),
		[individualDocument?.documents],
	)
	const fields = useMemo(() => {
		const $fieldsArray = fieldsArray.filter(
			(f) => f?.is_document_bound === false || typeof f?.is_document_bound === 'undefined',
		)
		return getFieldsByUser($fieldsArray, user_type)
	}, [fieldsArray, user_type])

	const URL = useMemo(
		() => (workflow?.id ? `${apiEndpoints.workflow}/review/${workflow?.id}/documents` : null),
		[workflow?.id],
	)
	const { data, isLoading } = useQuery(URL)

	const documents = useMemo(
		() =>
			data?.data.map((document) => ({
				id: document?.id,
				title: document?.title,
				url: document?.file?.path,
			})) || [],
		[data, isLoading],
	)

	return (
		<div className='flex divide-x divide-gray-200'>
			<div className='w-2/3 py-3 pr-6'>
				<h3 className='mb-6 text-lg font-semibold text-gray2-dark'>Filled Details</h3>
				<div>
					<Tab.Group>
						<Tab.List className='-mt-[10px] mb-3 flex space-x-3 rounded-sm !border-b border-gray-200'>
							{tabsList.map((tab, idx) => (
								<Tab
									as='span'
									key={idx}
									className={({ selected }) =>
										classNames(
											'max-w-fit cursor-pointer border-b-2 border-l-0 py-2.5 text-sm font-bold transition-all hover:border-main hover:text-main focus:outline-none',
											selected
												? 'border-main text-main'
												: 'border-transparent  text-[#B5B5C3]',
										)
									}>
									{tab.label}
								</Tab>
							))}
						</Tab.List>

						<Tab.Panels>
							<Tab.Panel>
								<div className='grid grid-cols-3 gap-4'>
									{fields.map((field, idx) => (
										<div key={idx} className='flex flex-col'>
											<span className='text-[13px] text-gray-500'>
												{field?.label}
											</span>
											<span className='font-bold'>
												{getFieldValue(field)}
											</span>
										</div>
									))}
								</div>
							</Tab.Panel>
							<Tab.Panel>
								<div className='flex flex-col divide-y'>
									{documentBoundFields.map((document, idx) => (
										<div key={idx} className='py-4'>
											<h4 className='mb-2 text-lg font-bold'>
												{idx + 1}. {document?.title}
											</h4>
											<div className='ml-4 grid grid-cols-3 gap-4'>
												{document?.fields.map((field, idx) => (
													<div key={idx} className='flex flex-col'>
														<span className='text-[13px] text-gray-500'>
															{field?.label}
														</span>
														<span className='font-bold'>
															{field?.value}
														</span>
													</div>
												))}
											</div>
										</div>
									))}
								</div>
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
				</div>
			</div>

			<div className='w-1/3 pl-6'>
				{uploadedFiles.length > 0 ? (
					<div className='py-3'>
						<h3 className='mb-6 text-lg font-semibold text-gray2-dark'>
							Uploaded Files
						</h3>
						<div className={classNames('grid grid-cols-1 flex-wrap gap-3')}>
							{uploadedFiles.map((file, idx) => (
								<FileCard file={file} key={idx} />
							))}
						</div>
					</div>
				) : undefined}

				{documents.length > 0 ? (
					<div className='py-3'>
						<h3 className='mb-6 text-lg font-semibold text-gray2-dark'>Documents</h3>
						<div className={classNames('grid grid-cols-1 flex-wrap gap-3')}>
							{documents.map((file, idx) => (
								<PdfFileCard
									file={{
										title: file?.title,
										files: {
											mime_type: 'application/pdf',
										},
										fileUrl: file?.url,
									}}
									key={idx}
								/>
							))}
						</div>
					</div>
				) : undefined}
			</div>
		</div>
	)
}

export default CompletedWorkflowDetails
