import { Dialog, Transition } from '@headlessui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Fragment } from 'react'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import * as Yup from 'yup'
import { useCreateHouseholdMutation, useUpdateHouseholdMutation } from '../households-api'

const CreateHouseholdModal = ({ isOpen, setIsOpen, household }) => {
	const toggleModal = () => setIsOpen(!isOpen)

	const [createHousehold] = useCreateHouseholdMutation()
	const [updateHousehold] = useUpdateHouseholdMutation()

	const validationSchema = Yup.object({
		householdName: Yup.string().trim().required('Group Name is required'),
	})

	const handleSubmit = async (values, { setSubmitting, resetForm }) => {
		try {
			let response
			if (household?.id) {
				response = await updateHousehold({
					payload: { name: values.householdName },
					id: household.id,
				})
			} else {
				response = await createHousehold({ name: values.householdName })
			}

			if (response?.data?.status === 200 || response?.data?.status === 201) {
				notification('success', response?.data?.message)
				toggleModal()
				resetForm()
			} else {
				notification('warn', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.response?.data?.message || 'An error occurred')
		}
		setSubmitting(false)
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={toggleModal}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-4 text-left shadow-xl transition-all'>
								<div className='relative flex items-center justify-between rounded-3xl bg-blue-50 p-3'>
									<div className='text-[20px] font-bold'>
										{household?.id ? 'Update' : 'Create'} Group
									</div>
									<button type='button' className='right-0' onClick={toggleModal}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>

								<Formik
									initialValues={{ householdName: household?.name || '' }}
									validationSchema={validationSchema}
									onSubmit={handleSubmit}>
									{({ isSubmitting }) => (
										<Form className='mt-3 flex flex-col'>
											<div className='flex flex-col gap-2'>
												<label className=' mt-2 px-2 pt-1'>
													Group Name:
												</label>
												<div className='px-1'>
													<Field
														className='form-control'
														type='text'
														name='householdName'
														placeholder='Enter group name'
													/>
													<ErrorMessage
														name='householdName'
														component='div'
														className='mt-1 text-sm text-red-500'
													/>
												</div>
											</div>

											<div className='mt-3 flex justify-end'>
												<Button type='submit' isLoading={isSubmitting}>
													{household?.id ? 'Update' : 'Create'}
												</Button>
											</div>
										</Form>
									)}
								</Formik>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default CreateHouseholdModal
