import { Field } from 'formik'
import { useEffect, useMemo } from 'react'

import { useLazyGetCategoriesQuery } from 'modules/scrum-boards/boards-api'
import { SelectField } from 'theme/ui/forms'

const TaskCategoryDropdown = ({
	label,
	name,
	onAutoSave,
	onHighlight,
	onCreateOption,
	className,
	helperText = '',
}) => {
	const [fetchCategory, { data, isLoading }] = useLazyGetCategoriesQuery()
	useEffect(() => {
		fetchCategory()
	}, [])

	const options = useMemo(
		() =>
			Array.isArray(data?.data)
				? data?.data.map((c) => ({
						label: (
							<div className='flex items-center gap-2'>
								<span
									className='block h-3 w-3 rounded-sm'
									style={{ backgroundColor: c?.color }}
								/>
								<span>{c?.name}</span>
							</div>
						),
						value: c?.id,
					}))
				: [],
		[data?.data],
	)

	return (
		<Field
			name={name}
			type='select'
			label={label}
			placeholder={label}
			isLoading={isLoading}
			options={options}
			className={className}
			onAutoSave={onAutoSave}
			onFocus={onHighlight}
			onCreateOption={onCreateOption}
			component={SelectField}
			isSearchable={false}
			isClearable={false}
			isMulti={false}
			helperText={helperText}
		/>
	)
}

export default TaskCategoryDropdown
