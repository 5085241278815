import { useDraggable } from '@dnd-kit/core'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { GripVertical, Trash2 } from 'lucide-react'
import { useDeleteTaskStatusMutation } from 'modules/scrum-boards/boards-api'
import { convertToTranslate } from 'modules/tasks/task-card'
import React, { useMemo } from 'react'
import Swal from 'sweetalert2'

const StatusCard = ({ status, index }) => {
	const { active, attributes, listeners, setNodeRef, transform } = useDraggable({
		id: status?.id,
		data: {
			index,
			task: status,
			parent: status?.parent,
			type: snakeCase(status?.parent),
		},
	})

	const [deleteStatus] = useDeleteTaskStatusMutation()

	const onDelete = async (id) => {
		const result = await Swal.fire({
			title: 'Are you sure?',
			text: 'This Status will be permanently deleted!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		})
		if (result?.isConfirmed) {
			const response = await deleteStatus(id).unwrap()
			if (response?.status === 200) {
				Swal.fire('Deleted!', 'Status has been deleted.', 'success')
			}
		}
	}

	const transForm = useMemo(() => convertToTranslate(transform), [transform])

	return (
		<div
			className={classNames(
				'space-y-2 rounded !border bg-white p-[10px]',
				active?.id === status?.id ? '!z-[1000] border-main shadow-md' : 'drop-shadow-sm',
			)}
			style={{ transform: transForm }}
			ref={setNodeRef}
			role='button'>
			<div className='flex justify-between items-center gap-3'>
				<button
					className='cursor-move  p-1 rounded hover:bg-gray-300'
					{...listeners}
					{...attributes}
					title='Drag to move'>
					<GripVertical size={14} />
				</button>

				<div className='flex-1'>
					<span>{status?.name}</span>
				</div>

				<button
					className='cursor-move p-1 rounded hover:text-red-600 text-red-400'
					title='Delete'
					onClick={() => onDelete(status?.id)}>
					<Trash2 size={14} />
				</button>
			</div>
		</div>
	)
}

export default StatusCard
