import React, { useMemo } from 'react'
import { Col, Row } from 'reactstrap'

const SystemField = ({ client }) => {
	const userProfile = client?.userProfile[0]
	const clientProfile = useMemo(
		() => ({
			address1: userProfile?.address_1,
			address2: userProfile?.address_2,
			city: userProfile?.city,
			unit: userProfile?.unit,
			province: userProfile?.province,
			zip_code: userProfile?.zip_code,
			country: userProfile?.country,
		}),
		[client],
	)

	return (
		<div>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>Address 1</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.address1}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>Address 2</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.address2}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>Unit</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.unit || '-'}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>City</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.city}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>Province</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.province}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>Zip Code</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.zip_code}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>Country</span>
				</Col>
				<Col xs={6}>
					<strong>{clientProfile?.country}</strong>
				</Col>
			</Row>
			<Row className='m-0 border-b p-3'>
				<Col xs={6}>
					<span className='text-gray-500'>SIN</span>
				</Col>
				<Col xs={6}>
					<strong>{client?.sin_number}</strong>
				</Col>
			</Row>
		</div>
	)
}

export default SystemField
