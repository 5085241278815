import { useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import ToolTip from 'theme/ui/tool-tip'
import notification from 'utilities/notification'
import { USERS_APP_ROUTES } from '../routes'
import { useRemoveUserMutation } from '../users-api'

const UsersActions = ({ hasTeam, id }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	const [removeUser, { isLoading }] = useRemoveUserMutation()
	const onRemoveUser = async (userId) => {
		try {
			const response = await removeUser({ userId })
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}
	return (
		<div>
			<DeleteConfirmAlert
				isDeleting={isLoading}
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={() => onRemoveUser(id)}
			/>
			<div className='float-right flex justify-end space-x-2'>
				<ToolTip targetId={`view-user-${id}`} tooltipText='View' placement='top' />
				<Link
					to={USERS_APP_ROUTES.view(id)}
					className='action-btn btn-info-outline'
					id={`view-user-${id}`}>
					<i className='uil uil-eye' style={{ fontSize: 16 }} />
				</Link>
				<>
					{' '}
					<ToolTip targetId={`delete-user-${id}`} tooltipText='Delete' placement='top' />
					<button
						type='button'
						id={`delete-user-${id}`}
						className='action-btn btn-red-outline'
						disabled={hasTeam}
						onClick={onOpen}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
					</button>
				</>
			</div>
		</div>
	)
}

export default UsersActions
