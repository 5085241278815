import checMarkIcon from 'assets/svg/green-check-mark.png'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Page } from 'react-pdf'
import FieldActionButtons from '../field-action-buttons'
import RestoreLabel from '../restore-label'

// Constants for default field dimensions
const DEFAULT_FIELD_WIDTH = 152
const DEFAULT_FIELD_HEIGHT = 24

// Utility function to check if two rectangles overlap
const doRectanglesOverlap = (rect1, rect2) => {
	return (
		rect1.x < rect2.x + rect2.width &&
		rect1.x + rect1.width > rect2.x &&
		rect1.y < rect2.y + rect2.height &&
		rect1.y + rect1.height > rect2.y
	)
}

const PageFields = ({
	pageIndex,
	fields,
	containerWidth,
	isMultiSelectMode,
	selectedFields,
	onFieldSelect,
	onDelete,
	onRestore,
	arrayHelpers,
	formValues,
	onAutoSave,
	setFields,
	userOptions,
	dragPreview, // Added from parent component
	setDragPreview, // Added from parent component
}) => {
	const [resizingField, setResizingField] = useState(null)
	const [draggingField, setDraggingField] = useState(null)
	const dragRef = useRef(null)
	const resizeRef = useRef(null)

	const pageFields = useMemo(
		() =>
			Array.isArray(fields)
				? fields.filter((field) =>
						field.rects.some((rect) => rect.page.index === pageIndex + 1),
					)
				: [],
		[fields, pageIndex],
	)
	const document_mapping = useMemo(
		() => (Array.isArray(formValues?.document_mapping) ? formValues?.document_mapping : []),
		[formValues],
	)
	const delete_document_mapping = useMemo(
		() =>
			Array.isArray(formValues?.delete_document_mapping)
				? formValues?.delete_document_mapping
				: [],
		[formValues],
	)
	const new_fields = useMemo(
		() => (Array.isArray(formValues?.new_fields) ? formValues?.new_fields : []),
		[formValues],
	)

	const updateNewFieldsRects = (fieldName, updatedRects) => {
		const updatedNewFields = new_fields.map((field) =>
			field.original_name === fieldName ? { ...field, rects: updatedRects } : field,
		)
		arrayHelpers.form.setFieldValue('new_fields', updatedNewFields)
	}

	const pageRef = useRef(null)
	const [scale, setScale] = useState(1)

	// Calculate scale when container width changes
	useEffect(() => {
		if (containerWidth) {
			setScale(containerWidth / 612) // Standard PDF page width
		}
	}, [containerWidth])

	const handleDragOver = (e) => {
		e.preventDefault()
		if (!draggingField && !resizingField) {
			const rect = pageRef.current.getBoundingClientRect()
			const x = (e.clientX - rect.left) / scale
			const y = (e.clientY - rect.top) / scale
			setDragPreview({
				x: x - DEFAULT_FIELD_WIDTH / 2, // Center the preview
				y: y - DEFAULT_FIELD_HEIGHT / 2,
				type: e.dataTransfer.getData('fieldType'),
				page: { index: pageIndex + 1 },
			})
		}
	}

	const handleDrop = (e) => {
		e.preventDefault()
		setDragPreview(null)

		const fieldType = e.dataTransfer.getData('fieldType')
		if (!fieldType) return

		const rect = pageRef.current.getBoundingClientRect()
		const x = (e.clientX - rect.left) / scale
		const y = (e.clientY - rect.top) / scale

		// Center the field at drop position
		const adjustedX = x - DEFAULT_FIELD_WIDTH / 2
		const adjustedY = y - DEFAULT_FIELD_HEIGHT / 2

		// Ensure field stays within page bounds
		const boundedX = Math.max(0, Math.min(adjustedX, 612 - DEFAULT_FIELD_WIDTH))
		const boundedY = Math.max(0, Math.min(adjustedY, 792 - DEFAULT_FIELD_HEIGHT))

		const newFieldRect = {
			x: boundedX,
			y: boundedY,
			width: DEFAULT_FIELD_WIDTH,
			height: DEFAULT_FIELD_HEIGHT,
			page: { index: pageIndex + 1, width: 612, height: 792 },
		}

		// Check for overlap with existing fields
		const overlaps = pageFields.some((field) =>
			field.rects.some(
				(rect) =>
					rect.page.index === pageIndex + 1 && doRectanglesOverlap(newFieldRect, rect),
			),
		)

		if (overlaps) return // Prevent drop if it overlaps

		const newFields = []
		const newMappings = []

		const primaryField = {
			original_name: `${fieldType}_${Date.now()}`,
			rects: [newFieldRect],
			type: fieldType,
			is_new: true,
		}
		newFields.push(primaryField)

		if (fieldType === 'signature') {
			const timestampRect = {
				page: { index: pageIndex + 1, width: 612, height: 792 },
				x: boundedX,
				y: boundedY + DEFAULT_FIELD_HEIGHT + 5, // Position below signature with padding
				width: DEFAULT_FIELD_WIDTH,
				height: DEFAULT_FIELD_HEIGHT,
			}

			// Check if timestamp overlaps with any existing fields
			const timestampOverlaps = pageFields.some((field) =>
				field.rects.some(
					(rect) =>
						rect.page.index === pageIndex + 1 &&
						doRectanglesOverlap(timestampRect, rect),
				),
			)

			if (!timestampOverlaps) {
				const timestampField = {
					original_name: `timestamp_${primaryField.original_name}`,
					rects: [timestampRect],
					type: 'timestamp',
					is_new: true,
					linked_to: primaryField.original_name,
				}
				newFields.push(timestampField)

				newMappings.push({
					pdf_field: timestampField.original_name,
					field: 'timestamp',
					user: null,
					is_required: false,
					type: 'timestamp',
					linked_to: primaryField.original_name,
					is_document_bound: true,
				})
			}
		}

		newMappings.push({
			pdf_field: primaryField.original_name,
			rects: primaryField.rects,
			field: fieldType,
			user: null,
			is_required: false,
			type: fieldType,
			is_document_bound: fieldType === 'signature',
		})

		const updatedFields = [...fields, ...newFields]
		const updatedDocumentMapping = [...formValues.document_mapping, ...newMappings]
		const updatedNewFields = [...formValues.new_fields, ...newFields]

		Promise.all([
			arrayHelpers.form.setFieldValue('pdf_fields', updatedFields),
			arrayHelpers.form.setFieldValue('new_fields', updatedNewFields),
			arrayHelpers.form.setFieldValue('document_mapping', updatedDocumentMapping),
		]).then(() => {
			setFields(updatedFields)
			onAutoSave()
		})
	}

	const handleResizeStart = (e, fieldName, rectIdx, direction) => {
		e.stopPropagation()
		const field = fields.find((f) => f.original_name === fieldName)
		if (!field) return

		const rect = field.rects[rectIdx]
		setResizingField({ fieldName, rectIdx, direction, startX: e.clientX, startY: e.clientY })
		resizeRef.current = { x: rect.x, y: rect.y, width: rect.width, height: rect.height }
	}

	const handleResize = (e) => {
		if (!resizingField || !resizeRef.current) return

		const field = fields.find((f) => f.original_name === resizingField.fieldName)
		if (!field) return

		const scale = containerWidth / 612
		const deltaX = (e.clientX - resizingField.startX) / scale
		const deltaY = (e.clientY - resizingField.startY) / scale

		let newWidth = resizeRef.current.width
		let newHeight = resizeRef.current.height
		let newX = resizeRef.current.x
		let newY = resizeRef.current.y

		switch (resizingField.direction) {
			case 'bottom-right':
				newWidth = Math.max(20, resizeRef.current.width + deltaX)
				newHeight = Math.max(20, resizeRef.current.height + deltaY)
				break
			case 'bottom-left':
				newWidth = Math.max(20, resizeRef.current.width - deltaX)
				newX = resizeRef.current.x + deltaX
				newHeight = Math.max(20, resizeRef.current.height + deltaY)
				break
			case 'top-right':
				newWidth = Math.max(20, resizeRef.current.width + deltaX)
				newHeight = Math.max(20, resizeRef.current.height - deltaY)
				newY = resizeRef.current.y + deltaY
				break
			case 'top-left':
				newWidth = Math.max(20, resizeRef.current.width - deltaX)
				newHeight = Math.max(20, resizeRef.current.height - deltaY)
				newX = resizeRef.current.x + deltaX
				newY = resizeRef.current.y + deltaY
				break
		}

		const updatedRect = { x: newX, y: newY, width: newWidth, height: newHeight }

		// Check for overlap with other fields
		const overlaps = pageFields.some(
			(f) =>
				f.original_name !== resizingField.fieldName &&
				f.rects.some(
					(rect) =>
						rect.page.index === pageIndex + 1 && doRectanglesOverlap(updatedRect, rect),
				),
		)

		if (overlaps) return // Prevent resize if it overlaps

		const updatedFields = fields.map((f) =>
			f.original_name === resizingField.fieldName
				? {
						...f,
						rects: f.rects.map((r, idx) =>
							idx === resizingField.rectIdx ? { ...r, ...updatedRect } : r,
						),
					}
				: f,
		)

		setFields(updatedFields)
	}

	const handleResizeEnd = () => {
		if (resizingField) {
			const field = fields.find((f) => f.original_name === resizingField.fieldName)
			if (field) {
				const isNewField = new_fields.some(
					(f) => f.original_name === resizingField.fieldName,
				)
				Promise.all([
					arrayHelpers.form.setFieldValue('pdf_fields', fields),
					isNewField
						? updateNewFieldsRects(resizingField.fieldName, field.rects)
						: Promise.resolve(),
				]).then(() => {
					onAutoSave()
				})
			}
		}
		setResizingField(null)
		resizeRef.current = null
	}

	const handleDragStart = (e, fieldName, rectIdx) => {
		e.stopPropagation()
		const field = fields.find((f) => f.original_name === fieldName)
		if (!field) return

		const rect = field.rects[rectIdx]
		setDraggingField({ fieldName, rectIdx, startX: e.clientX, startY: e.clientY })
		dragRef.current = { x: rect.x, y: rect.y }
	}

	const handleDrag = (e) => {
		if (!draggingField || !dragRef.current) return

		const field = fields.find((f) => f.original_name === draggingField.fieldName)
		if (!field) return

		const rect = field.rects[draggingField.rectIdx]
		const scale = containerWidth / 612
		const deltaX = (e.clientX - draggingField.startX) / scale
		const deltaY = (e.clientY - draggingField.startY) / scale

		const newX = Math.max(0, Math.min(dragRef.current.x + deltaX, 612 - rect.width))
		const newY = Math.max(0, Math.min(dragRef.current.y + deltaY, 792 - rect.height))

		const updatedRect = { ...rect, x: newX, y: newY }

		// Check for overlap with other fields
		const overlaps = pageFields.some(
			(f) =>
				f.original_name !== draggingField.fieldName &&
				f.rects.some(
					(r) => r.page.index === pageIndex + 1 && doRectanglesOverlap(updatedRect, r),
				),
		)

		if (overlaps) return // Prevent drag if it overlaps

		let updatedFields = fields.map((f) =>
			f.original_name === draggingField.fieldName
				? {
						...f,
						rects: f.rects.map((r, idx) =>
							idx === draggingField.rectIdx ? { ...r, x: newX, y: newY } : r,
						),
					}
				: f,
		)

		if (field.type === 'signature') {
			const timestampField = fields.find((f) => f.linked_to === field.original_name)
			if (timestampField) {
				const timestampRect = timestampField.rects[0]
				const originalOffsetY = timestampRect.y - rect.y
				const minSeparation = 5
				const defaultHeight = 24

				let newTimestampY = newY + Math.max(originalOffsetY, defaultHeight + minSeparation)
				const boundedTimestampY = Math.max(
					0,
					Math.min(newTimestampY, 792 - timestampRect.height),
				)

				const updatedTimestampRect = {
					...timestampRect,
					x: newX,
					y: boundedTimestampY,
				}

				// Check for overlap of timestamp with other fields (excluding the signature field)
				const timestampOverlaps = pageFields.some(
					(f) =>
						f.original_name !== timestampField.original_name &&
						f.original_name !== draggingField.fieldName &&
						f.rects.some(
							(r) =>
								r.page.index === pageIndex + 1 &&
								doRectanglesOverlap(updatedTimestampRect, r),
						),
				)

				if (timestampOverlaps) return // Prevent drag if timestamp overlaps

				updatedFields = updatedFields.map((f) =>
					f.original_name === timestampField.original_name
						? {
								...f,
								rects: f.rects.map((r) => ({
									...r,
									x: newX,
									y: boundedTimestampY,
								})),
							}
						: f,
				)
			}
		}

		setFields(updatedFields)
	}

	const handleDragEnd = () => {
		if (draggingField) {
			const field = fields.find((f) => f.original_name === draggingField.fieldName)
			if (field) {
				const isNewField = new_fields.some(
					(f) => f.original_name === draggingField.fieldName,
				)
				const promises = [arrayHelpers.form.setFieldValue('pdf_fields', fields)]

				// Prepare updates for new_fields
				let updatedNewFields = [...new_fields]

				// Update the signature field's rects if it's a new field
				if (isNewField) {
					updatedNewFields = updatedNewFields.map((f) =>
						f.original_name === draggingField.fieldName
							? { ...f, rects: field.rects }
							: f,
					)
				}

				// Handle signature field with linked timestamp
				if (field.type === 'signature') {
					const timestampField = fields.find(
						(f) => f.linked_to === draggingField.fieldName,
					)
					if (timestampField) {
						const isNewTimestampField = new_fields.some(
							(f) => f.original_name === timestampField.original_name,
						)
						if (isNewTimestampField) {
							updatedNewFields = updatedNewFields.map((f) =>
								f.original_name === timestampField.original_name
									? { ...f, rects: timestampField.rects }
									: f,
							)
						}
					}
				}

				// Apply all updates to new_fields in a single operation
				if (isNewField || (field.type === 'signature' && updatedNewFields !== new_fields)) {
					promises.push(arrayHelpers.form.setFieldValue('new_fields', updatedNewFields))
				}

				Promise.all(promises)
					.then(() => {
						onAutoSave()
					})
					.catch((err) => console.error('Error saving fields:', err))
			}
		}
		setDraggingField(null)
		dragRef.current = null
	}

	useEffect(() => {
		const handleMouseMove = (e) => {
			handleResize(e)
			handleDrag(e)
		}
		const handleMouseUp = () => {
			handleResizeEnd()
			handleDragEnd()
		}

		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
		return () => {
			document.removeEventListener('mousemove', handleMouseMove)
			document.removeEventListener('mouseup', handleMouseUp)
		}
	}, [resizingField, draggingField, fields])

	return (
		<div
			ref={pageRef}
			className='relative outline outline-1 outline-gray-200'
			onDragOver={handleDragOver}
			onDrop={handleDrop}
			onDragLeave={() => setDragPreview(null)}>
			<Page pageNumber={pageIndex + 1} width={containerWidth || window.innerWidth * 0.9} />

			{/* Render drag preview if on this page */}
			{dragPreview && dragPreview?.page?.index === pageIndex + 1 && (
				<div
					className='absolute border-2 border-dashed border-blue-500 bg-blue-100/30 flex items-center justify-center text-xs'
					style={{
						left: `${dragPreview.x * scale}px`,
						top: `${dragPreview.y * scale}px`,
						width: `${DEFAULT_FIELD_WIDTH * scale}px`,
						height: `${DEFAULT_FIELD_HEIGHT * scale}px`,
						zIndex: 999,
					}}>
					{dragPreview.type}
				</div>
			)}
			{pageFields.flatMap((field, fieldIdx) =>
				field.rects
					.filter((rect) => rect.page.index === pageIndex + 1)
					.map((rect, rectIdx) => {
						const scale = containerWidth / (rect.page.width || 612)
						const isSelected = selectedFields.includes(field.original_name)
						const mappedField = document_mapping.find(
							(f) => f?.pdf_field === field?.original_name,
						)
						const deleteField = delete_document_mapping.find(
							(f) => f?.pdf_field === field?.original_name,
						)
						const isDeleted = !mappedField && !!deleteField && !field.is_new
						const isRequired = mappedField?.is_required || false
						const isMapped =
							(!!mappedField?.field ||
								mappedField?.type === 'signature' ||
								mappedField?.type === 'timestamp') &&
							!!mappedField?.user

						const displayName =
							field.type === 'timestamp' ? 'Signature Timestamp' : field.original_name
						const fieldColor = userOptions.find(
							(o) => o?.value === mappedField?.user?.value,
						)

						return (
							<div
								className={classNames(
									'text-xs absolute transition-all duration-50',
									{
										'outline outline-1 outline-gray-500 text-gray-600 bg-gray-300':
											isDeleted,
										'outline outline-2 outline-transparent bg-yellow-100/80':
											isSelected,
										'outline outline-1': isMapped && !isDeleted,
										'border-l-4 border-green-500': isMapped && !isDeleted,
										'outline outline-1 outline-blue-600 bg-white/80 text-gray-500':
											!isMapped && !isDeleted && !isSelected,
										'z-[980] shadow-[0_0_0_2px_rgba(91,115,232,0.8),0_0_0_4px_rgba(91,115,232,0.5)]':
											isSelected,
										'z-[970]': !isSelected && field.type !== 'timestamp',
										'z-[975]': !isSelected && field.type === 'timestamp',
										'opacity-70': field.type === 'timestamp',
										'cursor-move':
											isSelected && !isDeleted && !isMultiSelectMode,
									},
								)}
								style={{
									left: `${rect.x * scale}px`,
									top: `${rect.y * scale}px`,
									width: `${rect.width * scale}px`,
									height: `${rect.height * scale}px`,
									backgroundColor:
										isMapped && !isDeleted ? fieldColor?.color : {},
									color: isMapped && !isDeleted ? fieldColor?.textColor : {},
									outlineColor:
										isMapped && !isDeleted ? fieldColor?.textColor : {},
								}}
								key={`${field.original_name}-${fieldIdx}-${rectIdx}`}
								onMouseDown={(e) => {
									if (
										e.target instanceof HTMLButtonElement ||
										e.target.className.includes('resize-handle')
									)
										return
									if (isSelected && !isDeleted && !isMultiSelectMode) {
										handleDragStart(e, field.original_name, rectIdx)
									} else {
										onFieldSelect(field.original_name, arrayHelpers)
									}
								}}>
								{isRequired && (
									<div className='absolute top-0 right-0 flex gap-1'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={Math.min(rect.height * 0.3, 12)}
											height={Math.min(rect.height * 0.3, 12)}
											fill='none'
											viewBox='0 0 12 12'
											style={{ minWidth: '8px', minHeight: '8px' }}>
											<path fill='#F46D6D' d='M0 0h12v10a2 2 0 0 1-2 2H0z' />
											<path
												stroke='#fff'
												strokeWidth='2'
												d='M6 2v4.308m0 0L3.538 10M6 6.308 8.462 10M6 6.308l4-1.231m-4 1.23-4-1.23'
											/>
										</svg>
									</div>
								)}
								{!isDeleted && isSelected && !isMultiSelectMode && (
									<>
										<FieldActionButtons
											fieldName={field.original_name}
											onDelete={onDelete}
											arrayHelpers={arrayHelpers}
										/>
										{isMapped && !isDeleted ? (
											<div
												className='absolute top-1/2 -right-6 transform -translate-y-1/2'
												style={{ width: '20px', height: '20px' }}>
												<img
													src={checMarkIcon}
													alt='check-mark'
													className='h-auto w-full'
												/>
											</div>
										) : undefined}

										<div
											className='absolute bottom-[-4px] right-[-4px] w-2 h-2 bg-blue-600 rounded-full cursor-se-resize resize-handle'
											onMouseDown={(e) =>
												handleResizeStart(
													e,
													field.original_name,
													rectIdx,
													'bottom-right',
												)
											}
										/>
										<div
											className='absolute bottom-[-4px] left-[-4px] w-2 h-2 bg-blue-600 rounded-full cursor-sw-resize resize-handle'
											onMouseDown={(e) =>
												handleResizeStart(
													e,
													field.original_name,
													rectIdx,
													'bottom-left',
												)
											}
										/>
										<div
											className='absolute top-[-4px] right-[-4px] w-2 h-2 bg-blue-600 rounded-full cursor-ne-resize resize-handle'
											onMouseDown={(e) =>
												handleResizeStart(
													e,
													field.original_name,
													rectIdx,
													'top-right',
												)
											}
										/>
										<div
											className='absolute top-[-4px] left-[-4px] w-2 h-2 bg-blue-600 rounded-full cursor-nw-resize resize-handle'
											onMouseDown={(e) =>
												handleResizeStart(
													e,
													field.original_name,
													rectIdx,
													'top-left',
												)
											}
										/>
									</>
								)}
								{isDeleted && isSelected && !isMultiSelectMode && (
									<RestoreLabel
										fieldName={field.original_name}
										onRestore={() => onRestore([field?.original_name])}
									/>
								)}
								<div
									className={classNames(
										'w-full h-full flex items-center justify-between text-center overflow-hidden break-words select-none',
									)}>
									<span className='line-clamp-1 pl-4'>{displayName}</span>
								</div>
							</div>
						)
					}),
			)}
		</div>
	)
}

export default PageFields
