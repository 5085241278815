import classNames from 'classnames'
import { Field, Formik } from 'formik'
import React from 'react'

const CertificatesOptions = ({ name = 'certificate-type' }) => {
	const options = [
		{
			value: 'combined',
			label: 'Generate a Combined Certificate',
			description: 'All documents will be merged into a single certificate.',
		},
		{
			value: 'separate',
			label: 'Generate Separate Certificates',
			description: 'Each document will have its own individual certificate.',
		},
	]

	return (
		<Formik initialValues={{ [name]: 'separate' }}>
			{({ values }) => (
				<div>
					<ul className='m-0 flex flex-col p-0 '>
						{options.map((option) => (
							<li key={option.value}>
								<Field name={name}>
									{({ field }) => (
										<label
											htmlFor={option.value}
											className={classNames(
												'inline-flex w-full items-center gap-2.5 rounded-md border border-gray-200 bg-white px-2 py-2 text-gray-500 active:bg-main/20 peer-checked:border-main peer-checked:text-main',
												{
													'border-main text-main':
														values[name] === option.value,
												},
											)}>
											<input
												type='radio'
												id={option.value}
												{...field}
												value={option.value}
												disabled={true}
												checked={values[name] === option.value}
												className='form-check-input peer disabled:cursor-not-allowed'
											/>
											<div className='block'>
												<div className='w-full text-md font-semibold'>
													{option.label}
												</div>
												{option.description && (
													<div className='w-full text-xs font-normal'>
														{option.description}
													</div>
												)}
											</div>
										</label>
									)}
								</Field>
							</li>
						))}
					</ul>
				</div>
			)}
		</Formik>
	)
}

export default CertificatesOptions
