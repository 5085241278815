import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import FilePenIcon from '../../../../../../../assets/svg/file-pen-line.svg'
import AddressForm from './address-form'

const AddressFormPopup = ({ client }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	return (
		<div>
			<button className='btn btn-outline-primary btn-sm' onClick={onOpen} type='button'>
				<i className='uil uil-pen' /> Edit
			</button>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/30' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-fit transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
									<div className='relative flex justify-between rounded-3xl bg-blue-50 p-3'>
										<div></div>
										<div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white p-3'>
											<img
												src={FilePenIcon}
												alt='File Check'
												className='h-6 w-6 text-blue-500'
											/>
										</div>
										<button type='button' className='right-0' onClick={onClose}>
											<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
										</button>
									</div>
									<div className='mt-10 flex flex-col'>
										<div className='ms-4 flex flex-col justify-start ps-2'>
											<Dialog.Title
												as='h2'
												className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
												Edit Client Information
											</Dialog.Title>
											<p className='m-0 p-0 text-sm text-gray-500'>
												{`Update the client's information below and save the
												changes.`}
											</p>
										</div>
										<hr className='mx-4 my-3 bg-gray-300' />

										<div className='rounded-t-[20px] p-3 md:rounded-b-[20px]'>
											<AddressForm client={client} onClose={onClose} />
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	)
}

export default AddressFormPopup