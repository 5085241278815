import classNames from 'classnames'
import { useTabs } from 'contexts/tabs-context'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Alert from 'theme/ui/alert'
import { Button } from 'theme/ui/forms'
import { removeQueryParam } from 'utilities/helpers'
import { getTabs } from '../tabs-header'
const TabsFooter = ({ formik, document }) => {
	const { activeTab, setActiveTab } = useTabs(2)
	const isSignatureRequired = useMemo(
		() => formik?.values?.signature_required === 'yes',
		[formik?.values],
	)
	const totalTabs = useMemo(
		() =>
			getTabs(
				isSignatureRequired,
				formik?.values?.document_type === 'form' ||
					formik?.values?.document_type === 'public_form',
			).length,
		[isSignatureRequired],
	)

	const isDisabledWhenDoc = formik?.values?.document_type === 'document' && !formik?.values?.file

	const history = useHistory()

	const onPrevious = () => {
		const queryString = location.search
		const updatedQueryString = removeQueryParam(queryString, 'isCreate')
		history.push(`${location.pathname}?${updatedQueryString}`)
		setActiveTab(activeTab - 1)
	}

	const errors = formik?.errors?.document_mapping || {}
	const document_mapping_errors = Object.values(errors).filter((err) => err !== undefined)
	const fields = Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const document_mapping = useMemo(
		() => (Array.isArray(formValues?.document_mapping) ? formValues?.document_mapping : []),
		[formValues],
	)
	const delete_document_mapping = useMemo(
		() =>
			Array.isArray(formValues?.delete_document_mapping)
				? formValues?.delete_document_mapping
				: [],
		[formValues],
	)
	const mappedFields = document_mapping.filter(
		(mapping) =>
			(!!mapping?.field || mapping?.type === 'signature' || mapping?.type === 'timestamp') &&
			!!mapping?.user &&
			!!mapping?.pdf_field,
	).length

	const $unMappedFields = document_mapping.filter(
		(mapping) =>
			(!mapping?.field && mapping?.type !== 'signature' && mapping?.type !== 'timestamp') ||
			!mapping?.user ||
			!mapping?.pdf_field,
	)

	const unMappedFields = $unMappedFields.length

	const selectedFields = $unMappedFields.map((f) => f?.pdf_field)

	const updateSelectedFields = useCallback(
		(deleted) => {
			if (!selectedFields.length) return

			if (deleted) {
				const updated_document_mapping = document_mapping.filter(
					(f) => !selectedFields.includes(f?.pdf_field),
				)
				const deleted_fields = document_mapping.filter((f) =>
					selectedFields.includes(f?.pdf_field),
				)
				formik.setFieldValue('document_mapping', [...updated_document_mapping])
				formik.setFieldValue('delete_document_mapping', [
					...delete_document_mapping,
					...deleted_fields,
				])
			} else {
				const updated_delete_document_mapping = delete_document_mapping.filter(
					(f) => !selectedFields.includes(f?.pdf_field),
				)
				const restored_fields = delete_document_mapping.filter((f) =>
					selectedFields.includes(f?.pdf_field),
				)
				formik.setFieldValue('delete_document_mapping', [
					...updated_delete_document_mapping,
				])
				formik.setFieldValue('document_mapping', [...document_mapping, ...restored_fields])
			}
		},
		[selectedFields, document_mapping, delete_document_mapping],
	)
	return (
		<div className='tabs_spacing px-[75px]'>
			{document_mapping_errors.length > 0 ? (
				<div className='bg-white'>
					<Alert variant='warning'>
						<div className='flex flex-col px-1'>
							<span className='font-semibold text-base'>Note</span>
							<p>
								Out of a total of{' '}
								<span className='font-medium'>{fields.length}</span> fields,{' '}
								<span className='font-medium'>{mappedFields}</span> have been
								successfully assigned,{' '}
								<span className='font-medium'>{unMappedFields}</span> remains
								unassigned, and{' '}
								<span className='font-medium'>
									{delete_document_mapping.length}
								</span>{' '}
								have been deleted.
							</p>
							<p>
								If you prefer not to use the unassigned{' '}
								{selectedFields.length > 1 ? 'fields' : 'field'}, you can delete{' '}
								{selectedFields.length > 1 ? 'them' : 'it'} by clicking{' '}
								<span
									className='text-main font-semibold hover:underline cursor-pointer'
									onClick={() => updateSelectedFields(true)}>
									here
								</span>
								.{' '}
							</p>
						</div>
					</Alert>
				</div>
			) : undefined}
			<div
				className={classNames(
					'flex !border-t py-6',
					activeTab === 1 ? 'justify-end' : 'justify-between',
				)}>
				{activeTab > 1 ? (
					<Button type='button' onClick={onPrevious} variant='white'>
						Previous
					</Button>
				) : undefined}
				<div className='next-doc-button'>
					<Button
						className='next-doc-button'
						type='submit'
						isLoading={formik.isSubmitting}
						disabled={isDisabledWhenDoc || document_mapping_errors.length > 0}
						isLoadingText='Saving...'>
						{activeTab === totalTabs ? 'Publish Now' : 'Next'}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default TabsFooter
