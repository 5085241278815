import { Tab } from '@headlessui/react'
import { getIn } from 'formik'
import { Fragment, useMemo } from 'react'

import { useAppRouter } from 'hooks'
// import { DocumentViewerProvider } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
// import FieldMapping from './upload-document/field-mapping'
import { getUserTypeOption } from '.'
import FormDesignerTab from './form-designer-tab'
import PdfPreviewTab from './pdf-preview-tab'
import SignatureTab from './signature-tab'
import UploadDocument from './upload-document'

const TabsContent = ({ formik, document, onAutoSave, isAutoSaving }) => {
	const formBuilderName = 'document_body'
	const { params } = useAppRouter()
	const documentId = document?.id || params?.id
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const numberOfUsers = formValues?.min_clients?.value
	// const userColors = ['#FFF2CC', '#D9EAD3', '#C9DAF8', '#D9D2E9', '#EAD1DC', '#E6B8AF']
	const userOptions = useMemo(
		() => getUserTypeOption(numberOfUsers),
		[numberOfUsers, getUserTypeOption],
	)

	const fields = useMemo(
		() =>
			Array.isArray(getIn(formik?.values, formBuilderName))
				? getIn(formik?.values, formBuilderName)
				: [],
		[formik?.values],
	)
	const is_view_mode = useMemo(() => formik?.values?.is_view_mode, [formik?.values])
	const completed_fields =
		fields.filter((f) => f?.type !== null || f.type !== undefined || f?.type !== '').length > 0

	return (
		<Tab.Panels className='tabs_spacing px-[75px]' as='div'>
			<Tab.Panel>
				<UploadDocument formik={formik} document={document} onAutoSave={onAutoSave} />
			</Tab.Panel>

			{formik?.values?.document_type !== 'form' &&
			formik?.values?.document_type !== 'public_form' ? (
				<Fragment>
					<Tab.Panel>
						{/* <DocumentViewerProvider>
							<FieldMapping
								isAutoSaving={isAutoSaving}
								document={document}
								formik={formik}
								onAutoSave={onAutoSave}
							/>
						</DocumentViewerProvider> */}
						<PdfPreviewTab
							document={document}
							isAutoSaving={isAutoSaving}
							formik={formik}
							onAutoSave={onAutoSave}
							userOptions={userOptions}
						/>
					</Tab.Panel>
					<Tab.Panel>
						<FormDesignerTab
							formik={formik}
							completed_fields={completed_fields}
							fields={fields}
							userOptions={userOptions}
							is_view_mode={is_view_mode}
							fieldName={formBuilderName}
						/>
					</Tab.Panel>
				</Fragment>
			) : (
				<Tab.Panel>
					<FormDesignerTab
						formik={formik}
						completed_fields={completed_fields}
						fields={fields}
						userOptions={userOptions}
						is_view_mode={is_view_mode}
						fieldName={formBuilderName}
					/>
				</Tab.Panel>
			)}

			<Tab.Panel>
				<SignatureTab formik={formik} documentId={documentId} />
			</Tab.Panel>
		</Tab.Panels>
	)
}

export default TabsContent
