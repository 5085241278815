import { Dialog, Transition } from '@headlessui/react'
import { History } from 'lucide-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import Spinner from 'theme/ui/spinner'
import { useLazyGetSftpHistoryQuery } from '../../sftp-config-api'
import SftpHistoryList from './sftp-history-list'
const SftpHistoryModal = ({ isOpen, setIsHistoryOpen }) => {
	const [fetchSftpHistory, { data, isLoading }] = useLazyGetSftpHistoryQuery()
	const [historyData, setHistoryData] = useState([])
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { pageIndex, pageSize } = pagination
	const params = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		return obj
	}, [pageIndex, pageSize])

	useEffect(() => {
		console.log(params)
		fetchSftpHistory({ params, id: isOpen })
	}, [isOpen, params])

	useEffect(() => {
		if (Array.isArray(data?.data)) {
			setHistoryData(data?.data)
		}
	}, [data])

	return (
		<Transition appear show={isOpen ? true : false} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white !p-3'>
										<History />
									</div>
									<button
										type='button'
										className='right-0'
										onClick={() => setIsHistoryOpen(null)}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								{isLoading ? (
									<div className='my-10'>
										<Spinner />
									</div>
								) : (
									<div className='my-10'>
										<SftpHistoryList
											meta={data?.meta}
											isLoading={isLoading}
											historyData={historyData}
											pagination={pagination}
											setPagination={setPagination}
										/>
									</div>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SftpHistoryModal
