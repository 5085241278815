import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import updateTask from 'assets/svg/list-todo.svg'
import { Button } from 'theme/ui/forms'

const CustomModal = ({ isOpen, onClose, onUpdateSingle, onUpdateAll , isLoading }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [error, setError] = useState('')

  if (!isOpen) return null

  const handleApplyClick = () => {
    if (!selectedOption) {
      setError('Please select an option.')
      return
    }
    setError('') // Clear error message

    if (selectedOption === 'single') {
      onUpdateSingle(isLoading )
    } else if (selectedOption === 'all') {
      onUpdateAll(isLoading )
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-[9999] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black/30' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-xl z-[999999] transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
                  {/* Header with Blue Bar and Icon */}
                  <div className='relative flex justify-between rounded-3xl bg-blue-50 p-3'>
                    <div></div>
                    <div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white p-3'>
                      <img src={updateTask} alt='' />
                    </div>
                    <button
                      type='button'
                      className='right-0'
                      onClick={onClose}>
                      <i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
                    </button>
                  </div>

                  {/* Content Section */}
                  <div className='mt-10 flex flex-col'>
                    <div className='ms-3 flex flex-col justify-start p-2'>
                      <Dialog.Title
                        as='h2'
                        className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
                        Update Recurrence
                      </Dialog.Title>
                      <div>
                        <p className='m-0 p-0 text-base font-medium'>
                          Select an option below to update either this specific task or all tasks.
                        </p>
                      </div>
                    </div>

                    <hr className='mx-4 mb-3 bg-gray-300' />

                    {/* Radio Buttons for Selecting Actions */}
                    <div className='ms-3 mb-4 flex w-full flex-col pe-5 ps-2'>
                    <label htmlFor='single' className='flex items-center gap-2 cursor-pointer'>
                      <input
                        type='radio'
                        id='single'
                        name='updateOption'
                        value='single'
                        checked={selectedOption === 'single'}
                        onChange={() => setSelectedOption('single')}
                        className='form-check-input h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500'
                      />
                      <span className='text-base font-medium'>Update this specific task</span>
                    </label>

                    <label htmlFor='all' className='flex items-center gap-2 cursor-pointer'>
                      <input
                        type='radio'
                        id='all'
                        name='updateOption'
                        value='all'
                        checked={selectedOption === 'all'}
                        onChange={() => setSelectedOption('all')}
                        className='form-check-input h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500'
                      />
                      <span className='text-base font-medium'>Update All the tasks</span>
                    </label>
                  </div>

                    {/* Error message */}
                    {error && <div className='ms-4 mb-4 text-red-500 text-sm'>{error}</div>}

                    <div className='ms-3 flex w-full flex-col gap-2 pe-5 ps-2'>
                      <Button
                        disabled={isLoading}
                        isLoading={isLoading}
                        type='button'
                        block={true}
                        onClick={handleApplyClick}>
                        Apply
                      </Button>
                      <Button
                        variant='ghost'
                        type='button'
                        block={true}
                        onClick={onClose}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default CustomModal
