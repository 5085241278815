import { useState } from 'react'
import { generateUniqueId } from 'utilities/helpers'

const NetPromoterScoreView = ({ name, field, onChange }) => {
	const [selectedScore, setSelectedScore] = useState(field?.value || 0)
	const tooltipId = generateUniqueId()

	const handleScoreChange = (score) => {
		const newScore = selectedScore === score ? 0 : score
		setSelectedScore(newScore)
		onChange(name, newScore)
	}
	return (
		<div className='w-full space-y-2 rounded-md '>
			{field?.label && (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label} {field?.required && <span className='text-red-500'>*</span>}
					</span>
				</label>
			)}

			<div
				className='flex items-center justify-between overflow-hidden rounded-sm border border-gray-300'
				id={tooltipId}>
				{Array.from({ length: 11 }, (_, index) => (
					<button
						key={index}
						onClick={() => handleScoreChange(index)}
						className={`flex-1 !border border-r py-2 text-center text-sm font-medium transition-colors ${
							index == 10 ? '!border-r-0' : ''
						} ${
							field?.value == index || selectedScore == index
								? 'bg-primary text-white'
								: 'bg-white text-gray-700 hover:bg-gray-200'
						}`}>
						{index}
					</button>
				))}
			</div>

			<div className='mt-2 flex justify-between text-sm text-gray-700'>
				{field?.nps_label?.low_score ? (
					<span className='rounded-sm !border bg-white px-3 py-1'>
						{field?.nps_label?.low_score}
					</span>
				) : (
					<span></span>
				)}
				{field?.nps_label?.high_score ? (
					<span className='rounded-sm !border bg-white px-3 py-1'>
						{field?.nps_label?.high_score}
					</span>
				) : (
					<span></span>
				)}
			</div>

			{field?.description && (
				<span className='text-[13px] text-gray-400'>{field?.description}</span>
			)}
		</div>
	)
}

export default NetPromoterScoreView
