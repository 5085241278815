import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import CreateHouseholdModal from 'modules/households/household-create/household-create'
import { useMediaQuery } from 'react-responsive'
import { useDataTable } from 'theme/ui/data-table'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString, isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { toQueryString } from 'utils/commonFunctions'
import { getFormikFilterValues, getInitialValues } from './index'

const HouseholdsFilters = ({ setPagination }) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const [isOpen, setIsOpen] = useState(false)

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])
	const isGroupsPermission = isAllowTo(permissions?.groups?.create)

	return (
		<>
			<FormikProvider value={formik}>
				<form className='mb-3'>
					<div className='flex flex-col space-y-3'>
						<div className='flex justify-between'>
							<div className='min-w-[320px]'>
								<Field
									className='form-control'
									type='search'
									name='search'
									component={SearchField}
									placeholder='Search groups'
								/>
							</div>
							{isGroupsPermission && !isMobile ? (
								<Button type='button' onClick={() => setIsOpen(true)}>
									Create Group
								</Button>
							) : undefined}
						</div>
					</div>
				</form>
			</FormikProvider>
			<CreateHouseholdModal isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	)
}

export default HouseholdsFilters
