import userPlaceholder from 'assets/images/users/avatar1.jpg'
import { useLazyGetOneEmailQuery } from 'modules/clients/client-email-api'
import { Fragment, useEffect } from 'react'
import { Offcanvas, OffcanvasBody } from 'reactstrap'
import Spinner from 'theme/ui/spinner'
import RecipientCard from './recipient-card'

const EmailCanvas = ({ isOpen, onEmailClose, toggleCanvas, activeEmail, client_id }) => {
	const [fetchEmail, { data, isUninitialized, isFetching }] = useLazyGetOneEmailQuery()
	useEffect(() => {
		if (activeEmail) {
			fetchEmail(activeEmail)
		}
	}, [activeEmail])
	const email = data?.data
	const recipients = Array.isArray(email?.recipients)
		? email.recipients.map((r) => r?.recipient_user)
		: []
	const recipient = recipients.find((r) => r?.id === client_id)
	const $recipients = recipients.filter((r) => r?.id !== client_id)

	return (
		<Offcanvas
			backdrop={true}
			scrollable
			isOpen={isOpen}
			onClosed={onEmailClose}
			toggle={toggleCanvas}
			direction='end'
			className='create-task-sidebar overflow-auto md:min-w-[50%]'>
			{isUninitialized || isFetching ? (
				<div className='p-6'>
					<div>
						<Spinner label='Loading...' />
					</div>
				</div>
			) : email ? (
				<Fragment>
					<div className='offcanvas-header border-b py-[12px]'>
						<div className='createform-alignment flex w-full items-center justify-between'>
							{email?.subject ? (
								<h4 className='m-0 text-base font-bold'>{email?.subject}</h4>
							) : undefined}
							<div className='space-x-1'>
								<button
									type='button'
									className='btn !bg-[#F9F9F9] !p-1.5'
									onClick={onEmailClose}>
									<i className='fas fa-times px-2 text-[16px]' />
								</button>
							</div>
						</div>
					</div>
					<OffcanvasBody className='min-h-[70vh] !p-6'>
						<div className=''>
							{/* <div className='mb-3 flex items-center'>
							<button
								className='flex items-center text-base font-normal not-italic leading-5 text-main'
								onClick={onEmailClose}>
								<i className='uil-angle-left text-3xl' />
							</button>

							{email?.subject ? (
								<h4 className='m-0 text-base font-bold'>{email?.subject}</h4>
							) : undefined}
						</div> */}
							<div className='mb-8 flex max-w-[80%]  items-center space-x-2'>
								<img
									src={email?.sender?.avatar?.path || userPlaceholder}
									alt={''}
									className='aspect-square h-[32px] rounded-full'
								/>
								<div className='flex flex-col'>
									{email?.sender?.fullName ? (
										<span
											className='font-bold  text-gray-800'
											title={email?.sender?.fullName}>
											{email?.sender?.fullName}
										</span>
									) : undefined}

									<RecipientCard
										email={email}
										recipient={recipient}
										recipients={$recipients}
									/>
								</div>
							</div>
							{email?.body ? (
								<div dangerouslySetInnerHTML={{ __html: email?.body }} />
							) : undefined}
						</div>
					</OffcanvasBody>
				</Fragment>
			) : (
				<div className='p-6'>
					<div className='mb-3 flex items-center'>
						<button
							className='flex items-center text-base font-normal not-italic leading-5 text-main'
							onClick={onEmailClose}>
							<i className='uil-angle-left text-3xl' />
						</button>
					</div>
					<div>
						<p className='m-0 text-center'>Email not found</p>
					</div>
				</div>
			)}
		</Offcanvas>
	)
}

export default EmailCanvas
