import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'store/axios-base-query'

export const clientEmailApi = createApi({
	reducerPath: 'ClientEmails',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['ClientEmail'],
	endpoints: (builder) => ({
		getEmails: builder.query({
			query: ({ params, client_id }) => ({
				url: `/email-has-recipients/find-by-user-and-team/${client_id}`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['ClientEmail'],
		}),
		getOneEmail: builder.query({
			query: (client_id) => ({
				url: `/emails/${client_id}`,
				method: 'GET',
			}),
			providesTags: ['ClientEmail'],
		}),
		sendEmail: builder.mutation({
			query: (payload) => ({
				url: `/emails/send`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ClientEmail'],
		}),
		getClientGroups: builder.query({
			query: (client_id) => ({
				url: `/team-user-has-clientgroups/user-groups?userId=${client_id}`,
				method: 'GET',
			}),
		}),
	}),
})

export const {
	useSendEmailMutation,
	useLazyGetEmailsQuery,
	useLazyGetOneEmailQuery,
	useLazyGetClientGroupsQuery,
} = clientEmailApi
