import { useCallback, useState } from 'react'

export const useFieldSelection = (fields, document_mapping) => {
	const [selectedFields, setSelectedFields] = useState([])
	const [isMultiSelectMode, setIsMultiSelectMode] = useState(false)
	const [modifierKeys, setModifierKeys] = useState({ shift: false, ctrl: false })
	const [selectedField, setSelectedField] = useState(null)
	const [activeFieldSettings, setActiveFieldSettings] = useState(null)

	const handleFieldSelection = useCallback(
		(fieldName, arrayHelpers) => {
			const allFieldNames = fields.map((f) => f.original_name)
			const fieldIndex = document_mapping.findIndex((f) => f?.pdf_field === fieldName)
			const field_name = `${arrayHelpers.name}[${fieldIndex}]`
			setSelectedField(field_name)
			let newSelectedFields = []
			if (modifierKeys.ctrl || isMultiSelectMode) {
				// Multi-selection with Ctrl or existing multi-select mode
				setIsMultiSelectMode(true) // Activate multi-select mode
				newSelectedFields = selectedFields.includes(fieldName)
					? selectedFields.filter((name) => name !== fieldName)
					: [...selectedFields, fieldName]
			} else if (modifierKeys.shift && selectedFields.length > 0) {
				// Range selection with Shift
				setIsMultiSelectMode(true) // Activate multi-select mode
				const currentIdx = allFieldNames.indexOf(fieldName)
				const lastSelectedIdx = allFieldNames.indexOf(
					selectedFields[selectedFields.length - 1],
				)
				const start = Math.min(currentIdx, lastSelectedIdx)
				const end = Math.max(currentIdx, lastSelectedIdx)
				newSelectedFields = [
					...new Set([...selectedFields, ...allFieldNames.slice(start, end + 1)]),
				]
			} else {
				// Single selection
				setIsMultiSelectMode(false) // Deactivate multi-select mode
				newSelectedFields = [fieldName]
			}

			// If fewer than 2 fields remain after deselection, exit multi-select mode
			if (newSelectedFields.length < 2) {
				setIsMultiSelectMode(false)
			}

			setSelectedFields(newSelectedFields)
			updateActiveFieldSettings(newSelectedFields)
			return field_name
		},
		[fields, isMultiSelectMode, modifierKeys, selectedFields, document_mapping],
	)

	const updateActiveFieldSettings = useCallback(
		(newSelectedFields) => {
			if (newSelectedFields.length === 0) {
				setActiveFieldSettings(null)
				return
			}
			const selectedFieldsData = fields.filter((f) =>
				newSelectedFields.includes(f.original_name),
			)
			if (selectedFieldsData.some((f) => f.deleted)) {
				setActiveFieldSettings(null)
				return
			}
			if (newSelectedFields.length === 1) {
				setActiveFieldSettings(selectedFieldsData[0])
			} else {
				const allSame = (key) =>
					selectedFieldsData.every((f) => f[key] === selectedFieldsData[0][key])
				setActiveFieldSettings({
					...selectedFieldsData[0],
					original_name: `Multiple fields (${newSelectedFields.length})`,
					crmMapping: allSame('crmMapping') ? selectedFieldsData[0].crmMapping : '',
					fillableBy: allSame('fillableBy') ? selectedFieldsData[0].fillableBy : 'Client',
					required: allSame('required') ? selectedFieldsData[0].required : false,
					profileBound: allSame('profileBound')
						? selectedFieldsData[0].profileBound
						: false,
				})
			}
		},
		[fields],
	)

	return {
		activeFieldSettings,
		selectedField,
		selectedFields,
		setSelectedFields,
		isMultiSelectMode,
		setIsMultiSelectMode,
		modifierKeys,
		setModifierKeys,
		handleFieldSelection,
		setActiveFieldSettings,
	}
}
