import * as yup from 'yup'
import { POPUP_STATES } from '..'
import ChangeAssigneePopup from './change-assignee-popup'

export const getValidationSchema = (actionType) => {
	switch (actionType) {
		case POPUP_STATES.ASSIGNEE:
			return yup.object().shape({
				assignee_id: yup.mixed().nonNullable().required('Please choose assignee'),
				task_ids: yup.array(yup.string()).min(1),
			})

		case POPUP_STATES.STATUS:
			return yup.object().shape({
				task_status_id: yup.mixed().nonNullable().required('Please choose status'),
				task_ids: yup.array(yup.string()).min(1),
			})

		case POPUP_STATES.PRIORITY:
			return yup.object().shape({
				priority: yup.mixed().nonNullable().required('Please choose priority'),
				task_ids: yup.array(yup.string()).min(1),
			})

		default:
			return yup.object().shape({})
	}
}

export const getPayload = (values, actionType) => {
	switch (actionType) {
		case POPUP_STATES.ASSIGNEE:
			return {
				assignee_id: values?.assignee_id?.value,
				task_ids: Array.isArray(values?.task_ids) ? values?.task_ids : [],
			}

		case POPUP_STATES.STATUS:
			return {
				task_status_id: values?.task_status_id?.value,
				task_ids: Array.isArray(values?.task_ids) ? values?.task_ids : [],
			}

		case POPUP_STATES.PRIORITY:
			return {
				priority: values?.priority?.value,
				task_ids: Array.isArray(values?.task_ids) ? values?.task_ids : [],
			}

		default:
			return {}
	}
}

export default ChangeAssigneePopup
