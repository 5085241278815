import { Field } from 'formik'
import { Copy, Trash2 } from 'lucide-react'

import classNames from 'classnames'
import { FIELD_TYPES, OPTION_ABLE_FIELDS } from 'modules/form-designer/helpers'
import { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { Button, SwitchField } from 'theme/ui/forms'
import MultipleToggleField from './multiple-toggle-field'

const FieldActions = ({ field, onRemove, onClone, index, name, is_score_able, totalFields }) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false)
	return (
		<div className='flex items-center gap-3'>
			{field?.type ? (
				<div className='flex items-center gap-3 '>
					{field?.multiple || field?.type === 'list' ? (
						<Field name={`${name}.max_selected_option`}>
							{({ field, meta: { touched, error } }) => (
								<div className='invalid-tooltip-container'>
									<Tooltip
										id='my-tooltip'
										content={error}
										className='danger'
										isOpen={touched && error && isTooltipOpen}
									/>
									<input
										{...field}
										type='number'
										onFocus={() => setIsTooltipOpen(true)}
										onBlur={() => setIsTooltipOpen(false)}
										data-tooltip-id='my-tooltip'
										placeholder='Max options selection'
										className={classNames(
											'max-w-[40px] rounded !border !px-2 !py-1 text-center !text-[13px]',
											{
												'is-invalid': touched && error,
											},
										)}
									/>
								</div>
							)}
						</Field>
					) : undefined}
					{OPTION_ABLE_FIELDS.includes(field?.type) ? (
						<>
							<MultipleToggleField field={field} name={name} />
						</>
					) : undefined}

					{is_score_able &&
					[
						FIELD_TYPES.CHOICE,
						FIELD_TYPES.DROPDOWN,
						FIELD_TYPES.LIST,
						FIELD_TYPES.COMBOBOX,
						FIELD_TYPES.NETPROMOTER,
					].includes(field?.type) ? (
						<Field component={SwitchField} label='Scored' name={`${name}.is_scored`} />
					) : undefined}

					{/* {field?.type === 'text' ? <SubTypeField name={name} /> : undefined} */}

					<Field component={SwitchField} label='Required' name={`${name}.required`} />
				</div>
			) : undefined}
			<div className='flex'>
				{field?.type ? (
					<Button
						variant='ghost-white'
						size='sm'
						type='button'
						onClick={() => onClone(field)}>
						<Copy size={20} className='text-gray-700' strokeWidth='1px' />
					</Button>
				) : undefined}

				{totalFields > 1 ? (
					<Button
						variant='ghost-white'
						size='sm'
						type='button'
						onClick={() => onRemove(index)}>
						<Trash2 size={20} className='text-red-600' strokeWidth='1px' />
					</Button>
				) : undefined}

				{/* <Button variant='ghost-white' size='sm' type='button'>
								<EllipsisVertical
									size={20}
									className='text-gray-700'
									strokeWidth={1}
								/>
							</Button> */}
			</div>
		</div>
	)
}

export default FieldActions
