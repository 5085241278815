import classNames from 'classnames'
import { Field } from 'formik'
import { createElement } from 'react'
import Select from 'react-select'

import { FIELD_TYPES, FORM_CONTROLS } from 'modules/form-designer/helpers'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'

const FieldTypeField = ({ name, isDisabled = false, isRemoveNPS = false }) => {
	const createOption = (value) => {
		const option = FORM_CONTROLS.find((control) => {
			if ([FIELD_TYPES.CHOICE, FIELD_TYPES.CHECKBOX].includes(value)) {
				return FIELD_TYPES.CHOICE === control?.type
			} else {
				return control?.type === value
			}
		})
		if (!option) {
			return null
		}
		return {
			label: (
				<div className='flex items-center gap-1'>
					<span>
						{createElement(option?.icon, {
							className: '',
							size: 14,
							strokeWidth: '2px',
						})}
					</span>
					<span>{option?.title}</span>
				</div>
			),
			value: option?.type,
		}
	}

	const options = FORM_CONTROLS.filter((f) => f?.type !== 'timestamp')
		.map((option) => createOption(option?.type))
		.filter((option) =>
			isRemoveNPS ? !['netpromoter', 'rating'].includes(option?.value) : option,
		)

	return (
		<div className={classNames('min-w-[140px]')}>
			<Field name={`${name}.type`}>
				{({ field, form }) => (
					<Select
						{...field}
						isDisabled={isDisabled}
						value={field?.value ? createOption(field?.value) : null}
						options={options}
						onChange={(option) => form.setFieldValue(field?.name, option?.value)}
						isSearchable={false}
						isClearable={false}
						isMulti={field?.multiple}
						placeholder={'Field Type'}
						// classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
						classNamePrefix={'select-dropdown'}
						className={classNames('select-dropdown-container')}
						styles={{
							menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
							menu: (provided) => ({ ...provided, zIndex: 9999 }),
						}}
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
						components={{
							DropdownIndicator: DropdownIndicator,
						}}
					/>
				)}
			</Field>
		</div>
	)
}

export default FieldTypeField
