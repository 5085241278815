// Inside src/components/Workflow/AddStatusModal.js

import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';

// Default color for the picker
const DEFAULT_COLOR_PICKER = '#cccccc';

function AddStatusModal({ isOpen, onClose, onAddStatus }) {
  const [statusName, setStatusName] = useState('');
  const [statusColor, setStatusColor] = useState(DEFAULT_COLOR_PICKER); // State for color

  // Reset form when modal opens
  useEffect(() => {
      if (isOpen) {
          setStatusName('');
          setStatusColor(DEFAULT_COLOR_PICKER);
      }
  }, [isOpen]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!statusName.trim()) return;

    const newStatus = {
      id: uuidv4(),
      name: statusName.trim(),
      color: statusColor, // Include color in submitted data
    };
    onAddStatus(newStatus); // Pass the complete object
    handleClose(); // Close after adding
  };

  const handleClose = () => {
      // Resetting state is handled by useEffect based on isOpen now
      onClose();
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Modal Content */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded bg-white p-6 shadow-xl">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Add New Status
          </Dialog.Title>

          <form onSubmit={handleSubmit} className="mt-4 space-y-4"> {/* Added space-y */}
            {/* Status Name Input */}
            <div>
                <label htmlFor="status-name" className="block text-sm font-medium text-gray-700">
                    Status Name <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    id="status-name"
                    value={statusName}
                    onChange={(e) => setStatusName(e.target.value)}
                    className="mt-1 block w-full rounded border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                    autoFocus
                />
            </div>

            {/* Status Color Input */}
            <div>
                <label htmlFor="status-color" className="block text-sm font-medium text-gray-700">
                    Status Color
                </label>
                <input
                    type="color"
                    id="status-color"
                    value={statusColor}
                    onChange={(e) => setStatusColor(e.target.value)}
                    className="mt-1 h-10 w-full cursor-pointer rounded border border-gray-300 p-1" // Basic styling for color input
                />
                 {/* Optional: Display the hex code */}
                 {/* <span className="text-xs text-gray-500 ml-2">{statusColor}</span> */}
            </div>


            <div className="mt-6 flex justify-end gap-3 pt-4"> {/* Added padding-top */}
              <button
                type="button"
                onClick={handleClose}
                className="rounded bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
              >
                Add Status
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default AddStatusModal;