import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';

function AddTransitionModal({ isOpen, onClose, onAddTransition, statuses }) {
  const [fromStatus, setFromStatus] = useState('');
  const [toStatus, setToStatus] = useState('');
  const [transitionName, setTransitionName] = useState('');
  const [error, setError] = useState('');

  // Reset form when modal opens or closes, or statuses change
  useEffect(() => {
    if (isOpen) {
      setFromStatus(statuses[0]?.id || ''); // Default to first status if available
      setToStatus(statuses[0]?.id || '');
      setTransitionName('');
      setError('');
    }
  }, [isOpen, statuses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    if (!fromStatus || !toStatus || !transitionName.trim()) {
      setError('All fields are required.');
      return;
    }

    if (fromStatus === toStatus) {
      setError('From Status and To Status cannot be the same.');
      return;
    }

    const newTransition = {
      id: uuidv4(),
      from: fromStatus,
      to: toStatus,
      name: transitionName.trim(),
    };
    onAddTransition(newTransition); // Parent handles checking for duplicates if needed
    handleClose(); // Close after successful add
  };

   const handleClose = () => {
      // Resetting state is handled by useEffect based on isOpen
      onClose();
   }

  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-lg rounded bg-white p-6 shadow-xl">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Add New Transition
          </Dialog.Title>

          <form onSubmit={handleSubmit} className="mt-4 space-y-4">
            <div>
              <label htmlFor="from-status" className="block text-sm font-medium text-gray-700">
                From Status <span className="text-red-500">*</span>
              </label>
              <select
                id="from-status"
                value={fromStatus}
                onChange={(e) => setFromStatus(e.target.value)}
                className="mt-1 block w-full rounded border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              >
                {statuses.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="to-status" className="block text-sm font-medium text-gray-700">
                To Status <span className="text-red-500">*</span>
              </label>
              <select
                id="to-status"
                value={toStatus}
                onChange={(e) => setToStatus(e.target.value)}
                className="mt-1 block w-full rounded border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              >
                {statuses.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="transition-name" className="block text-sm font-medium text-gray-700">
                Transition Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="transition-name"
                value={transitionName}
                onChange={(e) => setTransitionName(e.target.value)}
                className="mt-1 block w-full rounded border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            {error && (
              <p className="text-sm text-red-600">{error}</p>
            )}

            <div className="mt-6 flex justify-end gap-3">
              <button
                type="button"
                onClick={handleClose}
                className="rounded bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
              >
                Add Transition
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default AddTransitionModal;