import { capitalize } from 'lodash'
import { useState } from 'react'

import { statuses } from 'modules/workflows'
import { getReviewers } from 'modules/workflows/workflow-review/helpers'
import { formatDate, isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import Actions from './actions'
import './workflow-table.css'

import { Table, Tbody, Td, Thead, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Badge from 'theme/ui/badge'

const WorkflowTable = ({ workflows }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	return (
		<Table className='workflows-outstandings'>
			<Thead className='hidden'>
				<Tr>
					<Td>Clients</Td>
					<Td>Assigned On</Td>
					<Td>Final Reviewer</Td>
					<Td>Reviewers</Td>
					<Td>Scores</Td>
					{/* <Td>Team</Td> */}
					<Td>Status</Td>
				</Tr>
			</Thead>
			<Tbody>
				{workflows.map((workflow, idx) => {
					const finalReviewer = workflow?.workflowFinalReviewer?.finalReviewer
					const status = statuses.find((status) => status?.name === workflow?.status)
					const reviewers = getReviewers(workflow?.reviewerMaps || [])

					return (
						<Tr
							key={idx}
							onMouseEnter={onMouseEnter ? () => onMouseEnter(idx) : null}
							onMouseLeave={onMouseLeave}>
							{/* <td className='w-[40px]'>
								<span>{idx + 1}.</span>
							</td> */}
							<Td className='px-3 md:w-[170px]'>
								<div className='flex flex-col'>
									{workflow?.user ? (
										<div className='flex items-center space-x-2'>
											<span
												className='line-clamp-1 max-w-fit font-ibmplex font-bold'
												title={`${workflow?.user?.first_name} ${workflow?.user?.last_name}`}>{`${workflow?.user?.first_name} ${workflow?.user?.last_name}`}</span>
										</div>
									) : undefined}
									<span className='text-xs text-gray-500'>
										{capitalize(workflow?.user_type)}
									</span>
								</div>
							</Td>
							<Td className='md:w-[170px]'>
								<span>{formatDate(workflow?.created_at)}</span>
							</Td>
							<Td>
								{finalReviewer ? (
									<span key={idx} className='text-sm font-semibold'>
										{finalReviewer?.first_name} {finalReviewer?.last_name}
									</span>
								) : (
									' '
								)}
							</Td>
							<Td>
								{reviewers.length > 0 ? (
									<div className='flex flex-wrap gap-2'>
										{reviewers.map((reviewer, idx) => (
											<span
												key={idx}
												className='rounded !border border-zinc-300 bg-zinc-100 px-2 py-1 text-xs font-semibold'>
												{reviewer?.full_name}
											</span>
										))}
									</div>
								) : (
									' '
								)}
							</Td>
							<Td>
								{workflow?.scores ? (
									<Badge variant='secondary'>
										{workflow?.scores?.docTitle} : {workflow?.scores?.total}
									</Badge>
								) : undefined}
							</Td>
							{/* <Td className='width_remove w-40'>
								<span>{team?.team_name}</span>
							</Td> */}
							<Td className='px-3 py-2'>
								{actionActiveId === idx &&
								isAllowTo(permissions?.workflowOutstandings?.view) ? (
									<Actions workflow={workflow} user_type={workflow?.user_type} />
								) : (
									<div className='flex items-center space-x-2 md:justify-end'>
										<span
											style={{
												backgroundColor: status?.color || '#74788D',
											}}
											className=' !h-3 !w-3 rounded-full'
										/>
										<span className='line-clamp-1' title={workflow?.status}>
											{workflow?.status}
										</span>
									</div>
								)}
							</Td>
						</Tr>
					)
				})}
			</Tbody>
		</Table>
	)
}

export default WorkflowTable
