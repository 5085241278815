import React, { useEffect, useState } from "react";
import { Clock, Edit, User, CheckCircle, FileText, MoveRight, Workflow } from "lucide-react";
import { useLazyGetTaskHistoryQuery } from "modules/tasks/task-api";
import Spinner from 'theme/ui/spinner'

const getIcon = (type) => {
  switch (type) {
    case "Status":
      return <CheckCircle className="text-green-500" size={18} />;
    case "Description":
      return <FileText className="text-blue-500" size={18} />;
    case "Title":
      return <FileText className="text-blue-500" size={18} />;
    case "Assignee":
      return <User className="text-purple-500" size={18} />;
    case "Client":
      return <User className="text-purple-500" size={18} />;
    case "Watcher":
        return <User className="text-purple-500" size={18} />;
    case "Workflow":
        return <Workflow className="text-purple-500" size={18} />;
    default:
      return <Edit className="text-gray-500" size={18} />;
  }
};

const TaskHistory = ({ taskId }) => {
  const [fetchHistory, { data, isLoading, error }] = useLazyGetTaskHistoryQuery();
  const [isFetching, setIsFetching] = useState(true);

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  useEffect(() => {
    if (taskId) {
      setIsFetching(true);
      fetchHistory(taskId).finally(() => setIsFetching(false));
    }
  }, [taskId, fetchHistory]);

  if (isFetching || isLoading) {
    return <p className="text-sm text-gray-500"><Spinner label={'Loading history...'} /></p>;
  }

  if (error) {
    return <p className="text-sm text-red-500">Error loading history.</p>;
  }

  if (!data || data.length === 0) {
    return <p className="text-sm text-gray-500">No history available.</p>;
  }
  return (
    <div>
      <div className="space-y-4 max-h-[198px] overflow-auto">
        {data.map((entry, index) => (
          <div key={index} className="flex items-start space-x-3 p-3 border-b">
            <div className="flex-shrink-0">{getIcon(entry.field)}</div>
            <div className="flex-1">
              <p className="text-sm text-gray-800 mb-0.5">
                <span className="font-semibold">{entry.changed_by?.fullName || "Unknown User"}</span> {" "}
                {(!entry.old_value || entry.old_value === "null" || entry.old_value === "") ? "Added" : entry.action}{" "}
                <span className="font-semibold capitalize">{entry.field}</span>
              </p>

              <p className="text-xs text-gray-600 mb-0.5">
                {entry.old_value && entry.old_value !== "null" && entry.old_value !== "" ? (
                  typeof entry.old_value === "object" ? (
                    <>
                      <span className="line-through text-gray-500">
                        {entry.field === "Status"
                          ? entry.old_value.name || "Unknown"
                          : entry.field === "Watcher" && Array.isArray(entry.old_value)
                          ? entry.old_value.map((watcher) => watcher.fullName || "Unknown").join(" , ")
                          : entry.old_value.fullName || "Unknown"}
                      </span>{" "}
                      <MoveRight size={15} className="inline mx-1 text-gray-500" />{" "}
                    </>
                  ) : (
                    <>
                      <span className="line-through text-gray-500">
                        {entry.field === "Due Date"
                          ? formatDate(entry.old_value)
                          : entry.field === "Status"
                          ? entry.old_value.name || "Unknown"
                          : entry.old_value}
                      </span>{" "}
                      <MoveRight size={15} className="inline mx-1 text-gray-500" />{" "}
                    </>
                  )
                ) : null}

                <span className="text-gray-900">
                  {typeof entry.new_value === "object"
                    ? entry.field === "Status"
                      ? entry.new_value.name || "Unknown"
                      : entry.field === "Watcher" && Array.isArray(entry.new_value)
                      ? entry.new_value.map((watcher) => watcher.fullName || "Unknown").join(", ")
                      : entry.new_value.fullName || "Unknown"
                    : entry.field === "Due Date"
                    ? formatDate(entry.new_value)
                    : entry.field === "Status"
                    ? entry.new_value.name || "Unknown"
                    : entry.new_value}
                </span>
              </p>

              <p className="text-xs text-gray-500 flex items-center mb-0.5">
                <Clock size={14} className="mr-1" /> {formatDate(entry.changed_at)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskHistory;
