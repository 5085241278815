import React from 'react'
import { Button } from 'theme/ui/forms'

function WorkflowToolbar({ onAddStatus, onAddTransition, showLabels, onToggleLabels }) {
	return (
		<div className='mb-4 flex items-center gap-4 rounded border bg-gray-50 p-3 shadow-sm'>
			<Button onClick={onAddStatus} type='button' disabled={true}>
				+ Add Status
			</Button>
			<Button type='button' onClick={onAddTransition} disabled={true}>
				+ Add Transition
			</Button>
			<div className='ml-auto flex items-center gap-2 '>
				<input
					type='checkbox'
					id='show-labels'
					checked={showLabels}
					onChange={onToggleLabels}
					className='form-check-input m-0 '
				/>
				<label htmlFor='show-labels' className='m-0 '>
					Show transition labels
				</label>
			</div>
			{/* Add Zoom/Export buttons here later */}
		</div>
	)
}

export default WorkflowToolbar
