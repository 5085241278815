import { useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import { generateUniqueId } from 'utilities/helpers'

const RatingField = ({
	input,
	meta: { touched, error },
	label,
	isRequired,
	infoText,
	ratingCount,
	isTooltip = false,
	tooltipIcon = null,
	tooltipText = '',
}) => {
	const [selectedRating, setSelectedRating] = useState(input.value || 0)
	const tooltipId = generateUniqueId()

	const handleRatingChange = (rating) => {
		const newRating = selectedRating === rating ? 0 : rating
		setSelectedRating(newRating)
		input.onChange(newRating) // Use redux-form's onChange
	}

	return (
		<div className='form-group flex flex-col'>
			{label ? (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label} {isRequired ? <span className='text-red-500'>*</span> : undefined}
						{isTooltip && (
							<>
								<ToolTip
									targetId={`rating-tooltip-1`}
									tooltipText={tooltipText}
									placement='top'
								/>
								<i
									id={`rating-tooltip-1`}
									className={`${
										tooltipIcon ? tooltipIcon : ''
									} ms-2 cursor-pointer`}></i>
							</>
						)}
					</span>
				</label>
			) : undefined}

			<div className='flex items-center space-x-2' id={tooltipId}>
				{Array.from({ length: ratingCount }).map((_, index) => (
					<span
						key={index}
						onClick={() => handleRatingChange(index + 1)}
						className='cursor-pointer text-2xl'>
						<i
							className={`${
								selectedRating > index
									? 'fas fa-star text-yellow-400'
									: 'far fa-star text-gray-400'
							}`}></i>
					</span>
				))}
			</div>

			{infoText ? <span className='text-[13px] text-gray-400'>{infoText}</span> : null}

			{touched && error ? (
				<span className=' mt-1 w-full text-[87.5%] text-[#f46a6a]'>{error}</span>
			) : undefined}
		</div>
	)
}

export default RatingField
