import { TableFilterProvider } from 'contexts/table-filter-context'
import { useAppRouter } from 'hooks'
import { useLazyGetBoardQuery } from 'modules/scrum-boards/boards-api'
import { BOARD_ROUTES } from 'modules/scrum-boards/routes'
import { Header } from 'modules/tasks'
import TaskListingContainer from 'modules/tasks/task-listing'
import { useEffect } from 'react'
import AppLayout from 'theme/layouts/app-layout'

const BoardListView = () => {
	const { params } = useAppRouter()
	const boardId = params?.boardId
	const [fetchBoard, { data }] = useLazyGetBoardQuery()
	useEffect(() => {
		if (boardId) {
			fetchBoard(boardId)
		}
	}, [boardId])

	const board = data?.data

	return (
		<AppLayout
			meta={{
				title: board?.name,
				hasBackButton: true,
				redirectTo: `${BOARD_ROUTES.findAll()}?page=1&limit=6`,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<Header board={board} />

					<div className='page-body'>
						<TableFilterProvider>
							<TaskListingContainer boardId={boardId} board={board} />
						</TableFilterProvider>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default BoardListView
