import { useMemo } from 'react'

import infinityIcon from 'assets/svg/infinity-svgrepo-com.svg'
import { isObject } from 'lodash'
import ProgressBar from 'theme/ui/progress-bar'
import { getRemainingDays } from '.'

const UsageSubscription = ({ $subscription }) => {
	const { remainingDays, remainingPercentage, subscriptionDays } = useMemo(
		() => getRemainingDays($subscription),
		[$subscription],
	)

	return (
		<div>
			<div className='!mb-3 flex items-center justify-between'>
				<h3 className='m-0 text-lg font-medium text-gray-600'>Usage</h3>
			</div>

			<div className='grid-col-1 grid gap-4 sm:grid-cols-3'>
				{$subscription?.subscription?.userHasSubscription?.metadata?.max_users && (
					<div className='rounded-lg !border !p-4'>
						<div className='flex flex-col space-y-6'>
							<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
								<span className='uil-users-alt text-xl' />
							</div>

							<div>
								<div className='!mb-3 flex items-baseline justify-between'>
									<span className='text-[13px] text-gray-500'>Total Users</span>
									<span className='flex text-sm font-semibold text-gray-950'>
										{$subscription?.user_counts} of{' '}
										{$subscription?.subscription?.userHasSubscription?.metadata
											?.max_users || (
											<img
												src={infinityIcon}
												alt='infinity'
												className='ml-1 h-5'
											/>
										)}
									</span>
								</div>
								<ProgressBar
									value={
										$subscription?.subscription?.userHasSubscription?.metadata
											?.max_users
											? Math.min(
													($subscription?.user_counts /
														$subscription?.subscription
															?.userHasSubscription?.metadata
															?.max_users) *
														100,
													100,
												)
											: 100
									}
									variant={
										$subscription?.subscription?.userHasSubscription?.metadata
											?.max_users &&
										($subscription?.user_counts /
											$subscription?.subscription?.userHasSubscription
												?.metadata?.max_users) *
											100 >=
											90
											? 'danger'
											: 'primary'
									}
								/>
							</div>
						</div>
					</div>
				)}

				{$subscription?.subscription?.userHasSubscription?.metadata?.max_pdf_views && (
					<div className='rounded-lg !border !p-4'>
						<div className='flex flex-col space-y-6'>
							<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
								<span className='uil-document-layout-right text-xl' />
							</div>

							<div>
								<div className='!mb-3 flex items-baseline justify-between'>
									<span className='text-[13px] text-gray-500'>
										PDF Access count
									</span>
									<span className='flex text-sm font-semibold text-gray-950'>
										{$subscription?.pdf_access_count} of{' '}
										{$subscription?.subscription?.userHasSubscription?.metadata
											?.max_pdf_views || (
											<img
												src={infinityIcon}
												alt='infinity'
												className='ml-1 h-5'
											/>
										)}
									</span>
								</div>
								<ProgressBar
									value={
										$subscription?.subscription?.userHasSubscription?.metadata
											?.max_pdf_views
											? Math.min(
													($subscription?.pdf_access_count /
														$subscription?.subscription
															?.userHasSubscription?.metadata
															?.max_pdf_views) *
														100,
													100,
												)
											: 100
									}
									variant={
										$subscription?.subscription?.userHasSubscription?.metadata
											?.max_pdf_views &&
										($subscription?.pdf_access_count /
											$subscription?.subscription?.userHasSubscription
												?.metadata?.max_pdf_views) *
											100 >=
											90
											? 'danger'
											: 'primary'
									}
								/>
							</div>
						</div>
					</div>
				)}
				{$subscription?.subscription?.userHasSubscription?.metadata?.max_workflows && (
					<div className='rounded-lg !border !p-4'>
						<div className='flex flex-col space-y-6'>
							<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
								<span className='uil-channel text-xl' />
							</div>

							<div>
								<div className='!mb-3 flex items-baseline justify-between'>
									<span className='text-[13px] text-gray-500'>
										Number of Workflows
									</span>
									<span className='flex text-sm font-semibold text-gray-950'>
										{!isObject($subscription?.workflow_count)
											? $subscription?.workflow_count
											: 0}{' '}
										of{' '}
										{$subscription?.subscription?.userHasSubscription?.metadata
											?.max_workflows || (
											<img
												src={infinityIcon}
												alt='infinity'
												className='ml-1 h-5'
											/>
										)}
									</span>
								</div>
								<ProgressBar
									value={
										$subscription?.subscription?.userHasSubscription?.metadata
											?.max_workflows
											? Math.min(
													($subscription?.workflow_count /
														$subscription?.subscription
															?.userHasSubscription?.metadata
															?.max_workflows) *
														100,
													100,
												)
											: 100
									}
									variant={
										$subscription?.subscription?.userHasSubscription?.metadata
											?.max_workflows &&
										($subscription?.workflow_count /
											$subscription?.subscription?.userHasSubscription
												?.metadata?.max_workflows) *
											100 >=
											90
											? 'danger'
											: 'primary'
									}
								/>
							</div>
						</div>
					</div>
				)}

				{$subscription?.subscription?.userHasSubscription?.metadata
					?.max_document_templates && (
					<div className='rounded-lg !border !p-4'>
						<div className='flex flex-col space-y-6'>
							<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
								<span className='uil-file-alt text-xl' />
							</div>

							{
								<div>
									<div className='!mb-3 flex items-baseline justify-between'>
										<span className='text-[13px] text-gray-500'>
											Number of Document Templates
										</span>
										<span className='flex text-sm font-semibold text-gray-950'>
											{$subscription?.document_count} of{' '}
											{$subscription?.subscription?.userHasSubscription
												?.metadata?.max_document_templates || (
												<img
													src={infinityIcon}
													alt='infinity'
													className='ml-1 h-5'
												/>
											)}
										</span>
									</div>
									<ProgressBar
										value={
											$subscription?.subscription?.userHasSubscription
												?.metadata?.max_document_templates
												? Math.min(
														($subscription?.document_count /
															$subscription?.subscription
																?.userHasSubscription?.metadata
																?.max_document_templates) *
															100,
														100,
													)
												: 100
										}
										variant={
											$subscription?.subscription?.userHasSubscription
												?.metadata?.max_document_templates &&
											($subscription?.document_count /
												$subscription?.subscription?.userHasSubscription
													?.metadata?.max_document_templates) *
												100 >=
												90
												? 'danger'
												: 'primary'
										}
									/>
								</div>
							}
						</div>
					</div>
				)}

				<div className='rounded-lg !border !p-4'>
					<div className='flex flex-col space-y-6'>
						<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
							<span className='uil-calendar-alt text-xl' />
						</div>

						<div>
							<div className='!mb-3 flex items-baseline justify-between'>
								<span className='text-[13px] text-gray-500'>Remaining days</span>
								<span className='text-sm font-semibold text-gray-950'>
									{remainingDays} of {subscriptionDays}
								</span>
							</div>

							<ProgressBar
								value={remainingPercentage}
								variant={
									remainingPercentage < 10
										? 'danger'
										: remainingPercentage < 75
											? 'warning'
											: 'primary'
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UsageSubscription
