import TaskHistory from 'modules/tasks/task-form/audit-logs/audit-logs'
import TaskComments from 'modules/tasks/task-form/task-comments/task-comments'
import { useState } from 'react'

const TaskTabs = ({ taskId }) => {
    const [activeTab, setActiveTab] = useState('Comments')

    const handleTabChange = (tab) => (e) => {
        e.preventDefault() 
        setActiveTab(tab)
    }

    return (
        <div className=''>
            <span className="text-sm font-bold text-gray-700 flex text-center items-center mb-2">
                Activity
            </span>

            {/* Tabs Navigation */}
            <div className='flex items-center justify-start gap-2'>
                <button
                    type="button" 
                    className={`py-1.5 px-3 rounded-md text-sm font-medium transition ${
                        activeTab === 'Comments'
                            ? 'bg-blue-100 text-blue-600'
                            : 'text-gray-600 bg-gray-100 hover:bg-gray-200'
                    }`}
                    onClick={handleTabChange('Comments')}
                >
                    Comments
                </button>

                <button
                    type="button" 
                    className={`py-1.5 px-3 rounded-md text-sm font-medium transition ${
                        activeTab === 'History'
                            ? 'bg-blue-100 text-blue-600'
                            : 'text-gray-600 bg-gray-100 hover:bg-gray-200'
                    }`}
                    onClick={handleTabChange('History')}
                >
                    History
                </button>
            </div>

            {/* Tabs Content */}
            <div className='mt-2'>
                {activeTab === 'History' && <TaskHistory taskId={taskId} />}
                {activeTab === 'Comments' && <TaskComments taskId={taskId} />}
            </div>
        </div>
    )
}

export default TaskTabs