import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import React from 'react'

import { validationSchema } from '.'
import CustomFieldArray from './custom-field-array'

const CustomFieldForm = ({
	client,
	meta,
	fields,
	onPageChange,
	setPagination,
	isLoading,
	onAdd,
}) => {
	const formik = useFormik({
		initialValues: {
			fields: fields,
			user: client?.id,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async () => {},
	})
	const isDisable = !formik.dirty || !formik.isValid
	const isEnable = !isDisable

	return (
		<FormikProvider value={formik}>
			<Form>
				<FieldArray
					name='fields'
					render={({ push, remove, name }) => (
						<CustomFieldArray
							fields={fields}
							onAdd={onAdd}
							isLoading={isLoading}
							name={name}
							push={push}
							remove={remove}
							meta={meta}
							onPageChange={onPageChange}
							setPagination={setPagination}
							isEnable={isEnable}
							formik={formik}
						/>
					)}
				/>
			</Form>
		</FormikProvider>
	)
}

export default CustomFieldForm
