import classNames from 'classnames'
import { AsyncImage, Pagination } from 'components/Common'
import { useHistory } from 'react-router-dom'

import { useEffect, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'
import Spinner from 'theme/ui/spinner'
import { dateFormat, getPaginationParams, getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import {
	useLazyGetNotificationsQuery,
	useToggleReadMutation,
	useUpdateNotificationMutation,
} from '../notification-api'
import { useRedirectBasedOnType } from './use-redirection'
const NotificationsList = ({ isReadAllPage, setUnreadCount }) => {
	const { page, limit } = useMemo(() => getPaginationParams(location.search), [location.search])
	const parseQueryString = useMemo(() => getParseQueryString(location.search), [location.search])
	const history = useHistory()
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	const [fetchNotifications, { data, isLoading }] = useLazyGetNotificationsQuery()

	useEffect(() => {
		const queryString = toQueryString({
			...parseQueryString,
			page: pageIndex + 1,
			limit: pageSize,
		})
		if (history.location.pathname === '/notifications') {
			history.replace(`${location.pathname}?${queryString}`)
		}
	}, [pagination, parseQueryString, history, location.pathname, pageIndex, pageSize])
	useEffect(() => {
		const fetchData = () => {
			fetchNotifications({ page: pageIndex + 1, limit: pageSize })
		}

		fetchData()

		const intervalId = setInterval(fetchData, 60000)

		return () => clearInterval(intervalId)
	}, [fetchNotifications, pageIndex, pageSize])
	useEffect(() => {
		if (data?.unreadNotificationCount && setUnreadCount) {
			setUnreadCount(data?.unreadNotificationCount)
		}
	}, [data])

	const onPageChange = (e, page) => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
		setPagination((prev) => ({ ...prev, pageIndex: page - 1 }))
	}
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const redirectBasedOnType = useRedirectBasedOnType()
	const [toggleRead] = useToggleReadMutation()
	const [updateNotification] = useUpdateNotificationMutation()

	const onReadChange = async (checked, id, isReadOnly = false) => {
		try {
			if (isReadOnly) {
				await updateNotification({ payload: { is_read: 1 }, id })
			} else {
				const payload = { status: checked ? 1 : 0 }
				await toggleRead({ payload, id })
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	if (isLoading) {
		return (
			<div className='py-2'>
				<Spinner />{' '}
			</div>
		)
	}
	return (
		<>
			<SimpleBar
				style={{ height: isReadAllPage ? '' : '' }}
				className={`notification_mobile bg-gray-100 ${isReadAllPage ? ' ' : 'max-h-80'}`}>
				{data?.notifications?.length > 0 ? (
					<div className='flex flex-col divide-y'>
						{data?.notifications?.map((notification, ind) => {
							return (
								<div
									className={classNames(
										'text-reset relative cursor-pointer !px-3 !py-3 transition-all hover:bg-main/10 hover:!shadow ',
										{
											'mb-2 p-2 py-3': isReadAllPage,
										},
										notification?.is_read
											? 'bg-gray-100'
											: 'bg-white shadow shadow-slate-300',
									)}
									onMouseEnter={() => onMouseEnter(ind)}
									onMouseLeave={onMouseLeave}
									key={notification?.id}>
									<div
										className='d-flex align-items-start'
										onClick={() => {
											onReadChange(true, notification?.id, true)
											redirectBasedOnType(
												notification?.type,
												notification?.link,
											)
										}}>
										<div className='avatar-xs me-3'>
											{notification?.profilePhoto ? (
												<AsyncImage
													className='rounded-circle h-full w-full '
													src={notification?.profilePhoto}
													alt='Header Avatar'
												/>
											) : (
												<span className='avatar-title bg-primary rounded-circle font-size-16'>
													<i className='uil-comment-alt-message'></i>
												</span>
											)}
										</div>
										<div className='flex-1 '>
											<h5 className='mb-1 mt-0 text-sm font-semibold text-black '>
												{notification?.title}
											</h5>
											<div className=''>
												<div
													className='mb-1 text-[13px] text-gray-600'
													dangerouslySetInnerHTML={{
														__html: notification?.message,
													}}
												/>

												<p className='mb-0 text-xs text-gray-500'>
													<i className='mdi mdi-clock-outline'></i>{' '}
													{dateFormat(notification?.created_at)}
												</p>
											</div>
										</div>
									</div>
									{actionActiveId === ind &&
										(!notification?.is_read ? (
											<span
												className='bx bx-radio-circle absolute right-2 top-3 m-0 rounded !border-black p-0 px-1 text-2xl text-blue-600 '
												title='Mark as read'
												onClick={() =>
													onReadChange(
														notification?.is_read,
														notification.id,
													)
												}></span>
										) : (
											<span
												className='bx bx-radio-circle-marked absolute right-2 top-3 m-0 rounded !border-black p-0 px-1 text-2xl text-blue-600'
												title='Mark as unread'
												onClick={() =>
													onReadChange(
														notification?.is_read,
														notification.id,
													)
												}></span>
										))}
								</div>
							)
						})}
					</div>
				) : (
					<div className='flex justify-center'>
						<span>No notifications found!</span>
					</div>
				)}
			</SimpleBar>
			{!isReadAllPage || data?.notifications?.length == 0 ? (
				''
			) : (
				<div className='mt-4 flex justify-center '>
					<Pagination
						total={data?.meta?.totalRecords}
						limit={data?.meta?.limit}
						currentPage={data?.meta?.currentPage}
						onPageChange={onPageChange}
					/>
				</div>
			)}
		</>
	)
}

export default NotificationsList
