import { FieldWrapper } from '../components'
import DateFormator from '../components/date-formator'

const DateFieldEdit = ({ name, field, onDateChange, userOptions }) => {
	return (
		<FieldWrapper name={name} userOptions={userOptions}>
			<DateFormator field={field} name={name} onDateChange={onDateChange} />
		</FieldWrapper>
	)
}

export default DateFieldEdit
