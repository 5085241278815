/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */

import { getFieldsByUser } from 'pages/Authentication/profile/components/workflow/utils'
import { createSelectOption } from 'utilities/helpers'
import { flattenArray, showErrorToast, toJson } from 'utils/commonFunctions'
import { userTypes } from '..'

export const findUploadSteps = (steps) => {
	if (Array.isArray(steps)) {
		return steps.filter((item) => item?.type === 'uploads')
	} else {
		return []
	}
}

export const getReviewFileTabs = (uploads) => {
	if (Array.isArray(uploads) && uploads[0]?.note) {
		const tabsValue = toJson(uploads[0]?.note).map((item) => item?.user_type)
		return userTypes.filter((item) => tabsValue.includes(item?.value))
	} else {
		return []
	}
}

export const getUploadsReview = (uploads) => {
	if (Array.isArray(uploads)) {
		return uploads.map((item) => {
			const fileTypes = toJson(item?.note)
			return {
				file: item?.files,
				info: fileTypes,
			}
		})
	} else {
		return []
	}
}

// remove duplicate objects by key from array
export const getUniqueListBy = (arr, key) => {
	return [...new Map(arr.map((item) => [item[key], item])).values()]
}

// combine all the fields of document into singal array
export const getFieldsArray = (array) => {
	if (Array.isArray(array)) {
		const withDocumentTitle = array.map((document) => ({
			...document,
			document_body: {
				...document?.document_body,
				document: Array.isArray(document?.document_body?.document)
					? document?.document_body?.document.map((doc) => ({
							...doc,
							document_title: document?.title,
						}))
					: [],
			},
		}))

		const mappedArray = withDocumentTitle.map((item) => item?.document_body?.document || [])
		return flattenArray(mappedArray)
	} else {
		return []
	}
}

export const getReviewFields = (array) => {
	if (Array.isArray(array)) {
		const fieldsArray = getFieldsArray(array)
		return fieldsArray
			.filter(
				(value, index, self) =>
					index ===
					self.findIndex((t) => t.user_type === value.user_type && t.name === value.name),
			)
			.filter(
				(field) =>
					field?.type !== 'signature' &&
					(field?.type !== 'timestamp' || !field?.linked_to),
			)
	} else {
		return []
	}
}

export const addStatusToFields = (array, values) => {
	if (Array.isArray(array) && Array.isArray(values)) {
		return array.map((field) => {
			let fieldObj = { ...field }

			const value = values.find(
				(i) => i?.field_name === field?.name && i?.user_type === field?.user_type,
			)
			if (value) {
				Object.assign(fieldObj, {
					status: value?.status,
					value: value?.field_value,
				})
			}

			return fieldObj
		})
	} else {
		return []
	}
}

export const addStatusToFiles = (array, values, user_type) => {
	if (Array.isArray(array) && Array.isArray(values)) {
		return array.map((file) => {
			let fileObj = { ...file }

			const value = values.find((i) => i?.title === file?.title && i?.user_type === user_type)
			if (value) {
				Object.assign(fileObj, { correction: value?.status === 'approved' ? false : true })
			}
			return fileObj
		})
	} else {
		return []
	}
}

export const getAllFiles = (uploads) => {
	if (Array.isArray(uploads)) {
		const files = uploads.map((upload) =>
			upload?.files.map((file) => ({ ...file, client_type: upload?.client_type })),
		)
		return flattenArray(files)
	} else {
		return []
	}
}

export const AUTO_APPROVED = 'autoApproved'
export const MANUAL_APPROVED = 'manualApproved'
export const DOCUMENT_BOUND = 'documentBound'

export const fillValueBasedOnFieldType = (field) => {
	let fieldValue = field?.value

	const selectLikeTypes = ['select', 'list', 'combobox', 'dropdown']
	if (selectLikeTypes.includes(field?.type)) {
		const options = Array.isArray(field?.values) ? field?.values : []

		if (Array.isArray(field?.value)) {
			fieldValue = field.value.map((val) => {
				const option = options.find((o) => o?.value === val)
				return option || createSelectOption(val)
			})
		} else if (typeof field?.value === 'string') {
			const option = options.find((o) => o?.value === field?.value)
			fieldValue = option || createSelectOption(field?.value)
		} else if (field?.value && typeof field?.value === 'object') {
			fieldValue = field.value
		}
	}

	if (field?.type === 'checkbox' && field?.values?.length > 1) {
		fieldValue = field?.value?.split(',') || []
	}

	if (field?.type === 'checkbox' && field?.values?.length === 1) {
		fieldValue = field?.value || false
	}

	return {
		field_name: field?.name,
		field_value: fieldValue,
		user_type: field?.user_type,
		status: field?.status || 'approved',
	}
}

export function getInitialValues(individualDocument, uploads, user_type) {
	try {
		const fields = getReviewFields(individualDocument?.documents).filter(
			(field) => field?.user_type === user_type,
		)
		const documentBoundFields = getDocumentBoundFields(individualDocument?.documents, user_type)
		const files = getAllFiles(uploads)

		const userTypes = uploads.map((item) => item?.client_type)
		const reviews = uploads.map((item) => item?.review_notes)
		const filesReview = {}
		userTypes.forEach((el, idx) => {
			Object.assign(filesReview, { [el]: reviews[idx] })
		})

		const fieldsReview = individualDocument?.review_notes

		const initialValues = {
			fields: {
				[MANUAL_APPROVED]: fields
					.filter(
						(field) =>
							field?.isDefaultApproved === false &&
							field?.is_document_bound === false,
					)
					.map((field) => fillValueBasedOnFieldType(field)),
				[AUTO_APPROVED]: fields
					.filter(
						(field) =>
							field?.isDefaultApproved === true && field?.is_document_bound === false,
					)
					.map((field) => fillValueBasedOnFieldType(field)),
				[DOCUMENT_BOUND]: documentBoundFields.map((doc) => ({
					fields: doc?.fields.map((field) => fillValueBasedOnFieldType(field)),
				})),
			},
			files: files.map((file) => ({
				title: file?.title,
				user_type: file?.client_type,
				status: file?.correction ? 'reject' : 'approved',
			})),
			files_notes: filesReview,
			fields_notes: fieldsReview,
		}
		return initialValues
	} catch (error) {
		//
	}
}

export function preparePayload(workflow, values) {
	try {
		const payload = { title: `${Math.random()}` }
		const fieldValues = [...values?.fields[AUTO_APPROVED], ...values?.fields[MANUAL_APPROVED]]
		const documentBoundFieldValues = values?.fields[DOCUMENT_BOUND]

		payload.steps = workflow?.workflow_steps.map((step) => {
			let stepObj = { id: step?.id, notify: step?.notify, delete: false }

			if (step?.type === 'uploads') {
				stepObj.files = addStatusToFiles(step?.files, values?.files, step?.client_type)
				stepObj.type = 'uploads'
				stepObj.status =
					values?.files.filter(
						(file) =>
							file?.status === 'reject' && file?.user_type === step?.client_type,
					).length > 0
						? 'correction'
						: null
				stepObj.review_notes = values?.files_notes[step?.client_type] || null
			}

			if (step?.type === 'individual_document') {
				stepObj['documents'] = step?.documents
					.map((item, idx) => {
						if (Array.isArray(item?.document_body?.document)) {
							const docBoundFields = documentBoundFieldValues[idx].fields

							const $fillFieldValues = addStatusToFields(
								item?.document_body?.document,
								fieldValues,
								false,
							)
							const updated_doc = addStatusToFields(
								$fillFieldValues,
								docBoundFields,
								true,
							)

							return {
								id: item?.id,
								title: item?.title,
								document_body: updated_doc,
							}
						}
					})
					.filter((item) => item)

				stepObj['rejected_fields'] = stepObj['documents'].map((item) => {
					const rejected_fields = item?.document_body.filter(
						(doc) => doc?.status === 'reject',
					)
					return {
						...item,
						document_body: rejected_fields
							.filter(
								(f) =>
									f?.is_document_bound === false ||
									typeof f?.is_document_bound === 'undefined',
							)
							.map((field) => field?.label),
						document_bound_body: rejected_fields
							.filter((f) => f?.is_document_bound === true)
							.map((field) => field?.label),
					}
				})

				stepObj.type = 'individual_document'
				stepObj.status =
					fieldValues.filter((field) => field?.status === 'reject').length > 0
						? 'correction'
						: null
				stepObj.review_notes = values?.fields_notes
			}

			return stepObj
		})
		return payload
	} catch (error) {
		showErrorToast('Something went wrong while processing request.')
	}
}

export const getReviewers = (reviewers) => {
	return reviewers.map((reviewer) => ({
		id: reviewer?.reviewer?.id,
		full_name: `${reviewer?.reviewer?.first_name} ${reviewer?.reviewer?.last_name}`,
		email: reviewer?.reviewer?.email,
	}))
}

// get document bound fields document wise
export const getDocumentBoundFields = (documents, user_type) => {
	try {
		if (Array.isArray(documents)) {
			return documents.map((document) => {
				const fields = document?.document_body?.document.filter(
					(field) =>
						field.is_document_bound === true &&
						field?.type !== 'signature' &&
						(field?.type !== 'timestamp' || !field?.linked_to),
				)
				const fieldbyUser = getFieldsByUser(fields, user_type)

				return {
					id: document?.id,
					title: document?.title,
					fields: Array.isArray(fieldbyUser) ? fieldbyUser : [],
				}
			})
		} else {
			return []
		}
	} catch (error) {
		console.log('error', error)
		return []
	}
}
