import { useAppRouter } from 'hooks'
import { Edit, MoreVertical, Trash2, X } from 'lucide-react'
import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import Swal from 'sweetalert2'
import { formatDate } from 'utilities/helpers'
import AddBoardModal from './AddBoardModal'
import { useDeleteboardsMutation } from './boards-api'
import { BOARD_ROUTES } from './routes'
import ScrumBoardSkeleton from './sekeleton-boards'

const ScrumBoardContent = ({ boards, setBoards, isLoading }) => {
	const [openModal, setOpenModal] = useState(false)
	const [selectedBoard, setSelectedBoard] = useState(null)
	const [dropdownOpen, setDropdownOpen] = useState(null)
	const [deleteboards] = useDeleteboardsMutation()
	const { navigate } = useAppRouter()

	const toggleDropdown = (boardId) => {
		setDropdownOpen(dropdownOpen === boardId ? null : boardId)
	}

	const handleEdit = (board) => {
		setSelectedBoard(board)
		setOpenModal(true)
		setDropdownOpen(null)
	}

	const handleDelete = (value, id) => {
		setDropdownOpen(null)

		Swal.fire({
			title: 'Are you sure?',
			text: 'This board will be permanently deleted!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				deleteboards({ id, payload: value })
				Swal.fire('Deleted!', 'Your board has been deleted.', 'success')
			}
		})
	}

	const handleBoardClick = (e, boardId) => {
		if (e.target.closest('.dropdown')) return
		navigate(BOARD_ROUTES.findOne(boardId))
	}

	return (
		<div className='pt-2 pb-2'>
			{isLoading ? (
				<ScrumBoardSkeleton />
			) : boards.length === 0 ? (
				<div className='text-center text-gray-500 text-base mt-10'>
					No board is added yet. Click "Add Board" to create one!
				</div>
			) : (
				<div className='grid grid-cols-1 md:grid-cols-3 gap-6 hover:cursor-pointer'>
					{boards.map((board) => (
						<div
							key={board.id}
							onClick={(e) => handleBoardClick(e, board.id)}
							className='bg-white hover:shadow-md rounded-lg p-4 min-h-[230px] flex flex-col justify-between relative'>
							<div
								className='absolute top-6 right-3'
								onClick={(e) => e.stopPropagation()}>
								<Dropdown
									isOpen={dropdownOpen === board.id}
									toggle={() => toggleDropdown(board.id)}>
									<DropdownToggle
										tag='button'
										className='bg-transparent border-0 p-0'>
										{dropdownOpen === board.id ? (
											<X className='text-gray-500 hover:text-black' />
										) : (
											<MoreVertical className='text-gray-500 hover:text-black' />
										)}
									</DropdownToggle>
									<DropdownMenu end className='shadow-md rounded-lg'>
										<DropdownItem
											onClick={(e) => {
												e.stopPropagation()
												handleEdit(board)
											}}
											className='d-flex align-items-center'>
											<Edit size={16} className='me-2 text-gray-700' />
											<span>Edit</span>
										</DropdownItem>
										<DropdownItem
											onClick={(e) => {
												e.stopPropagation()
												handleDelete(board, board.id)
											}}
											className='d-flex align-items-center text-danger'>
											<Trash2 size={16} className='me-2 text-danger' />
											<span>Delete</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>

							<div>
								<h3 className='text-lg font-semibold'>{board.name}</h3>
								<p className='text-sm text-gray-600 mt-2'>{board.description}</p>
							</div>

							<div className='mt-2 pt-2 border-t border-gray-200 flex flex-row justify-between'>
								<div className='flex items-center'>
									{board.users.slice(0, 3).map((person, index) => {
										const colors = [
											'bg-blue-200 text-blue-900',
											'bg-green-200 text-green-900',
											'bg-red-200 text-red-900',
											'bg-yellow-200 text-yellow-900',
											'bg-purple-200 text-purple-900',
											'bg-indigo-200 text-indigo-900',
											'bg-pink-200 text-pink-900',
										]
										const randomColor = colors[index % colors.length]
										const name = person.fullName
											? person.fullName
													.split(' ')
													.map((word) => word[0])
													.slice(0, 2)
													.join('')
													.toUpperCase()
											: '?'

										return (
											<div
												key={index}
												className={`w-8 h-8 flex items-center justify-center rounded-full text-xs font-semibold ${randomColor} border-2 border-white ${
													index > 0 ? "-ml-2" : ""
												}`}
												>
												{person.avatar?.path ? (
													<img
													src={person.avatar.path}
													alt={person.fullName}
													className="w-full h-full rounded-full border-2 border-white object-cover"
													/>
												) : (
													<span className="w-full h-full flex items-center justify-center">
													{(person.fullName || "?")
														.split(" ")
														.map((word) => word[0])
														.slice(0, 2)
														.join("")
														.toUpperCase()}
													</span>
												)}
											</div>

										)
									})}

									{board.users.length > 3 && (
										<div className='w-8 h-8 flex items-center justify-center rounded-full text-xs font-semibold bg-gray-300 text-gray-700 border border-white'>
											+{board.users.length - 3}
										</div>
									)}
								</div>

								<div className='flex flex-row gap-1'>
									<span className='text-xs text-gray-500 mt-2'>Edited:</span>
									<span className='text-xs font-bold text-black-600 mt-2'>
										{formatDate(board.updated_at)}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			)}

			<AddBoardModal
				open={openModal}
				onClose={() => {
					setOpenModal(false)
					setSelectedBoard(null)
				}}
				addBoard={(board) => {
					if (selectedBoard) {
						setBoards((prev) =>
							prev.map((b) => (b.id === selectedBoard.id ? { ...b, ...board } : b)),
						)
					} else {
						setBoards((prev) => [...prev, board])
					}
					setOpenModal(false)
					setSelectedBoard(null)
				}}
				boardToEdit={selectedBoard}
			/>
		</div>
	)
}

export default ScrumBoardContent
