import { useEffect } from 'react'
import Swal from 'sweetalert2'

const DocumentDeleteConfirmAlert = ({
	title,
	subtitle,
	isOpen,
	onClose,
	onConfirm,
	isDeleting,
	variant = 'danger',
	isLoadingText = 'Deleting...',
	deleteBtnText,
}) => {
	useEffect(() => {
		if (isOpen) {
			Swal.fire({
				title: title || 'Are you sure?',
				text: subtitle || "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: isDeleting ? isLoadingText : deleteBtnText || 'Yes',
				cancelButtonText: 'No',
				customClass: {
					confirmButton: `swal2-confirm ${
						variant === 'danger'
							? 'bg-green-600 hover:bg-green-700'
							: 'bg-green-600 hover:bg-green-700'
					} rounded-md text-white`,
					cancelButton:
						'swal2-cancel bg-red-600 hover:bg-red-700 text-white border border-red-600 rounded-md',
				},
				showLoaderOnConfirm: isDeleting,
				allowOutsideClick: false,
				preConfirm: async () => {
					if (isDeleting) return false
					const response = await onConfirm()
					if (response) {
						Swal.fire({
							title: 'Done!',
							text: 'Action completed successfully.',
							icon: 'success',
							confirmButtonText: 'OK',
						})
					} else {
						Swal.fire({
							title: 'Oops!',
							text: 'Something went wrong. Please try again.',
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					}
				},
				didClose: onClose,
			})
		}
	}, [isOpen])

	return null
}

export default DocumentDeleteConfirmAlert
