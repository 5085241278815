import TabsContent from './tabs-content'

export const userTypeColors = ['#FFF2CC', '#D9EAD3', '#C9DAF8', '#D9D2E9', '#EAD1DC', '#E6B8AF']
export const userTypeDarkColors = ['#997C66', '#73866C', '#637591', '#736E82', '#846C75', '#805448']

export const createUserTypeOption = (label, value, idx) => {
	return {
		label: (
			<span className='line-clamp-1 flex items-center'>
				<span
					className='mr-2 h-2.5 w-2.5 rounded-full'
					style={{
						backgroundColor: userTypeColors[idx],
						border: `1px solid ${userTypeDarkColors[idx]}`,
					}}
				/>
				{label}
			</span>
		),
		value: value,
		color: userTypeColors[idx],
		textColor: userTypeDarkColors[idx],
	}
}

export const getUserTypeOption = (numberOfUsers) => {
	const users = [
		{
			label: (
				<span className='line-clamp-1 flex items-center'>
					<span
						className='mr-2 h-2.5 w-2.5 rounded-full'
						style={{
							backgroundColor: userTypeColors[0],
							border: `1px solid ${userTypeDarkColors[0]}`,
						}}
					/>
					Advisor
				</span>
			),
			user: 'Advisor',
			value: 'advisor',
			color: userTypeColors[0],
			textColor: userTypeDarkColors[0],
		},
		{
			label: (
				<span className='line-clamp-1 flex items-center'>
					<span
						className='mr-2 h-2.5 w-2.5 rounded-full'
						style={{
							backgroundColor: userTypeColors[1],
							border: `1px solid ${userTypeDarkColors[1]}`,
						}}
					/>
					Client
				</span>
			),
			user: 'Client',
			value: 'client',
			color: userTypeColors[1],
			textColor: userTypeDarkColors[1],
		},
	]
	if (numberOfUsers > 1) {
		users.push(
			...new Array(numberOfUsers - 1).fill(0).map((_, idx) => ({
				label: (
					<span className='line-clamp-1 flex items-center'>
						<span
							className='mr-2 h-2.5 w-2.5 rounded-full'
							style={{
								backgroundColor: userTypeColors[(idx + 2) % userTypeColors.length],
								border: `1px solid ${userTypeDarkColors[(idx + 2) % userTypeColors.length]}`,
							}}
						/>
						Co Applicant {idx + 1}
					</span>
				),
				user: `Co Applicant ${idx + 1}`,
				value: `applicant_${idx + 1}`,
				color: userTypeColors[(idx + 2) % userTypeColors.length],
				textColor: userTypeDarkColors[(idx + 2) % userTypeColors.length],
			})),
		)
	}
	return users
}

export default TabsContent
